import { StartCardTitle } from "./StartCardTitle";
import "./StartCard.css";
import React from "react";
import { StartCardSubtitle } from "./StartCardSubtitle";
import { StartCardDescription } from "./StartCardDescription";
import { StartCardNote } from "./StartCardNote";
import { StartButton } from "./StartButton";
import StartCardSwitches from "./StartCardSwitches";
import { Divider } from "../../../styledComponents/Dividers";
import { StartCardLink } from "./StartCardLink";

const StartCard = (props) => {
  const {
    title,
    subtitle,
    description,
    buttonText,
    buttonFunc,
    note,
    switches,
    link,
  } = props;

  return (
    <div className="StartCard">
      <StartCardTitle>{title}</StartCardTitle>
      {subtitle && <StartCardSubtitle>{subtitle}</StartCardSubtitle>}
      {description && (
        <StartCardDescription>{description}</StartCardDescription>
      )}
      {switches && <StartCardSwitches switches={switches} />}
      <div className="StartCard__button-row">
        {buttonText && (
          <StartButton onClick={buttonFunc}>{buttonText}</StartButton>
        )}
      </div>
      <StartCardNote>{note}</StartCardNote>
      {link && (
        <>
          <Divider style={{ margin: "15px 0" }} />
          <StartCardLink>{link}</StartCardLink>
        </>
      )}
    </div>
  );
};

export default StartCard;
