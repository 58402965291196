import {
  USER_SENDWORKER_TRANSFER_ACCEPT,
  USER_SENDWORKER_TRANSFER_REJECT,
} from "../../../constants/urls";
import { getCookie } from "../../../utilits/cookies";
import loadEmployers from "./loadEmployers";

const transferAccept = (workerId, alert, isReject) => async (dispatch) => {
  const token = getCookie("token");
  try {
    const response = await fetch(
      isReject
        ? USER_SENDWORKER_TRANSFER_REJECT
        : USER_SENDWORKER_TRANSFER_ACCEPT,
      {
        body: JSON.stringify({ workerid: workerId }),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    const data = await response.json();
    if (response.ok) {
      dispatch(loadEmployers(alert));
    } else {
      throw Error(data.message || "Щось пішло не так");
    }
  } catch (e) {
    alert(e.message, "error");
  }
};

export default transferAccept;
