import React, {useEffect, useRef, useState} from "react";

import closeIcon from "../../../../assets/close.svg";
import searchIcon from "../../icons/search.svg";
import {connect, useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";
import {getSearchEmployer} from "../../../../redux/employers/employers-thunks";
import {addDeletedWorker} from "../../../../redux/workers/workers-thunks";
import TableCell from "../../../../components/tables/TableCell/TableCell";
import moment from "moment/moment";
import {diffDate} from "../../../../utilits/diffDate";

const matches = (needle, haystack) => {
  console.log(haystack)
  needle = needle.toLocaleLowerCase();
  haystack = (haystack || '').toLocaleLowerCase();

  return haystack.indexOf(needle) > -1;
};

export let KadriCardsSearch = ({toggleSearch, addDeletedWorker, worker, alert}) => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [search, setSearch] = React.useState('');
  const refInput = useRef();
  const data = useSelector(state => state.employersReducer.employerData)
  useEffect(() => {
    refInput.current.focus()
    dispatch(getSearchEmployer(worker.workerid))
  }, [dispatch])
  let filtered = data;
  if (data) {
    if (filtered.length > 0 && search.length > 0) {
      const fields = [
        (item) => item.name,
        (item) => item.surname,
        (item) => item.patronymic,
        (item) => item.RNOKPP ? item.RNOKPP.toString() : "",
      ];

      filtered = filtered.filter((item) => {
          return fields.some((field) => matches(search, field(item)))
        }
      )
    }
  }
  const handleChangeName = (e) => {
    setSearch(e.target.value)
    if (e.target.value.length >= 1) {
      setIsShow(true)
    } else {
      setIsShow(false)
    }
  }
  const handleSelectList = (e, setFieldValue, id) => {
    console.log(id)
    setSearch(e.target.textContent)
    setIsShow(false)
    setFieldValue("id", id)
    addDeletedWorker({id: id, ...worker}, alert, toggleSearch)
  }


  return (
    <Formik
      initialValues={{
        id: '',
      }}
    >
      {({setFieldValue}) => {
        return <div className="search__container">
          <div className="search__header">
            <span>Дані працівника</span>
            <img src={closeIcon} onClick={toggleSearch}/>
          </div>
          <img src={searchIcon} alt="" className="staff-search-icon"/>
          <div className="search__content" style={{flexDirection: "column"}}>
            <div className="table__value__item__input">
              <span>Прізвище або РНОКПП</span>
              <div className="table__value__item__row">
                <input value={search} ref={refInput} onChange={(e) => handleChangeName(e, setFieldValue)}
                       style={{paddingLeft: 8, minWidth: 400}}
                       id={'id'}
                       className="staff-search" placeholder="Пошук"/>
              </div>
              <button className="staff-close-btn">
                <img src={closeIcon} alt="" className="staff-close-icon"/>
              </button>
              {isShow && filtered && <ul className={`search__list ${filtered.length <= 0 ? "active" : ""}`}>
                {filtered.map((el) => <li key={el._id}
                                          onClick={(e) => handleSelectList(e, setFieldValue, el._id)}>{`${el.surname} ${el.name} ${el.patronymic} (${el.RNOKPP ? el.RNOKPP : "без рнокпп"}) ${
                  el.postInfo.currentStatus === "cancellation" ?
                    "скасовано"
                    : el.postInfo.currentStatus === "deleted" || moment(el.postInfo.uvalLastDay, "DD.MM.YYYY").isBefore(new Date()) ?
                      "звільнено" : el.postInfo.currentStatus === "truancy" ?
                        "прогул" : el.postInfo.status === 'Неоформленный' ? "Неоформлений" : el.postInfo.status === "deleted" ? 'був видалений' : "працює"
                }`}</li>)}
              </ul>}
            </div>
          </div>
        </div>
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  searchedWorker: state.workersReducer.searchedWorker
})

KadriCardsSearch = connect(mapStateToProps, {addDeletedWorker})(KadriCardsSearch)
