//libs
import React from "react";
//components
import Modal from "../modalComponents/Modal/Modal";
import ModalAcceptButton from "../../buttons/ModalAcceptButton/ModalAcceptbutton";
//styles
import "../modalComponents/Modal/Modal.css";

const TelegramLink = (props) => {
  const { closeFunc, link } = props;

  return (
    <Modal title={`Прив'язати телеграм-акаунт`} toggleFunc={closeFunc}>
      <div className="Modal__button-row">
        <a href={link} className="Modal__button-link">
          <ModalAcceptButton>Перейти в телеграм-бот</ModalAcceptButton>
        </a>
      </div>
    </Modal>
  );
};

export default TelegramLink;
