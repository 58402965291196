import './MiniButton.css'
import React from 'react'
const MiniButton = props => {

    const { onClick, icon, type, style, disabled, dataFor } = props

    const clickHandler = () => {
        if(onClick) {
            onClick()
        }
    }

    return (
        <button onClick={disabled ? null : clickHandler} 
            className={`MiniButton ${disabled ? 'MiniButton_disabled' : ''}`} 
            style={style}
                data-tip data-for={dataFor}
        >
            <img src={icon} alt={`${type} button`}></img>
        </button>
    )
}

export default MiniButton
