import instance from "../../instance";
import {TRANSFER_POSADA_EMPLOYER} from "../../../constants/urls";
import loadWorkers from "../workers/loadWorkers";

const transferPosadaEmployer = (data, setIsFetching, toggle,workerId) => async (dispatch) => {
  setIsFetching(true)
  const response = await instance.post(TRANSFER_POSADA_EMPLOYER, data)
  setIsFetching(false)
  toggle()
  dispatch(loadWorkers(workerId, alert))
  return response.data
}

export default transferPosadaEmployer;