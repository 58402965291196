import {useSelector} from 'react-redux'
import './AlertsContainer.css'
import React, {useEffect} from "react";
import {useAlert} from "react-alert";

const AlertsWrapper = ({children}) => {
  const alert = useAlert()
  const alerts = useSelector(state => {
    return state.alerts
  })
  useEffect(() => {
    alerts.message !== "" && alert.show(alerts.message, {type: alerts.type})
  }, [alerts]);

  return (
    <>
      {children}
    </>
  )
}

export default AlertsWrapper
