import styled from "styled-components";

export const StartCardNote = styled.p`
    position: absolute;
    top: 22px;
    right: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.04em;
    color: #A41919;
`