import { Popup } from "../../styledComponents/PopUps";
import "./PopUp.css";

export const PopUp = ({ children, isOpne, isClose }) => {
  return (
    <div className="popup-container">
      <div className="popup-content">{children}</div>
    </div>
  );
};
