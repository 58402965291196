import React, {useEffect, useState} from "react";
import {MButtonOrange, SButtonGrey,} from "../../../../../styledComponents/Buttons";

import "../KadriCards.css";
import {BASE_EDUCATION, PROF_EDUCATION, SECOND_EDUCATION} from "../../../../../redux/types";
import KadriPageEducationRow from "./KadriPageRows/KadriPageEducationRow";
import KadriPageWorkRow from "./KadriPageRows/KadriPageWorkRow";
import KadriPageFamilyRow from "./KadriPageRows/KadriPageFamilyRow";
import {connect} from "react-redux";
import {editSecondaryWorkerInfo} from "../../../../../redux/actions/workers/editSecondaryWorkerInfo";
import {SelectForm} from "../../../../../styledComponents/Inputs";


export let KadriPageSpecialQuota = ({
                                      editSecondaryWorkerInfo,
                                      openNextTab,
                                      setIsEdited,
                                      currentWorker,
                                      isEdited,
                                      handleSubmit,
                                      educationRows,
                                      setEducationRows,
                                      workRows,
                                      setWorkRows,
                                      familyRows,
                                      setFamilyRows,
                                      values,
                                      handleChange,
                                      setActive,
                                      setFieldValue,
                                      currentStatus,
                                      setCurrentStatus
                                    }) => {

  const [isFetching, setIsFetching] = useState(false)

  let educationItems = [
    {type: BASE_EDUCATION, education: 'базова загальна середня'},
    {type: BASE_EDUCATION, education: 'повна загальна середня'},
    {type: SECOND_EDUCATION, education: 'професійно-технічна'},
    {type: SECOND_EDUCATION, education: 'неповна вища (спеціаліст)'},
    {type: SECOND_EDUCATION, education: 'базова вища (бакалавр)'},
    {type: SECOND_EDUCATION, education: 'повна вища (магістр)'},
    {type: PROF_EDUCATION, education: 'післядипломна (аспірантура)'},
    {type: PROF_EDUCATION, education: 'післядипломна (ад’юнктура)'},
    {type: PROF_EDUCATION, education: 'післядипломна (докторантура)'},
  ]

  return (
    <div className="quota__container">
      <div className="quota__row">
        <div className="quota__row__header">
          <span>Освіта</span>
          <div className={'pos-rel'}>
            <SButtonGrey>+ Додати</SButtonGrey>
            <div style={{width: '250px'}} className={"table__dropdown__content left-0"}>
              {educationItems.map((item, index) => (
                <button key={index} onClick={() => setEducationRows([...educationRows, {...item, id: Date.now()}])}
                        className={'submenu__dropdown__btn'}>
                  {item.education}
                </button>))}
            </div>
          </div>
        </div>
        <div className="quota__row__table">
          <table>
            <tbody>
            {educationRows.map((item, index) => (
              <KadriPageEducationRow index={index}
                                     key={index}
                                     item={item}
                                     educationRows={educationRows}
                                     setEducationRows={setEducationRows}
                                     titleId={`title${item.id}`}
                                     atestatId={`atestat${item.id}`}
                                     yearReleaseId={`yearRelease${item.id}`}
                                     specialityId={`speciality${item.id}`}
                                     qualificationId={`qualification${item.id}`}
                                     powerId={`power${item.id}`}
                                     title={values[`title${item.id}`]}
                                     atestat={values[`atestat${item.id}`]}
                                     yearRelease={values[`yearRelease${item.id}`]}
                                     speciality={values[`speciality${item.id}`]}
                                     qualification={values[`qualification${item.id}`]}
                                     power={values[`power${item.id}`]}
                                     setFieldValue={setFieldValue}
                                     setIsEdited={setIsEdited}
                                     handleChange={handleChange}
              />
            ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="quota__row">
        <div className="quota__row__header">
          <span>Останнє місце роботи</span>
          <SButtonGrey onClick={() => {
            setWorkRows([...workRows, {id: Date.now()}])
            setIsEdited(true)
          }}>+ Додати</SButtonGrey>
        </div>
        <div className="quota__row__table">
          <table>
            <tbody>
            {workRows.map((item, index) => (
              <KadriPageWorkRow key={index} index={index}
                                onDelete={() => {
                                  setWorkRows([...workRows].filter(r => r.id !== item.id))
                                  setIsEdited(false)
                                }}
                                setIsEdited={setIsEdited}
                                workerId={`worker${item.id}`}
                                positionId={`position${item.id}`}
                                reasonId={`reason${item.id}`}
                                dateDismissalId={`dateDismissal${item.id}`}
                                worker={values[`worker${item.id}`]}
                                position={values[`position${item.id}`]}
                                reason={values[`reason${item.id}`]}
                                dateDismissal={values[`dateDismissal${item.id}`]}
                                handleChange={handleChange}
              />
            ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* В процессе */}
      <div className="quota__row__footer">
        <div className="quota__row__select">
          <div className="quota__row__header">
            <span>Родинний стан</span>
          </div>
          <SelectForm
            style={{width: "143px", paddingLeft: "5px"}}
            placeholder={'Виберіть зі списку'}
            value={currentStatus}
            onChange={e => setCurrentStatus(e.target.value)}
          >
            <option value="" selected hidden>Виберіть зі списку</option>
            {currentWorker?.sex === 'Ж' ? <>
              <option value="незаміжня">незаміжня</option>
              <option value="заміжня">заміжня</option>
            </> : <>
              <option value="неодружений">неодружений</option>
              <option value="одружений">одружений</option>
            </>}
          </SelectForm>
        </div>
        {(currentStatus !== 'незаміжня' && currentStatus !== 'неодружений') && <div className="quota__row__table">
          <div className="quota__row__header">
            <span>Склад сім’ї</span>
          </div>
          <table>
            <tr className="tr-sm">
              <td
                className="table-md"
                style={{
                  height: "24px",
                  textAlign: "center",
                  border: "1px solid #BDBDBD",
                  borderRight: "none",
                }}
              >
                Член сім’ї
              </td>
              <td
                className="table-lg"
                style={{
                  height: "24px",
                  textAlign: "center",
                  border: "1px solid #BDBDBD",
                }}
              >
                ПІБ
              </td>
              <td
                className="table-md"
                style={{
                  height: "24px",
                  textAlign: "center",
                  border: "1px solid #BDBDBD",
                }}
              >
                Рік народження
              </td>
            </tr>
            {familyRows.map((item, index) => {
              return <KadriPageFamilyRow key={index}
                                         familyMemberId={`familyMember${item.id}`}
                                         PIBId={`PIB${item.id}`}
                                         birthYearId={`birthYear${item.id}`}
                                         familyMember={values[`familyMember${item.id}`]}
                                         PIB={values[`PIB${item.id}`]}
                                         birthYear={values[`birthYear${item.id}`]}
                                         handleChange={handleChange}
                                         setIsEdited={setIsEdited}
                                         onDelete={() => {
                                           setFamilyRows([...familyRows].filter(r => r.id !== item.id))
                                           setIsEdited(false)
                                         }}
              />
            })}
          </table>
          <div className="quota__row__footer">
            <SButtonGrey onClick={() => {
              setIsEdited(true);
              setFamilyRows([...familyRows, {id: Date.now()}])
            }}
                         style={{width: '80px', height: '30px'}}>+ Додати</SButtonGrey>
          </div>
        </div>}
        {isEdited && <MButtonOrange
          disabled={isFetching} onClick={() => handleSubmit()}
          className="buttonDisabled"
          style={{
            margin: "2px 0 0 527px",
            position: 'absolute',
            right: '30px',
            bottom: '30px'
          }}>
          Далі
        </MButtonOrange>}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentWorker: state.currentWorker
})

KadriPageSpecialQuota = connect(mapStateToProps, {editSecondaryWorkerInfo})(KadriPageSpecialQuota)

