import {ADD_AND_LOAD_USER_CONTROLLER} from "../../../constants/urls";
import {LOAD_CONTROLLER_USER, LOAD_INFO_CONTROLLER} from "../../types";
import instanceController from "../../instanceController";
import showAlert from "../alerts/showAlert";

const addAndLoadUserController = (array, objectId) => async (dispatch) => {
  try {
    const response = await instanceController.post(ADD_AND_LOAD_USER_CONTROLLER, {array: array, objectId: objectId})
    if (response.data) {
      dispatch({
        type: LOAD_CONTROLLER_USER,
        payload: response.data.employers
      })
      return response.data.employers
    }
  } catch (e) {
    dispatch({
      type: LOAD_INFO_CONTROLLER,
      payload: null
    })
    showAlert(e.message, 'error')
  }
}

export default addAndLoadUserController
