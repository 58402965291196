import axios from 'axios'
import {controllerURL} from "../constants/urls";

console.log(controllerURL)
export const instanceController = axios.create({
  baseURL: controllerURL,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    mode: 'no-cors',
  }
})

export default instanceController



