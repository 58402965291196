import instance from "../instance";
import {setSettings, setZpSettings} from "./settings-actions";


export const getSettings = (workerid) => async (dispatch) => {
  const response = await instance.post('/employer/getSettings', {workerid})
  dispatch(setSettings(response.data))
}

export const changeAutoNumber = (number, isAutoNumber2) => async (dispatch, getState) => {
  const {currentEmployer} = getState().employersReducer
  await instance.post(`/employer/autoNumber${isAutoNumber2 ? '2' : ''}`, {workerid: currentEmployer._id, number})
}

export const autoReport = (report) => async (dispatch, getState) => {
  const {currentEmployer} = getState().employersReducer
  await instance.post('/employer/autoReport', {workerid: currentEmployer._id, report})
  dispatch(getSettings(currentEmployer._id))
}

export const autoIndex = (indexat) => async (dispatch, getState) => {
  const {currentEmployer} = getState().employersReducer
  await instance.post('/employer/autoIndex', {workerid: currentEmployer._id, indexat})
  dispatch(getSettings(currentEmployer._id))
}

export const autoBill = (sendBill) => async (dispatch, getState) => {
  const {currentEmployer} = getState().employersReducer
  await instance.post('/employer/autoBill', {workerid: currentEmployer._id, sendBill})
  dispatch(getSettings(currentEmployer._id))
}

export const setSellaryInfo = (zp) => async (dispatch, getState) => {
  const {currentEmployer} = getState().employersReducer
  await instance.post('/employer/setSellaryInfo', {workerid: currentEmployer._id, zp})
  dispatch(getSettings(currentEmployer._id))
}

export const setAddress = (rahunki) => async (dispatch, getState) => {
  const {currentEmployer} = getState().employersReducer
  await instance.post('/employer/setAddress', {workerid: currentEmployer._id, rahunki})
  dispatch(getSettings(currentEmployer._id))
}

export const getZpSettings = (isId) => async (dispatch, getState) => {
  const {currentEmployer} = getState().employersReducer
  const response = await instance.post('/employer/settingsAll', isId && {
    workerid: currentEmployer._id
  })
  dispatch(setZpSettings(response.data))
}