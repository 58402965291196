import {LOAD_ALL, LOAD_REGION} from "./statistic-types";


const initialState = {
  data: [],
  all: null
}

export const statisticReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_REGION:
      return {...state, data: action.data}
    case LOAD_ALL:
      return {...state, all: action.data}
    default:
      return state
  }
}