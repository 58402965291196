import React, {useState} from "react";
import { Switch, Route, Link } from "react-router-dom";
import { Kadri1 } from "./kadri1";
import {KadriItem} from './KadriItem/KadriItem'

export const Kadri = () => {
  const [pressedItem, setPressedItem] = useState(null)

  return (
    <div>
      <Switch>
          <Route exact path="/home/kadri/" render={() => <Kadri1  pressedItem={pressedItem} setPressedItem={setPressedItem} />} />
          <Route exact path="/home/kadri/:id" component={() => <KadriItem/>} />
          {/* <Route exact path="/home/kadri/item" component={() => <KadriItem/>} /> */}
      </Switch>
    </div>
  );
};
