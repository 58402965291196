import React, {useEffect, useState} from "react";
import getDateAndTime from "../../../utilits/getDateAndTime";
import {getUsers} from "../../../api/users";

const EdiRentHistory = ({history}) => {
  const [user, setUser] = useState({});
  useEffect(async () => {
    let users = await getUsers().then(res => res.users)
    setUser(users)
  }, [])
  return (
    <React.Fragment>
      <div className="history__container">
        <table className="history__table">
          <thead>
          <tr>
            <td style={{fontWeight: '500'}}>Дата</td>
            <td style={{fontWeight: '500'}}>Користувач</td>
            <td style={{fontWeight: '500'}}>Дія</td>
            <td style={{fontWeight: '500'}}>Коментар</td>
          </tr>
          </thead>
          <tbody>
          {history && history.map((item, i) => (
            <tr key={i}>
              <td>{getDateAndTime(new Date(item.date))}</td>
              <td>{
                user?.length && user.map(el => el._id === item.user && `${el.lastName} ${el.firstName} ${el.middleName}`)}</td>
              <td>{item.type}</td>
              <td dangerouslySetInnerHTML={{__html: item.comment}}/>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </React.Fragment>)
}

export default EdiRentHistory;