import React, {useState} from "react";

import "../KadriCards.css";
import {SearchInput} from "../../../../../styledComponents/Inputs";
import {connect} from "react-redux";
import {autoBill, autoIndex, autoReport, changeAutoNumber} from "../../../../../redux/settings/settings-thunks";
import {Formik} from "formik";
import moment from "moment";

export let KadriCardsSettingsFirst = ({settings, ...props}) => {
  const [disabledInput, setDisabledInput] = useState(settings.number1 === -1);
  const [disabledInput_2, setDisabledInput_2] = useState(settings.number2 === -1);
  const toggleDisabledInput = () => {
    setDisabledInput(!disabledInput);
  };

  const toggleDisabledInput_2 = () => {
    setDisabledInput_2(!disabledInput_2);
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        number1: settings.number1 !== -1 ? settings.number1 : '',
        number2: settings.number2 !== -1 ? settings.number2 : '',
        reportStatus: settings.report.status === 'on',
        avans: settings.report.avans,
        zp: settings.report.zp,
        indexat: settings.indexat,
        sendBill: settings.sendBill,
      }}
    >
      {({values, handleChange, setFieldValue}) => {
        // if(settings.number2.toString() !== values.number2) setFieldValue('number2', settings.number2 !== -1 ? settings.number2 : '')
        return <div className="settings__container">
          <div className="settings__row">
            <div className="settings__row__header">
              <span>Присвоювати номер наказу автоматично</span>
              <label className="checkbox-google">
                <input
                  type="checkbox"
                  checked={!disabledInput}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      props.changeAutoNumber(-1, false)
                      setFieldValue('number1', '')
                    }
                    toggleDisabledInput()
                  }}
                />
                <span className="checkbox-google-switch"></span>
              </label>
            </div>
            <div className="settings__row__content">
          <span>
            Номер наказа присвоюється автоматично. При цьому ви можете вказати
            номер, <br/> з якого почнеться або продовжиться облік. <br/> Якщо
            функцію вимкнути, при створенні документів необхідно буде вказувати{" "}
            <br/> номер вручну.
          </span>
              <SearchInput
                style={
                  disabledInput
                    ? {
                      backgroundColor: "#F2F2F2",
                      width: "64px",
                      height: "36px",
                      padding: "0 7px",
                    }
                    : {width: "64px", height: "36px", padding: "0 7px"}
                }
                placeholder="Номер"
                disabled={disabledInput}
                  value={values.number1}
                id={'number1'}
                onChange={handleChange}
                type={'number'}
                onBlur={(e) => {
                  e.preventDefault();
                  if (e.target.value.length > 0 && +e.target.value.length <= 31) props.changeAutoNumber(e.target.value, false)
                }}
              />
            </div>
          </div>
          <div className="settings__row">
            <div className="settings__row__header">
              <span>Присвоювати табельний номер працівника автоматично</span>
              <label className="checkbox-google">
                <input
                  type="checkbox"
                  checked={!disabledInput_2}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      props.changeAutoNumber(-1, true)
                      setFieldValue('number2', '')
                    }
                    toggleDisabledInput_2()
                  }}
                />
                <span className="checkbox-google-switch"></span>
              </label>
            </div>
            <div className="settings__row__content">
          <span>
            Табельний номер присвоюється автоматично. При цьому ви можете
            вказати <br/> номер, з якого почнеться або продовжиться облік.{" "}
            <br/> Якщо функцію вимкнути, обліку табельних номерів не буде.
          </span>
              <SearchInput
                style={
                  disabledInput_2
                    ? {
                      backgroundColor: "#F2F2F2",
                      width: "64px",
                      height: "36px",
                      padding: "0 7px",
                    }
                    : {width: "64px", height: "36px", padding: "0 7px"}
                }
                placeholder="Номер"
                disabled={disabledInput_2}
                value={values.number2}
                id={'number2'}
                onChange={handleChange}
                type={'number'}
                onBlur={(e) => {
                  e.preventDefault();
                  if (e.target.value.length > 0) props.changeAutoNumber(e.target.value, true)
                }}
              />
            </div>
          </div>
          <div className="settings__row">
            <div className="settings__row__header">
              <span>Формувати та надсилати відомості автоматично</span>
              <label className="checkbox-google">
                <input type="checkbox" checked={values.reportStatus}
                       onChange={(e) => {
                         e.preventDefault();
                         props.autoReport({
                           status: !!values.reportStatus ? 'off' : 'on',
                           avans: values.avans,
                           zp: values.zp
                         })
                         setFieldValue('reportStatus', !values.reportStatus)
                       }}
                />
                <span className="checkbox-google-switch"></span>
              </label>
            </div>
            <div className="settings__row__content">
          <span>
            {values.reportStatus ? <select
              className="conditions__select-client select"
              style={{
                width: "55px",
                height: "20px",
                marginRight: "5px",
                paddingLeft: "0px",
                color: "#000",
                borderRadius: "0",
              }}
              value={values.avans}
              id={'avans'}
              onChange={e => {
                handleChange(e)
                props.autoReport({
                  status: 'on',
                  avans: e.target.value,
                  zp: values.zp
                })
              }}
            >
              {Array.apply(null, {length: moment(new Date()).daysInMonth()}).map((_, index) =>
                index !== 0 && <option value={index.toString().padStart(2, '0')}>
                  {index.toString().padStart(2, '0')}
                </option>)}
              <option value={moment(new Date()).daysInMonth()}>
                ОДМ
              </option>
            </select> : values.avans} числа поточного місяця - формується відомість про аванс у PDF файлі
            та <br/> автоматично направляється на електронну пошту магазина.{" "}
            <br/>
            {values.reportStatus ? <select
              className="conditions__select-client select"
              style={{
                width: "55px",
                height: "20px",
                marginRight: "5px",
                paddingLeft: "0px",
                color: "#000",
                borderRadius: "0",
              }}
              value={values.zp}
              id={'zp'}
              onChange={e => {
                handleChange(e)
                props.autoReport({
                  status: 'on',
                  avans: values.avans,
                  zp: e.target.value
                })
              }}
            >
              {Array.apply(null, {length: moment(new Date().setMonth(new Date().getMonth() + 1)).daysInMonth()}).map((_, index) =>
                index !== 0 && <option value={index.toString().padStart(2, '0')}>
                  {index.toString().padStart(2, '0')}
                </option>)}
              <option  value={"ОДМ"}>
                ОДМ
              </option>
            </select> : values.zp} числа наступного місяця - формується відомість про ЗП у PDF файлі та{" "}
            <br/>
            автоматично направляється на електронну пошту магазина.
          </span>
            </div>
          </div>
          <div className="settings__row">
            <div className="settings__row__header">
              <span>Проводити штучну індексацію</span>
              <label className="checkbox-google">
                <input type="checkbox" checked={values.indexat}
                       onChange={(e) => {
                         e.preventDefault();
                         props.autoIndex(!values.indexat)
                         setFieldValue('indexat', !values.indexat)
                       }}/>
                <span className="checkbox-google-switch"/>
              </label>
            </div>
            <div className="settings__row__content">
          <span>
            Штучна індексація дозволяє округлити суму у колонці “Нараховано”, та
            позбутися <br/> копійок. Штучна індексація не заважає нарахуванню
            реальної індексації.
          </span>
            </div>
          </div>
          <div className="settings__row">
            <div className="settings__row__header">
              <span>Направляти повідомлення у податкову</span>
              <label className="checkbox-google">
                <input type="checkbox" checked={values.sendBill}
                onChange={(e) => {
                  e.preventDefault();
                  props.autoBill(!values.sendBill)
                  setFieldValue('sendBill', !values.sendBill)
                }}/>
                <span className="checkbox-google-switch"></span>
              </label>
            </div>
            <div
              className="settings__row__content"
              style={{borderBottom: "none"}}
            >
          <span>
            При прийнятті працівника направляти повідомлення у податкову
            автоматично. <br/> Копію повідомлення та квитанції буде збережено в
            розділі “документи <br/> працівника”.
          </span>
            </div>
          </div>
        </div>
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settingsReducer.settings
})

KadriCardsSettingsFirst = connect(mapStateToProps, {
  changeAutoNumber,
  autoReport,
  autoIndex,
  autoBill
})(KadriCardsSettingsFirst)
