import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import App from "./App";
import rootReducer from "./redux/rootReducer";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import Alert from "./components/alerts/Alert";

const options = {
  position: positions.BOTTOM_RIGHT,
  timeout: 2500,
  transition: transitions.FADE,
  containerStyle: {
    zIndex: 9999
  }
}

export const store = createStore(rootReducer, applyMiddleware(thunk))

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AlertProvider template={Alert} {...options}>
        <App />
      </AlertProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

