import {UPDATE_FINGER_USER} from "../../../constants/urls";
import instanceController from "../../instanceController";
import showAlert from "../alerts/showAlert";
import {LOAD_CONTROLLER_USER} from "../../types";

const updateFingerUser = (id, update, objectId) => async (dispatch) => {
  try {
    const response = await instanceController.post(UPDATE_FINGER_USER, {id, update, objectId})
    if (response.data) {
      dispatch({
        type: LOAD_CONTROLLER_USER,
        payload: response.data.employers
      })
      return response
    }
    return response;
  } catch (e) {
    dispatch(showAlert(e.message, 'error'))
  }
}

export default updateFingerUser
