import './Input.css'
import React from 'react'
const Input = props => {

    const {height, width, placeholder, onChange, value, name, padding, style, disabled} = props

    return (
        <input className='Input'
               disabled={disabled}
               placeholder={placeholder}
               onChange={onChange}
               value={value}
               name={name}
               style={{
                height: `${height.slice(0, -2) - 2}px`,
                width: width,
                padding: padding,
                ...style
            }}
        >

        </input>
    )
}

export default Input
