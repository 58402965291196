import React, {useEffect} from "react";
import closeGrey from "../../../../../assets/closeGrey.svg";
import {KadriCardsSettingsFirst} from "./KadriCardsSettingsFirst"
import {KadriCardSettingsTwo} from "./KadriCardSettingsTwo"
import {KadriCardsSettingsThree} from "./KadriCardsSettingsThree";

import "../KadriCards.css";
import {connect} from "react-redux";
import {getSettings} from "../../../../../redux/settings/settings-thunks";


const TabSettingsContent = ({page}) => (
  <div className="tabcontent">
    {page === "one" && <KadriCardsSettingsFirst/>}
    {page === "two" && <KadriCardSettingsTwo/>}
    {page === "three" && <KadriCardsSettingsThree/>}
  </div>
);

export let KadriCardsSettingsEdit = ({
                                       settingItems,
                                       toggleSettingEdit,
                                       ...props
                                     }) => {
  const [active, setActive] = React.useState(0);

  const openTab = (e) => {
    setActive(+e.target.dataset.index)
    props.getSettings(employerId)
  };
  const employerId = window.location.pathname.slice(window.location.pathname.lastIndexOf('/') + 1)
  useEffect(() => {
    props.getSettings(employerId)
  }, [])
  return (
    <div className="tab__modal__settings">
      <div className="tab__settings">
        <div className="tab__btn__settings">
          {settingItems.map((n, i) => (
            <button
              className={`tablinks ${i === active ? "active" : ""}`}
              onClick={openTab}
              data-index={i}
            >
              {n.title}
            </button>
          ))}
        </div>
        <div className="tab__close__settings" onClick={(e)=>{
          e.preventDefault();
          toggleSettingEdit();
          props.getSettings(employerId)
        }}>
          <img src={closeGrey}/>
        </div>
      </div>
      {(settingItems[active] && props.settings) && <TabSettingsContent {...settingItems[active]} />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentEmployer: state.employersReducer.currentEmployer,
  settings: state.settingsReducer.settings
})

KadriCardsSettingsEdit = connect(mapStateToProps, {getSettings})(KadriCardsSettingsEdit)