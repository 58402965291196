import Select from "react-select";
import React, { useEffect, useState } from "react";
import provider from "react-redux/lib/components/Provider";

const CustomSelect = ({
  setFieldValue,
  name,
  paddingValue = 18,
  textSize = 13,
  scrollHeight = 140,
  defaultValue,
  options,
  disabled,
  placeholder,
  isChange = true,
  changeFn,
  width = null,
  get_table_data,
}) => {
  const [value, setValue] = useState(defaultValue);
  const onChange = (newValue) => {
    setValue(newValue);
    setFieldValue && setFieldValue(name, newValue);
  };

  if (setFieldValue) {
    useEffect(() => {
      setFieldValue(name, value);
    }, []);
  }

  useEffect(() => {
    console.log(value);
  }, [value]);

  return (
    <Select
      onChange={isChange ? onChange : changeFn}
      placeholder={placeholder}
      isDisabled={disabled}
      value={defaultValue}
      styles={{
        multiValueRemove: (base, state) => {
          return state.data.isFixed ? { ...base, display: "none" } : base;
        },
        control: (provided) => ({
          ...provided,
          minHeight: 34,
          borderRadius: 4,
          border: "1px solid rgba(193, 193, 193, 0.8)",
        }),
        option: (provided) => ({
          ...provided,
          padding: "10px 12px",
          fontSize: `${textSize}px`,
        }),
        singleValue: (provided) => ({
          ...provided,
          fontSize: `${textSize}px`,
          margin: 0,
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: 0,
          fontSize: `${textSize}px`,
          fontFamily: "Roboto, sans-serif",
          paddingLeft: paddingValue,
        }),
        menu: (provided) => ({
          ...provided,
          zIndex: 4,
        }),
        menuList: (provided) => ({
          ...provided,
          maxHeight: scrollHeight,
          paddingTop: 0,
          paddingBottom: 0,
        }),
        placeholder: (provided) => ({
          ...provided,
          fontSize: `${textSize}px`,
          margin: 0,
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          display: "flex",
          alignItems: "center",
          width: "22px",
          height: "8px",
          justifyContent: "center",
          padding: 0,
        }),
        multiValue: (provided) => ({
          ...provided,
          margin: 2,
          height: 24,
          display: "flex",
          alignItems: "center",
          fontSize: `${textSize}px`,
          "div:nth-child(1)": {
            display: "flex",
            alignItems: "center",
            padding: "0 3px",
            height: 24,
          },
        }),
        input: (provided) => ({
          ...provided,
          margin: 0,
          height: 20,
          padding: 0,
          input: {
            height: 20,
          },
        }),
        container: (provided) => ({
          ...provided,
          width: width ? width : "100%",
          maxWidth: width ? width : "100%",
        }),
      }}
      options={options}
      onKeyDown={() => get_table_data()}
      className={"custom_all"}
      components={{
        IndicatorSeparator: false,
      }}
    />
  );
};

export default CustomSelect;
