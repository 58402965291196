import styled from "styled-components";
import Button from "../Button/Button";

const GreenButton = styled(Button)`
    width: 191px;
    height: 36px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
    &:focus + .Dropdown__menu,
     & + .Dropdown__menu:hover {
      display: flex !important;
    }
`

export default GreenButton