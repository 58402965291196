import '../Input.css'
import './SearchInput.css'
import searchIcon from "../../../images/icons/search.svg";
import closeIcon from "../../../images/icons/close.svg";
import Input from '../MainInput/Input';
import MiniButton from '../../common/MiniButton/MiniButton';
// import { useState } from 'react';

const SearchInput = props => {

  const {name, onChange, value, placeholder, clearInputFunc, hideIcon = true} = props
  // const [inputValue, setInputValue] = useState(value)

  return (
    <div className='SearchInput'>
      <Input
        width={hideIcon ? '184px' : '100%'}
        height='36px'
        padding={hideIcon ? '0 40px 0 32px' : '0 10px 0 10px'}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        name={name}
      />
      {hideIcon && <img src={searchIcon} alt="" className="SearchInput__icon"/>}
      <MiniButton
        style={{
          position: 'absolute',
          top: '6px',
          right: '6px'
        }}
        disabled={value === ''}
        icon={closeIcon}
        onClick={clearInputFunc}
      />
    </div>
  )
}

export default SearchInput