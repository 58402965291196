import React, {useEffect, useRef, useState} from "react";
import {InputForm} from "../../../../../styledComponents/Inputs";

import closeIcon from "../../../../../assets/closeGrey.svg";
import datePicker from "../../../../../assets/datePicker.svg";
import trash from "../../../../../assets/trash.svg";
import {SButtonGreen, SButtonGrey} from "../../../../../styledComponents/Buttons";
import DatePickerFragment from "../../../../../components/datePicker/datePicker";
import {useFormik} from "formik";

import * as Yup from "yup";
import {addMandate} from "../../../../../api/kadriConditions";
import {useDispatch, useSelector} from "react-redux";
import {deletedDocument} from "../../../../../redux/documentOld/document-thunks";
import {setModalInfo} from "../../../../../redux/modalInfo/modal-actions";
import submenuArrow from "../../../../../assets/submenuArrow.svg";
import ErrorMessageAll from "../../../../../utilits/errorMessageAll";
import {getDocumentsJournal} from "../../../../../redux/cadrDocuments/cadrDocuments-thunks";

export const KadriCardsDocAddModal = ({toggleAddModal, workers, year, id}) => {
  const modal = useSelector(state => state.modal.info)
  const [isFetch, setFetch] = useState(false);
  const [isShow, setShow] = useState(false);

  const [cursorPosition, setCursorPosition] = useState(0)
  const validationSchema = Yup.object().shape({
    nameMandate: Yup.string().required('Вкажіть назву наказу'),
    number: Yup.string().required('Вкажіть номер наказу'),
    date: Yup.string().nullable().required('Вкажіть дату'),
    description: Yup.string().required('Вкажіть текст'),
    articles: Yup.array()
      .of(
        Yup.object()
      )
      .min(1, "Додайте пункт")
  });
  const refTextarea = useRef();
  const dispatch = useDispatch();

  const initialValues = {
    isActive: modal?.isActive ?? false,
    nameMandate: modal?.nameMandate ?? "",
    date: (modal && new Date(modal?.date)) ?? null,
    number: modal?.number ?? "",
    description: modal?.description ?? "",
    articles: modal?.articles ?? []
  }
  useEffect(() => {
    refTextarea.current.addEventListener('mouseout', function (event) {
      setCursorPosition(refTextarea.current.selectionStart)
    });
  }, [])

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log(values)
      addMandate({...values, workerId: id}, setFetch).then(res => {
        if (modal) {
          dispatch(deletedDocument(modal.documentId))
          dispatch(setModalInfo(null))
        }
        dispatch(getDocumentsJournal(year))
        toggleAddModal();
      })
    },
  });
  const handleInsertText = (text) => {
    const textToAdd = text;
    const currentValue = values.description;

    const newValue = currentValue.slice(0, cursorPosition) + textToAdd + currentValue.slice(cursorPosition);
    setFieldValue("description", newValue)
    // Устанавливаем курсор в конец вставленного текста
    refTextarea.current.selectionStart = cursorPosition + textToAdd.length;
    refTextarea.current.selectionEnd = cursorPosition + textToAdd.length;
    // Сохраняем новый индекс курсора
    setCursorPosition(refTextarea.current.selectionStart)
  }

  return (
    <form onSubmit={handleSubmit} action="#" method="post" className="add__modal_container modal_doc_accept">
      {isFetch && <div className={'disabled__overlay'}>
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>}
      <div className="modal__header">
        <div className="modal__header__left">
          <div className="table__value__item">
            <span>Назва</span>
            <div className="doc__add__wrapper">
              <InputForm placeholder={"Вкажіть назву"} id="nameMandate" type="text" onChange={handleChange}
                         value={values.nameMandate}
                         style={{width: "298px"}}/>
              <ErrorMessageAll name="nameMandate" errors={errors} touched={touched}/>
            </div>

          </div>
        </div>

        <img src={closeIcon} onClick={() => {
          toggleAddModal();
          dispatch(setModalInfo(null))
        }}/>
      </div>
      <div className="modal__header__content">
        <div className="header__content__item">
          <div className="table__value__item">
            <span>№ </span>
            <div className="doc__add__wrapper">
              <InputForm placeholder={"Вкажіть номер"} id="number" onChange={handleChange}
                         value={values.number}
                         style={{width: "155px"}}/>
              <ErrorMessageAll name="number" errors={errors} touched={touched}/>
            </div>
          </div>
        </div>

        <div className="header__content__item">
          <div className="doc__add__wrapper">

            <div className="conditions__calendar">
              <DatePickerFragment placeholder={"Дата"} selected={values.date}
                                  dateFormat="dd.MM.yyyy"
                                  IconGray={<div className="item__input__check"
                                                 type={'button'}
                                                 style={{paddingTop: "8px", marginBottom: '2px'}}>
          <span>
          <img src={datePicker}/>
          </span>
                                  </div>}
                                  onChange={date => setFieldValue("date", date.setHours(12))}/>

            </div>
            <ErrorMessageAll name="date" errors={errors} touched={touched}/>
          </div>
        </div>
        <div className="table__container__scroll">
          <div className="header__content__item">
            <div className="table__value__item">
          <span>Основний текст

          <div className="dropdown dropdown__works" id="create" data-tip data-for="create">
          <SButtonGrey style={{margin: '0'}} onClick={(e) => {
            e.preventDefault();
            setShow(true)
          }}>Вставлення</SButtonGrey>
            {isShow && <div className={"table__dropdown__content left-0"}>
              <div className="submenu">
                <div className="submenu__dropdown__content">
                  {workers.map(el => <button className={'submenu__dropdown__btn'}
                                             onClick={(e) => {
                                               e.preventDefault();
                                               setShow(false)
                                               handleInsertText(`${[el.surname, el.name, el.patronymic].filter(el => !!el).join(" ")} (${el.position.title})`)
                                             }}>
                    {[el.surname, el.name, el.patronymic].filter(el => !!el).join(" ")} ({el?.position.title ?? el.postInfo.status})
                  </button>)}
                </div>
                <button onClick={e => e.preventDefault()} className={'submenu__dropdown__btn'}>
                  <span>Дані пацівника</span>
                  <img src={submenuArrow}/>
                </button>
              </div>
            </div>}
          </div>
            <div className="doc__add__wrapper" style={{display: "flex", alignItems: "center", marginLeft: 8}}>
                <input type="checkbox" id={"isActive"} onChange={handleChange} checked={values.isActive}
                       style={{marginRight: 4}}/>
              <label htmlFor="isActive">Додати текст про ознайомлення працівника з наказом</label>
              </div>
          <ErrorMessageAll name="description" errors={errors} touched={touched}/>
          </span>
              <textarea ref={refTextarea} className="text__table__content" id="description" value={values.description}
                        onChange={handleChange} cols={150} rows={3}>
          </textarea>
            </div>
          </div>
          <div
            className="header__content__item"
            style={{flexDirection: "column"}}
          >
            <div className="table__value__item">
              <span>Наказую: <ErrorMessageAll name="articles" errors={errors} touched={touched}/></span>
              {values.articles.map((article, index) => {
                return <div key={index} className="header__content__text__table">
                  <div className="text__table__left">п.{index + 1}</div>
                  <textarea value={article.description}
                            id={`article${article.id}`}
                            onChange={(e) => {
                              values.articles[index].description = e.target.value
                              setFieldValue("articles", values.articles)
                            }}
                            className="text__table__content"/>
                  <button
                    onClick={() => setFieldValue("articles", values.articles.filter((a, indexArticle) => index !== indexArticle))}
                    className="text__table__right">
                    <img src={trash}/>
                  </button>
                </div>
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="modal__footer">
        <button onClick={(e) => {
          e.preventDefault();
          setFieldValue("articles", [...values.articles, {description: ""}])
        }
        }>
          <img src={closeIcon}/>
        </button>
      </div>
      <div className="modal__footer__green">
        <SButtonGreen>Зберегти</SButtonGreen>
      </div>
    </form>

  );
};
