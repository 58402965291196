import React from "react";
import {Popup} from "../../styledComponents/PopUps";
import warning from "../../assets/warning.svg";
import {SButtonRed, SButtonGreenBorder} from "../../styledComponents/Buttons";

export const WarningPopUp = ({toggleWarningModal, onDelete, name, isNewText = true, isLayer = false}) => {
  return (
    <>
      {isLayer && <div className="bg__layer"></div>}
      <Popup style={{
        position: 'fixed',
        zIndex: '9999',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff'
      }}>
        <img src={warning} alt=""/>
        {isNewText ? <p>
          Запис “<span>{name}</span>” буде видалено з
          таблиці. Бажаєте продовжити?{" "}
        </p> : <p dangerouslySetInnerHTML={{__html: name}}/>
        }
        <div>
          <SButtonGreenBorder onClick={toggleWarningModal}>Скасувати</SButtonGreenBorder>
          <SButtonRed onClick={onDelete}>Видалити</SButtonRed>
        </div>
      </Popup>

    </>
  );
};
