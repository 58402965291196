import MiniButton from "../../common/MiniButton/MiniButton"
import Dropdown from "../Dropdown/Dropdown"
import moreIcon from '../../../images/icons/more.svg'
import { useState } from "react"

const DropdownMore = props => {


    const {onClick,  disabled, setDisabled} = props

    const toggleDropdown = () => {
        setDisabled(!disabled)
    }

    // const clickHandler = () => {
    //     setDisabled(!disabled)
    //     onClick()
    // }

    return (
        <Dropdown
            button={<MiniButton icon={moreIcon} type='more'/>}
            // disabled={disabled}
            toggleFunc={toggleDropdown}
        >
            {props.children}
        </Dropdown>
    )
}

export default DropdownMore