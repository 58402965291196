import {SAVE_TOKEN} from "../types";
// import store from '../../index'


const saveToken = (token) => ({
  type: SAVE_TOKEN,
  payload: {
    token: token
  }
})

export default saveToken
