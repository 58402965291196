//libs
import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { store } from "../../../index";
import { Formik, useFormik } from "formik";
//components
import Modal from "../modalComponents/Modal/Modal";
import ModalAcceptButton from "../../buttons/ModalAcceptButton/ModalAcceptbutton";
import Input from "../../Inputs/Input/Input";
//hoooks
import { useAlert } from "react-alert";
//styles
import "../modalComponents/Modal/Modal.css";
import { getCookie } from "../../../utilits/cookies";
import { editUserName } from "../../../redux/user/user-thunks";

const UserEdit = ({ closeFunc, editUserName }) => {
  const alert = useAlert();
  const user = useSelector((state) => {
    return state.user;
  });

  const alertFunc = (message, type) => {
    alert.show(message, { type: type });
  };

  const formik = useFormik({
    initialValues: { firstName: user.firstName, lastName: user.lastName },
    onSubmit: async (values) => {
      editUserName(values, closeFunc, alertFunc);
    },
  });

  return (
    <Modal title="Редагування профілю" toggleFunc={closeFunc}>
      <form onSubmit={formik.handleSubmit}>
        <div className="Modal__content">
          <label className="Modal__text">Ім'я</label>
          <Input
            id="firstName"
            name="firstName"
            type="text"
            placeholder={`Ім'я`}
            onChange={formik.handleChange}
            value={formik.values.firstName}
          />
          <label className="Modal__text">Прізвище</label>
          <Input
            id="lastName"
            name="lastName"
            type="text"
            placeholder="Прізвище"
            onChange={formik.handleChange}
            value={formik.values.lastName}
          />
        </div>
        <div className="Modal__button-row">
          <ModalAcceptButton type="submit">Зберегти</ModalAcceptButton>
        </div>
      </form>
    </Modal>
  );
};

export default connect(null, { editUserName })(UserEdit);
