import React from "react";
import {
  SButtonGreen,
} from "../../../../../styledComponents/Buttons";

import closeIcon from "../../../icons/close.svg";
import calendarIcon from "../../../icons/calendar.svg";

import "../KadriCards.css";

export const KadriCardsWorkDoc__4 = ({ toggleWork__4 }) => {
  return (
    <div className="conditions__workdoc__4">
      <div className="conditions__header">
        <p>Додаткова угода до трудового договору</p>
        <img
          src={closeIcon}
          alt=""
          onClick={toggleWork__4}
          className="conditions__close-icon"
        />
      </div>
      <hr className="conditions__header__hr" />
      <div className="conditions__content">
        <div className="conditions__content-left">
          <p>Що змінилося?</p>
          <p>Підстава</p>
          <p>Реквізити документа</p>
          <p>Дата угоди</p>
        </div>
        <div className="conditions__content-right">
          <form className="conditions__form" action="#">
            <div className="conditions__row">
              <select className="conditions__select-client select">
                <option value="">Виберіть зі списку</option>
              </select>
            </div>
            <div className="conditions__row">
              <select className="conditions__select-client select">
                <option value="">Виберіть зі списку</option>
              </select>
            </div>
            {/* <div className="conditions__row">
                <select className="conditions__select-type-of-work select">
                  <option value="">Виберіть зі списку</option>
                </select>
              </div> */}
            <div className="conditions__row">
              <input
                type="text"
                name="time-start"
                tabIndex="4"
                className="conditions__time-input"
                placeholder="АА - 0000000"
              />
              <p> </p>
              <input
                type="text"
                name="time-end"
                tabIndex="5"
                className="conditions__time-input"
                placeholder="дд/мм/рррр"
              />
              <img
                src={calendarIcon}
                alt=""
                className="conditions__clock-icon--2"
              />
            </div>
            <div className="conditions__row">
              <input
                type="text"
                name="time-start"
                tabIndex="4"
                className="conditions__time-input"
              />
              <img
                src={calendarIcon}
                alt=""
                className="conditions__clock-icon--1"
              />
            </div>
          </form>
        </div>
      </div>
      <div className="conditions__buttons">
        <SButtonGreen onClick={toggleWork__4}>Зберегти</SButtonGreen>
      </div>
    </div>
  );
};
