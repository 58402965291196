import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import moment from "moment";

import uk from "date-fns/locale/uk";
import "moment/locale/uk";
import React, { useRef, useState } from "react";
import InputMask from "react-input-mask";

const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const DatePickerFragment = (props) => {
  console.log(props);
  let propsEdit = {
    ...props,
    Icon: true,
    IconGray: true,
    isClearable: true,
  };
  const { Icon, IconGray } = props;
  const refData = useRef(null);
  const years = range(1990, moment(new Date()).year() + 5, 1);
  const months = moment.monthsShort();
  const [isOpen, setOpen] = useState(false);
  return (
    <label>
      <DatePicker
        placeholderText={props.placeholder}
        customInput={
          props.dateFormat === "dd.MM.yyyy" ? (
            <InputMask
              maskChar={""}
              mask={"99.99.9999"}
              disabled={props.disabled}
            />
          ) : (
            props.dateFormat === "dd/MM/yyyy" && (
              <InputMask
                disabled={props.disabled}
                maskChar={""}
                mask={"99/99/9999"}
              />
            )
          )
        }
        open={isOpen}
        onClickOutside={() => {
          setOpen(false);
        }}
        onSelect={() => {
          setOpen(false);
        }}
        onFocus={function () {
          setOpen(true);
        }}
        locale={uk}
        {...propsEdit}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 0,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              style={{
                display: "flex",
                justifyContent: "center",
                color: "#FFF",
                alignItems: "center",
                borderRadius: "2px 0 0 2px",
                width: 20,
                height: 20,
                background: "#009640",
              }}
              onClick={(e) => {
                e.preventDefault();
                decreaseMonth();
              }}
              disabled={prevMonthButtonDisabled}
            >
              {"<"}
            </button>
            <select
              value={moment(date).year()}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[moment(date).month()]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button
              style={{
                display: "flex",
                justifyContent: "center",
                color: "#FFF",
                alignItems: "center",
                borderRadius: "0 2px 2px 0",
                width: 20,
                height: 20,
                background: "#009640",
              }}
              onClick={(e) => {
                e.preventDefault();
                increaseMonth();
              }}
              disabled={nextMonthButtonDisabled}
            >
              {">"}
            </button>
          </div>
        )}
      />
      {IconGray && IconGray}
      {Icon && (
        <img
          className={Icon.className}
          alt=""
          src={Icon.src}
          onClick={() => console.log(refData.current, "data")}
        />
      )}
    </label>
  );
};
export default DatePickerFragment;
