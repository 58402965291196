import React from "react";

import "./EmployeeDataPopUp.css";

import searchIconGrey from "../icons/search-icon-grey.svg";

import { MButtonGreen } from "../../../styledComponents/Buttons";
import { MButtonGreenBorder } from "../../../styledComponents/Buttons";

export const EmployeeDataPopUp = () => {
  return (
    <div className="employee-data">
      <div className="employee-data__header">
        <p>Дані підрядника</p>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.3014 8.25571C10.1553 8.10959 10.1553 7.89041 10.3014 7.74429L15.7808 2.26484C15.9269 2.11872 16 1.89954 16 1.75342C16 1.60731 15.9269 1.38813 15.7808 1.24201L14.758 0.219178C14.6119 0.0730594 14.3927 0 14.2466 0C14.0274 0 13.8813 0.0730594 13.7352 0.219178L8.25571 5.69863C8.10959 5.84475 7.89041 5.84475 7.74429 5.69863L2.26484 0.219178C2.11872 0.0730594 1.89954 0 1.75342 0C1.60731 0 1.38813 0.0730594 1.24201 0.219178L0.219178 1.24201C0.0730594 1.38813 0 1.60731 0 1.75342C0 1.89954 0.0730594 2.11872 0.219178 2.26484L5.69863 7.74429C5.84475 7.89041 5.84475 8.10959 5.69863 8.25571L0.219178 13.7352C0.0730594 13.8813 0 14.1005 0 14.2466C0 14.3927 0.0730594 14.6119 0.219178 14.758L1.24201 15.7808C1.38813 15.9269 1.60731 16 1.75342 16C1.89954 16 2.11872 15.9269 2.26484 15.7808L7.74429 10.3014C7.89041 10.1553 8.10959 10.1553 8.25571 10.3014L13.7352 15.7808C13.8813 15.9269 14.1005 16 14.2466 16C14.3927 16 14.6119 15.9269 14.758 15.7808L15.7808 14.758C15.9269 14.6119 16 14.3927 16 14.2466C16 14.1005 15.9269 13.8813 15.7808 13.7352L10.3014 8.25571Z"
            fill="white"
          />
        </svg>
      </div>
      <div className="employee-data__section">
        <div className="employee-data__box-left">
          <p className="employee-data__title title">Персональні дані</p>
        </div>
        <div className="employee-data__box-right">
          <form className="employee-data__form" action="#">
            <div className="employee-data__surname">
              <p>Прізвище</p>
              <input
                tabIndex="1"
                name="surname"
                type="text"
                className="input"
              />
            </div>
            <div className="employee-data__name">
              <p>Ім’я</p>
              <input tabIndex="2" name="name" type="text" className="input" />
            </div>
            <div className="employee-data__patronymic.">
              <p>По батькові</p>
              <input
                tabIndex="3"
                name="patronymic"
                type="text"
                className="input"
              />
            </div>
          </form>
        </div>
      </div>
      <div className="employee-data__section">
        <div className="employee-data__box-left">
          <p className="employee-data__title title">
            Адреса місця реєстрації або проживання
          </p>
        </div>
        <div className="employee-data__box-right">
          <form className="employee-data__form" action="#">
            <div className="employee-data__street">
              <p>Вулиця</p>
              <input
                tabIndex="4"
                name="street"
                type="text"
                className="street-input input"
              />
              <img
                className="employee-data__search-icon"
                src={searchIconGrey}
                alt=""
              />
            </div>
            <div className="employee-data__house">
              <p>Будинок</p>
              <input
                tabIndex="5"
                name="street"
                type="text"
                className="house-input input"
              />
            </div>
            <div className="employee-data__flat">
              <p>Квартира</p>
              <input
                tabIndex="5"
                name="street"
                type="text"
                className="flat-input input"
              />
            </div>
          </form>
        </div>
      </div>
      <div className="employee-data__buttons">
        <MButtonGreenBorder>Скасувати</MButtonGreenBorder>
        <MButtonGreen>Зберегти</MButtonGreen>
      </div>
    </div>
  );
};
