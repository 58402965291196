import styled from "styled-components"
import searchIconDisabled from '../../../images/icons/search.svg'
import searchIconEnabled from '../../../images/icons/search_active.svg'
import Loader from "../../loaders/Loader"

const ButtonTemplate = styled.button.attrs(props => ({
    disabled: props.disabled
}))`
    height: 34px;
    width: 50px;
    border-radius: 0 5px 5px 0;
    box-sizing: border-box;
    border: ${({disabled}) => disabled ? '2px solid #EFEFEF' : '2px solid #F2C94C'};
    background-color: ${({disabled}) => disabled ? '#FAFAFA' : '#F2C94C'};
    cursor: ${({disabled}) => disabled ? 'auto' : 'pointer'};
    &:hover{
        background-color: ${({disabled}) => disabled ? '#FAFAFA' : '#FFDC64'};
        border-color: ${({disabled}) => disabled ? '#EFEFEF' : '#FFDC64'};
    }
`

const ButtonSearchInput = props => {

    const { disabled, loading } = props || false

    return(
        <ButtonTemplate disabled={disabled} {...props}>
            { loading ? <Loader/> :
                <img src={searchIconEnabled} style={{opacity: `${disabled ? '.5' : '1'}`}}></img>
            }
        </ButtonTemplate>
    )
}

export default ButtonSearchInput