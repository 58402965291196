//libs
import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { store } from "../../../index";
import { Formik, useFormik } from "formik";
//components
import Modal from "../modalComponents/Modal/Modal";
import TextInput from "../../Inputs/TextInput/TextInput";
import ModalAcceptButton from "../../buttons/ModalAcceptButton/ModalAcceptbutton";
import Input from "../../Inputs/Input/Input";
//hoooks
import "../modalComponents/Modal/Modal.css";
import { useAlert } from "react-alert";
import { editUserPassword } from "../../../redux/user/user-thunks";

const ChangePassword = ({ closeFunc, editUserPassword }) => {
  const alert = useAlert();

  const alertFunc = (message, type) => {
    alert.show(message, { type: type });
  };

  const formik = useFormik({
    initialValues: { oldPassword: "", password: "", repeat: "" },
    onSubmit: async (values) => {
      editUserPassword(values, closeFunc, alertFunc);
    },
  });

  return (
    <Modal title="Зміна пароля" toggleFunc={closeFunc}>
      <form onSubmit={formik.handleSubmit}>
        <div className="Modal__content">
          <label className="Modal__text">Старий пароль</label>
          <Input
            id="oldPassword"
            name="oldPassword"
            type="password"
            placeholder="Старий пароль"
            onChange={formik.handleChange}
            value={formik.values.oldPassword}
          />
          <label className="Modal__text">Новий пароль</label>
          <Input
            id="password"
            name="password"
            type="password"
            placeholder="Новий пароль"
            onChange={formik.handleChange}
            value={formik.values.password}
          />
          <label className="Modal__text">Повторіть новий пароль</label>
          <Input
            id="repeat"
            name="repeat"
            type="password"
            placeholder="Повторіть новий пароль"
            onChange={formik.handleChange}
            value={formik.values.repeat}
          />
        </div>
        <div className="Modal__button-row">
          <ModalAcceptButton type="submit">Зберегти</ModalAcceptButton>
        </div>
      </form>
    </Modal>
  );
};

export default connect(null, { editUserPassword })(ChangePassword);
