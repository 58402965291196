import styled from "styled-components"
import Button from "../Button/Button"

const ModalAcceptButton = styled(Button).attrs(props => ({
    disabled: props.disabled || false
}))`
    height: 36px;
    width: 157px;
    background: ${props => props.disabled ? '#F2f2f2' : '#27AE60'};
    text-decoration: none;
    -webkit-text-decoration: none;
`

// const ModalAcceptButton = props => {

//     const {active, onClick, text} = props

//     return (
//         <Button
//             active={active !== false}
//             height='36px'
//             width='157px'
//             color='#27AE60'
//             onClick={onClick}
//             text={text}
//             shadow={active !== false}
//             weight='normal'
//         />
//     )
// }

export default ModalAcceptButton