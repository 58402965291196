import React, { useState } from "react";
import "./Home.css";
import Header from "../../components/layout/Header/Header";
import Start from "../Start/Start";
import { Users } from "../Users/Users";
import { Kadri } from "../Kadri/kadri";
import { Personal } from "../Personal/Personal";
import { Shop } from "../Shop/Shop";
import { Route, Switch } from "react-router-dom";
import { hasRole } from "../../utilits/hasRole";
import { Rent } from "../Rent/Rent";
import { shallowEqual, useSelector } from "react-redux";
import { Controller } from "../Controller/Controller";
import Statistic from "../Statistic";
import { Reference } from "../Reference/Reference";

export const Home = () => {
  const user = useSelector((state) => state.user, shallowEqual);
  const canAddPosition = ["regionalManager"].includes(user.userType);
  return (
    <div className="home">
      <Header
        user={user}
        manager={user.personal ? user.personal.length >= 1 : false}
      />
      <div className="content">
        <Switch>
          {(canAddPosition || hasRole(user, "ROLE_RENT")) && (
            <Route path="/home/rent" component={() => <Rent />} />
          )}
          {hasRole(user, "ROLE_STORE") && (
            <Route exact path="/home" component={Shop} />
          )}
          {user.personal
            ? user.personal.length >= 1 && (
                <Route
                  path="/home/personal"
                  component={() => <Personal landlordArray={user.personal} />}
                />
              )
            : false}
          {hasRole(user, "ROLE_CONTROLLER") && (
            <Route path="/home/controller/" component={Controller} />
          )}
          {hasRole(user, "ROLE_HR") && (
            <Route path="/home/kadri/" component={Kadri} />
          )}
          {<Route path="/home/admin/" component={Users} />}
          <Route
            path="/home/statistic/"
            component={() => <Statistic user={user} />}
          />
          <Route path="/home/profile/" component={Start} />
          <Route path="/home/reference/" component={Reference} />
        </Switch>
      </div>
    </div>
  );
};
export default React.memo(Home);
