const escapeRegExp = (str = "") =>
  str.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");

const Highlight = ({ search = "", children = "" }) => {
  const patt = new RegExp(`(${escapeRegExp(search)})`, "i");
  const parts = String(children).split(patt);

  if (search) {
    return parts.map((part, index) =>
      patt.test(part) ? <strong key={index}>{part}</strong> : part
    );
  } else {
    return children;
  }
};

export const StrongString = ({ str, children }) => {
  const split_str = children.split(",");
  console.log(split_str, "split_str");

  return (
    <>
      <strong>{split_str[0]}</strong>

      <span style={{ fontWeight: 300 }}>
        {split_str.length >= 2 ? `,${split_str[1]}` : ""}
        {split_str.length >= 3 ? `,${split_str[2]}` : ""}
        {split_str.length === 4 ? `,${split_str[3]}` : ""}
      </span>
    </>
  );
};

export default Highlight;
