import DatePickerInline from "../../../components/datePickerInline";
import {useEffect, useState} from "react";
import styled from "../assets/style/info.module.css"
import moment from "moment";
import loadControllerHistory from "../../../redux/actions/controller/loadControllerHistory";
import {useDispatch} from "react-redux";
import {diffMinutes, getTimeFromMins, sumArray} from "../../../utilits/minutsToHours";

const momentFullDate = (date) => {
  return moment(new Date(date)).format("YYYY-MM-DD")
}

const ControllerUserInfo = ({history, visitorId,date}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [historyArray, setHistory] = useState(null);
  const dispatch = useDispatch()
  const historyRealTime = () => {
    if (!historyArray) return;
    setHistory(...history.filter(el => moment(momentFullDate(el.dateSearch)).isSame(momentFullDate(new Date()) !== momentFullDate(startDate) ? momentFullDate(startDate) : momentFullDate(new Date()))))
    dispatch(loadControllerHistory(history[0].objectId, visitorId))
  }
  useEffect(() => {
    setHistory(...history.filter(el => moment(momentFullDate(el.dateSearch)).isSame(momentFullDate(new Date()) !== momentFullDate(startDate) ? momentFullDate(startDate) : momentFullDate(new Date()))))
  }, [startDate, history])
  const onChange = (dates) => {
    setStartDate(dates);
  };

  const hoursWork = ({doorLog}) => {
    let arrayMinutes = [];

    doorLog.filter((el, index) => doorLog[index + 1] ? doorLog[index].nDoor !== doorLog[index + 1].nDoor : el).forEach((items, index, array) => {
      console.log(`${moment(array[index].stuTime)}`)
      if ((index + 1) % 2 !== 0 && array.length >= 1) {

        array[index + 1]?.stuTime && arrayMinutes.push(diffMinutes(moment(array[index + 1].stuTime), moment(array[index].stuTime)))
      }
    })

    return Math.floor(sumArray(arrayMinutes)) === 0 ? "невірні дані обліку" : getTimeFromMins(Math.floor(sumArray(arrayMinutes)))
  }

  useEffect(() => {
    const timerID = setInterval(() => historyRealTime(), 10000);

    return () => clearInterval(timerID);
  })

  return <div className={styled.controller__user__wrapper}>
    <div className={styled.controller__user__row}>
      <h2 className={styled.controller__user__h2}>Календар</h2>
      <DatePickerInline selected={startDate}
                        dayClassName={(date) =>
                          history.some(el => el.doorLog.length >= 1 && moment(new Date(el.dateSearch).toDateString()).isSame(new Date(date).toDateString())) ? "history" : undefined
                        }
                        onChange={onChange}
                        showMonthDropdown
                        showYearDropdown
                        inline/>
    </div>
    {historyArray && <div className={styled.controller__user__row}>
      <div className={styled.controller__user__text__row}>
        <h2 className={styled.controller__user__h2}>Деталі</h2>
        <p>Станом на: <span>{moment(date).format("HH:mm:ss")}</span></p>
      </div>
      <div className={styled.controller__user__detail}>
        <table className={styled.controller__user__detail__table}>
          <thead className={styled.controller__user__box} style={{padding: 0}}>
          <tr>
            <th>Час</th>
            <th>Подія</th>
            <th>Метод</th>
          </tr>
          </thead>
          <tbody className={styled.controller__user__scroll}>
          {historyArray.doorLog.length >= 1 ? historyArray.doorLog.map(el => <tr>
            <td>{moment(el.stuTime).add(2, "hours").format("HH:mm:ss")}</td>
            <td>{!el.nDoor ? "Вхід" : "Вихід"}</td>
            <td>Відбиток</td>
          </tr>) : <tr align="center">
            <td style={{gridColumn: "1/4", textAlign: "center"}}>Історії немає</td>
          </tr>}
          </tbody>
        </table>
        <div className={`${styled.controller__user__box} ${styled.controller__user__text}`}>
          Тривалість робочтого
          дня <span>{hoursWork(historyArray)}</span>
        </div>
      </div>
    </div>}
  </div>
}

export default ControllerUserInfo;