import {getCookie} from "../../utilits/cookies";
import {CHANGE_MY_INFO_URL, CHANGE_MY_PASSWORD, TOGGLE_NOTIFICATONS} from "../../constants/urls";
import loadUserData from "../actions/loadUserData";
import instance from "../instance";

export const editNotificationSettings = (body, alert) => async (dispatch) => {
  const token = getCookie('token')
  try {
    const response = await instance.post(TOGGLE_NOTIFICATONS, body)
    if (response.status === 200) {
      dispatch(loadUserData(token))
      alert(response.data.message, 'success')
    } else {
      throw Error(response.data)
    }
  } catch (e) {
    alert(e.message, 'error')
  }
}

export const editUserName = (body, close, alert) => async (dispatch) => {
  const token = getCookie('token')
  try {
    const response = await instance.post(CHANGE_MY_INFO_URL, body)
    if (response.status === 200) {
      close()
      alert(response.data.message, 'success')
      dispatch(loadUserData(token))
    } else {
      throw Error(response.data)
    }
  } catch (e) {
    console.log(e.message)
  }
}

export const editUserPassword = (body, close, alert) => async () => {
  try {
    const response = await instance.post(CHANGE_MY_PASSWORD, body)
    if (response.status === 200) {
      close()
      alert(response.data.message, 'success')
    } else {
      throw Error(response.data.message)
    }
  } catch (e) {
    alert(e.message, 'error')
  }
}
