import Button from "../Button/Button"
import refreshIcon from '../../../images/icons/reload.svg'
import styled from "styled-components"

const RefreshButton = props => {

    const {onClick, disabled} = props
    const StyledButton = styled(Button).attrs(props => ({
        disabled: props.disabled || false
    }))`
        width: 116px;
        height: 36px;
        background: ${props => props.disabled ? null :'#0DADD9'};    
    `

    return (
        <StyledButton
            disabled={disabled}
            height='36px'
            width
            color='#0DADD9'
            onClick={onClick}
        >
            { !disabled && <img src={refreshIcon}></img>}
            <span>{!disabled ? 'Оновити' : 'Оновлено'}</span>
        </StyledButton>
    )
}

export default RefreshButton