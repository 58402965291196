import {REMOVE_ALERT, SHOW_ALERT} from "../types"

const initialAlert = {
  message: "",
  type: ""
}

export const alertReducer = (state = initialAlert, action) => {
  switch (action.type) {
    case SHOW_ALERT:
      return {...state, message: action.payload.message, type: action.payload.type}
    case REMOVE_ALERT:
      return action.payload
    default:
      return state
  }
}
