import "./style.css"


const ButtonLoading = ({children, color = "#FFF", scale, isActive}) => {
  const styled = {
    transform: `translate(0%,-50%) scale(${scale})`,
    color: color
  }
  return <div className={isActive ? "button__loader active" : "button__loader"}>
    <div className={"button__loader__sinner"} style={styled}></div>
    {children}
  </div>
}
export default ButtonLoading