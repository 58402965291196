export const regions = {
  'ВНЦ': { id: 1, name: 'Вінниця' },
  'КВЛ': { id: 3, name: 'Волинь (Ковель)' },
  'ЖТР': { id: 4, name: 'Житомир' },
  'ЗКТ': { id: 5, name: 'Закарпаття' },
  'КИВ': { id: 6, name: 'Київ' },
  'ЛЬВ': { id: 7, name: 'Львів' },
  'РВН': { id: 8, name: 'Рівне' },
  'СУМ': { id: 9, name: 'Суми' },
  'ЛЦК': { id: 9, name: 'Луцьк' },
  'ТЕР': { id: 10, name: 'Тернопіль' },
  'ХМК': { id: 11, name: 'Хмельницький' },
  'ЧКС': { id: 12, name: 'Черкаси' },
  'ЧРН': { id: 14, name: 'Чернігів' },
};

export default regions;
