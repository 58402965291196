import {DELETE_CONTROLLER} from "../../../constants/urls";
import instanceController from "../../instanceController";
import showAlert from "../alerts/showAlert";
import loadControllerIp from "./loadControllerIp";
import loadController from "./loadController";

const deletedController = (objectId) => async (dispatch) => {
  try {
    const response = await instanceController.post(DELETE_CONTROLLER, {objectId: objectId})
    dispatch(loadControllerIp());
    dispatch(loadController())
    return response;
  } catch (e) {
    dispatch(showAlert(e.message, 'error'))
  }
}

export default deletedController
