export const userTypes = {
    'administrator': { id: 1, name: 'Адміністратор магазину' },
    'assistantAdministrator': { id: 8, name: 'Заступник Адміністратора' },
    'storeManager': { id: 9, name: 'Керуюча магазину' },
    'warehouseManager': { id: 7, name: 'Завідуючий складом' },
    'assistanWarehouseManagert': { id: 10, name: 'Заступник завідуючого складом' },
    'headAccountant': { id: 11, name: 'Головний бухгалтер' },
    'accountant': { id: 2, name: 'Бухгалтер' },
    'networkManager': { id: 12, name: 'Керівник мережі' },
    'regionalManager': { id: 3, name: 'Регіональний директор' },
    'assistantRegionalManager': { id: 3, name: 'Заступник регіонального директора' },
    'headHr': { id: 13, name: 'Директор служби HR' },
    'hr': { id: 5, name: 'Менеджер служби HR' },
    'lawyer': { id: 6, name: 'Юрист' },
    // 'director': { id: 4, name: 'Директор' },
};

export default userTypes;