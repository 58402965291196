import React, { useEffect, useState } from "react";

import closeGrey from "../../../../../assets/closeGrey.svg";
import printer from "../../../../../assets/printer.svg";

import { TextGrey } from "../../../../../styledComponents/Text";
import { InputForm, SelectForm } from "../../../../../styledComponents/Inputs";
import { connect, useDispatch } from "react-redux";
import {
  downloadFrees,
  getVacations,
} from "../../../../../redux/cadrDocuments/cadrDocuments-thunks";
import getDateAndTime from "../../../../../utilits/getDateAndTime";
import { downloadDocument } from "../../../../../redux/workers/workers-thunks";
import { docTypes } from "../../../../../redux/types";
import KadriPreviewDocument from "../CardsPersonEdit/KadriPreviewDocument";
import { setPreviewFile } from "../../../../../redux/workers/worker-actions";
import moment from "moment";
import DatePickerFragment from "../../../../../components/datePicker/datePicker";
import Select from "react-select";
import { Formik } from "formik";
import {
  MButtonGreen,
  MButtonOrange,
  SButtonRed,
} from "../../../../../styledComponents/Buttons";
import {
  addVacationFuture,
  deletedVacationFuture,
} from "../../../../../api/kadriConditions";
import { useAlert } from "react-alert";
import datePicker from "../../../../../assets/datePicker.svg";
import ReactTooltip from "react-tooltip";
import ButtonLoading from "../../../../../components/buttons/ButtonLoading";

export let KadriCardsDocModal = ({
  toggleDocModal,
  vacations,
  getVacations,
  workers,
  downloadFrees,
  downloadDocument,
  ...props
}) => {
  const [date, setDate] = useState(null);
  const [isLoader, setLoader] = useState(false);
  const [year, setYear] = useState(new Date().getUTCFullYear());
  const [isFuture, setIsFuture] = useState(false);
  //   useEffect(() => {
  //     const years = getYears();
  //     setYear(years);
  // }, []);

  // const getYears = () => {
  //   const currentYear = new Date().getUTCFullYear();
  //   const previousYear = currentYear - 1;
  //   const nextYear = currentYear + 1;
  //   console.log(previousYear, currentYear, nextYear, "dates");
  //   return [previousYear, currentYear, nextYear];
  // };
  const [vacationList, setVacationList] = useState([
    {
      value: "щорічна основна відпустка",
      label: "щорічна основна відпустка",
    },
    {
      value: "додаткова відпустка у зв'язку з навчанням",
      label: "додаткова відпустка у зв'язку з навчанням",
    },
    {
      value: "відпустка у зв'язку з вагітністю та пологами",
      label: "відпустка у зв'язку з вагітністю та пологами",
    },
    {
      value:
        "відпустка для догляду за дитиною до досягнення нею трирічного віку",
      label:
        "відпустка для догляду за дитиною до досягнення нею трирічного віку",
    },
    {
      value: "відпустка без збереження заробітної плати",
      label: "відпустка без збереження заробітної плати",
    },
  ]);
  useEffect(() => {
    getVacations(null, year);
  }, []);
  const addVacation = (e) => {
    e.preventDefault();
    setIsFuture(true);
  };
  const customStyles = {
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
      paddingLeft: 12,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 140,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    option: (provided) => ({
      ...provided,
      padding: "2px 12px",
      fontSize: "13px",
    }),
    multiValue: (provided) => ({
      ...provided,
      margin: 2,
      height: 24,
      display: "flex",
      alignItems: "center",
      fontSize: "14px",
      "div:nth-child(1)": {
        display: "flex",
        alignItems: "center",
        padding: "0 3px",
        height: 24,
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0,
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "12px",
    }),
    input: (provided) => ({
      ...provided,
      margin: 0,
      height: 24,
      padding: 0,
      input: {
        height: 24,
      },
    }),
    control: (provided) => ({
      ...provided,
      minHeight: 20,
    }),
    container: (provided) => ({
      ...provided,
      position: "relative",
      width: "100%",
      display: "inline-block",
    }),
  };
  const alert = useAlert();
  const deleteVacation = async (e, id) => {
    e.preventDefault();
    await deletedVacationFuture(id).then((res) => {
      alert.success(res.data);
      getVacations(null, year);
    });
  };

  const VacationForm = () => {
    let currentWorker = workers.filter(
      (el) =>
        !moment(el.postInfo.uvalLastDay, "DD.MM.YYYY").isBefore(new Date()) &&
        el.postInfo?.currentStatus !== "cancellation" &&
        el.postInfo?.currentStatus !== "deleted" &&
        el.postInfo.status !== "Неоформленный"
    );
    console.log(currentWorker);
    const handleSubmit = (data) => {
      console.log(data);
      addVacationFuture(data).then((res) => {
        getVacations(null, year);
        setIsFuture(false);
      });
    };
    return (
      <Formik
        initialValues={{
          startDate: null,
          id: null,
          finishDate: null,
          posada: "-",
          vacation: "",
          fullName: "",
        }}
        onSubmit={handleSubmit}
      >
        {({
          values,
          setFieldValue,
          submitForm,
          /* and other goodies */
        }) => (
          <tr className="table-row">
            <td>-</td>
            <td>{values.posada}</td>
            <td>
              <Select
                placeholder={"Виберіть зі списку"}
                onChange={(data) => {
                  setFieldValue(
                    "id",
                    currentWorker.filter((el) => data.value === el._id)[0]._id
                  );
                  setFieldValue(
                    "posada",
                    currentWorker.filter((el) => data.value === el._id)[0]
                      .postInfo.status
                  );
                  setFieldValue("fullName", data.label);
                }}
                components={{
                  IndicatorSeparator: false,
                }}
                styles={customStyles}
                options={currentWorker.map((el) => {
                  return {
                    value: el._id,
                    label: `${el.surname} ${el.name} ${el.patronymic}`,
                  };
                })}
              />
            </td>
            <td
              style={{
                width: 200,
              }}
            >
              <Select
                onChange={(value) => setFieldValue("vacation", value.label)}
                components={{
                  IndicatorSeparator: false,
                }}
                styles={customStyles}
                placeholder={"Виберіть зі списку"}
                options={vacationList}
              />
            </td>
            <td
              className="table__icon "
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <div
                className="vacation__date"
                style={{ maxWidth: 85, width: 100 }}
              >
                <DatePickerFragment
                  // minDate={new Date()}
                  dateFormat="dd.MM.yyyy"
                  selected={values.startDate}
                  onChange={(date) =>
                    setFieldValue("startDate", new Date(date).setHours(12))
                  }
                />
              </div>
              <span style={{ marginLeft: 10, marginRight: -6 }}>-</span>
              <div className="vacation__date" style={{ maxWidth: 85 }}>
                <DatePickerFragment
                  // minDate={new Date()}
                  dateFormat="dd.MM.yyyy"
                  selected={values.finishDate}
                  onChange={(date) =>
                    setFieldValue("finishDate", new Date(date).setHours(12))
                  }
                />
              </div>
            </td>
            <td className="table__icon">-</td>
            <td className="table__icon" style={{ color: "#2F80ED" }}>
              <MButtonGreen
                onClick={() => submitForm()}
                type="submit"
                style={{
                  padding: "6px 8px",
                  height: "inherit",
                  width: "inherit",
                }}
              >
                Зберегти
              </MButtonGreen>
            </td>
          </tr>
        )}
      </Formik>
    );
  };
  return (
    <>
      <div className="doc__modal__container">
        <div className="doc__modal__header">
          <div className="modal__header__title">
            <div></div>
            <TextGrey style={{ textAlign: "center", marginBottom: "16px" }}>
              Графік відпусток працівників
            </TextGrey>
            <button onClick={toggleDocModal} className={"modal__close"}>
              <img src={closeGrey} />
            </button>
          </div>
          <div className="modal__header__select">
            <SelectForm
              onChange={(e) => {
                setYear(e.target.value);
                getVacations(null, e.target.value);
              }}
            >
              <option value={moment(new Date()).subtract(1, "years").year()}>
                {moment(new Date()).subtract(1, "years").year()} рік
              </option>
              <option value={new Date().getUTCFullYear()} selected>
                {new Date().getUTCFullYear()} рік
              </option>
              <option value={moment(new Date()).add(1, "years").year()}>
                {moment(new Date()).add(1, "years").year()} рік
              </option>
              <option value={moment(new Date()).add(2, "years").year()}>
                {moment(new Date()).add(2, "years").year()} рік
              </option>
            </SelectForm>
          </div>
        </div>
        <button className="buttonVacation" onClick={addVacation}>
          +
        </button>
        <div className="doc__modal__table">
          <table className="table" style={{ fontSize: "13px" }}>
            <thead>
              <tr className="table-row">
                <th>№</th>
                <th>Посада</th>
                <th>ПІБ</th>
                <th>Вид відпустки</th>
                <th className="table__icon">Період відпустки</th>
                <th>Тривалість відпустки</th>
                <th style={{ textAlign: "center" }}>
                  Наказ <br /> (розпорядження)
                </th>
              </tr>
            </thead>
            <tbody>
              {isFuture && <VacationForm />}
              {vacations
                .filter((items) => items.info.nomer !== 0)
                .map((vacation, index) => {
                  let currentWorker = workers.find(
                    (w) => w._id === vacation.indexId
                  );
                  if (currentWorker === undefined) return null;
                  // let dayCount = ((new Date(vacation.info.exp.end) - new Date(vacation.info.exp.start))/(24*3600*1000)).toString()
                  let lastNumb = vacation.info.exp.timeFree
                    ? vacation.info.exp.timeFree[
                        vacation.info.exp.timeFree.length - 1
                      ]
                    : "";
                  return (
                    <tr className="table-row">
                      <td>{index + 1}</td>
                      <td>{currentWorker.postInfo.status}</td>
                      <td>
                        {currentWorker.surname} {currentWorker.name}{" "}
                        {currentWorker.patronymic}
                      </td>
                      <td>{vacation.info.typeText}</td>
                      <td className="table__icon">
                        {vacation.info.exp.start} - {vacation.info.exp.end}
                      </td>
                      <td className="table__icon">
                        {vacation.info.exp.timeFree + 1}{" "}
                        {lastNumb === "1"
                          ? "день"
                          : lastNumb === "2" ||
                            lastNumb === "3" ||
                            lastNumb === "4"
                          ? "дні"
                          : "днів"}
                      </td>
                      <td
                        onClick={() =>
                          downloadDocument([vacation.filename], true)
                        }
                        className="table__icon"
                        style={{ color: "#2F80ED", cursor: "pointer" }}
                      >
                        {vacation.info.nomer === 0
                          ? "-"
                          : `№ ${vacation.info.nomer} від ${getDateAndTime(
                              new Date(vacation.createdAt),
                              true
                            )}`}
                      </td>
                    </tr>
                  );
                })}

              <tr
                style={{
                  background: "#dddddd",
                  borderTop: "1px #ededed",
                }}
              >
                <td
                  colSpan={8}
                  style={{
                    textAlign: "center",
                    color: "black",
                    padding: "4px 0",
                  }}
                >
                  Заплановані відпустки
                </td>
              </tr>
              {vacations
                .filter((items) => items.info.nomer === 0)
                .map((vacation, index) => {
                  let currentWorker = workers.find(
                    (w) => w._id === vacation.indexId
                  );
                  if (currentWorker === undefined) return null;
                  // let dayCount = ((new Date(vacation.info.exp.end) - new Date(vacation.info.exp.start))/(24*3600*1000)).toString()
                  let lastNumb = vacation.info.exp.timeFree
                    ? vacation.info.exp.timeFree[
                        vacation.info.exp.timeFree.length - 1
                      ]
                    : "";
                  return (
                    <tr className="table-row">
                      <td>-</td>
                      <td>{currentWorker.postInfo.status}</td>
                      <td>
                        {currentWorker.surname} {currentWorker.name}{" "}
                        {currentWorker.patronymic}
                      </td>
                      <td>{vacation.info.typeText}</td>
                      <td className="table__icon">
                        {vacation.info.exp.start} - {vacation.info.exp.end}
                      </td>
                      <td className="table__icon">
                        {vacation.info.exp.timeFree + 1}{" "}
                        {lastNumb === "1"
                          ? "день"
                          : lastNumb === "2" ||
                            lastNumb === "3" ||
                            lastNumb === "4"
                          ? "дні"
                          : "днів"}
                      </td>
                      <td
                        onClick={() =>
                          downloadDocument([vacation.filename], true)
                        }
                        className="table__icon"
                        style={{ color: "#2F80ED", cursor: "pointer" }}
                      >
                        {vacation.info.nomer === 0 ? (
                          <SButtonRed
                            onClick={(e) => deleteVacation(e, vacation._id)}
                            style={{
                              padding: "4px 6px",
                              height: "inherit",
                            }}
                          >
                            Видалити
                          </SButtonRed>
                        ) : (
                          `№ ${vacation.info.nomer} від ${getDateAndTime(
                            new Date(vacation.createdAt),
                            true
                          )}`
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="doc__modal__footer__btn" style={{ padding: 0 }}>
            <div className="conditions__calendar" style={{ marginRight: 8 }}>
              <DatePickerFragment
                selected={date}
                dateFormat="dd.MM.yyyy"
                placeholderText="Введіть дату"
                IconGray={
                  <div
                    className="item__input__check"
                    type={"button"}
                    style={{ paddingTop: "8px", marginBottom: "2px" }}
                  >
                    <span>
                      <img src={datePicker} />
                    </span>
                  </div>
                }
                onChange={(data) => setDate(data.setHours(12))}
              />
            </div>
            <div data-tip data-for={"vacationButton"}>
              <ButtonLoading color="black" scale={0.4} isActive={isLoader}>
                <button
                  onClick={() => downloadFrees(setLoader, year, date)}
                  disabled={date === null}
                >
                  <img
                    src={printer}
                    style={{ filter: date == null ? "invert(70%)" : "inherit" }}
                  />
                  <p>
                    Відкрити у <br /> PDF форматі
                  </p>
                </button>
              </ButtonLoading>
            </div>
            {date == null && (
              <ReactTooltip
                id="vacationButton"
                effect={"solid"}
                backgroundColor={"#52616E"}
              >
                Виберіть дату документа
              </ReactTooltip>
            )}
          </div>
        </div>
      </div>
      {docTypes.indexOf(props.previewFile?.fileType) !== -1 && (
        <KadriPreviewDocument
          file={props.previewFile}
          closeModal={() => {
            props.setPreviewFile(null);
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  vacations: state.cadrDocumentsReducer.vacations,
  workers: state.workers,
  previewFile: state.workersReducer.previewFile,
});

KadriCardsDocModal = connect(mapStateToProps, {
  getVacations,
  downloadFrees,
  downloadDocument,
  setPreviewFile,
})(KadriCardsDocModal);
