import React, {useEffect, useState} from "react";

import closeGrey from "../../../../../assets/closeGrey.svg";
import edit from "../../../../../assets/edit.svg";

import {TextGrey} from "../../../../../styledComponents/Text";
import {SButtonGrey} from "../../../../../styledComponents/Buttons";
import {KadriCardsEditAddModal} from "./KadriCardsEditAddModal";
import {deletePosition, getPositions} from "../../../../../redux/cadrDocuments/cadrDocuments-thunks";
import {connect} from "react-redux";
import {useAlert} from "react-alert";
import editIcon from "../../../../../images/icons/edit.svg";
import MiniButton from "../../../../../components/common/MiniButton/MiniButton";
import deleteIcon from "../../../../../images/icons/delete.svg";
import ReactTooltip from "react-tooltip";
import week from "../../../../../constants/week";


export let KadriCardsPosEditor = ({toggleEditorModal, positions, getPositions, deletePosition, user}) => {
  const [addEditModal, setAddEditModal] = useState(false);
  const [editPosition, setEditPosition] = useState(null)
  const alert = useAlert()
  const alertFunc = (message, type) => {
    alert.show(message, {type: type})
  }
  const canAddPosition = ['headAccountant', 'lawyer', 'headHr'].includes(user.userType);

  const toggleAddEditModal = () => {
    if (canAddPosition) {
      setAddEditModal(!addEditModal);
    } else {
      alertFunc('Ви не можете створити посаду', 'error');
    }
  };
  const workerId = window.location.pathname.slice(window.location.pathname.lastIndexOf('/') + 1)
  useEffect(() => {
    getPositions(workerId)
  }, []);

  return (
    <div className="doc__modal__container">
      <div className="doc__modal__header">
        <div className="modal__header__title">
          <div></div>
          <TextGrey style={{textAlign: "center", marginBottom: "16px"}}>
            Редактор посад
          </TextGrey>
          <button className={'modal__close'} onClick={toggleEditorModal}>
            <img src={closeGrey}/>
          </button>
        </div>
        <div className="modal__header__button__journal">
          <SButtonGrey
            onClick={toggleAddEditModal}
            style={{
              width: "149px",
              height: "36px",
              marginLeft: 0,
              marginBottom: "0",
            }}
          >
            + Створити посаду
          </SButtonGrey>
          <div></div>
          <div></div>
        </div>
      </div>
      <div className="journal__modal__table journal__modal__table--scroll">
        <table className="table" style={{fontSize: "13px", marginTop: "0"}}>
          <thead>
          <tr className="table-row">
            <th>№</th>
            <th>Код</th>
            <th>Назва посади</th>
            <th>Посадовий оклад</th>
            <th>Графік</th>
            <th style={{textAlign: "center"}}>Редагувати</th>
            <th style={{textAlign: "center"}}>Видалити</th>
          </tr>
          </thead>
          <tbody>
          {positions.sort((a, b) => a.vacancy.toLowerCase().localeCompare(b.vacancy.toLowerCase(), "uk-UA", {sensitivity: 'accent'})).map((position, index) => (
            <tr className="table-row" key={index}>
              <td>{index + 1}</td>
              <td>{position.numberProfessions ?? "-"}</td>
              <td>{position.vacancy}</td>
              <td>{Number(position.salary).toFixed(2)} ({position.percent}% мін. ЗП)</td>
              <td>{!!position.day ? week[position.day].name : "-"}</td>
              <td style={{
                display: 'flex',
                justifyContent: 'center'
              }}>
                <div data-tip data-for={'edit' + position._id}>
                  <MiniButton disabled={!canAddPosition} icon={editIcon} type='edit' onClick={() => {
                    toggleAddEditModal()
                    setEditPosition(position)
                  }}/>
                </div>
                {!canAddPosition &&
                  <ReactTooltip id={'edit' + position._id} effect={'solid'} backgroundColor={'#52616E'}>
                    Ця дія доступна лише головному бухгалтеру
                  </ReactTooltip>}
              </td>
              <td>
                <div style={{marginLeft: '40%'}}>
                  <MiniButton dataFor={'delete' + position._id} disabled={!canAddPosition}
                              icon={deleteIcon} type='edit' onClick={() => deletePosition(alertFunc, position._id)}/>
                  {!canAddPosition &&
                    <ReactTooltip id={'delete' + position._id} effect={'solid'} backgroundColor={'#52616E'}>
                      Ця дія доступна лише головному бухгалтеру
                    </ReactTooltip>}
                </div>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
      {addEditModal && <div className="bg__layer"></div>}
      {(addEditModal || editPosition) && <KadriCardsEditAddModal editPosition={editPosition}
                                                                 toggleAddEditModal={() => {
                                                                   setEditPosition(null)
                                                                   toggleAddEditModal()
                                                                 }}/>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  positions: state.cadrDocumentsReducer.positions,
  user: state.user
})

KadriCardsPosEditor = connect(mapStateToProps, {getPositions, deletePosition})(KadriCardsPosEditor)
