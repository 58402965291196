import {GET_AUTH_CONTROLLER} from "../../../constants/urls";
import instanceController from "../../instanceController";
import showAlert from "../alerts/showAlert";

const getAuthController = (body) => async (dispatch) => {
  try {
    return await instanceController.post(GET_AUTH_CONTROLLER, body);
  } catch (e) {
    dispatch(showAlert(e.message, 'error'))
  }
}

export default getAuthController;
