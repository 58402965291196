import React, {useEffect, useRef, useState} from 'react';
import {MButtonGreenBorder} from "../../../../../styledComponents/Buttons";
import FileViewer from 'react-file-viewer';
import close from '../../../../../images/icons/close.svg'
import ReactPictureViewer from "react-picture-viewer";
import turnAround from '../../../../../images/icons/turn-around.svg'
import zoomPlus from '../../../../../images/icons/zoom_plus.svg'
import zoomMinus from '../../../../../images/icons/zoom_minus.svg'

const KadriPreviewDocument = ({file, closeModal}) => {
  const [currentRotation, setCurrentRotation] = useState(0)
  const [currentScale, setCurrentScale] = useState(1)
  const [isOnePageFile, setIsOnePageFile] = useState(false)
  const fileWrapperRef = useRef(null)
  const getMargin = (scale, isOnePageFile) => {
    if (scale === 1) return 0
    let blockHeight = fileWrapperRef.current.clientHeight
    switch (scale) {
      case 1.25:
        return isOnePageFile ? 80 : blockHeight / 100 * 12.5;
      case 1.5:
        return isOnePageFile ? 160 : blockHeight / 100 * 25;
      case 1.75:
        return isOnePageFile ? 240 : blockHeight / 100 * 37.5;
      case 2:
        return isOnePageFile ? 320 : blockHeight / 100 * 50;
      default:
        return 0
    }
  }
  return (
    <div
      // style={{paddingTop: '20px', flexDirection: 'column', width: '900px', textAlign: 'center'}}
      className={'conditions__workdoc doc-viewer'}>
      <div style={{
        overflowY: 'scroll', height: '100vh',
        width: '100%', padding: '10px 0', overflowX: 'hidden'
      }}>
        {/*{(file.fileType === 'pdf') ?*/}
        {/*  <DocumentViewer*/}
        {/*    class="img"*/}
        {/*    viewerUrl={""}*/}
        {/*    url={file.base64}*/}
        {/*    viewer="url"*/}
        {/*  ></DocumentViewer>*/}
        {/*  : <FileViewer filePath={file.base64} fileType={file.fileType}*/}
        {/*  key={file.base64}*/}
        {/*/>}*/}
        {(file.fileType === 'docx' || file.fileType === 'pdf') ? <>
          <div ref={fileWrapperRef} style={{
            transform: `scale(${currentScale})`,
            margin: `${getMargin(currentScale, isOnePageFile)}px 0`
          }}>
            <FileViewer filePath={file.base64} fileType={file.fileType}
                        key={file.base64} style={{backgroundColor: 'red'}}/>
          </div>
        </> : <ReactPictureViewer className={'pictureViewer'}>
          <img style={{transform: `rotate(${currentRotation}deg)`}} src={file.base64} alt="picture" draggable="false"/>
        </ReactPictureViewer>}
      </div>
      {(file.fileType === 'docx' || file.fileType === 'pdf') ? <>
        <button onClick={() => setCurrentScale(currentScale === 1 ? 1 : currentScale - 0.25)} style={{right: 65}}
                className="doc-viewer__close">
          <img src={zoomMinus} alt=""/>
        </button>
        <button onClick={() => {
          setCurrentScale(currentScale === 2 ? 2 : currentScale + 0.25)
          setIsOnePageFile(fileWrapperRef.current.clientHeight < window.innerHeight)
        }} style={{right: 30}} className="doc-viewer__close">
          <img src={zoomPlus} alt=""/>
        </button>
      </> : <>
        <button onClick={() => setCurrentRotation(currentRotation - 90)} style={{right: 65}}
                className="doc-viewer__close">
          <img style={{transform: 'scaleX(-1)'}} src={turnAround} alt=""/>
        </button>
        <button onClick={() => setCurrentRotation(currentRotation + 90)} style={{right: 30}}
                className="doc-viewer__close">
          <img src={turnAround} alt=""/>
        </button>
      </>}
      <button onClick={closeModal} className="doc-viewer__close">
        <img src={close} alt=""/>
      </button>
    </div>
  );
};

export default KadriPreviewDocument;