import React, { useState } from "react";
import {
  SButtonGreen, SButtonLightGreen, SButtonLightGrey
} from "../../../../styledComponents/Buttons";

import closeIcon from "../../icons/close.svg";
import calendarIcon from "../../icons/calendar-grey.svg";
import ConditionsHeader from "./CardsWorkDoc/components/ConditionsHeader";
import { Divider } from "../../../../styledComponents/Dividers";
import "./KadriCards.css";
import {Formik} from "formik";
import InputMask from 'react-input-mask'
import datePicker from "../../../../assets/datePicker.svg";
import Calendar from "react-calendar";
import getDateAndTime from "../../../../utilits/getDateAndTime";

export const KadriCardsNep = ({ toggleNep }) => {
  const [beginDate, setBeginDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [isFetching, setIsFetching] = useState(false)

  return (
    <Formik
      initialValues={{
        beginDate: '',
        endDate: ''
      }}
      onSubmit={(values) => {
        console.log(values)
        toggleNep()
      }}
    >
      {({values, handleChange, handleSubmit, setFieldValue, ...formikProps}) => {
        let isValid = values.beginDate && values.endDate
        return <div className="conditions__workdoc">
          <ConditionsHeader toggle={toggleNep}>
            Оформлення непрацездатності
          </ConditionsHeader>
          <Divider/>
          <div className="conditions__content">
            <div className="conditions__content-left">
              <p>Період непрацездатності</p>
            </div>
            <div className="conditions__content-right">
              <form className="conditions__form" action="#">
                <div className="conditions__row">
                  <InputMask
                    mask={'99.99.9999'}
                    maskChar={''}
                    value={values.beginDate}
                    id={'beginDate'}
                    onChange={handleChange}
                    type="text"
                    tabIndex="4"
                    className="conditions__time-input"
                    placeholder="Початок"
                  />
                  <div className={'pos-rel'}>
                    <button className="item__input__check"
                            type={'button'}
                            style={{paddingTop: "8px", marginBottom: '2px'}}>
                    <span>
                      <img src={datePicker}/>
                    </span>
                    </button>
                    <div style={{width: '250px'}} className={"table__dropdown__content"}>
                      <Calendar
                        value={beginDate}
                        onChange={(e) => {
                          setBeginDate(e)
                          setFieldValue('beginDate', getDateAndTime(e, true))
                        }}
                      />
                    </div>
                  </div>
                  <p> - </p>
                  <InputMask
                    mask={'99.99.9999'}
                    maskChar={''}
                    value={values.endDate}
                    id={'endDate'}
                    onChange={handleChange}
                    type="text"
                    tabIndex="5"
                    className="conditions__time-input"
                    placeholder="Закінчення"
                  />
                  <div className={'pos-rel'}>
                    <button className="item__input__check"
                            type={'button'}
                            style={{paddingTop: "8px", marginBottom: '2px'}}>
                    <span>
                      <img src={datePicker}/>
                    </span>
                    </button>
                    <div style={{width: '250px'}} className={"table__dropdown__content"}>
                      <Calendar
                        value={endDate}
                        onChange={(e) => {
                          setEndDate(e)
                          setFieldValue('endDate', getDateAndTime(e, true))
                        }}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="conditions__buttons">
            {(!isFetching && isValid) ?
              <SButtonLightGreen onClick={handleSubmit}>Зберегти</SButtonLightGreen> :
              <SButtonLightGrey>Зберегти</SButtonLightGrey>
            }
          </div>
        </div>
      }}
    </Formik>
  );
};
