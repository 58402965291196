import "./App.css";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {Login} from "./pages/Login/Login";
import {Logout} from "./pages/Login/Logout";
import {Register} from "./pages/Login/Register";
import {Home} from "./pages/Home/Home";
import loadUserData from "./redux/actions/loadUserData";
import {connect, useDispatch, useSelector} from "react-redux";
import {getCookie} from "./utilits/cookies";
// import {store} from "./index";
import {setUserData} from "./redux/user/user-actions";
import React, {useEffect} from "react";
import instance from "./redux/instance";
import AlertsWrapper from "./components/alerts/AlertsContainer/AlertsContainer";
import 'react-loading-skeleton/dist/skeleton.css'

const App = () => {

  const token = useSelector(state => {
    return state.auth.token
  }) || getCookie('token')

  const dispatch = useDispatch();
  const isAuth = !!token
  const userCookies = getCookie('user')

  if (userCookies) {
    dispatch(setUserData(JSON.parse(userCookies)))
  }
  if (isAuth) {
    dispatch(loadUserData(token))
  }

  useEffect(() => {
    if (token) {
      Object.assign(instance.defaults, {headers: {Authorization: token}});
    }
  }, [token])


  return (
    <div className="App">
      <AlertsWrapper>
        <Router>
          <Switch>
            <Route exact path="/login">
              {isAuth ? <Redirect to='/home/kadri'/> : <Login/>}
            </Route>
            <Route exact path="/logout">
              <Redirect to='/login'/> : <Logout/>
            </Route>
            <Route path="/register">
              {isAuth ? <Redirect to='/home/kadri'/> : <Register/>}
            </Route>
            <Route path="/home">
              {!isAuth ? <Redirect to='/'/> : <Home/>}
            </Route>
            <Route exact path="/">
              {isAuth ? <Redirect to='/home/kadri'/> : <Login/>}
            </Route>
          </Switch>
        </Router>
      </AlertsWrapper>
    </div>
  );

}

export default connect(null,)(App)
