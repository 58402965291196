import {USER_SENDWORKER_PROOFWORKER} from "../../../constants/urls";
import {getCookie} from "../../../utilits/cookies";
import loadEmployers from "./loadEmployers";

const sendEmployer = (form, alert) => async (dispatch) => {
  const token = getCookie('token')
  try {
    const response = await fetch(USER_SENDWORKER_PROOFWORKER, {
      body: JSON.stringify(form),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
    const data = await response.json()
    if (response.ok) {
      alert(data.message, 'success')
      dispatch(loadEmployers(alert,!!localStorage.getItem("checked")))
      return {status: true}
    } else {
      throw Error(data.message)
    }
  } catch (e) {
    alert(e.message, 'error')
  }
}

export default sendEmployer
