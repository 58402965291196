export const sum = (array) => {
  return array.reduce((a, b) => a + b, 0);
};
export const sumArray = (array) => {
  return array.reduce((a, b) => a + b);
};

export const sumKeys = (obj, type) => {
  const result = obj.reduce((acc, el) => {
    const item = el[type];
    Object.entries(item).forEach(([itemKey, itemValue]) => {
      acc[itemKey] = (acc[itemKey] || 0) + itemValue;
    });
    return acc;
  }, {});

  return Object.keys(result).map(key => {
    return {
      name: key,
      value: result[key]
    }
  })
}