import {SET_ALL_EMPLOYER, SET_ALL_EMPLOYER_WORKER, SET_CURRENT_EMPLOYER} from "./employers-types";

const initialState = {
  currentEmployer: null,
  employerData: null,
  employerAll: null
}

export const employersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_EMPLOYER:
      return {...state, currentEmployer: action.currentEmployer}
    case SET_ALL_EMPLOYER_WORKER:
      return {...state, employerData: action.employerData}
    case SET_ALL_EMPLOYER:
      return {...state, employerAll: action.employerAll}
    default:
      return state
  }
}
