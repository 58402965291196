import styled from "styled-components";

const Input = styled.input.attrs(props => ({
    width: props.width || '100%',
    height: props.height || 36,
}))`
    width: calc(100% - 4px);
    height: 32px;
    margin: 2px;
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0 0 0 12px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    box-sizing: border-box;
    color: #535353;
    &:hover {
        border: 1px solid #A4A4A4;
    }
    &:focus {
        border: 3px solid #56CCF2;
        margin: 0;
        width: 100%;
    }
    &::placeholder {
        color: #BFB5BD
    }
`

export default Input
