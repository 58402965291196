import React, { Fragment, useEffect, useRef, useState } from "react";

import "./Personal.css";

import { InfoPopUp } from "../PopUps/InfoPopUp";
import { WarningPopUp } from "../PopUps/WarningPopup";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import MiniButton from "../../components/common/MiniButton/MiniButton";
import docsIcon from "../../images/icons/docs.svg";
import editIcon from "../../images/icons/edit.svg";
import deleteIcon from "../../images/icons/delete.svg";
import { deleteWorker } from "../../redux/workers/workers-thunks";
import loadCurrentWorker from "../../redux/actions/currentWorker/loadCurrentWorker";
import { KadriCardsPersonEdit } from "../Kadri/KadriItem/KadriCards/CardsPersonEdit/KadriCardsPersonEdit";
import { KadriCardsPersonEdit__2 } from "../Kadri/KadriItem/KadriCards/CardsPersonEdit/KadriCardsPersonEdit__2";
import { KadriCardsDocModal } from "../Kadri/KadriItem/KadriCards/CardsDocModal/KadriCardsDocModal";
import { store } from "../../index";
import { REMOVE_CURRENT_WORKER } from "../../redux/types";
import Practitioners from "./PopUps/modals/practitioners/Practitioners";
import {
  deletePersonal,
  getIdEmployerLength,
  getPersonal,
  getPersonalEmployerAll,
} from "../../api/personal";
import ReactTooltip from "react-tooltip";
import { Conditions } from "./PopUps/Conditions";
import moment from "moment";
import Select from "react-select";
import addressPlace from "../../utilits/addressPlace";
import { getCookie } from "../../utilits/cookies";
import {
  getPositions,
  getVacations,
} from "../../redux/cadrDocuments/cadrDocuments-thunks";
import TableCell from "../../components/tables/TableCell/TableCell";
import { diffDate } from "../../utilits/diffDate";
import KadriRow from "../Kadri/KadriItem/KadriRow/KadriRow";
import Table from "../../components/tables/Table";
import TableRow from "../../components/tables/TableRow/TableRow";
import loadWorkers from "../../redux/actions/workers/loadWorkers";

const defaultObject = {
  name: "",
  surname: "",
  lastname: "",
  address: "",
  flat: "",
  houseNumber: "",
};
const token = getCookie("token");
export const Personal = ({ landlordArray }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const [checkedDeleted, setCheckedDeleted] = useState(false);
  const [landlordRight, setLandlordRight] = useState(null);
  const [landlordInfo, setLandlordInfo] = useState(null);
  const [infoModal, setInfoModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [deleteItemPersonal, setDeleteItemPersonal] = useState(null);
  const [personal, setPersonal] = useState(null);
  const [editPersonal, setEditPersonal] = useState(null);
  const [isButton, setIsButton] = useState(true);
  const [isConditional, setIsConditional] = useState(false);
  const [isAddModal, setAddModal] = useState(false);
  const checkboxList = useRef(null);
  const [workers, setWorkers] = useState([]);
  const user = useSelector((state) => {
    return state.user;
  });
  const [workersDisabled, setWorkersDisabled] = useState(true);
  const [disabled, setDisabled] = useState(true); // add worker modal
  const [isWorker, setIsWorker] = useState(false);
  const vacation = useSelector((state) => state.cadrDocumentsReducer.vacations);
  const [isWorkerStatus, setIsWorkerStatus] = useState(true);
  const [landlordEdit, setLandlordEdit] = useState(null);
  const items = [
    { title: "Персональні дані", page: "personData" },
    {
      title: "Особова картка",
      page: "specialQuota",
    },
    { title: "Умови праці", page: "conditionsWork" },
    { title: "Документи", page: "document" },
    {
      title: "Історія",
      page: "history",
    },
  ];

  useEffect(() => {
    if (landlordRight && landlordRight.length > 0) {
      dispatch(getVacations(landlordRight[0]._id, new Date().getFullYear()));
    }
  }, [dispatch, workers]);

  useEffect(async () => {
    console.log("🚀 ~ useEffect ~ landlordArray:", landlordArray);
    const validLandlord = landlordArray.find((e) => e.landlord.length > 0);

    dispatch(getPositions(validLandlord.landlord[0]._id));
    setLandlordRight(validLandlord.landlord);
    setLandlordInfo(validLandlord);
    // if (landlordArray[0].landlord.length > 0) {
    //   dispatch(getPositions(landlordArray[0].landlord[0]._id));
    //   setLandlordRight(landlordArray[0].landlord);
    //   setLandlordInfo(landlordArray[0]);
    // } else if (landlordInfo) {
    //   if (landlordInfo.landlord.length > 0) {
    //     console.log("🚀 ~ useEffect ~ landlordInfo:", landlordInfo);
    //     dispatch(getPositions(landlordInfo.landlord[0]._id));
    //     setLandlordRight(landlordInfo.landlord);
    //     setLandlordInfo(landlordInfo);
    //   }
    // } else {
    //   setLandlordInfo(landlordArray[0]);
    // }
  }, []);

  const [modals, setModals] = useState({
    personAdd: false,
    personEdit: false,
    practitioners: false,
    docModal: false,
    wageModal: false,
    workTime: false,
    warningModal: false,
  });

  const alertFunc = (message, type) => {
    alert.show(message, { type: type });
  };

  const closeModal = (name) => {
    setModals((prev) => {
      return { ...prev, [name]: false };
    });
  };
  const openNewWorkerModal = () => {
    setAddModal(true);
    dispatch({ type: REMOVE_CURRENT_WORKER });
    openModal("personEdit");
    dispatch(loadWorkers(landlordEdit._id));
  };
  const openModal = (name, value) => {
    if (modals[name] !== false)
      setModals((prev) => ({ ...prev, [name]: false }));
    else
      setModals((prev) => ({
        ...prev,
        [name]: value ?? true,
      }));
  };

  const openEditWorkerModal = (workerId, screen) => {
    dispatch(
      loadCurrentWorker(workerId, alert, () => openModal("personEdit", screen))
    );
  };
  const updateDataPersonal = () => {
    landlordRight &&
      getPersonal(landlordInfo._id).then((object) => {
        if (!object?.message) {
          setPersonal(object);
        } else {
          setPersonal(null);
        }
      });
  };

  const handleChange = (e, id) => {
    setPersonal(
      personal.map((item) => {
        if (item._id === id) {
          if (e.target.checked) {
            return {
              ...item,
              status: e.target.checked,
            };
          } else {
            return {
              ...item,
              status: false,
            };
          }
        }
        return item;
      })
    );

    if (
      checkboxList.current.querySelectorAll("input[type='checkbox']:checked")
        .length > 0
    ) {
      setIsButton(false);
    } else {
      setIsButton(true);
    }
  };
  const checkboxAll = (e) => {
    if (e.target.checked) {
      checkboxList.current
        .querySelectorAll("input[type='checkbox']")
        .forEach((el) => {
          el.checked = true;
        });
    } else {
      checkboxList.current
        .querySelectorAll("input[type='checkbox']")
        .forEach((el) => {
          el.checked = false;
        });
    }
    if (
      checkboxList.current.querySelectorAll("input[type='checkbox']:checked")
        .length > 0
    ) {
      setIsButton(false);
      setPersonal(
        personal.map((items) => {
          return {
            ...items,
            status: true,
          };
        })
      );
    } else {
      setIsButton(true);
      setPersonal(
        personal.map((items) => {
          return {
            ...items,
            status: false,
          };
        })
      );
    }
  };
  const personalGet = async (accountant = undefined) => {
    if (landlordRight.length > 0) {
      const res = await getPersonalEmployerAll(landlordRight, accountant);
      setWorkers(res.employers);
      await updateDataPersonal();
    }
  };

  useEffect(async () => {
    if (isWorker) {
      await personalGet(landlordInfo && landlordInfo.accountant[0]);
      setIsWorker(false);
    }
  }, [isWorker]);
  useEffect(async () => {
    if (!isWorker) {
      if (landlordRight) {
        await personalGet();
        setIsWorker(false);
        await getIdEmployerLength(landlordRight).then((res) => {
          setLandlordEdit(
            res.sort((a, b) => {
              if (a.qualityEmployer < b.qualityEmployer) {
                return 1;
              }
              if (a.qualityEmployer > b.qualityEmployer) {
                return -1;
              }
              return 0;
            })[res.length - 1]
          );
        });
      }
    }
  }, [modals.personEdit, deleteItem, landlordInfo, landlordRight]);
  const statusLength = (type) => {
    if (type === "deleted") {
      return workers.filter(
        (el) =>
          el.postInfo.currentStatus === "deleted" ||
          moment(el.postInfo.uvalLastDay, "DD.MM.YYYY").isBefore(new Date())
      ).length;
    } else if (type === "cancellation") {
      return workers.filter(
        (el) => el.postInfo.currentStatus === "cancellation"
      ).length;
    } else if (type === "vacation") {
      return workers.filter((el) => el.postInfo.currentStatus === "vacation")
        .length;
    } else if (type === "truancy") {
      return workers.filter((el) => el.postInfo.currentStatus === "truancy")
        .length;
    } else if (type === "full") {
      return workers.filter((el) => el.postInfo.status !== "Неоформленный")
        .length;
    }
  };
  const handleChangeSelect = (data) => {
    setLandlordInfo(landlordArray.filter((el) => el._id === data.value)[0]);
    setLandlordRight(
      landlordArray.filter((el) => el._id === data.value)[0].landlord
    );
  };
  const vacationEmployer = (vacationArray, el) => {
    const vacation =
      vacationArray &&
      vacationArray.filter((items) => items.employerId === el._id);

    return (
      vacation.length >= 1 &&
      diffDate(
        new Date(),
        moment(vacation[vacation.length - 1].info.exp.end, "DD.MM.YYYY"),
        "days"
      ) >= 0 &&
      el.postInfo.currentStatus === "vacation" && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            className="label"
            style={{ maxWidth: "max-content", color: "black" }}
          >
            відпустка
          </div>
          <span
            style={{
              fontSize: 12,
              fontWeight: 400,
            }}
          >
            {vacation[vacation.length - 1].info.exp.start} -{" "}
            {vacation[vacation.length - 1].info.exp.end}
          </span>
        </div>
      )
    );
  };

  const deletedWorkersDOM = () => {
    return workers
      .filter(
        (el) =>
          (el.postInfo.currentStatus &&
            el.postInfo.currentStatus === "cancellation") ||
          moment(el.postInfo.uvalLastDay, "DD.MM.YYYY").isBefore(new Date())
      )
      .map((el, i) => {
        return (
          <tr
            className={
              el._id === activeRowIndex ? "table-row active" : "table-row"
            }
            key={i}
            onClick={(event) => {
              const target = event.target;
              if (
                !target.closest(".dropdown") &&
                !target.closest(".MiniButton")
              ) {
                setActiveRowIndex(el._id);
                openEditWorkerModal(el._id);
              }
            }}
          >
            <td>{i + 1}</td>
            <td>{`${el.surname} ${el.name} ${el.patronymic}`}</td>
            <td>
              {el.postInfo.status === "Неоформленный" ? (
                el.postInfo.posada ?? "-"
              ) : (
                <p>
                  {el.postInfo.status}
                  <br />
                  {el.postInfo.format !== "основне" && (
                    <span style={{ fontSize: 13, fontWeight: 300 }}>
                      (сумісництво)
                    </span>
                  )}
                </p>
              )}
            </td>
            <td>
              {landlordRight.map(
                (item) =>
                  item._id === el.workerSuperior && item.userInfO.full_name
              )}
            </td>
            <td style={{ display: "flex", justifyContent: "center" }}>
              {el.postInfo.currentStatus === "cancellation" ? (
                <div className="label label--gray">скасовано</div>
              ) : el.postInfo.currentStatus === "deleted" ||
                moment(el.postInfo.uvalLastDay, "DD.MM.YYYY").isBefore(
                  new Date()
                ) ? (
                <div
                  className="label label--red"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  звільнено
                  <span
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                    }}
                  >
                    {el.postInfo.uvalLastDay}
                  </span>
                </div>
              ) : vacationEmployer(vacation, el) ? (
                vacationEmployer(vacation, el)
              ) : el.postInfo.currentStatus === "truancy" ? (
                <div className="label label--orange">прогул</div>
              ) : el.postInfo.status === "Неоформленный" ? (
                <span
                  style={{
                    backgroundColor:
                      el.postInfo.status === "Неоформленный"
                        ? "#ffe58c"
                        : "transparent",
                    padding: 5,
                    borderRadius: 4,
                  }}
                >
                  Неоформлений
                </span>
              ) : (
                "працює"
              )}
            </td>

            {user.userType === "headAccountant" ||
            user.userType === "lawyer" ? (
              <></>
            ) : (
              el.postInfo.status !== "Неоформленный" && (
                <ReactTooltip
                  id={`deleted${i}`}
                  effect={"solid"}
                  style={{ maxWidth: "60px" }}
                  backgroundColor={"#52616E"}
                >
                  <p style={{ maxWidth: "160px", textAlign: "center" }}>
                    Ця дія доступна лише головному бухгалтеру
                  </p>
                </ReactTooltip>
              )
            )}
          </tr>
        );
      });
  };

  const getWorkersDOM = () => {
    return workers
      .filter(
        (el) =>
          el.postInfo.currentStatus !== "cancellation" &&
          !moment(el.postInfo.uvalLastDay, "DD.MM.YYYY").isBefore(new Date())
      )
      .map((item, i) => {
        return (
          <tr
            className={
              item._id === activeRowIndex ? "table-row active" : "table-row"
            }
            key={i}
            onClick={(event) => {
              const target = event.target;
              if (
                !target.closest(".dropdown") &&
                !target.closest(".MiniButton")
              ) {
                setActiveRowIndex(item._id);
                openEditWorkerModal(item._id);
              }
            }}
          >
            <td>{i + 1}</td>
            <td>{`${item.surname} ${item.name} ${item.patronymic}`}</td>
            <td>
              {item.postInfo.status === "Неоформленный" ? (
                item.postInfo.posada ?? "-"
              ) : (
                <p>
                  {item.postInfo.status}
                  <br />
                  {item.postInfo.format !== "основне" && (
                    <span style={{ fontSize: 13, fontWeight: 300 }}>
                      (сумісництво)
                    </span>
                  )}
                </p>
              )}
            </td>
            <td>
              {landlordRight.map(
                (el) =>
                  el._id === item.workerSuperior &&
                  (el.userInfO.short_name
                    ? el.userInfO.short_name
                    : el.userInfO.full_name)
              )}
            </td>
            <td
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {item.postInfo.currentStatus === "cancellation" ? (
                <div className="label label--gray">скасовано</div>
              ) : item.postInfo.currentStatus === "deleted" ||
                moment(item.postInfo.uvalLastDay, "DD.MM.YYYY").isBefore(
                  new Date()
                ) ? (
                <div
                  className="label label--red"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  звільнено
                  <span
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                    }}
                  >
                    {item.postInfo.uvalLastDay}
                  </span>
                </div>
              ) : vacationEmployer(vacation, item) ? (
                vacationEmployer(vacation, item)
              ) : item.postInfo.currentStatus === "truancy" ? (
                <div className="label label--orange">прогул</div>
              ) : item.postInfo.status === "Неоформленный" ? (
                <span
                  style={{
                    backgroundColor:
                      item.postInfo.status === "Неоформленный"
                        ? "#ffe58c"
                        : "transparent",
                    padding: 5,
                    borderRadius: 4,
                  }}
                >
                  Неоформлений
                </span>
              ) : (
                "працює"
              )}
            </td>
            {
              <td className="table__icon">
                <MiniButton
                  dataFor={`deleted${i}`}
                  disabled={
                    user.userType === "headAccountant" ||
                    user.userType === "lawyer"
                      ? false
                      : item.postInfo.status !== "Неоформленный"
                  }
                  icon={deleteIcon}
                  type="delete"
                  onClick={() => setDeleteItem(item)}
                />
              </td>
            }

            {user.userType === "headAccountant" ||
            user.userType === "lawyer" ? (
              <></>
            ) : (
              item.postInfo.status !== "Неоформленный" && (
                <ReactTooltip
                  id={`deleted${i}`}
                  effect={"solid"}
                  style={{ maxWidth: "60px" }}
                  backgroundColor={"#52616E"}
                >
                  <p style={{ maxWidth: "160px", textAlign: "center" }}>
                    Ця дія доступна лише головному бухгалтеру
                  </p>
                </ReactTooltip>
              )
            )}
          </tr>
        );
      });
  };

  return (
    landlordInfo &&
    landlordRight && (
      <Fragment>
        {landlordArray.length !== 1 && (
          <div
            style={{
              marginTop: 20,
              maxWidth: 400,
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <Select
              onChange={handleChangeSelect}
              defaultValue={
                landlordArray
                  .filter((e) => e.landlord.length > 0)
                  .map((item) => {
                    return {
                      value: item._id,
                      label: !item.place.street
                        ? "вулиця відсутня"
                        : `${addressPlace(
                            {
                              city: item.place.city,
                              street: item.place.street,
                              street_number: item.place.street_number,
                              isNameCity: false,
                            },
                            item.houseNumber
                          )
                            .join(", ")
                            .replace("будинок ", "")}`,
                    };
                  })[0]
              }
              options={landlordArray
                .filter((e) => e.landlord.length > 0)
                .map((item) => {
                  return {
                    value: item._id,
                    label: !item.place.street
                      ? "вулиця відсутня"
                      : `${addressPlace(
                          {
                            city: item.place.city,
                            street: item.place.street,
                            street_number: item.place.street_number,
                            isNameCity: false,
                          },
                          item.houseNumber
                        )
                          .join(", ")
                          .replace("будинок ", "")}`,
                  };
                })}
              components={{
                IndicatorSeparator: false,
              }}
            />
          </div>
        )}
        <div className="personal">
          <div className="personal__left">
            <div
              className="space-between"
              style={{ marginBottom: "21px", marginTop: "26px" }}
            >
              <h3 className="personal__title">
                Фізичні особи за договорами підряду
              </h3>
              <button
                className="personal__btn"
                onClick={() => setEditPersonal(defaultObject)}
              >
                + Додати підрядника
              </button>
            </div>
            <table className="table">
              <thead>
                <tr className="table-row">
                  <th>
                    <input
                      type="checkbox"
                      className="green__checkbox"
                      onChange={checkboxAll}
                    />
                  </th>
                  <th>№</th>
                  <th>ПІБ</th>
                  <th
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    Редагувати
                  </th>
                  <th
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    Видалити
                  </th>
                </tr>
              </thead>
              <tbody ref={checkboxList}>
                {personal &&
                  personal.map((item, i) => (
                    <tr className="table-row" key={i}>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => handleChange(e, item._id)}
                          className="green__checkbox"
                        />
                      </td>
                      <td>{i + 1}</td>
                      <td>{`${item.lastname} ${item.name} ${item.surname}`}</td>
                      <td className="table__icon">
                        <MiniButton
                          icon={editIcon}
                          type="edit"
                          onClick={() => {
                            setEditPersonal(item);
                          }}
                        />
                      </td>
                      <td className="table__icon">
                        <MiniButton
                          icon={deleteIcon}
                          type="delete"
                          onClick={() => setDeleteItemPersonal(item)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <button
              data-tip
              data-for={"createDoc"}
              className={`personal__btn_grey ${isButton && "disabled"}`}
              onClick={() => {
                if (!isButton) {
                  setIsConditional(true);
                }
              }}
            >
              Створити договір
            </button>
            {isButton && (
              <ReactTooltip
                id={"createDoc"}
                effect={"solid"}
                backgroundColor={"#52616E"}
              >
                Виберіть одного або більше підрядника
              </ReactTooltip>
            )}
          </div>

          <div className="personal__right">
            <div
              className="space-between"
              style={{ marginBottom: "21px", marginTop: "26px" }}
            >
              <h3 className="personal__title">Штатні працівники</h3>
              <button
                className="personal__btn"
                onClick={() => {
                  openNewWorkerModal();
                  setWorkersDisabled(false);
                  setIsWorkerStatus(true);
                  setDisabled(true);
                }}
              >
                + Додати працівника
              </button>
            </div>
            <table className="table" style={{ marginBottom: 0 }}>
              <thead>
                <tr className="table-row">
                  <th>№</th>
                  <th>ПІБ</th>
                  <th>Посада</th>
                  <th>Роботодавець</th>
                  <th style={{ textAlign: "center" }}>Cтатус</th>
                  <th className="table__icon">Видалити</th>
                </tr>
              </thead>

              <tbody>{workers && getWorkersDOM()}</tbody>
            </table>
            {workers.length >= 1 && (
              <table style={{ width: "100%", display: "block" }}>
                <Table
                  style={{ marginTop: 0, position: "relative" }}
                  className="table__row__del"
                >
                  <TableRow type="header" style={{ display: "flex" }}>
                    <TableCell
                      style={{ gridColumn: "span 5", paddingLeft: "16px" }}
                    >
                      Всього за період діяльності: Прийнято:
                      <span style={{ marginRight: 5 }}>
                        {statusLength("full")}
                      </span>
                      Звільнено:
                      <span style={{ marginRight: 5 }}>
                        {statusLength("deleted")}
                      </span>{" "}
                      Скасовано:<span>{statusLength("cancellation")}</span>
                    </TableCell>
                    <TableCell justify="center">
                      <div className="checkbox__deletedList">
                        <input
                          type="checkbox"
                          id="checkAllDeleted"
                          checked={checkedDeleted}
                          name="checkAllDeleted"
                          onChange={(e) => {
                            setCheckedDeleted(e.target.checked);
                          }}
                          style={{ marginRight: 8, width: 16, height: 16 }}
                        />
                        <label htmlFor="checkAllDeleted">Показати архів</label>
                      </div>
                    </TableCell>
                  </TableRow>
                  {checkedDeleted && deletedWorkersDOM()}
                </Table>
              </table>
            )}
          </div>
        </div>
        {infoModal && (
          <InfoPopUp
            setInfoModal={setInfoModal}
            message="Виберіть одного або більше підрядників для яких потрібно створити
        договір"
          />
        )}
        {isConditional && (
          <Conditions
            checkboxList={checkboxList}
            landlordInfo={landlordInfo}
            personal={personal.filter((items) => items.status === true)}
            setIsConditional={setIsConditional}
            landlordRight={landlordRight}
          />
        )}
        {Object.values(modals).some((isOpened) => {
          return isOpened !== false;
        }) ||
        deleteItem !== null ||
        editPersonal ||
        deleteItemPersonal ||
        isConditional ? (
          <div className="bg__layer" />
        ) : null}
        <div className="modals">
          {modals.personEdit && (
            <KadriCardsPersonEdit
              setIsWorker={setIsWorker}
              isWorker={isWorkerStatus}
              workersDisabled={workersDisabled}
              disabledInput={true}
              objectId={landlordInfo._id}
              isAddModal={isAddModal}
              isPersonal={{
                check: true,
                id: landlordEdit._id,
              }}
              screen={modals.personEdit}
              items={items}
              closeModal={closeModal}
              id={landlordEdit._id}
            />
          )}
          {modals.personEdit__2 && (
            <KadriCardsPersonEdit__2
              items={items}
              togglePersonEdit__2={() => {
                openModal("personEdit__2");
              }}
            />
          )}
          {modals.docModal && (
            <KadriCardsDocModal
              toggleDocModal={() => {
                openModal("docModal");
              }}
            />
          )}
          {modals.warningModal && (
            <WarningPopUp
              toggleWarningModal={() => openModal("warningModal")}
            />
          )}

          {editPersonal && landlordInfo && (
            <Practitioners
              updateDataPersonal={updateDataPersonal}
              editPersonal={editPersonal}
              landlordInfo={landlordInfo}
              objectId={landlordInfo._id}
              setEditPersonal={setEditPersonal}
            />
          )}
          {deleteItem && (
            <WarningPopUp
              onDelete={() => {
                dispatch(
                  deleteWorker(deleteItem._id, alertFunc, landlordEdit._id)
                );
                setDeleteItem(null);
              }}
              toggleWarningModal={() => setDeleteItem(null)}
              name={`${deleteItem.surname} ${deleteItem.name} ${deleteItem.patronymic}`}
            />
          )}
          {deleteItemPersonal && (
            <WarningPopUp
              onDelete={() => {
                deletePersonal(deleteItemPersonal._id).then(updateDataPersonal);
                alertFunc("Підрядника видаленно");
                setDeleteItemPersonal(null);
              }}
              toggleWarningModal={() => setDeleteItemPersonal(null)}
              name={`${deleteItemPersonal.surname} ${deleteItemPersonal.name} ${deleteItemPersonal.lastname}`}
            />
          )}
        </div>
      </Fragment>
    )
  );
};
