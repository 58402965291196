import styled from "styled-components";
import settings from "../../assets/settings.svg";
import ReactTooltip from "react-tooltip";

const buttons = {
    'settings': settings,
}
const Container =  styled.div`
        padding: 0;
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0
    `
const Title = styled.div`
        color: #313131;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
    `
const Button = styled.div`
        border: none;
        position: relative;
        left: 2px;
        width: 28px;
        height: 28px;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        &:hover {
            background-color: #ececec;
        }
    `

const InfoHeader = props => {

    const { buttonType, onClick } = props


    return (
        <Container>
            <Title>
                { props.children }
            </Title>
            <Button data-tip data-for={'settings'} onClick={onClick}>
                <img alt="Logo" src={buttons[buttonType]} style={{width: '16px', height: '16px', margin: '6px 6px 5px 6px', borderRadius: '3px'}}/>
            </Button>
          <ReactTooltip id={'settings'} effect={'solid'} backgroundColor={'#52616E'}>
            Налаштування
          </ReactTooltip>
        </Container>
    )
}

export default InfoHeader

// .kadri__item__info__close-btn {
//     border: none;
//     position: relative;
//     left: 2px;
//     width: 28px;
//     height: 28px;
//     background-color: transparent;
//     cursor: pointer;
//   }
//   .kadri__item__info__close-btn button {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin: 0 auto;
//   }

//   .kadri__item__info__close-btn:hover {
//     background-color: #ececec;
//   }

//   .kadri__item__info__close-btn:hover .info__close-icon {
//     filter: invert(57%) sepia(4%) saturate(23%) hue-rotate(314deg) brightness(88%)
//       contrast(97%);
//   }
