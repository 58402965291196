import './TransferEmployer.css'
import Modal from '../modalComponents/Modal/Modal'
import '../modalComponents/Modal/Modal.css'
import TextInput from '../../Inputs/TextInput/TextInput'
import ModalAcceptButton from '../../buttons/ModalAcceptButton/ModalAcceptbutton'
import {connect, useSelector} from 'react-redux'
import DropdownHint from '../../dropdowns/DropdownHint/DropdownHint'
import DropdownHintRow from '../../dropdowns/DropdownHintRow/DropdownHintRow'
import React, {useState} from 'react'
import {USER_SENDWORKER_GETADMINS} from '../../../constants/urls'
import {getCookie} from '../../../utilits/cookies'
import {useAlert} from "react-alert";
import sendEmployer from '../../../redux/actions/employers/sendEmployer'
import {store} from "../../../index";

const TransferEmployer = props => {

  const employers = useSelector(state => {
    return state.employers
  })
  const token = getCookie('token')
  const alert = useAlert()

  const {toggleFunc, selectedId} = props


  const [form, setForm] = useState({
    workerid: selectedId,
    userid: ''
  })
  const [hints, setHints] = useState([])
  const [userid, setUserid] = useState(null)

  const changeForm = (event) => {
    setForm({...form, [event.target.name]: event.target.value})
  }

  const acceptHint = (user) => {
    setHints([])
    setUserid(user._id)
    setForm({...form, userid: `${user.firstName} ${user.lastName}`})
  }

  const getHints = async (event) => {
    setForm({...form, [event.target.name]: event.target.value})
    if (event.target.value.length >= 1) {
      try {
        const response = await fetch(USER_SENDWORKER_GETADMINS, {
          body: JSON.stringify({name: event.target.value}),
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token
          }
        })
        const data = await response.json()
        if (response.ok) {
          setHints(data.admins)
        }
      } catch (error) {

      }
    }
  }

  const getHintsDOM = () => {
    console.log(hints)
    return hints.map(el => {
      return <DropdownHintRow
        id={el._id}
        onClick={() => {
          acceptHint(el)
        }}
      >
        {`${el.firstName} ${el.lastName}`}
      </DropdownHintRow>
    })
  }

  const alertFunc = (message, type) => {
    alert.show(message, {type: type})
  }

  const submit = async (event) => {
    event.preventDefault()
    const formToSend = {
      workerid: form.workerid,
      userid: userid
    }
    store.dispatch(sendEmployer(formToSend, alertFunc)).then(res => res.status && toggleFunc())

  }

  return (
    <Modal title='Передати роботодавця іншому користувачу'
           className="transfer"
           toggleFunc={toggleFunc}
    >
      <form onSubmit={submit}>
        <div className="Modal__content">
          <>
            <p className='Modal__text'>Роботодавець</p>
            {employers.map(el => {
              if (el._id === selectedId) {
                return <TextInput
                  width='314px' height='36px'
                  type="text"
                  name="workerid"
                  onChange={changeForm}
                  value={el.userInfO.full_name}
                  disabled={true}
                />
              }
            })}
          </>
          <>
            <p className='Modal__text'>Введіть ім'я адресата</p>
            <div className='Modal__input-dropdown' style={{position: 'relative', width: "max-content"}}>
              <TextInput
                width='314px' height='36px'
                type="text"
                name="userid"
                onChange={getHints}
                value={form.userid}
                placeholder={`Ім'я`}
              />

              <DropdownHint
                data-active={hints.length <= 0}>
                {getHintsDOM()}
              </DropdownHint>
            </div>
          </>
        </div>
        <div className="Modal__button-row">
          <ModalAcceptButton type='submit'>Передати</ModalAcceptButton>
        </div>
      </form>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default connect(mapStateToProps, {})(TransferEmployer)
