export const mainURL = process.env.REACT_APP_API_GATEWAY
export const signerURL = process.env.REACT_APP_API_SIGNER

export const controllerURL = process.env.REACT_APP_API_CONTROLLER
if (!mainURL) {
  throw new Error('API_GATEWAY environment variable was not set');
}
// TODO: the code is using mixed axios/fetch with mixed base URL and not
// AND mixed const urls vs inline URLS
//authorization
export const LOGIN_URL = `${mainURL}/auth/login`
export const REGISTRATION_URL = `${mainURL}/auth/register`
// signer
export const CREATE_JKS_SIGNATURE = `${signerURL}/sign`;
//user info
export const GET_MY_INFO_URL = `${mainURL}/user/getmyinfo`
export const CHANGE_MY_INFO_URL = `/user/changemyinfo`
export const CHANGE_MY_EMAIL = `${mainURL}/user/email/change`
export const CHANGE_MY_PASSWORD = `/user/changepassword`
export const SEND_CONFIRMATION_CODE = `${mainURL}/user/email/sendcode`
export const ACCEPT_CONFIRMATION_CODE = `${mainURL}/user/email/accept`
export const ADD_GOOGLE_2FA = `${mainURL}/user/add2fa`
export const ACCEPT_GOOGLE_2FA = `${mainURL}/user/accept2fa`
export const DELETE_GOOGLE_2FA = `${mainURL}/user/delete2fa`
export const CREATE_JKS_DOCUMENT = `${mainURL}/user/createJksDocument`
export const UPDATE_JKS_DOCUMENT = `${mainURL}/user/updateJksDocument`
export const GET_JKS_RECEIVER = `${mainURL}/user/jksReceiver`
export const GET_JKS_RECORDS_HISTORY = `${mainURL}/user/getJksRecordsHistory`
export const DELETE_JKS_RECORD = `${mainURL}/user/deleteJksDocument`

export const TOGGLE_NOTIFICATONS = `/user/changenotiff`
export const GET_TELEGRAM_LINK = `${mainURL}/user/gettelegrammlink`
export const GET_MY_USERS = `${mainURL}/user/getUsers`
export const CREATE_USER = `${mainURL}/user/createUser`
export const UPDATE_USER = `${mainURL}/user/updateUser`
export const DELETE_USER = `${mainURL}/user/deleteUser`
//workers
export const GET_MY_WORKER_URL = `/user/getmyworker`
export const ADD_WORKER_URL = `${mainURL}/user/addworker`
export const DELETE_WORKER_URL = `/user/deleteworker`
export const CHANGE_WORKER_URL = `${mainURL}/user/changeworker`
export const USER_SENDWORKER_TRANSFER_ACCEPT = `${mainURL}/user/sendworker/transfer/accept`
export const USER_SENDWORKER_TRANSFER_REJECT = `${mainURL}/user/sendworker/transfer/reject`
//docs
export const EMPLOYER_DOCS_UPLOAD = `${mainURL}/employer/docs/upload`
//admins
export const USER_SENDWORKER_GETADMINS = `${mainURL}/user/sendworker/getadmins`
export const USER_SENDWORKER_PROOFWORKER = `${mainURL}/user/sendworker/proofworker`
//workers
export const EMPLOYER_GETLIST = `${mainURL}/employer/getlist`
export const EMPLOYER_CREATENEW = `/employer/createnew`
export const EMPLOYER_EDITMAININFO = `/employer/editmaininfo`
export const EMPLOYER_EDITSECONDINFO = `/employer/editsecondinfo`
//current workers
export const EMPLOYER_GET_DATA = `/employer/get/id`

export const SEARCH_ADDRESS = `${mainURL}/searchAddress`;

export const SEARCH_ADDRESS_STREET = `${mainURL}/searchAddressStreet`;
export const EMPLOYER_GET_INFO = `${mainURL}/employer/kadr/info`
export const EMPLOYER_UPDATE_INFO = `${mainURL}/employer/kadr/updateInfo`
export const GET_KADRI_DOC = `${mainURL}/employer/getKadriDocuments`

export const ADD_KADRI_VACATION_FUTURE = `${mainURL}/employer/kadr/addVacationFuture`
export const DELETED_KADRI_VACATION_FUTURE = `${mainURL}/employer/kadr/deletedVacationFuture`


export const ADD_KADRI_MANDATE = `${mainURL}/employer/kadr/mandate`
export const BACK_EVENT = `/employer/backEvent`
export const TRANSFER_POSADA_EMPLOYER = `/employer/kadr/transfer`
//objects
//Object info
export const GET_MY_OBJECT = `/object/getObject`
export const GET_ONE_OBJECT = `${mainURL}/object/getOneObject`
export const CREATE_OBJECT = `/object/createObject`
export const UPDATE_OBJECT = `/object/updateObject`
export const DELETE_OBJECT = `${mainURL}/object/deleteObject`
export const SET_MONTH_OBJECT = `${mainURL}/object/updateMonthProlongation`
export const UPLOAD_OBJECT = `${mainURL}/object/upload`
export const GET_ONE_DOCUMENT = `${mainURL}/object/getDocuments`

//personal info
export const CREATE_PERSONAL = `${mainURL}/personal/create`
export const UPDATE_PERSONAL = `${mainURL}/personal/update`
export const GET_PERSONAL = `${mainURL}/personal/get`
export const EMPLOYER_PERSONAL_GETLIST = `${mainURL}/employer/getlistwork`

export const GET_ID_EMPLOYER_LENGTH = `${mainURL}/employer/getLengthLandlord`
export const DELETE_PERSONAL = `${mainURL}/personal/delete`
export const DOC_PERSONAL = `${mainURL}/personal/docPdf`
export const PDF = `${mainURL}/docxToPdf`
export const MINFIN = `${mainURL}/minfin`
export const HOLIDAYDAYS = `${mainURL}/holidayDay`
export const GOOGLE_FILE_DELETE = `${mainURL}/googleStorage/delete`
export const GOOGLE_FILE_DOWNLOAD = `${mainURL}/googleStorage/download`
export const GOOGLE_FILE_VIEW = `${mainURL}/googleStorage/view`

//controller
export const GET_AUTH_CONTROLLER = `/authController`
export const GET_CONTROLLER = `/getInfoIp`
export const ADD_AND_LOAD_USER_CONTROLLER = `/addAndGetUser`
export const GET_CONTROLLER_IP = `/getListIp`
export const GET_INFO_CONTROLLER = `/getInfo`
export const GET_OBJECT_LIST_CONTROLLER = `/getObjectList`
export const GET_USER_AND_UPDATE_FINGER = `/getUserAndUpdateFinger`
export const CREATE_CONTROLLER = `/addInfoIp`
export const UPDATE_CONTROLLER = `/updateInfoIp`
export const DELETE_CONTROLLER = `/deletedInfoIp`
export const SET_OBJECT_CONTROLLER = `/setObjectController`

export const UPDATE_FINGER_USER = `/updateFingerUser`
export const SCAN_FINGER_CONTROLLER = `/scanFinger`

export const GET_FINGER_CONTROLLER = `/getFinger`
// controller history
export const GET_CONTROLLER_HISTORY = `/getHistory`
// controller visitor
export const GET_CONTROLLER_VISITOR = `/getVisitor`
export const CREATE_CONTROLLER_VISITOR = `/addVisitor`
export const UPDATE_CONTROLLER_VISITOR = `/updateVisitor`
export const DELETED_CONTROLLER_VISITOR = `/deletedVisitor`
