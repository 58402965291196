import styled from 'styled-components'


const ButtonReload = styled.button.attrs(props => ({
  disabled: props.disabled || false
}))`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  color: #535353;
  img{
    margin-right: 10px;
  }
`

export default ButtonReload;