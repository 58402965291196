export const is_valid_params = (search_params) => {
  const black_list = [null, undefined, ""];

  for (let param in search_params) {
    if (black_list.includes(search_params[param].trim())) continue;
    return true;
  }
  return false;
};

export const validateEmail = (params) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(params).toLowerCase());
};

export const validatePhone = (params) => {
  const re = /^(\+?\d{1,3})?[-. (]?\d{3}[-. )]?\d{3}[-. ]?\d{2}[-. ]?\d{2}$/;
  return re.test(params);
};
