import './DropdownHint.css'

const DropdownHint = props => {
    return (
        <div className='DropdownHint' {...props}>
            {props.children}
        </div>
    )
}

export default DropdownHint
