import React, {useCallback, useEffect, useState} from "react";

import {SpaceBetween} from "../../styledComponents/Layout";
import Table from "../../components/tables/Table";
import TableCell from "../../components/tables/TableCell/TableCell";
import TableRow from "../../components/tables/TableRow/TableRow";
import {TextGrey} from "../../styledComponents/Text";
import GreenButton from "../../components/buttons/GreenBuuton.js/GreenButton";
import {useDispatch, useSelector} from "react-redux";
import ControllerAdd from "./modal/ControllerAdd";
import "./controller.css"
import createController from "../../redux/actions/controller/createController";
import loadController from "../../redux/actions/controller/loadController";
import updateController from "../../redux/actions/controller/updateController";
import {LOAD_INFO_CONTROLLER} from "../../redux/types";

const GRID_TEMPLATE = '20fr 100fr 100fr 350fr 100fr 100fr';
const GRID_PLACEHOLDER = '100%';
const defaultController = {
  ip: "",
  port: "",
  login: "",
  password: "",
  location: ""
};

const RentRow = ({controller, index, setPressedId, pressedId, setControllerData}) => {
  const {_id, ip, port, location, password, login} = controller;

  return (
    <div onClick={() => {
      setControllerData(controller);
      setPressedId(_id)
    }}>
      <TableRow gridTemplate={GRID_TEMPLATE} isPressed={pressedId === _id}>
        <TableCell justify='center'>{index + 1}</TableCell>
        <TableCell>{ip}</TableCell>
        <TableCell>{port}</TableCell>
        <TableCell>{location}</TableCell>
        <TableCell>{login}</TableCell>
        <TableCell>{password}</TableCell>
      </TableRow>
    </div>
  );
};

const PlaceholderRow = ({children}) => {
  return (
    <TableRow type='header' gridTemplate={GRID_PLACEHOLDER}>
      <TableCell style={{justifyContent: "center"}}>{children}</TableCell>
    </TableRow>
  );
};


export const Controller = () => {
    const [controllerData, setControllerData] = useState(null)
    const [pressedId, setPressedId] = useState(null);
    const controller = useSelector(state => state.controller.data)
    const dispatch = useDispatch();


    useEffect(() => {
      dispatch(loadController())
    }, [dispatch]);

    const saveController = useCallback(async (object) => {
      try {
        const {_id, ...update} = object;
        let response
        if (_id) {
          response = await dispatch(updateController(_id, update));
        } else {
          response = await dispatch(createController(update))
        }
        if (!response.data.success) {
          alert.show(response.data.message || 'Щось не так', {type: 'error'});
          return {status: false}
        }
        setControllerData(null);
        return {status: true}
      } catch (error) {
        alert.show(error.message, {type: 'error'});
        return {status: false}
      }
    }, [dispatch]);

    return (
      <div style={{width: 700, margin: "0 auto"}}>
        <SpaceBetween>
          <TextGrey>Список Контролерів</TextGrey>
          <div style={{position: 'relative'}}>
            <GreenButton onClick={() => setControllerData(defaultController)}>
              <span>+ Додати Контролер</span>
            </GreenButton>
          </div>
        </SpaceBetween>
        <Table className="table-rent">
          <TableRow type='header' gridTemplate={GRID_TEMPLATE}>
            <TableCell justify='center'>№</TableCell>
            <TableCell>IP</TableCell>
            <TableCell>ПОРТ</TableCell>
            <TableCell>Адрес</TableCell>
            <TableCell>Логин</TableCell>
            <TableCell>Пароль</TableCell>
          </TableRow>
          {controller?.length
            ? controller.map((item, index) =>
              <RentRow setPressedId={setPressedId} pressedId={pressedId} setControllerData={setControllerData}
                       controller={item} index={index}/>
            )
            : <PlaceholderRow>Немає Контролерів</PlaceholderRow>}
        </Table>
        {(controllerData) && (
          <div className="bg__layer"/>
        )}

        {(controllerData) &&
          <ControllerAdd setEditController={setControllerData} saveController={saveController}
                         controllerData={controllerData}/>}
      </div>
    );
  }
;
