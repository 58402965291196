import {LOAD_WORKERS} from "../types"

const initialWorkers = null

export const workerReducer = (state = initialWorkers, action) => {
  switch (action.type) {
    case LOAD_WORKERS:
      return action.payload
    default:
      return state
  }
}
