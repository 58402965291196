import styled from "styled-components"
import Button from "../Button/Button"

const TableGreyButton = styled.button`
    width: 82px;
    height: 30px;
    background: #F2F2F2;
    border-radius: 3px;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #313131;
    margin-right: 6px;
    &hover:{

    }
    &last-of-type: {
        margin-right: 0;
    }
`

export default TableGreyButton