import instance from "../instance";
import {
  setAllDocuments,
  setPositions,
  setVacations,
  setWorkDays,
  setZpInfo,
} from "./cadrDocuments-actions";
import { downloadDocument } from "../workers/workers-thunks";
import { employersReducer } from "../employers/employers-reducer";

export const getPositions = (id) => async (dispatch) => {
  try {
    const response = await instance.post("/employer/getMyPosadas", { id });
    if (response.status === 200) {
      dispatch(setPositions(response.data.posada));
    }
  } catch (e) {}
};

export const addPosition =
  (body, alert, closeModal, editPositionId) => async (dispatch) => {
    console.log(body);
    try {
      const response = await instance.post(
        editPositionId ? "/employer/updPosada" : "/employer/addPosada",
        editPositionId ? { ...body, id: editPositionId } : body
      );
      if (response.status === 200) {
        alert(response.data.message, "success");
        dispatch(getPositions(body.id));
        closeModal();
      } else {
        throw Error(response.data.message || "Щось пішло не так");
      }
    } catch (e) {
      alert(e.message, "error");
    }
  };

export const deletePosition = (alert, id) => async (dispatch, getState) => {
  const { positions } = getState().cadrDocumentsReducer;
  try {
    const response = await instance.post("/employer/deletePosada", { id });
    if (response.status === 200) {
      alert(response.data.message, "success");
      dispatch(setPositions([...positions].filter((p) => p._id !== id)));
    } else {
      throw Error(response.data.message || "Щось пішло не так");
    }
  } catch (e) {
    alert(e.message, "error");
  }
};

// work days
export const getWorkDays = (year, month) => async (dispatch, getState) => {
  const { currentEmployer } = getState().employersReducer;
  const response = await instance.post("/employer/getWorkDays", {
    workerId: currentEmployer._id,
    year: year,
    month: month,
  });
  dispatch(setWorkDays(response.data));
};

export const setWorkDay = (body) => async (dispatch, getState) => {
  const { currentEmployer } = getState().employersReducer;
  await instance.post("/employer/setWorkDay", {
    ...body,
    workerId: currentEmployer._id,
  });
  dispatch(getWorkDays(body.year, body.month));
};

//vacations
export const getVacations =
  (currId = null, year) =>
  async (dispatch, getState) => {
    const { currentEmployer } = getState().employersReducer;
    const response = await instance.post("/employer/getAllFrees", {
      workerid: currId ? currId : currentEmployer._id,
      year: year,
    });
    dispatch(setVacations(response.data));
  };

export const getDocumentsJournal = (year) => async (dispatch, getState) => {
  const { currentEmployer } = getState().employersReducer;
  const response = await instance.post("/employer/getJournalDocs", {
    workerid: currentEmployer._id,
    year: year,
  });
  dispatch(setAllDocuments(response.data));
};

export const downloadAllDocuments =
  (setLoader, year) => async (dispatch, getState) => {
    const { currentEmployer } = getState().employersReducer;
    setLoader(true);
    const response = await instance.post("/employer/getNakaz", {
      workerId: currentEmployer._id,
      year: year,
    });
    if (response.data.status === "sucess") {
      setLoader(false);
      dispatch(downloadDocument([response.data.data]));
    }
  };

export const downloadFrees =
  (setLoader, year, date) => async (dispatch, getState) => {
    const { currentEmployer } = getState().employersReducer;
    setLoader(true);
    const response = await instance.post("/employer/getFreesPdf", {
      workerId: currentEmployer._id,
      year: year,
      date: date,
    });
    if (response.data.status === "sucess") {
      setLoader(false);
      dispatch(downloadDocument([response.data.data]));
    }
  };

export const getZpInfo =
  (year, month, setLoader) => async (dispatch, getState) => {
    const { currentEmployer } = getState().employersReducer;
    setLoader(false);
    const response = await instance.post("/employer/getZpHTML", {
      workerid: currentEmployer._id,
      year,
      month,
    });
    setTimeout(() => {
      setLoader(true);
    }, 1000);

    dispatch(setZpInfo(response.data));
  };

export const updateAvans = (avans, employerId) => async () => {
  console.log("update");
  const response = await instance.post("/employer/updateAvans", {
    employerId,
    avans,
  });

  return response.data;
};

export const downloadZp = (body) => async (dispatch, getState) => {
  const { currentEmployer } = getState().employersReducer;
  const response = await instance.post("/employer/getZp", {
    workerid: currentEmployer._id,
    ...body,
  });
  if (response.data) {
    dispatch(downloadDocument([response.data]));
  }
};
