import React, {useEffect, useState} from "react";
import {SButtonLightGreen, SButtonLightGrey} from "../../../../../styledComponents/Buttons";

import ConditionsHeader from "./components/ConditionsHeader";
import {Divider} from "../../../../../styledComponents/Dividers";

import "../KadriCards.css";
import {Formik} from "formik";
import InputMask from 'react-input-mask'
import {InputForm} from "../../../../../styledComponents/Inputs";
import {connect, useDispatch, useSelector} from "react-redux";
import {acceptDismissal} from "../../../../../redux/workers/workers-thunks";
import {useAlert} from "react-alert";
import datePicker from "../../../../../assets/datePicker.svg";
import Calendar from "react-calendar";
import getDateAndTime from "../../../../../utilits/getDateAndTime";
import {getSettings} from "../../../../../redux/settings/settings-thunks";
import moment from "moment";
import {deletedDocument} from "../../../../../redux/documentOld/document-thunks";
import {setModalInfo} from "../../../../../redux/modalInfo/modal-actions";

export let KadriCardsWorkDoc__2 = ({
                                     toggleWork__2,
                                     workerId,
                                     employerId,
                                     openEditWorkerModal,
                                     setTypeModal,
                                     setTypeId,
                                     toggleModalList,
                                     setIndexTab,
                                     acceptDismissal,
                                     ...props
                                   }) => {
  const modal = useSelector(state => state.modal.info)
  const [beginDate, setBeginDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [nakazDate, setNakazDate] = useState(null)
  const [isFetching, setIsFetching] = useState(false)
  const alert = useAlert()
  const dispatch = useDispatch();
  const alertFunc = (message, type) => {
    alert.show(message.status, {type: type})
    setIsFetching(false)
  }
  const id = window.location.pathname.slice(window.location.pathname.lastIndexOf('/') + 1)
  useEffect(() => {
    props.getSettings(id)
  }, [])
  return (
    <>
      {props.settings && <Formik
        initialValues={{
          claimDate: (modal && getDateAndTime(new Date(moment(modal.claimDate, "DD.MM.YYYY")), true)) ?? '',
          lastDay: (modal && getDateAndTime(new Date(moment(modal.lastDay, "DD.MM.YYYY")), true)) ?? '',
          dateNakaz: (modal && getDateAndTime(new Date(moment(modal.dateNakaz, "DD.MM.YYYY")), true)) ?? '',
          order: modal?.nomer ?? (props.settings?.number1 !== -1 ? props.settings?.number1 + 1 : '')
        }}
        onSubmit={values => {
          setIsFetching(true)
          acceptDismissal({
            isEdit: modal?.isEdit,
            id: modal?.employerId ?? employerId,
            workerId: workerId,
            number: values.order,
            info: {
              claimDate: values.claimDate,
              lastDay: values.lastDay,
              dateNakaz: values.dateNakaz,
              type: 'угода сторін'
            }
          }, alertFunc, toggleWork__2).then((fileId) => {
            if (!modal) {
              openEditWorkerModal(employerId)
              setTypeModal("Пакет документів на звільнення");
              setTypeId(fileId);
              setIndexTab();
            } else {
              dispatch(deletedDocument(modal.documentId, modal.year))
              dispatch(setModalInfo(null))
            }
          })
        }}>
        {({values, handleChange, handleSubmit, setFieldValue}) => {
          const isValid = values.claimDate && values.lastDay && values.dateNakaz && values.order
          return <>
            {modal && <div className="bg__layer" style={{zIndex: 999}}/>}
            <div className="conditions__workdoc">
              <ConditionsHeader toggle={() => {
                toggleWork__2();
                if (modal) {
                  dispatch(setModalInfo(null))
                }
              }}>
                Умови припинення трудового договору (угода сторін)
              </ConditionsHeader>
              <Divider></Divider>
              <div className="conditions__content">
                <div className="conditions__content-left">
                  <p>Дата заяви про звільнення</p>
                  <p>Дата наказу</p>
                  <p>Останній робочий день</p>
                  <p>Наказ №</p>
                </div>
                <div className="conditions__content-right">
                  <form className="conditions__form" action="#">
                    <div className="conditions__row">
                      <InputMask
                        mask={'99.99.9999'}
                        maskChar={''}
                        id={'claimDate'}
                        value={values.claimDate}
                        onChange={handleChange}
                        type="text"
                        className="conditions__time-input"
                      />
                      <div className={'pos-rel'}>
                        <button className="item__input__check"
                                type={'button'}
                                style={{paddingTop: "8px", marginBottom: '2px'}}>
                    <span>
                      <img src={datePicker}/>
                    </span>
                        </button>
                        <div style={{width: '250px'}} className={"table__dropdown__content"}>
                          <Calendar
                            value={beginDate}
                            onChange={(e) => {
                              setBeginDate(e)
                              setFieldValue('claimDate', getDateAndTime(e, true))
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="conditions__row">
                      <InputMask
                        mask={'99.99.9999'}
                        maskChar={''}
                        value={values.dateNakaz}
                        id={'dateNakaz'}
                        onChange={handleChange}
                        type="text"
                        className="conditions__time-input"
                      />
                      <div className={'pos-rel'}>
                        <button className="item__input__check"
                                type={'button'}
                                style={{paddingTop: "8px", marginBottom: '2px'}}>
                    <span>
                      <img src={datePicker}/>
                    </span>
                        </button>
                        <div style={{width: '250px'}} className={"table__dropdown__content"}>
                          <Calendar
                            value={nakazDate}
                            onChange={(e) => {
                              setNakazDate(e)
                              setFieldValue('dateNakaz', getDateAndTime(e, true))
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="conditions__row">
                      <InputMask
                        mask={'99.99.9999'}
                        maskChar={''}
                        id={'lastDay'}
                        value={values.lastDay}
                        onChange={handleChange}
                        type="text"
                        className="conditions__time-input"
                      />
                      <div className={'pos-rel'}>
                        <button className="item__input__check"
                                type={'button'}
                                style={{paddingTop: "8px", marginBottom: '2px'}}>
                    <span>
                      <img src={datePicker}/>
                    </span>
                        </button>
                        <div style={{width: '250px'}} className={"table__dropdown__content"}>
                          <Calendar
                            value={endDate}
                            onChange={(e) => {
                              setEndDate(e)
                              setFieldValue('lastDay', getDateAndTime(e, true))
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="conditions__row">
                      <InputForm
                        value={values.order}
                        id={'order'}
                        onChange={handleChange}
                        type="text"
                        className="conditions__text-input"
                        style={{
                          backgroundColor: props.settings?.number1 !== -1 ? 'rgb(242, 242, 242)' : ''
                        }}
                        disabled={props.settings?.number1 !== -1}
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className="conditions__buttons">
                {(isValid && !isFetching) ? <SButtonLightGreen onClick={handleSubmit}>Зберегти</SButtonLightGreen>
                  : <SButtonLightGrey>Зберегти</SButtonLightGrey>}
              </div>
            </div>
          </>
        }}
      </Formik>
      }
    </>
  )
    ;
};

const mapStateToProps = (state) => ({
  settings: state.settingsReducer.settings
})


KadriCardsWorkDoc__2 = connect(mapStateToProps, {
  acceptDismissal, getSettings
})(KadriCardsWorkDoc__2)
