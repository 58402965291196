import moment from "moment";
import instance from "../../../redux/instance";
import { diffDate, isInCurrentMonth } from "../../../utilits/diffDate";

export const get_users = async (filter_data, show_deleted, vacation_list) => {
  const status_list = ["cancellation", undefined, null];

  filter_data.mobilePhone =
    filter_data.mobilePhone !== "" ? check_phone(filter_data.mobilePhone) : "";
  const searchParams = new URLSearchParams(filter_data);
  let { data } = await instance.get(`/user/get_reference?${searchParams}`);

  data = data
    .filter((e) => (e.postInfo ? true : false))
    .filter((e) =>
      status_list.includes(e.postInfo.status)
        ? // status_list.includes(e.postInfo.currentStatus)
          false
        : true
    )
    .filter((e) => (e.postInfo.currentStatus === "cancellation" ? false : true))
    .filter((e) => (e.workerSuperior ? true : false));

  if (filter_data["postInfo.posada"].length !== 0) {
    data = data.filter((e) =>
      e.postInfo.status === filter_data["postInfo.posada"] ? true : false
    );
  }

  data = get_vacation_status(vacation_list, data);
  data = sortByAlphabet(data);

  if (show_deleted) {
    return data;
  } else {
    return data.filter((e) =>
      e.postInfo.currentStatus === "deleted" ? false : true
    );
  }
};

const check_phone = (phone) => {
  let result = phone.replace(/\D/g, "").trim();
  if (result.startsWith("38")) {
    result = result.substring(2);
  }
  if (result.startsWith("3")) {
    result = result.substring(1);
  }
  if (result.startsWith("8")) {
    result = result.substring(1);
  }
  result =
    result.substring(0, 3) +
    " " +
    result.substring(3, 6) +
    " " +
    result.substring(6, 8) +
    " " +
    result.substring(8);
  return result;
};

const get_vacation_status = (vacation_list, employer_list) => {
  let result = [];
  for (let i = 0; i < employer_list.length; i++) {
    const status = employer_list[i].postInfo.currentStatus
      ? employer_list[i].postInfo.currentStatus
      : employer_list[i].postInfo.status;

    if (status === "vacation") {
      const vacation = vacation_list.find(
        (items) => items.indexId === employer_list[i]._id
      );
      console.log(vacation, status, employer_list[i].surname, [i]);
      if (vacation) {
        const startDate = moment(vacation.info.exp.start.trim(), "DD.MM.YYYY");
        const endDate = moment(vacation.info.exp.end.trim(), "DD.MM.YYYY");
        const diff = diffDate(new Date(), endDate, "days");

        const isCurrentMonth = isInCurrentMonth([startDate, endDate]);

        if (diff >= 0) {
          if (isCurrentMonth.includes(true)) {
            employer_list[i].postInfo.currentStatus = "vacation";
            employer_list[i].info = {
              exp: {
                start: vacation.info.exp.start,
                end: vacation.info.exp.end,
              },
            };

            result.push(employer_list[i]);
          } else {
            employer_list[i].postInfo.currentStatus = "work";
            result.push(employer_list[i]);
          }
          continue;
        } else {
          employer_list[i].postInfo.currentStatus = "work";
          result.push(employer_list[i]);
          continue;
        }
      } else {
        employer_list[i].postInfo.currentStatus = "work";
        result.push(employer_list[i]);
        continue;
      }
    } else {
      result.push(employer_list[i]);
    }
  }
  return result;
};

function sortByAlphabet(arr) {
  return arr.sort((a, b) => a.surname.localeCompare(b.surname, "uk"));
}
