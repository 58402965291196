//libs
import React, { useState } from "react";
//images
import mainLogo from "../../images/main-logo.svg";
//hooks
import useHttp from "../../hooks/http.hook";
import { useAuth } from "../../hooks/auth.hook";
import { useHistory } from "react-router";
import { useAlert } from "react-alert";
//constants
import { LOGIN_URL } from "../../constants/urls";
//styles
import "./Login.css";
import LoginWithGoogleAuth from "../../components/modals/LoginWithGoogleAuth/LoginWithgoogleAuth";
import { setCookie } from "../../utilits/cookies";
import { useSelector } from "react-redux";
import InputMask from "react-input-mask";
import phoneValidation from "../../validations/phoneValidation";

export const Login = () => {
  const matchEmail = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/g;
  const emailErrors = {
    "Невірний email": "Невірний e-mail",
  };
  const passwordErrors = {
    "Поле пароль не может быть пустыи": "Поле пароль не може бути порожнім",
  };
  const alertErrors = {
    "Error: User with this email does not exist": "Такого користувача не існує",
    "Error: User with this email or password does not exist": "Невірний пароль",
    "Wrong google authenticator code": "\n" + "Невірний код автентифікації",
  };

  const history = useHistory();
  const { login } = useAuth(); //logout
  const { loading, request, error, clearError, post } = useHttp();
  const alert = useAlert();
  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [googleAuthModal, setGoogleAuthModal] = useState(false);
  const [typeName, setTypeName] = useState("");
  const [form, setForm] = useState({
    email: "",
    telephone: "",
    password: "",
  });

  const loginHandler = async (forma) => {
    try {
      const request = await post(LOGIN_URL, forma);
      console.log(request.data);
      if (request.data.jwtToken) {
        setCookie("user", JSON.stringify(request.data.user));
        login(request.data.jwtToken);
      } else if (request.data.message === "Wrong google authenticator code") {
        if (googleAuthModal) {
          throw Error(request.data.message);
        } else {
          setGoogleAuthModal(true);
        }
      } else if (request.ok) {
        throw Error(request.data.message);
      } else {
        throw Error(request.data);
      }
    } catch (e) {
      if (Object.keys(passwordErrors).includes(e.message)) {
        setPasswordError(passwordErrors[e.message]);
        return;
      }

      if (!phoneValidation(form.telephone) && typeName === "telephone") {
        alert.show("Невірний номер телефону", { type: "error" });
        return;
      } else if (!form.email.match(matchEmail) && typeName === "email") {
        setEmailError(emailErrors["Невірний email"]);
        alert.show("Невірний email", { type: "error" });
        return;
      } else {
        if (Object.keys(alertErrors).includes(e.message)) {
          alert.show(alertErrors[e.message], { type: "error" });
          return;
        }
      }
      alert.show("Щось пішло не так :(", { type: "error" });
    }
  };

  const loginWithGoogleAuth = (authentificator) => {
    loginHandler({ ...form, googleAuthenticator: authentificator });
  };

  const changeHandler = (event) => {
    setEmailError("");
    setPasswordError("");
    setTypeName(event.target.name);

    setForm({
      ...form,
      [event.target.name]:
        event.target.name === "telephone"
          ? event.target.value.replace(/[^0-9]/g, "")
          : event.target.value,
    });
    if (event.target.value.length === 0) {
      setTypeName("email");
    }
    if (event.target.value.match(matchEmail)) {
      setForm({ ...form, email: event.target.value, telephone: "" });
    }
    if (phoneValidation(event.target.value)) {
      setForm({
        ...form,
        email: "",
        telephone: event.target.value.replace(/[^0-9]/g, ""),
      });
    }
  };

  return (
    <div className="login">
      {googleAuthModal && (
        <LoginWithGoogleAuth
          closeFunc={() => {
            setGoogleAuthModal(false);
          }}
          loginFunc={loginWithGoogleAuth}
        />
      )}
      <div className="login__left">
        <div className="login__logo">
          <img src={mainLogo} alt="logo"></img>
        </div>
      </div>
      <div className="login__right">
        <form
          onSubmit={(event) => {
            event.preventDefault();
            loginHandler(form);
          }}
          className="login__form"
        >
          {form.telephone === "" ? (
            <input
              type="text"
              name="email"
              className={`login__input ${
                !!emailError ? "login__input_error" : ""
              }`}
              placeholder="E-mail або Номер телефону"
              onChange={changeHandler}
            />
          ) : (
            <InputMask
              mask="(999) 999-99-99"
              name="telephone"
              value={form.telephone}
              onChange={changeHandler}
              className={`login__input ${
                !!emailError ? "login__input_error" : ""
              }`}
            />
          )}
          <p className="login__error">{emailError}</p>
          <input
            type="password"
            name="password"
            className={`login__input ${
              !!passwordError ? "login__input_error" : ""
            }`}
            placeholder="Пароль"
            onChange={changeHandler}
          />
          <p className="login__error">{passwordError}</p>
          <button
            className="login__btn"
            style={{ width: "130px", height: "50px" }}
          >
            <a>Увійти</a>
          </button>
        </form>
      </div>
    </div>
  );
};
