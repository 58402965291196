import React, { useEffect, useRef, useState } from "react";
import {
  InputForm,
  SelectForm,
} from "../../../../../../styledComponents/Inputs";
import InputMask from "react-input-mask";
import phoneValidation from "../../../../../../validations/phoneValidation";
import { useDispatch, useSelector } from "react-redux";
import { getSearchEmployerAll } from "../../../../../../redux/employers/employers-thunks";
import loadCurrentWorker from "../../../../../../redux/actions/currentWorker/loadCurrentWorker";
import {
  ID_CARD,
  LOAD_CURRENT_WORKER,
  OLD_PASSPORT,
} from "../../../../../../redux/types";
import { useAlert } from "react-alert";
import moment from "moment";

const matches = (needle, haystack) => {
  needle = needle.toLocaleLowerCase();
  haystack = (haystack || "").toLocaleLowerCase();

  return haystack.indexOf(needle) > -1;
};
const fields = [
  (item) => item.surname,
  (item) => (item.RNOKPP ? item.RNOKPP.toString() : ""),
];
const onClick = (e, root, setIsShow) => {
  if (!root.current) return;
  if (!root.current.contains(e.target)) {
    setIsShow(false);
  } else {
    return true;
  }
};
const FilterList = ({
  setFieldValue,
  setIsEdited,
  setSearch,
  search,
  data,
  setCurrentWorker,
  setSelectedPassport,
  ...props
}) => {
  const rootEl = useRef(null);
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const handleChangeFind = (e) => {
    setIsEdited && setIsEdited(true);

    if (e.target.value.length >= 1) {
      let newStr = e.target.value[0].toUpperCase() + e.target.value.slice(1);
      setFieldValue(e.target.name, newStr);
    } else {
      setFieldValue(e.target.name, e.target.value);
    }

    setSearch(e.target.value);

    if (e.target.value.length >= 3) {
      setIsShow(true);
      document.addEventListener("click", (e) => onClick(e, rootEl, setIsShow));
    } else {
      setIsShow(false);
    }
  };
  let filterData =
    data &&
    data.filter((item) => fields.some((field) => matches(search, field(item))));
  console.log("🚀 ~ filterData:", filterData);
  const selectEmployer = (object, name) => {
    dispatch(loadCurrentWorker(object._id, false, false, true)).then((res) => {
      console.log(res);
      setSelectedPassport &&
        setSelectedPassport(res.passport.length === 9 ? ID_CARD : OLD_PASSPORT);
      setFieldValue("place", res.place);
      dispatch({
        type: LOAD_CURRENT_WORKER,
        payload: {
          ...res,
          workerId: res.workerSuperior,
          searchId: object._id,
          isFullData: true,
        },
      });
    });
    setFieldValue(name, object[name]);
    setIsShow(false);
  };
  useEffect(() => {
    return () =>
      document.removeEventListener("click", (e) =>
        onClick(e, rootEl, setIsShow)
      );
  }, []);

  return (
    <>
      <InputForm
        onChange={(e) => handleChangeFind(e)}
        name={props.name}
        value={props.value}
        style={props.style}
      />
      {isShow && (
        <ul
          ref={rootEl}
          className={`search__list ${
            filterData && filterData.length <= 0 ? "active" : ""
          }`}
          style={{ width: 360 }}
        >
          {filterData.map((el) => (
            <li key={el._id} onClick={() => selectEmployer(el, props.name)}>{`${
              el.surname
            } ${el.name} ${el.patronymic} (${
              el.RNOKPP ? el.RNOKPP : "без рнокпп"
            }) ${
              el.postInfo.currentStatus === "cancellation"
                ? "скасовано"
                : el.postInfo.currentStatus === "deleted" ||
                  moment(el.postInfo.uvalLastDay, "DD.MM.YYYY").isBefore(
                    new Date()
                  )
                ? "звільнено"
                : el.postInfo.currentStatus === "truancy"
                ? "прогул"
                : el.postInfo.status === "Неоформленный"
                ? "Неоформлений"
                : "працює"
            }`}</li>
          ))}
        </ul>
      )}
    </>
  );
};

const IdentificationData = ({
  handleChange,
  surname,
  name,
  patronymic,
  setSelectedPassport,
  RNOKPP,
  mobilePhone,
  workPhoneNumber,
  sex,
  resours,
  methodInvite,
  birthday,
  setIsEdited,
  workerId,
  setFieldValue,
}) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const alertFunc = () => {
    alert.show("Заповніть спочатку поле Ресурс", { type: "info" });
  };
  const data = useSelector((state) =>
    state.employersReducer.employerAll ? state.employersReducer.employerAll : []
  );
  useEffect(() => {
    console.log("🚀 ~ data:", data);
  }, [data]);
  useEffect(() => {
    dispatch(getSearchEmployerAll(workerId));
  }, [dispatch]);
  const [search, setSearch] = React.useState("");
  const handleChangeCaseOne = (e) => {
    e.preventDefault();
    setIsEdited && setIsEdited(true);
    if (e.target.value.length >= 1) {
      let newStr = e.target.value[0].toUpperCase() + e.target.value.slice(1);
      setFieldValue(e.target.name, newStr);
    } else {
      setFieldValue(e.target.name, e.target.value);
    }
  };

  const WebSelect = () => {
    return (
      <>
        <option value="" hidden selected>
          Виберіть
        </option>
        <option value="work.ua">work.ua</option>
        <option value="robota.ua">robota.ua</option>
        <option value="jooble">Jooble</option>
        <option value="olx">OLX</option>
        <option value="PidBir">PidBir</option>
        <option value="Facebook">Facebook</option>
        <option value="Instagram">Instagram</option>
      </>
    );
  };
  const MessangerSelect = () => {
    return (
      <>
        <option value="" hidden selected>
          Виберіть
        </option>
        <option value="Telegram">Telegram групи</option>
        <option value="Viber">Viber групи</option>
      </>
    );
  };
  const AnotherSelect = () => {
    return (
      <>
        <option value="" hidden selected>
          Виберіть
        </option>
        <option value="Оголошення в магазині">Оголошення в магазині</option>
        <option value="Чек-оголошення">Чек-оголошення</option>
        <option value="Рекомендації">Рекомендації</option>
        <option value="Дзвінок менеджера з персоналу">
          Дзвінок менеджера з персоналу
        </option>
        <option value="Таргетована реклама">Таргетована реклама</option>
        <option value="Аудіо-оголошення в магазині">
          Аудіо-оголошення в магазині
        </option>
        <option value="Агенція з працевлаштування">
          Агенція з працевлаштування
        </option>
        <option value="Заклади навчання (технікум, інститут, тощо)">
          Заклади навчання (технікум, інститут, тощо)
        </option>
        <option value="Оголошення на вулиці (паперові)">
          Оголошення на вулиці (паперові)
        </option>
        <option value="Флаєри">Флаєри</option>
        <option value="Центр зайнятості">Центр зайнятості</option>
      </>
    );
  };
  return (
    <div className="table__row" style={{ marginBottom: "10px" }}>
      <div className="table__place">
        <span>Ідентифікаційні дані</span>
      </div>
      <div className="table__value">
        <div className="table__value__row">
          <div className="table__value__item" style={{ position: "relative" }}>
            <span>Прізвище</span>
            <FilterList
              setFieldValue={setFieldValue}
              setSearch={setSearch}
              setSelectedPassport={setSelectedPassport}
              setIsEdited={setIsEdited}
              data={data}
              search={search}
              style={{ width: "230px" }}
              id="surname"
              name="surname"
              type="text"
              value={surname}
            />
            {/* <InputForm style={{ width: "230px" }} /> */}
          </div>
          <div className="table__value__item">
            <span>Ім’я</span>
            <InputForm
              style={{ width: "230px" }}
              id="name"
              name="name"
              type="text"
              onChange={handleChangeCaseOne}
              value={name}
            />
            {/* <InputForm style={{ width: "230px" }} /> */}
          </div>
          <div className="table__value__item">
            <span>По батькові</span>
            <InputForm
              style={{ width: "230px" }}
              id="patronymic"
              name="patronymic"
              type="text"
              onChange={handleChangeCaseOne}
              value={patronymic}
            />
            {/* <InputForm style={{ width: "230px" }} /> */}
          </div>
        </div>

        <div
          className="table__value__row"
          style={{ marginBottom: "0px", justifyContent: "space-between" }}
        >
          <div className="table__value__item">
            <div className="table__value__item__row">
              <span className="table__value__item__input">
                <span>РНОКПП</span>
                <FilterList
                  style={{
                    width: "142px",
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0",
                    backgroundColor:
                      RNOKPP === null ? "#F2F2F2" : "transparent",
                  }}
                  setSelectedPassport={setSelectedPassport}
                  setFieldValue={setFieldValue}
                  setIsEdited={setIsEdited}
                  setSearch={setSearch}
                  data={data}
                  search={search}
                  id="RNOKPP"
                  name="RNOKPP"
                  type="text"
                  max={10}
                  maxLength={10}
                  value={RNOKPP ?? ""}
                  disabled={RNOKPP === null}
                />
              </span>
              <div style={{ marginBottom: 2 }} className="item__input__check">
                <span>
                  <input
                    onChange={(e) => {
                      if (e.target.checked) setFieldValue("RNOKPP", null);
                      else setFieldValue("RNOKPP", "");
                      setIsEdited && setIsEdited(true);
                    }}
                    checked={RNOKPP === null}
                    id="check"
                    type="checkbox"
                  />
                </span>
                <span style={{ marginLeft: "6px" }}>Особа діє без коду</span>
              </div>
            </div>
          </div>

          <div className="table__value__item">
            <span>Стать</span>
            <SelectForm
              style={{ width: "100px" }}
              id="sex"
              name="sex"
              onChange={handleChange}
              value={sex}
            >
              <option value="" hidden selected>
                Виберіть
              </option>
              <option value="Ч">чоловік</option>
              <option value="Ж">жінка</option>
            </SelectForm>
          </div>
          {/* <div className="table__value__item">
              <span>Стать</span>
              <SelectForm style={{ width: "107px" }} />
            </div> */}
          <div className="table__value__item">
            <span>Дата народження</span>
            <InputMask
              style={{ width: "107px", padding: "3px" }}
              maskChar={""}
              mask={"99.99.9999"}
              id="birthday"
              name="birthday"
              placeholder="дд.мм.рррр"
              type={"text"}
              autoComplete={"off"}
              onChange={handleChange}
              value={birthday}
              className={"inputForm"}
            />
          </div>
        </div>
        <div className="table__value__row">
          <div
            className="table__value__item__row"
            style={{ width: "100%", justifyContent: "flex-start" }}
          >
            <div className="table__value__item">
              <span>Номер телефона</span>
              <InputMask
                style={{ width: "150px" }}
                mask={"099 999 99 99"}
                maskChar={""}
                id="mobilePhone"
                name="mobilePhone"
                onChange={handleChange}
                type={"text"}
                autoComplete={"off"}
                value={mobilePhone}
                className={`inputForm ${
                  mobilePhone.length >= 1 &&
                  (phoneValidation(mobilePhone) ? "" : "error")
                }`}
              />
            </div>
            <div className="table__value__item">
              <span>Робочий номер телефона</span>
              <InputMask
                style={{ width: "150px" }}
                mask={"099 999 99 99"}
                maskChar={""}
                id="workPhoneNumber"
                name="workPhoneNumber"
                onChange={handleChange}
                type={"text"}
                autoComplete={"off"}
                value={workPhoneNumber}
                className={`inputForm ${
                  workPhoneNumber.length >= 1 &&
                  (phoneValidation(workPhoneNumber) ? "" : "error")
                }`}
              />
            </div>
            {/* {resours ? (
              <div className="table__value__item">
                <span>Ресурс</span>
                <SelectForm
                  style={{ width: "100px" }}
                  id="resours"
                  name="resours"
                  onChange={handleChange}
                  value={resours}
                >
                  <option value="" hidden selected>
                    Виберіть
                  </option>
                  <option value="website">Web-сайти</option>
                  <option value="messanger">Месенджери</option>
                  <option value="offline">Офлайн</option>
                </SelectForm>
              </div>
            ) : (
              ""
            )}
            {methodInvite ? (
              <div className="table__value__item">
                <span>Спосіб</span>
                <SelectForm
                  style={{ width: "100px" }}
                  id="methodInvite"
                  name="methodInvite"
                  onChange={handleChange}
                  disabled={resours === "" ? true : false}
                  onClick={resours === "" ? () => alertFunc : ""}
                  value={methodInvite}
                >
                  {resours === "website"
                    ? WebSelect()
                    : resours === "messanger"
                    ? MessangerSelect()
                    : AnotherSelect()}
                </SelectForm>
              </div>
            ) : (
              ""
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(IdentificationData);
