import './TextInput.css'
import Input from '../MainInput/Input'
import React from 'react'
const TextInput = props => {

    const {type, name, onChange, value, placeholder, style, width, height, padding, disabled} = props

    // const changeHandler = (event) => {
    //     onChange(event.target.value)
    // }

    return (
        <Input
          width={width}
          height={height}
          padding={padding}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          disabled={disabled}
          name={name}
        />
    )
}

export default TextInput
