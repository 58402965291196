import React, { useEffect, useState } from "react";
import {
  MButtonOrange,
  SButtonLightGrey,
} from "../../../../../styledComponents/Buttons";

import "../KadriCards.css";
import styled from "./assets/css/personalEditTwo.module.css";
import { InputForm, SelectForm } from "../../../../../styledComponents/Inputs";
import { KadriCardsWorkDoc__4 } from "../CardsWorkDoc/KadriCardsWorkDoc__4";
import { Formik } from "formik";
import addWorker from "../../../../../redux/actions/workers/addWorker";
import { connect } from "react-redux";
import { getPositions } from "../../../../../redux/cadrDocuments/cadrDocuments-thunks";
import { useAlert } from "react-alert";
import { ID_CARD } from "../../../../../redux/types";
import IdentificationData from "./KadriCardsPersonBlocks/IdentificationData";
import AddressData from "./KadriCardsPersonBlocks/AddressData";
import PassportData from "./KadriCardsPersonBlocks/PassportData";
import PassportModal from "../../../PassportModal";
import DatePickerFragment from "../../../../../components/datePicker/datePicker";
import { store } from "../../../../../index";

export let KadriPagePersonal__2 = ({
  currentEmployer,
  setIsPassport,
  addWorker,
  workerId,
  positions,
  getPositions,
  togglePersonEdit__2,
}) => {
  const [dateRelease, setDateRelease] = useState(null);
  const [work__4, setWork__4] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedPassport, setSelectedPassport] = useState(ID_CARD);
  useEffect(() => {
    console.log("🚀 ~ togglePersonEdit__2:", togglePersonEdit__2);
  });
  const [currentWorker, setCurrentWorker] = useState({
    surname: "",
    name: "",
    patronymic: "",
    mobilePhone: "",
    workPhoneNumber: "",
    RNOKPP: "", // или null, если нажата галочка "без РНОКПП"
    sex: "",
    birthday: "",
    street: "",
    place: null,
    format: "",
    house: "",
    apartments: "",
    hours: "",
    methodInvite: "",
    resours: "",
    passport: "",
    authority: "",
    dateOfIssue: "",
    nationality: "Україна",
    city: "",
    position: "",
    pilga: "",
    posada: "",
    osnova: "",
    dateAccept: null,
    dayStart: null,
    dayEnd: null,
  });
  const alert = useAlert();
  useEffect(() => {
    getPositions(currentEmployer._id);
  }, []);
  const toggleWork__4 = () => {
    setWork__4(!work__4);
  };
  const alertFunc = (message, type) => {
    alert.show(message, { type: type });
    setIsFetching(false);
  };
  return (
    <div className={styled.personal_edit}>
      <Formik
        autoComplete="off"
        enableReinitialize={true}
        initialValues={currentWorker}
        onSubmit={async (values) => {
          setIsFetching(true);
          if (currentWorker?.isFullData) {
            store.dispatch(
              addWorker(
                {
                  ...values,
                  _id: currentEmployer._id,
                  workerId: currentWorker.workerId,
                  searchId: currentWorker.searchId,
                  city: values.street,
                  house: values.house,
                  pilga: values.pilga,
                  dateAccept: values.dateAccept,
                  pilgapercent: values.pilga === " " && "0",
                  posadaid: positions.find((p) => p.vacancy === values.position)
                    ?._id,
                },
                alertFunc,
                currentEmployer._id,
                togglePersonEdit__2
              )
            ); // just add workers
            return;
          }
          addWorker(
            {
              ...values,
              _id: currentEmployer._id,
              city: values.street,
              house: values.house,
              pilga: values.pilga,
              pilgapercent: values.pilga === " " && "0",
              posadaid: positions.find((p) => p.vacancy === values.position)
                ?._id,
            },
            alertFunc,
            currentEmployer._id,
            togglePersonEdit__2
          ); // just add workers
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => {
          let isValid =
            values.surname &&
            values.name &&
            values.patronymic &&
            values.mobilePhone &&
            values.workPhoneNumber &&
            (values.RNOKPP || values.RNOKPP === null) &&
            values.sex &&
            values.birthday &&
            values.street &&
            values.house &&
            values.passport &&
            // values.resours &&
            // values.methodInvite &&
            values.place &&
            values.place.street &&
            values.place.street.length > 1 &&
            values.authority &&
            values.dateOfIssue &&
            values.nationality &&
            values.position &&
            values.pilga &&
            values.format &&
            values.osnova;
          console.log(values, "INFO2");
          return (
            <>
              <PassportModal
                setIsPassport={setIsPassport}
                setFieldValue={setFieldValue}
              />
              <div className="table__container__full">
                <IdentificationData
                  handleChange={handleChange}
                  surname={values.surname}
                  name={values.name}
                  workerId={workerId}
                  patronymic={values.patronymic}
                  RNOKPP={values.RNOKPP}
                  mobilePhone={values.mobilePhone}
                  resours={values.resours}
                  methodInvite={values.methodInvite}
                  workPhoneNumber={values.workPhoneNumber}
                  sex={values.sex}
                  birthday={values.birthday}
                  setCurrentWorker={setCurrentWorker}
                  setFieldValue={setFieldValue}
                />
                <AddressData
                  street={values.street}
                  place={values.place}
                  setFieldValue={setFieldValue}
                  house={values.house}
                  apartments={values.apartments}
                  handleChange={handleChange}
                />
                <PassportData
                  handleChange={handleChange}
                  authority={values.authority}
                  dateOfIssue={values.dateOfIssue}
                  dateRelease={dateRelease}
                  nationality={values.nationality}
                  passport={values.passport}
                  selectedPassport={selectedPassport}
                  setDateRelease={setDateRelease}
                  setFieldValue={setFieldValue}
                  setSelectedPassport={setSelectedPassport}
                />
                <div className="table__row">
                  <div className="table__place">
                    <span>Статус працівника</span>
                  </div>
                  <div
                    className="table__value"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      width: "100%",
                      maxWidth: 738,
                    }}
                  >
                    <div className="table__value__row">
                      <div className="table__value__item">
                        <span>Посада</span>
                        <SelectForm
                          value={values.position}
                          onChange={handleChange}
                          id={"position"}
                          style={{ width: "200px" }}
                        >
                          <option value="" disabled hidden />
                          {positions
                            .sort((a, b) =>
                              a.vacancy
                                .toLowerCase()
                                .localeCompare(
                                  b.vacancy.toLowerCase(),
                                  "uk-UA",
                                  { sensitivity: "accent" }
                                )
                            )
                            .map((pos, index) => (
                              <option key={index} value={pos.title}>
                                {pos.title}
                              </option>
                            ))}
                        </SelectForm>
                      </div>
                      <div className="table__value__item">
                        <span>Розмір соціальної пільги</span>
                        <SelectForm
                          value={values.pilga}
                          id={"pilga"}
                          onChange={handleChange}
                          style={{ width: "200px" }}
                        >
                          <option value="" disabled hidden>
                            Виберіть зі списку
                          </option>
                          <option value=" ">пільга не засосовується</option>
                          <option value="50">50%</option>
                          <option value="100">100%</option>
                          <option value="150">150%</option>
                          <option value="200">200%</option>
                          <option value="300">300%</option>
                          <option value="400">400%</option>
                          <option value="500">500%</option>
                        </SelectForm>
                      </div>
                      <div
                        className="table__value__item"
                        style={{ marginRight: 8 }}
                      >
                        <span>Місце роботи</span>
                        <SelectForm
                          value={values.format}
                          onChange={handleChange}
                          id={"format"}
                          className="conditions__select-input"
                          defaultValue={""}
                          style={{ width: "200px" }}
                        >
                          <option value="" disabled hidden>
                            Виберіть зі списку
                          </option>
                          <option value="основне">Основне</option>
                          <option value="за сумісництвом">
                            За сумісництвом
                          </option>
                        </SelectForm>
                      </div>
                      {values.format === "за сумісництвом" && (
                        <InputForm
                          value={values.hours}
                          onChange={handleChange}
                          id={"hours"}
                          type={"number"}
                          style={{ width: "30%" }}
                          placeholder={"год/день"}
                        />
                      )}
                    </div>
                    <div
                      className="table__value__row"
                      style={{ display: "flex", alignItems: "flex-end" }}
                    >
                      <div
                        className="table__value__item"
                        style={{ marginRight: 8, maxWidth: 110 }}
                      >
                        <span>Дата прийняття</span>
                        <DatePickerFragment
                          selected={values.dateAccept}
                          dateFormat="dd.MM.yyyy"
                          onChange={(data) => setFieldValue("dateAccept", data)}
                        />
                      </div>
                      <div
                        className="table__value__item"
                        style={{ marginRight: 8 }}
                      >
                        <span>Вид договору</span>
                        <SelectForm
                          value={values.osnova}
                          onChange={handleChange}
                          id={"osnova"}
                          className="conditions__select-input"
                          defaultValue={""}
                          style={{ width: "200px" }}
                        >
                          <option value="" disabled hidden>
                            Виберіть зі списку
                          </option>
                          <option value="строковий">строковий</option>
                          <option value="безстроковий">безстроковий</option>
                        </SelectForm>
                      </div>
                      <>
                        {values.osnova === "строковий" && (
                          <div className={styled.personal_edit__date}>
                            <DatePickerFragment
                              selected={values.dayStart}
                              dateFormat="dd.MM.yyyy"
                              onChange={(data) =>
                                setFieldValue("dayStart", data)
                              }
                            />
                            <DatePickerFragment
                              selected={values.dayEnd}
                              dateFormat="dd.MM.yyyy"
                              onChange={(data) => setFieldValue("dayEnd", data)}
                            />
                          </div>
                        )}
                      </>
                    </div>
                  </div>
                </div>

                <div style={{ marginBottom: 0 }} className="table__row">
                  <div className="table__value" style={{ marginLeft: "auto" }}>
                    <div className="table__value__item null">
                      {isFetching ||
                      !isValid ||
                      !values.dateAccept ||
                      (values.format === "за сумісництвом" && !values.hours) ||
                      (values.osnova === "строковий" &&
                        !(values.dayEnd && values.dayStart)) ? (
                        <SButtonLightGrey style={{ margin: "0" }}>
                          Зберегти
                        </SButtonLightGrey>
                      ) : (
                        <MButtonOrange
                          onClick={handleSubmit}
                          style={{ margin: "0" }}
                        >
                          Зберегти
                        </MButtonOrange>
                      )}
                    </div>
                  </div>
                </div>
                {work__4 && <div className="bg__layer"></div>}
                {work__4 && (
                  <KadriCardsWorkDoc__4 toggleWork__4={toggleWork__4} />
                )}
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentEmployer: state.employersReducer.currentEmployer,
  positions: state.cadrDocumentsReducer.positions,
});

KadriPagePersonal__2 = connect(mapStateToProps, {
  addWorker,
  getPositions,
})(KadriPagePersonal__2);
