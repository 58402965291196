import {SHOW_ALERT} from "../../types"


const showAlert = (message, type) => async dispatch => {
  dispatch({
    type: SHOW_ALERT, payload: {
      message: message,
      type: type
    }
  })
}

export default showAlert
