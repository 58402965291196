import React from 'react';
import trash from "../../../../../../assets/trash.svg";
import InputMask from 'react-input-mask'

const KadriPageFamilyRow = ({
                              birthYear,
                              birthYearId,
                              setIsEdited,
                              familyMember,
                              familyMemberId,
                              handleChange,
                              PIB,
                              PIBId,
                              onDelete
                            }) => {
  return (
    <tr className="tr-sm" style={{marginTop: "-5px"}}>
      <td
        className="table-md"
        style={{
          height: "24px",
          textAlign: "center",
          border: "1px solid #BDBDBD",
          margin: "0",
          padding: "0",
          borderRight: "none",
        }}
      >
        <select value={familyMember} onChange={(event) => {
          handleChange(event);
          setIsEdited(true)
        }} id={familyMemberId} style={{border: "none"}}>
          <option value="" disabled selected hidden>Виберіть зі списку</option>
          <option value="чоловік">чоловік</option>
          <option value="дружина">дружина</option>
          <option value="донька">донька</option>
          <option value="син">син</option>
        </select>
      </td>
      <td
        className="table-lg"
        style={{
          height: "24px",
          textAlign: "center",
          border: "1px solid #BDBDBD",
        }}
      >
        <input value={PIB} id={PIBId} onChange={(event) => {
          handleChange(event);
          setIsEdited(true)
        }} type="text" style={{border: "none"}}/>
      </td>
      <td
        className="table-md"
        style={{
          height: "24px",
          textAlign: "center",
          border: "1px solid #BDBDBD",
        }}
      >
        <InputMask mask={'9999'} maskChar={''} value={birthYear} id={birthYearId} onChange={(event) => {
          handleChange(event);
          setIsEdited(true)
        }}
                   type="text" style={{border: "none"}}/>
      </td>
      <td
        className="quota__row__table__trash"
        style={{
          height: "24px",
          width: "33px",
          textAlign: "center",
          border: "1px solid #BDBDBD",
          borderLeft: "none",
        }}
      >
        <button onClick={onDelete}>
          <img src={trash}/>
        </button>
      </td>
    </tr>
  );
};

export default KadriPageFamilyRow;
