import MiniButton from "../../common/MiniButton/MiniButton"
import Dropdown from "../Dropdown/Dropdown"
import moreIcon from '../../../images/icons/more.svg'
import { useState } from "react"
import GreenButton from "../../buttons/GreenBuuton.js/GreenButton"

const DropdownGreenButton = props => {
    const {onClick, buttonText, disabled, setDisabled} = props

    // const toggleDropdown = () => {
    //     setDisabled(!disabled)
    // }

    const clickHandler = () => {
        onClick()
    }

    return (
        <Dropdown
            button={<GreenButton><span>{buttonText}</span></GreenButton>}
            disabled={disabled}
            // toggleFunc={toggleDropdown}
        >
            {props.children}
        </Dropdown>
    )
}

export default DropdownGreenButton