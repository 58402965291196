import "./assets/css/conditionwork.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import DatePickerFragment from "../../../../../components/datePicker/datePicker";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessageAll from "../../../../../utilits/errorMessageAll";
import { useDispatch } from "react-redux";
import {
  getInfoKadrConditions,
  saveInfoEmployer,
} from "../../../../../api/kadriConditions";
import moment from "moment";
import instance from "../../../../../redux/instance";
import DateMonth from "../../../../../components/dateMonth/dateMonth";
import { diffDate } from "../../../../../utilits/diffDate";
import { EMPLOYER_GET_DATA } from "../../../../../constants/urls";
import {
  get_current_employer,
  get_number_vacation,
} from "../../../../../utilits/getVacationNumber";
import { plural } from "ru-plurals";
import { get_experience } from "../../../../../utilits/get_experiance";

const validationSchema = Yup.object().shape({
  localWork: Yup.string(),
  osnova: Yup.string(),
  posada: Yup.string(),
  salary: Yup.string(),
  tableNumber: Yup.string(),
  baseMoth: Yup.date(),
  numberSuccess: Yup.string(),
  startWorkDay: Yup.date().required("Вкажіть дату прийняття"),
  workExperience: Yup.string(),
  qualityHoliday: Yup.string(),
  sizePrivilege: Yup.string(),
});

const KadriPageConditionsWork = ({ postInfo, id, workerId }) => {
  let initValue = {
    dayStart: null,
    dayEnd: null,
    hours: postInfo.hours ?? "",
    localWork: postInfo.format ?? "",
    osnova: "",
    posada: postInfo.status ?? "",
    salary: "",
    tableNumber: "",
    baseMoth: null,
    numberSuccess: "",
    workExperience: "",
    workExperienceCompany: "",
    sizePrivilege: null,
    qualityHoliday: "",
    startWorkDay: null,
  };

  const get_by_id = async (_id) => {
    return await instance.post(EMPLOYER_GET_DATA, { id: _id });
  };
  const get_employer = async (user) => {
    return await instance.post("/employer/get_bot_info", { ...user });
  };

  const {
    values,
    handleSubmit,
    setValues,
    setFieldValue,
    handleChange,
    touched,
    submitForm,
    handleBlur,
    errors,
  } = useFormik({
    initialValues: { ...initValue },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (data) => {
      await saveInfoEmployer(id, values).then((res) => {
        res.more &&
          setValues({
            ...res.more,
            baseMoth: !data.baseMoth
              ? new Date(res.more.baseMoth)
              : data.baseMoth,
            dayEnd: res.more.dayEnd ? new Date(res.more.dayEnd) : data.dayEnd,
            dayStart: res.more.dayStart
              ? new Date(res.more.dayStart)
              : data.dayStart,
            startWorkDay: !data.startWorkDay
              ? new Date(res.more.startWorkDay)
              : data.startWorkDay,
            // workExperience: res.more?.startWorkDay
            //   ? `${diffDate(
            //       new Date(res.more.startWorkDay),
            //       new Date(),
            //       "days"
            //     )} днів`
            //   : "",
          });
      });
    },
  });

  useEffect(async () => {
    getInfoKadrConditions(id, workerId).then(async (res) => {
      setFieldValue(
        "osnova",
        res.more?.osnova
          ? res.more.osnova
          : res.type === "Безстроковий трудовий договір"
          ? "безстроковий"
          : "строковий"
      );
      setFieldValue("tableNumber", res.more?.tableNumber);
      setFieldValue("numberSuccess", res.more?.numberSuccess);
      setFieldValue(
        "baseMoth",
        res.more?.startWorkDay
          ? new Date(res.more.startWorkDay)
          : res.info?.dataaccept
          ? new Date(moment(res.info.dataaccept, "DD.MM.YYYY"))
          : res.more?.dayStart
          ? new Date(res.more.dayStart)
          : null
      );
      setFieldValue(
        "sizePrivilege",
        res.more?.sizePrivilege
          ? Number(res.more.sizePrivilege)
          : res.info?.pilga
          ? Number(res.info.pilga)
          : ""
      );
      console.log(
        res.more?.startWorkDay
          ? new Date(res.more.startWorkDay)
          : res.more?.dateAccept
          ? new Date(res.more.dateAccept)
          : res.info?.firstday
          ? new Date(moment(res.info.firstday, "DD.MM.YYYY"))
          : null
      );
      setFieldValue(
        "startWorkDay",
        res.more?.startWorkDay
          ? new Date(res.more.startWorkDay)
          : res.more?.dateAccept
          ? new Date(res.more.dateAccept)
          : res.info?.firstday
          ? new Date(moment(res.info.firstday, "DD.MM.YYYY"))
          : null
      );
      setFieldValue(
        "dayStart",
        res.more?.dayStart ? new Date(res.more.dayStart) : null
      );
      setFieldValue(
        "dayEnd",
        res.more?.dayEnd ? new Date(res.more.dayEnd) : null
      );

      setFieldValue(
        "salary",
        res.salary
          ? `${Number(res.salary).toFixed(2)} (${res.percent}% мін. ЗП)`
          : ""
      );

      const { data } = await get_by_id(id);
      console.log("🚀 ~ getInfoKadrConditions ~ data:", data);

      const query = data.employer.workerID
        ? {
            workerID: data.employer.workerID,
          }
        : { _id: data.employer._id };

      const result = await get_employer(query);
      setTimeout(async () => {
        let { all_exp, posada_exp } = await get_experience(
          result.data ? result.data : []
        );

        setFieldValue("workExperience", posada_exp);
        setFieldValue("workExperienceCompany", all_exp);
      }, 1000);
      const current_emp = get_current_employer(result.data);
      console.log(current_emp);
      const date = current_emp.postInfo.more
        ? current_emp.postInfo.more.startWorkDay
        : current_emp.postInfo.acceptDate;
      const a = get_number_vacation(date, current_emp.vacation);

      console.log("🚀 ~ getInfoKadrConditions ~ a:", a);
      setFieldValue("qualityHoliday", a.toString());
      if (res.info?.exp) {
        setFieldValue(
          "dayStart",
          new Date(moment(res.info.exp.start, "DD.MM.YYYY"))
        );
        setFieldValue(
          "dayEnd",
          new Date(moment(res.info.exp.end, "DD.MM.YYYY"))
        );
      }
    });
  }, []);

  useEffect(() => {
    console.log(values);
  }, [values]);
  const onMouseLive = () => {
    submitForm();
  };
  return (
    <form
      autoComplete="off"
      className="table__container__full"
      onMouseLeave={onMouseLive}
      onSubmit={handleSubmit}
    >
      <div className="table__conditions">
        <div className="table__conditions_column">
          <div className="table__conditions_row table__conditions_label">
            Вид договору
          </div>
          <div className="table__conditions_row table__conditions_group">
            {/*Строковий безстроковий текст*/}
            <div className="table__conditions_field">
              <select
                value={values.osnova}
                onChange={(e) => {
                  setFieldValue("osnova", e.target.value);
                }}
                id={"osnova"}
                style={{ width: "200px" }}
                className="table__conditions-select"
              >
                <option value="" disabled hidden>
                  Виберіть зі списку
                </option>
                <option value="безстроковий">безстроковий</option>
                <option value="строковий">строковий</option>
              </select>
              <ErrorMessageAll
                name="osnova"
                errors={errors}
                touched={touched}
              />
            </div>

            {values.osnova === "строковий" && (
              <div
                className="table__conditions-date"
                style={{ marginLeft: 8, display: "flex" }}
              >
                <DatePickerFragment
                  selected={values.dayStart}
                  dateFormat="dd.MM.yyyy"
                  onChange={(data) => setFieldValue("dayStart", data)}
                />
                <DatePickerFragment
                  selected={values.dayEnd}
                  dateFormat="dd.MM.yyyy"
                  onChange={(data) => setFieldValue("dayEnd", data)}
                />
              </div>
            )}
          </div>
        </div>
        <div className="table__conditions_column">
          <div className="table__conditions_row table__conditions_label">
            Місце роботи
          </div>
          <div className="table__conditions_row table__conditions_group">
            <div className="table__conditions_field">
              <input
                type="text"
                name="localWork"
                value={values.localWork}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled
              />
              <ErrorMessageAll
                name="localWork"
                errors={errors}
                touched={touched}
              />
            </div>
            {values.localWork !== "основне" && (
              <input
                type="text"
                className="small-input"
                name="hours"
                value={values.hours}
                disabled
              />
            )}
          </div>
        </div>
        <div className="table__conditions_column">
          <div className="table__conditions_row table__conditions_label">
            Назва посади
          </div>
          <div className="table__conditions_row">
            <div className="table__conditions_field">
              <input
                type="text"
                name="posada"
                value={values.posada}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled
              />
              <ErrorMessageAll
                name="posada"
                errors={errors}
                touched={touched}
              />
            </div>
          </div>
        </div>
        <div className="table__conditions_column">
          <div className="table__conditions_row table__conditions_label">
            Посадовий оклад
          </div>
          <div className="table__conditions_row">
            <div className="table__conditions_field">
              <input
                type="text"
                name="salary"
                value={values.salary}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled
              />
              <ErrorMessageAll
                name="salary"
                errors={errors}
                touched={touched}
              />
            </div>
          </div>
        </div>
        <div className="table__conditions_column">
          <div className="table__conditions_row table__conditions_label">
            Табельний номер
          </div>
          <div className="table__conditions_row">
            <div className="table__conditions_field">
              <input
                type="number"
                name="tableNumber"
                value={values.tableNumber}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <ErrorMessageAll
                name="tableNumber"
                errors={errors}
                touched={touched}
              />
            </div>
          </div>
        </div>
        <div className="table__conditions_column">
          <div className="table__conditions_row table__conditions_label">
            Базовий місяць
          </div>
          <div className="table__conditions_row">
            <div className="table__conditions_field">
              <DateMonth
                selected={values.baseMoth}
                onChange={(data) => setFieldValue("baseMoth", data)}
                disabled
              />
              <ErrorMessageAll
                name="baseMoth"
                errors={errors}
                touched={touched}
              />
            </div>
          </div>
        </div>
        <div className="table__conditions_column">
          <div className="table__conditions_row table__conditions_label">
            Розмір пільги
          </div>
          <div className="table__conditions_row">
            <div className="table__conditions_field">
              <select
                value={values.sizePrivilege}
                onChange={handleChange}
                id={"sizePrivilege"}
                style={{ width: "200px" }}
                className="table__conditions-select"
              >
                <option value="" disabled hidden>
                  Виберіть зі списку
                </option>
                <option value=" ">пільга не засосовується</option>
                <option value="50">50%</option>
                <option value="100">100%</option>
                <option value="150">150%</option>
                <option value="200">200%</option>
                <option value="300">300%</option>
                <option value="400">400%</option>
                <option value="500">500%</option>
              </select>
              <ErrorMessageAll
                name="sizePrivilege"
                errors={errors}
                touched={touched}
              />
            </div>
          </div>
        </div>
        <div className="table__conditions_column">
          <div className="table__conditions_row table__conditions_label">
            Дата прийняття
          </div>
          <div className="table__conditions_row">
            <div className="table__conditions_field">
              <DatePickerFragment
                selected={values.startWorkDay}
                dateFormat="dd.MM.yyyy"
                onChange={(data) => {
                  setFieldValue("startWorkDay", data);
                  setFieldValue("baseMoth", data);
                }}
              />
              <ErrorMessageAll
                name="startWorkDay"
                errors={errors}
                touched={touched}
              />
            </div>
          </div>
        </div>
        <div className="table__conditions_column">
          <div className="table__conditions_row table__conditions_label">
            Номер наказу про прийняття
          </div>
          <div className="table__conditions_row">
            <div className="table__conditions_field">
              <input
                type="text"
                name="numberSuccess"
                value={values.numberSuccess}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <ErrorMessageAll
                name="numberSuccess"
                errors={errors}
                touched={touched}
              />
            </div>
          </div>
        </div>
        <div className="table__conditions_column">
          <div className="table__conditions_row table__conditions_label">
            Стаж роботи на посаді
          </div>
          <div className="table__conditions_row">
            <div className="table__conditions_field">
              <input
                type="text"
                name="workExperience"
                value={values.workExperience}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled
              />
              <ErrorMessageAll
                name="workExperience"
                errors={errors}
                touched={touched}
              />
            </div>
          </div>
        </div>
        <div className="table__conditions_column">
          <div className="table__conditions_row table__conditions_label">
            Стаж роботи у компанії
          </div>
          <div className="table__conditions_row">
            <div className="table__conditions_field">
              <input
                type="text"
                name="workExperienceCompany"
                value={values.workExperienceCompany}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled
              />
              <ErrorMessageAll
                name="workExperienceCompany"
                errors={errors}
                touched={touched}
              />
            </div>
          </div>
        </div>
        <div className="table__conditions_column">
          <div className="table__conditions_row table__conditions_label">
            Кількість днів невикористаної відпустки
          </div>
          <div className="table__conditions_row">
            <div className="table__conditions_field">
              <input
                type="number"
                name="qualityHoliday"
                value={values.qualityHoliday}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled
              />
              <ErrorMessageAll
                name="qualityHoliday"
                errors={errors}
                touched={touched}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default KadriPageConditionsWork;
