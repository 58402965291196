import React, {useEffect, useState} from "react";

import closeGrey from "../../../../../assets/closeGrey.svg";
import printer from "../../../../../assets/printer.svg";


import {TextGrey} from "../../../../../styledComponents/Text";
import {SelectForm} from "../../../../../styledComponents/Inputs";
import {SButtonGrey} from "../../../../../styledComponents/Buttons";
import {KadriCardsDocAddModal} from "./KadrCardsDocAddModal";
import {connect, useDispatch, useSelector} from "react-redux";
import {downloadAllDocuments, getDocumentsJournal} from "../../../../../redux/cadrDocuments/cadrDocuments-thunks";
import JournalTd from "./JournalTd/JournalTd";
import {docTypes} from "../../../../../redux/types";
import KadriPreviewDocument from "../CardsPersonEdit/KadriPreviewDocument";
import {setPreviewFile} from "../../../../../redux/workers/worker-actions";
import moment from "moment";
import {WarningPopUp} from "../../../../PopUps/WarningPopup";
import {deletedDocument} from "../../../../../redux/documentOld/document-thunks";
import ButtonLoading from "../../../../../components/buttons/ButtonLoading";


function getDate(doc) {
  if (doc.info.typeName === "Наказ про надання відпуски") {
    return (doc.info?.dateNakaz && moment(doc.info.dateNakaz, "DD.MM.YYYY")) ?? moment(doc.info.dataaccept)
  } else if (doc.info.typeName === "Наказ про прийняття на роботу") {
    return (doc.postInfo?.dateAccept && moment(doc.postInfo?.dateAccept, "DD.MM.YYYY")) ?? moment(doc.postInfo.more.startWorkDay)
  } else if (doc.info.typeName === "Звільнення") {
    return moment(doc.info?.dateNakaz ?? doc.postInfo?.uvalLastDay.trim(), "DD.MM.YYYY")
  } else if (doc.info.typeName === "Наказ про переведення") {
    return moment(doc.info.dateNakaz)
  } else if (doc.info.typeName === "Наказ") {
    return moment(doc.info.date)
  }
}

function getNumber(doc) {
  return doc.info?.number || doc.info?.nomer || 0;
}

export let KadriCardsJournalModal = ({toggleJournalModal, toggleModalList, id, ...props}) => {
  const [year, setYear] = useState(moment(new Date()).year());
  const dispatch = useDispatch();
  const workers = useSelector(state => {
    return state.workers.filter(el => el.postInfo.currentStatus !== "cancellation" && !moment(el.postInfo.uvalLastDay, "DD.MM.YYYY").isBefore(new Date()))
  })
  useEffect(() => {
    props.getDocumentsJournal(year)
  }, [year])
  const [isLoader, setLoader] = useState(false);
  const [addModal, setAddModal] = useState(false)
  const [document, setDocument] = useState(false)
  const toggleAddModal = () => {
    setAddModal(!addModal)
  }

  return (
    <>
      <div className="doc__modal__container">
        <div className="doc__modal__header">
          <div className="modal__header__title">
            <TextGrey style={{textAlign: "center", marginBottom: '16px'}}>
              Журнал наказів
            </TextGrey>
            <button onClick={toggleJournalModal} className={'modal__close'}>
              <img src={closeGrey}/>
            </button>
          </div>
          <div className="modal__header__select">
            <SelectForm
              onChange={(e) => setYear(e.target.value)}
            >
              <option
                value={moment(new Date()).subtract("year", 1).year()}>{moment(new Date()).subtract("year", 1).year()} рік
              </option>
              <option selected value={moment(new Date()).year()}>{moment(new Date()).year()} рік</option>
            </SelectForm>
          </div>
          <div className="modal__header__button__journal">
            <SButtonGrey
              onClick={toggleAddModal}
              style={{
                width: "149px",
                height: "36px",
                marginLeft: "max(0, 0)",
                marginBottom: "0",
              }}
            >
              + Створити наказ
            </SButtonGrey>
          </div>
        </div>
        <div className="journal__modal__table">
          <table className="table" style={{fontSize: "13px", marginTop: "0"}}>
            <thead>
            <tr className="table-row">
              <th width={40}>№</th>
              <th width={40}>Номер</th>
              <th width={90}>Дата</th>
              <th style={{width: 500}}>Назва</th>
              <th style={{textAlign: "center", width: 50}}>
                Редагувати
              </th>
              <th style={{textAlign: "center", width: 50}}>
                Видалити
              </th>
            </tr>
            </thead>
            <tbody>
            {(typeof (props.allDocuments) !== "string" && props.allDocuments.length >= 1) && props.allDocuments.filter(el => el.info.nomer !== 0).sort((a, b) => {

              const dateA = new Date(getDate(a));
              const dateB = new Date(getDate(b));

              if (moment(getDate(a).format("DD.MM.YYYY")).isSame(getDate(b).format("DD.MM.YYYY"))) {
                const numberA = parseInt(getNumber(a));
                const numberB = parseInt(getNumber(b));
                return numberA - numberB;
              }

              return dateA - dateB;
            })
              .map((doc, index) => {
                return <JournalTd getDate={getDate(doc)} getNumber={getNumber(doc)} doc={doc} setDocument={setDocument}
                                  key={index} index={index}
                                  toggleModalList={toggleModalList}
                                  toggleAddModal={toggleAddModal}
                                  toggleJournal={toggleJournalModal} year={year}/>
              })}
            <tr className="table-row">
              <td></td>
              <td></td>
              <td style={{color: "#2F80ED"}}></td>

              <td style={{textAlign: "center"}}></td>
              <td style={{textAlign: "center", color: "white"}}>.</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="doc__modal__footer__btn">
          <ButtonLoading color="black" scale={.4} isActive={isLoader}>
            <button onClick={() => props.downloadAllDocuments(setLoader, year)}>
              <img src={printer}/>
              <p>
                Відкрити у <br/> PDF форматі
              </p>
            </button>
          </ButtonLoading>
        </div>
        {
          addModal && <div className="bg__layer"></div>
        }
        {
          addModal && <KadriCardsDocAddModal workers={workers} id={id} toggleModalList={toggleModalList} year={year}
                                             toggleAddModal={toggleAddModal}/>
        }
      </div>
      {document && <>
        <div className="bg__layer" style={{zIndex: 999}}/>
        <WarningPopUp
          onDelete={() => {
            dispatch(deletedDocument(document.deletedId, year))
            setDocument(false)
          }}
          toggleWarningModal={() => setDocument(false)}
          name={document.name}
        />
      </>}
      {(docTypes.indexOf(props.previewFile?.fileType) !== -1) &&
        <KadriPreviewDocument file={props.previewFile} closeModal={() => {
          props.setPreviewFile(null)
        }}/>}
    </>
  );
};

const mapStateToProps = (state) => ({
  allDocuments: state.cadrDocumentsReducer.allDocuments,
  previewFile: state.workersReducer.previewFile
})

KadriCardsJournalModal = connect(mapStateToProps, {
  getDocumentsJournal,
  downloadAllDocuments,
  setPreviewFile
})(KadriCardsJournalModal)