import React, {useEffect, useState, useRef} from "react";

const PdfViewer = ({url}) => {
  const [iframeTimeoutId, setIframeTimeoutId] = useState(undefined);
  const iframeRef = useRef(null);

  const [loaded, setLoaded] = useState(false);

  function iframeLoaded() {
    clearInterval(iframeTimeoutId);
  }

  function getIframeLink() {
    return `https://docs.google.com/gview?url=${encodeURIComponent(url.generateUrl)}&embedded=true`;
  }

  function updateIframeSrc() {
    setLoaded(true)
    if (iframeRef.current) {
      iframeRef.current.src = getIframeLink();
    }
  }

  useEffect(() => {
    const intervalId = setInterval(
      updateIframeSrc, 1000 * 3
    );
    setIframeTimeoutId(intervalId)
  }, []);


  const style = {
    width: 300,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
  return (
    <div className="doc-view" style={style}>
      {!loaded ?
        <div className={'disabled__overlay'}>
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div> :
        <iframe
          ref={iframeRef}
          frameBorder="no"
          width="400"
          height="100%"
          onError={updateIframeSrc}
          onLoad={iframeLoaded}
          src={getIframeLink()}
        />
      }
    </div>
  );
};

export default PdfViewer