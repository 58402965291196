//libs
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { store } from "../../../index";
import { Formik, useFormik } from "formik";
//components
import Modal from "../modalComponents/Modal/Modal";
import ModalAcceptButton from "../../buttons/ModalAcceptButton/ModalAcceptbutton";
import Input from "../../Inputs/Input/Input";
//hoooks
import useHttp from "../../../hooks/http.hook";
import { useAlert } from "react-alert";
//consts
import { ACCEPT_CONFIRMATION_CODE } from "../../../constants/urls";
//actions
import loadUserData from "../../../redux/actions/loadUserData";
//styles
import "../modalComponents/Modal/Modal.css";
import { getCookie } from "../../../utilits/cookies";

const ChangeConfirmedEmail = (props) => {
  const token = getCookie("token");
  const { closeFunc, userEmail, openModal } = props;
  const alert = useAlert();

  const { post } = useHttp();

  const formik = useFormik({
    initialValues: { confirmCode: "" },
    onSubmit: async (values) => {
      try {
        const request = await post(ACCEPT_CONFIRMATION_CODE, values);
        if (request.ok) {
          if (request.data.message === "успешно") {
            closeFunc();
            openModal("enterNewEmail");
            store.dispatch(loadUserData(token));
          } else {
            throw Error(request.data.message || "Щось пішло не так");
          }
        } else {
          throw Error(request.data || "Щось пішло не так");
        }
      } catch (e) {
        alert.show(e.message, { type: "error" });
      }
    },
  });

  return (
    <Modal
      title={`Код подтверждения был отправлен на почту ${userEmail}`}
      toggleFunc={closeFunc}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="Modal__content">
          <label className="Modal__text">4-х значный код</label>
          <Input
            id="confirmCode"
            name="confirmCode"
            type="text"
            placeholder="Код подтверждения"
            onChange={formik.handleChange}
            value={formik.values.confirmCode}
          ></Input>
        </div>
        <div className="Modal__button-row">
          <ModalAcceptButton type="submit">Зберегти</ModalAcceptButton>
        </div>
      </form>
    </Modal>
  );
};

export default ChangeConfirmedEmail;
