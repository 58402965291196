import './TableRow.css'

const TableRow = props => {

  const {type, gridTemplate, onClick, active, newUser, isWorkersTable, isPressed, isWaiting} = props
  const clickHandler = (event) => {
    if (!['TableRow', 'TableRow_active', 'TableHeader', 'TableCell'].includes(event.target.className)) {
      return
    }
    onClick()
  }

  return (
    <div
      className={`Table${type === 'header' ? 'Header' : 'Row'}${active ? '_active' : ''}${newUser ? '_new' : ''} ${isWorkersTable && 'Table__employer'}`}
      onClick={onClick ? clickHandler : null}
      style={{
        gridTemplateColumns: gridTemplate,
        backgroundColor: isPressed ? '#DFFAF0' : isWaiting ? '#FFFFEF' : '',
        border: isPressed ? '1px solid #59E4B1' : isWaiting ? '1px solid #FAFF00' : '',
        cursor: isWorkersTable ? 'unset' : 'pointer'
      }}
      {...props}
    >
      {props.children}
    </div>
  )
}

export default TableRow
