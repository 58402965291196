import React, { useEffect, useRef, useState } from "react";
import TableCell from "../../../../components/tables/TableCell/TableCell";
import { SButtonGrey } from "../../../../styledComponents/Buttons";
import submenuArrow from "../../../../assets/submenuArrow.svg";
import MiniButton from "../../../../components/common/MiniButton/MiniButton";
import deleteIcon from "../../../../images/icons/delete.svg";
import TableRow from "../../../../components/tables/TableRow/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import ReactTooltip from "react-tooltip";
import { cancellationDismissal } from "../../../../redux/workers/workers-thunks";
import moment from "moment";
import { diffDate, isInCurrentMonth } from "../../../../utilits/diffDate";
import backEvent from "../../../../redux/actions/employers/backEvent";
import { WarningPopUp } from "../../../PopUps/WarningPopup";

const findLastIndex = (array) => {
  let arrayType = [
    "Пакет документів на відпустку",
    "Пакет документів про прогул",
    "Пакет документів на звільнення",
    "Пакет документів про переведення",
    "Пакет документів про переведеня",
    "Трудовий договір",
  ];

  return array.findLast((element) =>
    arrayType.some((el) => el === element.type)
  );
};

const KadriRow = ({
  el,
  openModal,
  GRID_TEMPLATE,
  openEditWorkerModal,
  isObject,
  index,
  workerId,
  vacation,
  resetDocumentModal,
  activeRowIndex,
  setActiveRowIndex,
  setDeleteItem,
  deleted = true,
}) => {
  const user = useSelector((state) => state.user);
  const [element, setElement] = useState(el);
  const [eventBack, setEventBack] = useState(null);
  const [isActive, setActive] = useState(false);
  const alert = useAlert();
  const rowItem = useRef(null);
  const dispatch = useDispatch();

  const alertFunc = (message, type) => {
    alert.show(message.status, { type });
  };
  useEffect(() => {
    setElement((previousState) => previousState);
  }, [el]);

  useEffect(() => {
    let documentEventLast = findLastIndex(el.document);
    if (el.document.length === 0 || !documentEventLast) {
      setEventBack({ event: null, text: null });
      return false;
    }
    const listId = {
      employerId: el._id,
      fileId: documentEventLast._id,
    };

    if (documentEventLast.type === "Пакет документів на відпустку") {
      setEventBack({ event: "vacation", text: "Відпустка", ...listId });
    } else if (documentEventLast.type === "Пакет документів на звільнення") {
      setEventBack({ event: "deleted", text: "Звільнення", ...listId });
    } else if (
      documentEventLast.type === "Пакет документів про переведення" ||
      documentEventLast.type === "Пакет документів про переведеня"
    ) {
      setEventBack({ event: "transfer", text: "Переведення", ...listId });
    } else if (documentEventLast.type === "Пакет документів про прогул") {
      setEventBack({ event: "truancy", text: "Прогул", ...listId });
    } else if (documentEventLast.type === "Трудовий договір") {
      setEventBack({
        event: "accept",
        text: "Прийняття",
        ...listId,
        fileId: documentEventLast.fileId ?? documentEventLast._id,
        isFile: !!documentEventLast.fileId,
      });
    } else {
      setEventBack({ event: null, text: null });
    }
  }, [dispatch, el]);

  const handleClickEventBack = (data) => {
    if (data.event === null) {
      return true;
    }
    setActive(true);
  };
  const is_current_month = (startDate, endDate) => {
    const start = moment(startDate, "DD.MM.YYYY");
    const end = moment(endDate, "DD.MM.YYYY");

    const result = isInCurrentMonth([start, end]);
    return result.includes(true);
  };

  return (
    <>
      <div
        ref={rowItem}
        onClick={(event) => {
          const target = event.target;
          if (!target.closest(".dropdown") && !target.closest(".MiniButton")) {
            setActiveRowIndex(el._id);
            openEditWorkerModal(el._id);
            resetDocumentModal();
          }
        }}
      >
        <TableRow
          key={el._id}
          active={index === activeRowIndex}
          ref={rowItem}
          isPressed={activeRowIndex === el._id}
          gridTemplate={GRID_TEMPLATE}
        >
          <TableCell justify="center">{index + 1}</TableCell>
          <TableCell>
            {el.surname + " " + el.name + " " + el.patronymic}
          </TableCell>
          <TableCell>
            {el.postInfo.status === "Неоформленный" ? (
              el.postInfo.posada ?? "-"
            ) : (
              <p>
                {el.postInfo.status}
                <br />
                {el.postInfo.format !== "основне" && (
                  <span style={{ fontSize: 13, fontWeight: 300 }}>
                    (сумісництво)
                  </span>
                )}
              </p>
            )}
          </TableCell>
          <TableCell justify="center">
            {el.postInfo.currentStatus !== "cancellation" && (
              <div className="dropdown" id="create" data-tip data-for="create">
                <SButtonGrey style={{ margin: "0" }} disabled={!isObject}>
                  {" "}
                  + Cтворити
                </SButtonGrey>
                <div className={"table__dropdown__content left-0"}>
                  {deleted && (
                    <>
                      {el.postInfo.status === "Неоформленный" ? (
                        <div className="submenu">
                          <div className="submenu__dropdown__content">
                            <button
                              className={"submenu__dropdown__btn"}
                              onClick={() => openModal("work__5", el._id)}
                            >
                              Безстроковий
                            </button>
                            <button
                              className={"submenu__dropdown__btn"}
                              onClick={() => openModal("work__6", el._id)}
                            >
                              Cтроковий
                            </button>
                          </div>
                          <button
                            className={"submenu__dropdown__btn"}
                            onClick={() => openModal("toggleTableShow")}
                          >
                            <span>Прийняття</span>
                            <img src={submenuArrow} />
                          </button>
                        </div>
                      ) : (
                        <>
                          {el.postInfo.dateCreate &&
                            diffDate(
                              el.postInfo.dateCreate,
                              new Date(),
                              "days"
                            ) <= 7 && (
                              <button
                                className={"submenu__dropdown__btn"}
                                onClick={() => {
                                  dispatch(
                                    cancellationDismissal(
                                      {
                                        id: el._id,
                                        workerId: workerId,
                                      },
                                      alertFunc
                                    )
                                  );
                                }}
                              >
                                Скасувати прийняття
                              </button>
                            )}
                          <button
                            className={"submenu__dropdown__btn"}
                            onClick={() => openModal("transfer", el._id)}
                          >
                            Переведення
                          </button>
                          <button
                            className={"submenu__dropdown__btn"}
                            onClick={() => openModal("holiday", el._id)}
                          >
                            Відпустка
                          </button>

                          <div className="submenu">
                            <div className="submenu__dropdown__content">
                              <button
                                className={"submenu__dropdown__btn"}
                                onClick={() => openModal("truancy", el._id)}
                              >
                                Пару годин
                              </button>
                              <button
                                className={"submenu__dropdown__btn"}
                                onClick={() => openModal("truancy__2", el._id)}
                              >
                                Весь день
                              </button>
                            </div>
                            <button className={"submenu__dropdown__btn"}>
                              <span>Прогул</span>
                              <img src={submenuArrow} />
                            </button>
                          </div>
                          <div className="submenu">
                            <div className="submenu__dropdown__content">
                              <button
                                className={"submenu__dropdown__btn"}
                                onClick={() => openModal("work__3", el._id)}
                                style={{
                                  margin: "7px 0 2px 0",
                                  padding: "0 0 0 24px",
                                }}
                              >
                                Закінчення строку
                              </button>
                              <button
                                className={"submenu__dropdown__btn"}
                                onClick={() => openModal("work", el._id)}
                                style={{
                                  margin: "0px 0 2px 0",
                                  padding: "0 0 0 24px",
                                }}
                              >
                                З ініціативи працівника
                              </button>
                              <button
                                className={"submenu__dropdown__btn"}
                                onClick={() => openModal("work__2", el._id)}
                                style={{
                                  margin: "0px 0 2px 0",
                                  padding: "0 0 0 24px",
                                }}
                              >
                                За угодою сторін
                              </button>
                              <button
                                className={"submenu__dropdown__btn"}
                                onClick={() => openModal("work_died", el._id)}
                                style={{
                                  margin: "0px 0 2px 0",
                                  padding: "0 0 0 24px",
                                }}
                              >
                                Смерть працівника
                              </button>
                            </div>
                            <button className={"submenu__dropdown__btn"}>
                              <span>Звільнення</span>
                              <img src={submenuArrow} />
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {eventBack && eventBack.text !== null && (
                    <div
                      className="submenu submenu_back"
                      style={!deleted ? { borderTop: 0 } : {}}
                    >
                      <button
                        className={"submenu__dropdown__btn"}
                        onClick={() => handleClickEventBack(eventBack)}
                      >
                        <span>Скасувати останню дію:</span>
                        <strong>{eventBack.text}</strong>
                      </button>
                    </div>
                  )}
                </div>
                {!isObject && (
                  <ReactTooltip
                    id="create"
                    effect={"solid"}
                    backgroundColor={"#52616E"}
                  >
                    Даний роботодавець не зв'язаний з ТТ.
                    <br />
                    Зверніться до керівника регіону
                  </ReactTooltip>
                )}
              </div>
            )}
          </TableCell>
          <TableCell justify="center">
            {el.postInfo.currentStatus === "cancellation" ? (
              <div className="label label--gray">скасовано</div>
            ) : el.postInfo.currentStatus === "deleted" ||
              moment(el.postInfo.uvalLastDay, "DD.MM.YYYY").isBefore(
                new Date()
              ) ? (
              <div
                className="label label--red"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                звільнено
                <span
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                  }}
                >
                  {el.postInfo.uvalLastDay}
                </span>
              </div>
            ) : vacation.length >= 1 &&
              diffDate(
                new Date(),
                moment(
                  vacation[vacation.length - 1].info.exp.end,
                  "DD.MM.YYYY"
                ),
                "days"
              ) >= 0 &&
              is_current_month(
                vacation[vacation.length - 1].info.exp.start,
                vacation[vacation.length - 1].info.exp.end
              ) &&
              el.postInfo.currentStatus === "vacation" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  className="label"
                  style={{ maxWidth: "max-content", color: "black" }}
                >
                  відпустка
                </div>
                {vacation.length >= 1 && (
                  <span
                    style={{
                      fontSize: 11,
                      fontWeight: 400,
                    }}
                  >
                    {vacation[vacation.length - 1].info.exp.start} -{" "}
                    {vacation[vacation.length - 1].info.exp.end}
                  </span>
                )}
              </div>
            ) : el.postInfo.currentStatus === "truancy" ? (
              <div className="label label--orange">прогул</div>
            ) : el.postInfo.status === "Неоформленный" ? (
              <span
                style={{
                  backgroundColor:
                    el.postInfo.status === "Неоформленный"
                      ? "#ffe58c"
                      : "transparent",
                  padding: 5,
                  borderRadius: 4,
                }}
              >
                Неоформлений
              </span>
            ) : (
              "працює"
            )}
          </TableCell>
          {
            <TableCell justify="center">
              {deleted && (
                <MiniButton
                  dataFor={`deleted${index}`}
                  icon={deleteIcon}
                  type="delete"
                  disabled={
                    user.userType === "headAccountant" ||
                    user.userType === "lawyer"
                      ? false
                      : el.postInfo.status !== "Неоформленный"
                  }
                  onClick={() => setDeleteItem(el)}
                />
              )}
            </TableCell>
          }
          {user.userType === "headAccountant" || user.userType === "lawyer" ? (
            <></>
          ) : (
            el.postInfo.status !== "Неоформленный" && (
              <ReactTooltip
                id={`deleted${index}`}
                effect={"solid"}
                style={{ maxWidth: "60px" }}
                backgroundColor={"#52616E"}
              >
                <p style={{ maxWidth: "160px", textAlign: "center" }}>
                  Ця дія доступна лише головному бухгалтеру
                </p>
              </ReactTooltip>
            )
          )}
        </TableRow>
      </div>
      {isActive && (
        <WarningPopUp
          toggleWarningModal={() => setActive(false)}
          onDelete={() => {
            dispatch(backEvent(eventBack, workerId)).then(() => {
              setActive(false);
              rowItem.current.children[0].setAttribute("data-active", "active");
              setTimeout(() => {
                rowItem.current.children[0].setAttribute("data-active", "");
              }, 2000);
            });
          }}
          isNewText={false}
          name={`Дія (<strong>${eventBack.text}</strong>) буде скасована. Всі документи буде видаленно.<br> Бажаєте продовжити?`}
          isLayer={true}
        />
      )}
    </>
  );
};
export default KadriRow;
