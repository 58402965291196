import {
  LOAD_CONTROLLER,
  LOAD_CONTROLLER_IP,
  LOAD_CONTROLLER_USER,
  LOAD_CONTROLLER_VISITOR, LOAD_HISTORY_CONTROLLER,
  LOAD_INFO_CONTROLLER,
  LOAD_LIST_OBJECT
} from "../types"

const initialController = {
  data: null,
  listIp: null,
  listObject: null,
  info: null,
  usersController: [],
  visitor: null,
  history: null
}


export const controllerReduce = (state = initialController, action) => {
  switch (action.type) {
    case LOAD_CONTROLLER:
      return {...state, data: action.payload}
    case LOAD_CONTROLLER_IP:
      return {...state, listIp: action.payload}
    case LOAD_INFO_CONTROLLER:
      return {...state, info: action.payload}
    case LOAD_LIST_OBJECT:
      return {...state, listObject: action.payload}
    case LOAD_CONTROLLER_USER:
      return {...state, usersController: action.payload}
    case LOAD_CONTROLLER_VISITOR:
      return {...state, visitor: action.payload}
    case LOAD_HISTORY_CONTROLLER:
      return {...state, history: action.payload}
    default:
      return state
  }
}
