const ProgressBar = (props) => {
  const {bgcolor, completed, number} = props;

  const containerStyles = {
    height: 16,
    width: '218px',
    backgroundColor: "#e0e0de",
    borderRadius: 2,
    display: "flex",
    justifyContent: "flex-end",
    position: "relative"
  }

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'center'
  }

  const labelStyles = {
    width: "100%",
    display: "flex",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%",
    fontSize: "10px",
    justifyContent: "center",
    color: 'white',
    wordWrap: "no-wrap",
    fontWeight: 'bold'
  }

  return (
    <div style={containerStyles}>
      <span style={labelStyles}>{`${number} днів`}</span>
      <div style={fillerStyles}>
      </div>
    </div>
  );
};

export default ProgressBar;