import instance from "../../../redux/instance";

export const get_posada = async () => {
  const { data } = await instance.get("/user/get_posada");
  let sort_posada = data
    .map((e) => {
      return { label: e.title, value: e.vacancy };
    })
    .filter(
      (item, index, self) =>
        index ===
        self.findIndex((t) => t.label === item.label && t.value === item.value)
    );
  sort_posada = sortByAlphabet(sort_posada);
  sort_posada.unshift({ value: "", label: "Всі посади" });

  return sort_posada;
};

function sortByAlphabet(arr) {
  return arr.sort((a, b) => a.label.localeCompare(b.label, "uk"));
}
