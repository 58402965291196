import {DELETE_WORKER_URL} from "../../../constants/urls";
import loadEmployers from "./loadEmployers";
import instance from "../../instance";

const deleteEmployer = (body, alert) => async (dispatch) => {
  await instance.post(DELETE_WORKER_URL, body).then(response => {
      if (response.data.message === "Работодатель обновлён") {
        alert('Успішно', 'success')
        dispatch(loadEmployers(alert,!!localStorage.getItem("checked")))
      } else {
        throw Error(response.data.message || 'Щось пішло не так')
      }
    }
  ).catch(err => {
    if(err.response!==undefined){
      alert(err.response.data.message,"error")
    }else{
      alert(err.message,"error")
    }

  })
}

export default deleteEmployer

// const addEmployer = async () => {
//     try {
//         const request = await post(ADD_WORKER_URL, {"rnokpp": `${formik.values.rnokpp}` })
//         console.log(formik.values.rnokpp)
//         console.log(request)
//         if (request.ok) {
//             if (request.data.message === 'Работодатель добавлен'|| request.data.message === 'Пользователь восстановлен') {
//                 alert.show(request.data.message, {type: 'success'})
//                 refreshList()
//                 toggleFunc()
//             } else {
//                 alert.show(request.data.message, {type: 'error'})
//                 refreshList()
//             }
//         } else {
//             throw Error(request.data)
//         }
//     } catch (e) {
//         alert.show(e.message, {type: 'error'})
//     } finally {
//         setLoading(false)
//     }
// }
