//libs
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { store } from "../../../index";
import { Formik, useFormik } from "formik";
//components
import Modal from "../modalComponents/Modal/Modal";
import ModalAcceptButton from "../../buttons/ModalAcceptButton/ModalAcceptbutton";
import Input from "../../Inputs/Input/Input";
//hoooks
import useHttp from "../../../hooks/http.hook";
import { useAlert } from "react-alert";
//consts
import { ACCEPT_GOOGLE_2FA } from "../../../constants/urls";
//actions
import loadUserData from "../../../redux/actions/loadUserData";
//styles
import "../modalComponents/Modal/Modal.css";
import { getCookie } from "../../../utilits/cookies";

const GoogleAuth = (props) => {
  const { closeFunc, data } = props;
  const token = getCookie("token");
  const alert = useAlert();

  const { post } = useHttp();

  const formik = useFormik({
    initialValues: { token: "" },
    onSubmit: async (values) => {
      try {
        const request = await post(ACCEPT_GOOGLE_2FA, { ...values });
        console.log(request);
        if (request.ok) {
          if (request.data.message === "Wrong google authenticator code") {
            alert.show(request.data.message, { type: "error" });
          } else {
            alert.show(request.data.message, { type: "success" });
            store.dispatch(loadUserData(token));
            closeFunc();
          }
        } else {
          throw Error(request.data);
        }
      } catch (e) {
        console.log(e.message);
        alert.show(e.message, { type: "error" });
      }
    },
  });
  {
    console.log(data);
  }
  return (
    <Modal title="Двофакторна аутентифікація" toggleFunc={closeFunc}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <span className="Modal__text" style={{ marginTop: "20px" }}>
          Відскануйте QR-код додатком Google Authenticator:
        </span>
        <img
          src={data.data}
          style={{
            width: "50%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        ></img>
        <span className="Modal__text" style={{ marginTop: "20px" }}>
          Або введіть текстовий код вручну:
        </span>
        <span className="Modal__code" style={{ marginTop: "20px" }}>
          {data.code}
        </span>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="Modal__content">
          <label className="Modal__text">
            Введіть код із додатку Google Authenticator
          </label>
          <Input
            id="token"
            name="token"
            type="text"
            placeholder="6-значний код"
            onChange={formik.handleChange}
            value={formik.values.token}
          ></Input>
        </div>
        <div className="Modal__button-row">
          <ModalAcceptButton type="submit">Відправити</ModalAcceptButton>
        </div>
      </form>
    </Modal>
  );
};

export default GoogleAuth;
