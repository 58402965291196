import {SAVE_TOKEN, REMOVE_TOKEN, DELETED_DATE} from "../types"

const initialToken = {
  token: ''
}

export const tokenReducer = (state = initialToken, action) => {
  switch (action.type) {
    case SAVE_TOKEN:
      return action.payload
    case DELETED_DATE:
      return null;
    default:
      return state
  }
}
