import {getCookie} from "../utilits/cookies";
import {MINFIN, PDF} from "../constants/urls";
import axios from "axios";

export const minfinSetting = async () => {
  const token = getCookie('token')
  const response = await axios.get(MINFIN, {
    headers: {
      'Authorization': token
    }
  })
  return response;
}

export const pdfConv = async (name) => {
  const token = getCookie('token')
  const response = await axios.post(PDF, name, {
    responseType: "arraybuffer",
    headers: {
      'Authorization': token
    }
  })
  if (response.data.error) {
    console.error(response.data.error)
  }

  return response;
};
