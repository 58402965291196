import "./checkbox.css";

export const Checkbox = (params) => {
  const change_handler = () => {
    params.onChange();
  };

  return (
    <div className="checkbox">
      <input
        type="checkbox"
        id="checkAllDeleted"
        name="checkAllDeleted"
        onChange={change_handler}
        // style={{ width: 16, height: 16, marginTop: 20 }}
      />
      <label>{params.text}</label>
    </div>
  );
};
