import './Table.css'

const Table = props => {
const {className, style} = props

    return (
      <div className={`Table ${className}`} style={style}>
          {props.children}
      </div>
    )
}

export default Table
