import {SCAN_FINGER_CONTROLLER} from "../../../constants/urls";
import instanceController from "../../instanceController";
import showAlert from "../alerts/showAlert";

const scanFinger = (loading, objectId, visitorId) => async (dispatch) => {
  console.log("start")
  loading(true)
  try {
    return await instanceController.post(SCAN_FINGER_CONTROLLER, {objectId, visitorId});
  } catch (e) {
    if (e?.response.data) {
      dispatch(showAlert(e.response.data.message, 'error'))
    } else {
      dispatch(showAlert(e.message, 'error'))
    }
  } finally {
    loading(false)
  }
}

export default scanFinger
