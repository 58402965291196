import React, {useEffect, useState} from "react";

import "../KadriCards.css";
import {connect} from "react-redux";
import getDateAndTime from "../../../../../utilits/getDateAndTime";
import {getUsers} from "../../../../../api/users";

export let KadriPageHistory = ({currentWorker}) => {
  const [user, setUser] = useState({});
  useEffect(async () => {
    let users = await getUsers().then(res => res.users)
    setUser(users)
  }, [])
  return (
    <div className="history__container">
      <table className="history__table">
        <tr>
          <td style={{fontWeight: '500'}}>Дата</td>
          <td style={{fontWeight: '500'}}>Користувач</td>
          <td style={{fontWeight: '500'}}>Дія</td>
          <td style={{fontWeight: '500'}}>Коментар</td>
        </tr>
        {currentWorker.history.map((item, index) => (
          <tr>
            <td>{getDateAndTime(new Date(item.date))}</td>
            <td>{
              user?.length && user.map(el => el._id === item.user && `${el.lastName} ${el.firstName} ${el.middleName}`)}</td>
            <td>{item.type}</td>
            <td dangerouslySetInnerHTML={{ __html: item.comment }}/>
          </tr>
        ))}
      </table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentWorker: state.currentWorker,
})

KadriPageHistory = connect(mapStateToProps, {})(KadriPageHistory)
