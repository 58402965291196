import './Alert.css'
import React from 'react'
import { useAlert } from 'react-alert'

const Alert = props => {

    const { options, message, closeFunc } = props

    const getCorrectClassName = () => {

        switch (options.type) {
            case 'success': return 'Alert_success'
                // break;
            case 'error': return 'Alert_error'
                // break;
            case 'info': return 'Alert_info'
                // break;
            default: return 'Alert_success'
                // break;
        }
    }

    return (
        <div className={`Alert ${getCorrectClassName()}`}>
            {message}
        </div>
    )
}

export const ShowAlert = (message, type) => {
    const alert = useAlert()
    alert.show(message, {type: type})
}

export default Alert