import styled from "styled-components";

const commonProperties = `
  height: 36px;
  border-radius: 3px;
  font-family: Roboto;
  `

export const ButtonGreen = styled.button`
  ${commonProperties}
  height: 40px;
  min-width: 170px;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 19px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #fff;
  background: #009640;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: #27ae60;
  }
`
// S - small, размер кнопки
export const SButtonGreen = styled.button`
  min-width: 170px;
  height: 36px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  border-radius: 3px;
  padding: 0px 20px;
  border: none;
  background: #009640;
  margin: 0 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-family: 'Inter', sans-serif;

  &:hover {
    background: #27ae60;
  }
`;

export const SButtonLightGreen = styled.button`
  ${commonProperties};
  min-width: 157px;
  font-size: 13px;
  line-height: 15px;
  color: #ffffff;
  background: #27AE60;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  padding: 0px 20px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: #6FCF97;
  }
`;

export const SButtonLightGrey = styled.button`
  ${commonProperties};
  min-width: 157px;
  font-size: 13px;
  line-height: 15px;
  color: #c1c1c1;
  background: #f2f2f2;
  padding: 0px 20px;
  border: none;
  outline: none;
`;

// S - small, размер кнопки
export const SButtonGreenBorder = styled.button`
  height: 36px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #938686;
  border: 1px solid #938686;
  border-radius: 3px;
  padding: 0px 20px;
  background: #ffffff;
  margin: 0 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-family: 'Inter', sans-serif;

  &:hover {
    border-color: #009640;
    color: #009640;
  }

  &:active {
    border-color: #27ae60;
    background: #27ae60;
    color: #fff;
  }
`;

// S - small, размер кнопки
export const SButtonRed = styled.button`
  height: 36px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  border-radius: 3px;
  padding: 0px 20px;
  border: none;
  background: #e52c31;
  margin: 0 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-family: 'Inter', sans-serif;

  &:hover {
    background: #e04f53;
  }
`;

export const SButtonGrey = styled.button`
  text-align: center;
  padding: 8px 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  border-radius: 3px;
  border: none;
  background: #F2F2F2;
  margin: 0 8px 0 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: #313131;
  
  &:disabled {
    color: rgb(193, 193, 193);
    background: rgb(242, 242, 242);
  }

  &:hover {
    background: #E0E0E0;
  }

  &:focus + .table__dropdown__content {
    display: block;
  }
`;

// M - medium, размер кнопки
export const MButtonGreen = styled.button`
  width: 150px;
  height: 36px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  border-radius: 3px;
  padding: 0px 20px;
  border: none;
  background: #009640;
  margin: 0 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:disabled {
    color: rgb(193, 193, 193);
    background: rgb(242, 242, 242);
  }

  &:hover:disabled {
    color: rgb(193, 193, 193);
    background: rgb(242, 242, 242);
    cursor: inherit;
  }

  &:hover {
    background: #27ae60;
  }
`;
export const MButtonGray = styled.button`
  width: max-content;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: black;
  padding: 8px 10px;
  border: none;
  margin: 0 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background: #F2F2F2;
  border-radius: 6px;

  img {
    filter: invert(1);
  }

  &:disabled {
    color: rgb(193, 193, 193);
    background: rgb(242, 242, 242);
  }

  &:hover:disabled {
    color: rgb(193, 193, 193);
    background: rgb(242, 242, 242);
    cursor: inherit;
  }

  &:hover {
    background: #009640;
    color: white;
  }
`;
export const MButtonRed = styled.button`
  width: auto;
  height: 36px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  border-radius: 3px;
  padding: 0px 10px;
  border: none;
  background: red;
  margin: 0 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  img {
    filter: invert(1);
  }

  &:disabled {
    color: rgb(193, 193, 193);
    background: rgb(242, 242, 242);
  }

  &:hover:disabled {
    color: rgb(193, 193, 193);
    background: rgb(242, 242, 242);
    cursor: inherit;
  }

  &:hover {
    background: black;
  }
`;

export const MButtonOrange = styled.button`
  width: 150px;
  height: 36px;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  border-radius: 3px;
  padding: 0px 20px;
  border: none;
  background: #F2994A;
  margin: 0 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
`;

// M - medium, размер кнопки
export const MButtonGreenBorder = styled.button`
  width: 150px;
  height: 36px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #938686;
  border: 1px solid #938686;
  border-radius: 3px;
  padding: 0px 20px;
  background: #ffffff;
  margin: 0 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    border-color: #009640;
    color: #009640;
  }

  &:active {
    border-color: #27ae60;
    background: #27ae60;
    color: #fff;
  }
`;

// L - large, размер кнопки
export const LButtonGreen = styled.button`
  height: 36px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  border-radius: 3px;
  padding: 0 20px;
  border: none;
  background: #009640;
  margin: 0 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-family: 'Inter', sans-serif;

  &:hover {
    background: #27ae60;
  }
`;
