import { useState } from 'react'
import './Dropdown.css'

const Dropdown = props => {

    const {disabled, button, toggleFunc} = props



    return(
      <div className="Dropdown">
        {button}
        <div className={`Dropdown__menu`}>
          {props.children}
        </div>
      </div>
    )
}

export default Dropdown