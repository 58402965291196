import React, { useState } from "react";
import "./EmployersInfo.css";

import reloadIcon from "../../../images/icons/reload.svg";
import { getConfig } from "@testing-library/react";
import useHttp from "../../../hooks/http.hook";
import { CHANGE_WORKER_URL } from "../../../constants/urls";
import Modal from "../modalComponents/Modal/Modal";
import RefreshButton from "../../buttons/RefreshButton/RefreshButton";
import { getCorrectFormatOfDate } from "../../../utilits/getCorrectFormatOfDate";
import { useSelector } from "react-redux";
import { getCookie } from "../../../utilits/cookies";

const EmployersCompanyInfo = (props) => {
  const { toggleFunc, data, changeFunc, getWorkersFunc } = props;

  const getRefreshButtonStatus = () => {
    const currentTime = new Date();
    const updateTime = new Date(data.updatedAt);
    return (
      `${currentTime.getDate()}${currentTime.getMonth()}${currentTime.getFullYear()}` !==
      `${updateTime.getDate()}${updateTime.getMonth()}${updateTime.getFullYear()}`
    ); //обновлять пользователя можно раз в календарный день
  };

  const [buttonStatus, setButtonStatus] = useState(getRefreshButtonStatus());
  const token = getCookie("token");
  const { loading, request, error, clearError } = useHttp();

  const changeWorker = async (rnokpp) => {
    try {
      const data = await request(
        CHANGE_WORKER_URL,
        "POST",
        { rnokpp: `${rnokpp}` },
        { Authorization: token }
      );
      if (data.message === "Работодатель обновлён") {
        setButtonStatus(false);
      }
      getWorkersFunc();
    } catch (e) {
      console.log(e);
    } finally {
      // setWorkersData([1])
    }
  };

  return (
    <Modal title="Інформація про роботодаця" toggleFunc={toggleFunc}>
      <div className="EmployersInfo__content">
        <>
          <p className="EmployersInfo__text">Роботодавець</p>
          <p className="EmployersInfo__text">{data.userInfO.full_name}</p>
        </>
        <>
          <p className="EmployersInfo__text">ЄДРПОУ</p>
          <p className="EmployersInfo__text">{data.rnokpp}</p>
        </>
        <>
          <p className="EmployersInfo__text">Адреса</p>
          <p className="EmployersInfo__text">{data.userInfO.location}</p>
        </>
        <>
          <p className="EmployersInfo__text">Статус реєстрації</p>
          <p className="EmployersInfo__text">{data.userInfO.status}</p>
        </>
        <>
          <p className="EmployersInfo__text">Основний вид діяльності</p>
          <div className="EmployersInfo__text-container">
            {data.userInfO.activities.map((el) => {
              return <p className="EmployersInfo__text">{el.name}</p>;
            })}
          </div>
        </>
        <>
          <p className="EmployersInfo__text">Керівник</p>
          <p className="EmployersInfo__text">{data.userInfO.ceo_name}</p>
        </>
        <>
          <p className="EmployersInfo__text">Розмір статутного капіталу</p>
          <p className="EmployersInfo__text">{data.userInfO.capital}</p>
        </>
        <>
          <p className="EmployersInfo__text">Власники та їх частки</p>
          <div className="EmployersInfo__text-container">
            {data.userInfO.beneficiaries.map((el) => {
              return (
                <p className="EmployersInfo__text">{`${el.name} - ${el.amount_percent}%`}</p>
              );
            })}
          </div>
        </>
        <>
          <p className="EmployersInfo__text">Статус платника ПДВ</p>
          <p className="EmployersInfo__text">
            {!data.userInfO.warnings
              ? ""
              : data.userInfO.warnings[1]
              ? data.userInfO.warnings[1].pdv?.text
              : ""}
          </p>
        </>
        <>
          <p className="EmployersInfo__text">Податковий та зарплатні борги</p>
          <p className="EmployersInfo__text"></p>
        </>
        <>
          <p className="EmployersInfo__text">Планові перевірки</p>
          <div className="EmployersInfo__text-container">
            {!data.userInfO.warnings
              ? ""
              : data.userInfO.warnings[2]
              ? data.userInfO.warnings[2].audits?.map((el) => {
                  return (
                    <p className="EmployersInfo__text">{`${getCorrectFormatOfDate(
                      el.date
                    )} - ${el.agency}`}</p>
                  );
                })
              : ""}
          </div>
        </>
        <>
          <p className="EmployersInfo__text">Дата реєстрації</p>
          <p className="EmployersInfo__text">
            {getCorrectFormatOfDate(data.userInfO.registration_date)}
          </p>
        </>
      </div>
      <div className="EmployersInfo__content" style={{ marginTop: "37px" }}>
        <p className="EmployersInfo__date">Данi</p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p className="EmployersInfo__date">{`Станом на ${getCorrectFormatOfDate(
            data.updatedAt
          )}`}</p>
          <RefreshButton
            disabled={!buttonStatus}
            onClick={() => {
              changeWorker(data.rnokpp);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EmployersCompanyInfo;
