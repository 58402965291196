import './DropdownHintRow.css'

const DropdownHintRow = props => {

    const { onClick } = props
    return (
        <button className='DropdownHintRow' onClick={onClick} type='button'>
            {props.children}
        </button>
    )
}

export default DropdownHintRow