import instance from "../instance";
import {setAllEmployer, setAllWorkerEmployer, setCurrentEmployer} from "./employers-actions";


export const getEmployerById = (id) => async (dispatch) => {
  try {
    const response = await instance.post('/user/getOneWorker', {id})
    if (response.data) {
      dispatch(setCurrentEmployer(response.data))
    }
  } catch (e) {

  }
}

export const getSearchEmployer = (workerId) => async (dispatch) => {
  try {
    const response = await instance.post('/employer/getsearchemplpoyer', {workerId: workerId})
    if (response.data) {
      dispatch(setAllWorkerEmployer(response.data.employers))
    }
  } catch (e) {
    console.log(e)
  }
}

export const getSearchEmployerAll = (id) => async (dispatch) => {
  try {
    const response = await instance.post('/employer/getsearchemplpoyerall', {id: id})
    if (response.data) {
      dispatch(setAllEmployer(response.data.employers))
    }
  } catch (e) {
    console.log(e)
  }
}
