import React, {useEffect, useState} from "react";
import {SButtonGrey} from "../../../../../styledComponents/Buttons";

import trash from "../../../../../assets/trash.svg";
import infinity from "../../../../../assets/infinity.svg";

import "../KadriCards.css";
import {SearchInput, SelectForm} from "../../../../../styledComponents/Inputs";
import {connect} from "react-redux";
import {getZpSettings, setSellaryInfo} from "../../../../../redux/settings/settings-thunks";
import {Formik} from "formik";
import NumberFormat from 'react-number-format';

export let KadriCardSettingsTwo = ({settings, zpSettings, ...props}) => {
  const [editMode, setEditMode] = useState(settings.zp?.status === 'off');
  const toggleEditMode = () => {
    setEditMode(!editMode);
  };
  useEffect(() => {
    props.getZpSettings()
  }, [])
  const getMonthFields = (monthes) => {
    let fields = {}
    Array.apply(null, {length: 12}).map((_, index) => {
      fields = {...fields, [`month${index + 1}`]: monthes ? monthes[index] : 0}
    })
    return fields
  }
  const getMonthArray = (values) => {
    let arrayValues = []
    Array.apply(null, {length: 12}).map((_, index) => {
      arrayValues = [...arrayValues, +values[`month${index + 1}`]]
    })
    return arrayValues
  }
  return (
    <Formik
      initialValues={{
        pdfo: settings.zp?.pdfo ?? '0',
        military: settings.zp?.military ?? '0',
        esv: settings.zp?.esv ?? '0',
        ...getMonthFields(settings.zp?.monthes)
      }}
    >
      {({values, handleChange, setFieldValue}) => {
        return <div className="settings__container__2">
          <div className="settings__row__header__2">
            <span>Отримувати дані автоматично</span>
            <label className="checkbox-google">
              <input
                type="checkbox"
                checked={!editMode}
                onChange={() => {
                  props.setSellaryInfo({
                    status: editMode ? 'on' : 'off',
                    pdfo: values.pdfo,
                    military: values.military,
                    esv: values.esv,
                    monthes: getMonthArray(values)
                  })
                  toggleEditMode()
                }}
              />
              <span className="checkbox-google-switch"></span>
            </label>
          </div>
          <div className="settings__row__2">
            <SelectForm>
              <option value="">{new Date().getUTCFullYear()} рік</option>
            </SelectForm>
          </div>
          <div className="settings__row__2__content">
            <div className="content__left">
              <div className="content__left__header">
                <div className="header__row">
                  <span style={{fontWeight: "bold"}}>ПДФО</span>
                  {!editMode ? (
                    <span style={{marginRight: "9px"}}>{zpSettings?.settings?.podatok}%</span>
                  ) : (
                    <span>
                      <NumberFormat className={'search-input'} suffix={'%'} value={values.pdfo}
                                    id={'pdfo'}
                                    onValueChange={(values) => {
                                      handleChange(values.formattedValue)
                                    }}
                                    onBlur={e => {
                                      props.setSellaryInfo({
                                        status: 'off',
                                        pdfo: e.target.value,
                                        military: values.military,
                                        esv: values.esv,
                                        monthes: getMonthArray(values)
                                      })
                                      handleChange(e)
                                    }}
                      />
                </span>
                  )}
                </div>
                <div className="header__row">
                  <span style={{fontWeight: "bold"}}>Військовий збір</span>
                  {!editMode ? (
                    <span style={{marginRight: "9px"}}>{zpSettings?.settings?.military}%</span>
                  ) : (
                    <span>
                      <NumberFormat className={'search-input'} suffix={'%'} value={values.military}
                                    id={'military'}
                                    onValueChange={(values) => {
                                      handleChange(values.formattedValue)
                                    }}
                                    onBlur={e => {
                                      props.setSellaryInfo({
                                        status: 'off',
                                        pdfo: values.pdfo,
                                        military: e.target.value,
                                        esv: values.esv,
                                        monthes: getMonthArray(values)
                                      })
                                      handleChange(e)
                                    }}
                      />
                </span>
                  )}
                </div>
                <div className="header__row">
                  <span style={{fontWeight: "bold"}}>ЄСВ</span>
                  {!editMode ? (
                    <span style={{marginRight: "9px"}}>{zpSettings?.settings?.ecv?.procent}</span>
                  ) : (
                    <span>
                 <NumberFormat className={'search-input'} suffix={'%'} value={values.esv}
                               id={'esv'}
                               onValueChange={(values) => {
                                 handleChange(values.formattedValue)
                               }}
                               onBlur={e => {
                                 props.setSellaryInfo({
                                   status: 'off',
                                   pdfo: values.pdfo,
                                   military: values.military,
                                   esv: e.target.value,
                                   monthes: getMonthArray(values)
                                 })
                                 handleChange(e)
                               }}
                 />
                </span>
                  )}
                </div>
              </div>
              <div className="content__row">
            <span style={{fontWeight: "bold"}}>
              Мінімальні заробітна плата
            </span>
                {editMode && (
                  <SButtonGrey
                    style={{width: "82px", height: "24px", margin: "0"}}
                  >
                    + Додати
                  </SButtonGrey>
                )}
              </div>
              {zpSettings && zpSettings.settings.minzp.array.map((item, index) => (
                <div className="content__row__grey">
            <span>
              {item.zp} грн. {item.time} {index === 0 && <>
              по <img src={infinity}/>
            </>}
            </span>
                  {editMode && (
                    <span>
                <img src={trash} style={{marginRight: "18px"}}/>
              </span>
                  )}
                </div>
              ))}
              <div className="content__row" style={{marginTop: "7px"}}>
                <span style={{fontWeight: "bold"}}>Прожитковий мінімум</span>
                {editMode && (
                  <SButtonGrey
                    style={{width: "82px", height: "24px", margin: "0"}}
                  >
                    + Додати
                  </SButtonGrey>
                )}
              </div>
              {zpSettings && zpSettings.settings.minlife.map((item, index) => (
                <div className="content__row__grey">
            <span>
              {item.zp} грн. {item.time} {index === 0 && <>
              по <img src={infinity}/>
            </>}
            </span>
                  {editMode && (
                    <span>
                <img src={trash} style={{marginRight: "18px"}}/>
              </span>
                  )}
                </div>
              ))}
            </div>
            <div className="content__vertical"></div>
            <div className="content__right">
              <div className="content__right__name">
                <p
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    marginBottom: "5px",
                  }}
                >
                  Календарний <br/> місяць
                </p>
                <p>січень</p>
                <p>лютий</p>
                <p>березень</p>
                <p>квітень</p>
                <p>травень</p>
                <p>червень</p>
                <p>липень</p>
                <p>серпень</p>
                <p>вересень</p>
                <p>жовтень</p>
                <p>листопад</p>
                <p>грудень</p>
              </div>
              <div className="content__right__value">
                <p
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    marginBottom: "5px",
                  }}
                >
                  Індекс <br/> споживчих цін
                </p>
                {Array.apply(null, {length: 12}).map((_, index) => <div key={index}>
                  {editMode ? (
                    <SearchInput
                      style={{
                        width: "42px",
                        height: "19px",
                        fontSize: '13px',
                        padding: "2px 5px 0 7px",
                        color: "#000",
                      }}
                      value={values[`month${index + 1}`]}
                      id={`month${index + 1}`}
                      onChange={handleChange}
                      onBlur={e => {
                        props.setSellaryInfo({
                          status: 'off',
                          pdfo: values.pdfo,
                          military: values.military,
                          esv: values.esv,
                          monthes: getMonthArray(values)
                        })
                      }}
                      type={'number'}
                    />
                  ) : (
                    <p>{(zpSettings && zpSettings.settings.index.length !== 0) ? zpSettings.settings.index[index] : "0,00"}</p>
                  )}
                </div>)}
              </div>
            </div>
          </div>
        </div>
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settingsReducer.settings,
  zpSettings: state.settingsReducer.zpSettings
})

KadriCardSettingsTwo = connect(mapStateToProps, {setSellaryInfo, getZpSettings})(KadriCardSettingsTwo)