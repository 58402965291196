import { useEffect, useState } from "react";

import { UserInfoPopUp } from "./PopUp/UserInfoPopUp";
import { NotFound } from "./NotFound";
import "./Reference.css";
import { Table } from "./Table";
import regions from "../../constants/regions";
import { get_posada } from "./api/get_posada";
import { get_users } from "./api/get_users";
import {
  is_valid_params,
  validateEmail,
  validatePhone,
} from "../../utilits/valid_search_params";
import { useAlert } from "react-alert";
import close from "../../assets/close_pop.svg";

import { Checkbox } from "../../components/checkbox/Checkbox";
import { check_lowercase } from "../../utilits/check_lowercase";
import Loader from "../../components/loaders/Loader";
import { get_vacation } from "./api/get_vacation";
import CustomSelect from "../../components/selectCustom/customSelect";

export const Reference = () => {
  const alert = useAlert();
  const [isVisual, setVisual] = useState(false);
  const [workerId, setWorkerId] = useState();
  const [isGetTable, setIsGetTable] = useState(false);
  const [selectUser, setSelectUser] = useState({});
  const [posadaList, setPosadaList] = useState([]);
  const [tableData, setTabelData] = useState([]);
  const [region_list, setRegion] = useState();
  const [showDeleted, setShowDeleted] = useState(false);
  const [showLoader, setLoader] = useState(false);
  const [vacation, setVacation] = useState([]);
  const [showButton, setShowButton] = useState(false);
  const [selectRegion, setSelectRegion] = useState();
  const [selectPosada, setSelectPosada] = useState();
  const [filterData, setFilterData] = useState({
    name: "",
    surname: "",
    mobilePhone: "",
    email: "",
    region: "",
    "postInfo.posada": "",
  });

  useEffect(async () => {
    const fetchPosadaList = async () => {
      const posadaList = await get_posada();
      setPosadaList(posadaList);
    };
    fetchPosadaList();

    const fetchVacationList = async () => {
      const vacationList = await get_vacation(new Date().getFullYear());
      setVacation(vacationList);
    };
    fetchVacationList();
    get_region();
  }, []);

  useEffect(() => {
    let empty = true;
    for (const key in filterData) {
      if (filterData[key] !== "") empty = false;
    }
    if (empty) setShowButton(false);
    else setShowButton(true);
  }, [filterData]);

  const set_worker_id = async (user) => {
    setVisual(true);
    setWorkerId(user.workerSuperior);
    setSelectUser(user);
  };

  const close_window = () => {
    setVisual(false);
  };

  const get_region = () => {
    let result = [];
    for (let region in regions) {
      result.push({
        value: region,
        label: regions[region].name,
      });
    }
    result.unshift({ value: "", label: "Всі регіони" });
    setRegion(result);
  };

  const change_handler = (params) => {
    const key = params.target.name;
    let value = params.target.value;
    if (key === "name" || key === "surname") value = check_lowercase(value);
    if (!showButton) setShowButton(true);
    setFilterData((prev) => ({
      ...prev,
      [key]: value,
    }));
    let empty = false;
  };

  const get_table_data = async () => {
    setLoader(true);
    setIsGetTable(false);
    const valid_data = is_valid_params({
      name: filterData.name,
      surname: filterData.surname,
      phone: filterData.mobilePhone,
      email: filterData.email,
      region: "all",
      "postInfo.posada": "all",
    });
    console.log(valid_data, "valid_data");
    if (!valid_data) return alert.show("Заповніть хоча б одне поле");
    if (filterData.email.length > 0) {
      if (!validateEmail(filterData.email))
        return alert.show("Не вірно вказано поле E-mail");
    }
    if (filterData.mobilePhone.length > 0) {
      const phone = filterData.mobilePhone.replace(/\D/g, "");
      if (!validatePhone(phone))
        return alert.show("Не вірно вказано поле телефон");
    }

    const response = await get_users(filterData, showDeleted, vacation);
    setTabelData(response);
    setIsGetTable(true);
  };

  const show_table = () => {
    if (showLoader) setLoader(false);
    return tableData.length === 0 ? (
      <NotFound />
    ) : (
      <Table set_user={set_worker_id} table_data={tableData} />
    );
  };

  const set_show_deleted = () => {
    setShowDeleted((prev) => (prev ? false : true));
  };

  const clear_input = (name) => {
    setFilterData((prev) => ({ ...prev, [name]: "" }));
  };

  const clear_search = () => {
    setFilterData({
      name: "",
      surname: "",
      mobilePhone: "",
      email: "",
      region: "",
      "postInfo.posada": "",
    });
    set_select("region", { label: "Регіон", value: "" });
    set_select("postInfo.posada", { label: "Посада", value: "" });
    setTabelData([]);
    setIsGetTable(false);
    if (showButton) setShowButton(false);
  };

  const set_select = (key, element) => {
    if (key === "region") {
      setSelectRegion({ label: element.label, value: element.value });
    }
    if (key === "postInfo.posada") {
      setSelectPosada({ label: element.label, value: element.value });
    }

    setFilterData((prev) => ({
      ...prev,
      [key]: element.value,
    }));
  };

  return (
    <div className="reference">
      <div className="reference-search">
        <p>Пошук працівника</p>
        <div className="reference-block">
          <input
            value={filterData.surname}
            onChange={change_handler}
            name="surname"
            autoComplete="off"
            onKeyUp={(e) => (e.key === "Enter" ? get_table_data() : "")}
            placeholder="Прізвище"
          />
          <img
            onClick={() => clear_input("surname")}
            className={
              filterData.surname.length >= 1
                ? "clear_input__active"
                : "clear_input"
            }
            src={close}
            alt="close"
          />
        </div>
        <div className="reference-block">
          <input
            value={filterData.name}
            onChange={change_handler}
            name="name"
            autoComplete="off"
            placeholder="Ім'я"
            onKeyUp={(e) => (e.key === "Enter" ? get_table_data() : "")}
          />
          <img
            onClick={() => clear_input("name")}
            className={
              filterData.name.length >= 1
                ? "clear_input__active"
                : "clear_input"
            }
            src={close}
            alt="close"
          />
        </div>
        <div className="reference-block">
          <input
            value={filterData.mobilePhone}
            onChange={change_handler}
            name="mobilePhone"
            placeholder="Номер телефону"
            autoComplete="new-pass"
            onKeyUp={(e) => (e.key === "Enter" ? get_table_data() : "")}
          />
          <img
            onClick={() => clear_input("mobilePhone")}
            className={
              filterData.mobilePhone.length >= 1
                ? "clear_input__active"
                : "clear_input"
            }
            src={close}
            alt="close"
          />
        </div>

        <div className="reference-block">
          <CustomSelect
            placeholder="Посада"
            paddingValue={10}
            disabled={false}
            textSize={16}
            scrollHeight={320}
            changeFn={(e) => set_select("postInfo.posada", e)}
            isChange={false}
            width={"90%"}
            get_table_data={() => get_table_data()}
            defaultValue={selectPosada}
            options={posadaList}
          />
        </div>
        <div className="reference-block">
          <CustomSelect
            placeholder="Регіон"
            paddingValue={10}
            disabled={false}
            textSize={16}
            scrollHeight={320}
            changeFn={(e) => set_select("region", e)}
            isChange={false}
            defaultValue={selectRegion}
            get_table_data={() => get_table_data()}
            width={"90%"}
            options={region_list}
          />
        </div>

        <Checkbox onChange={set_show_deleted} text="Показувати звільнених" />
        <div className="btn-container">
          {showButton ? (
            <button
              onClick={() => clear_search()}
              className="reference__button clear-search"
            >
              Очистити пошук
            </button>
          ) : (
            ""
          )}
          <button
            onClick={() => get_table_data()}
            className="reference__button"
          >
            Пошук
          </button>
        </div>
      </div>
      {showLoader ? <Loader /> : ""}
      {isGetTable ? show_table() : ""}

      {isVisual ? (
        <UserInfoPopUp
          selectUser={selectUser}
          workerId={workerId}
          close={close_window}
        />
      ) : (
        ""
      )}
    </div>
  );
};
