import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import style from "../assets/style/controller.module.css"
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import deleteIcon from "../../../images/icons/delete.svg";
import Deleted from "../../../images/icons/delete.svg";
import fingerGray from "../assets/icon/finger-gray.svg"
import fingerBlack from "../assets/icon/finger-black.svg"
import Edit from "../../../images/icons/edit.svg"
import deletedController from "../../../redux/actions/controller/deletedController";
import {MButtonGray} from "../../../styledComponents/Buttons";
import deletedControllerVisitor from "../../../redux/actions/controller/visitor/deleted";
import ControllerUserInfo from "../modal/ControllerUserInfo";
import loadControllerHistory from "../../../redux/actions/controller/loadControllerHistory";
import close from "../../../assets/close.svg";
import Modal from "../../../components/modals/modalComponents/Modal/Modal";
import loadInfoController from "../../../redux/actions/controller/loadInfoController";
import Loader from "../../../components/loaders/Loader";
import getUserAndUpdateFinger from "../../../redux/actions/controller/getUserAndUpdateFinger";

const customEqual = (oldValue, newValue) => JSON.stringify(oldValue) === JSON.stringify(newValue)

const EdiRentController = ({objectId, setIsAddModal, setVisitorId, setModel, setRole, info, userList}) => {
  const dispatch = useDispatch()
  const [infoModal, setInfoModal] = useState(true);
  const [isLoader, setLoader] = useState(false);
  const visitor = useSelector(state => state.controller.visitor, customEqual);
  const history = useSelector(state => state.controller.history, customEqual);
  const [visitorIndex, setVisitorIndex] = useState(null);
  const test = true;

  useEffect(() => {
    dispatch(loadInfoController(objectId, setLoader))
  }, [objectId])

  const handleClickEditModal = (e, id, role, model) => {
    e.preventDefault();
    setIsAddModal(false);
    setModel(model)
    setRole(role)
    setVisitorId(id)
  }

  const handleClickDeletedVisitor = (e, id) => {
    e.preventDefault();
    dispatch(deletedControllerVisitor(id, objectId))
  }
  const toggleModal = () => {
    setInfoModal(true)
  }
  const WorkerInfo = ({el, model, finger = el.finger ? el?.finger : el?.stuFingerPrintInfoEx}) => {
    const handleClickHistory = (e) => {
      e.preventDefault();
      dispatch(loadControllerHistory(objectId, el._id))
      setVisitorIndex(el._id)
      setInfoModal(false)
    }

    return <li>
      <button onClick={(e) => handleClickHistory(e, el._id)} className={style.controller__box__container}>
        Переглянути звіт
      </button>
      <div>
        {el?.surname ? `${el.surname} ${el.name} ${el.patronymic}` : el.name}
      </div>
      <div>
        {el.postInfo.status}
      </div>
      {/*<div style={{*/}
      {/*  color: "white",*/}
      {/*  padding: "2px 4px",*/}
      {/*  borderRadius: 5,*/}
      {/*  alignItems: "center",*/}
      {/*  justifyContent: "center",*/}
      {/*  lineHeight: "initial",*/}
      {/*  display: "flex",*/}
      {/*  background: "#009640"*/}
      {/*}}>*/}
      {/*  {el?.szUserID ? el.szUserID : "-"}*/}
      {/*</div>*/}
      <div className={style.controller__box__container} style={{pointerEvents: "none"}}>
        {!finger?.nCount > 0 ? <img src={fingerGray} alt=""/> :
          <img src={fingerBlack} alt=""/>}
      </div>
      <div style={{width: 32}}>
        <button onClick={(e) => handleClickEditModal(e, el._id, el.role, model)}
                className={style.controller__box__container}>
          <img src={Edit} alt=""/>
        </button>
      </div>
      <div style={{width: 32}}>
        {el.role !== "employer" &&
          <button onClick={(e) => handleClickDeletedVisitor(e, el._id)} className={style.controller__box__container}>
            <img src={Deleted} alt=""/>
          </button>}
      </div>
    </li>
  }
  const TestContainer = () => {
    const testData = [{surname: "test", name: "test", patronymic: "test", postInfo: {status: "Посада"}}];
    return <div className={style.controller__container}>
      {
        <>
          <ul className={style.controller__header}>
            <li>
              Device type:
              <span>test</span>
            </li>
            <li>
              Serial number:
              <span>test</span>
            </li>
            <li>
              Current time:
              <span>test</span>
            </li>
            <li>
              Door status:
              <span>test</span>
            </li>
            <li>
              Lock status:
              <span>{test}</span>
            </li>
            <li>
              <button onClick={(e) => {
                e.preventDefault()
                // dispatch(deletedController(objectId))
              }}>
                <img src={deleteIcon}/>
              </button>
            </li>
          </ul>
          <ul className={style.controller__grid}>
            {testData.map(el => <WorkerInfo el={el}/>)}
          </ul>
          {/*<div className={style.controller__bottom}>*/}
          {/*  <MButtonGray onClick={() => setIsAddModal(false)}>*/}
          {/*    <span>+ Додати</span>*/}
          {/*  </MButtonGray>*/}
          {/*</div>*/}
        </>
      }
    </div>
  }

  const [scrollPosition, setScrollPosition] = useState({
    scrollX: 0,
    scrollY: 0,
  });
  const scroll = useRef(null);
  const getCurrentScrollPosition = (e) => {
    let cordsAfterClick = {
      scrollY: e.currentTarget.scrollTop,
      scrollX: e.currentTarget.scrollLeft
    };
    localStorage.setItem("scrollCord", JSON.stringify(cordsAfterClick));
  };
  useEffect(() => {
    setScrollPosition((prevScrollCord) => {
      return {
        ...prevScrollCord,
        ...JSON.parse(window.localStorage.getItem("scrollCord")),
      };
    });

  }, [scrollPosition.scrollX, scrollPosition.scrollY]);
  useLayoutEffect(() => {
    const scrollPos = JSON.parse(window.localStorage.getItem("scrollCord"));
    scroll.current && scrollPos && scroll.current.scrollTo(scrollPos.scrollX, scrollPos.scrollY)
  }, [isLoader, infoModal])

  return (
    infoModal ?
      <React.Fragment>

        {isLoader ? info ?
            <div ref={scroll} onScroll={getCurrentScrollPosition}
                 className={style.controller__container}>
              {
                <>
                  <ul className={style.controller__header}>
                    <li>
                      Device type:
                      <span>{info.szDevType}</span>
                    </li>
                    <li>
                      Serial number:
                      <span>{info.serialNo}</span>
                    </li>
                    <li>
                      Current time:
                      <span>{moment(info.date).format("HH:mm")}</span>
                    </li>
                    <li>
                      Door status:
                      <span>{info.emStateType ? "open" : "close"}</span>
                    </li>
                    <li>
                      Lock status:
                      <span>{info.emStateType ? "close" : "open"}</span>
                    </li>
                    {/*<li>*/}
                    {/*  <button onClick={(e) => {*/}
                    {/*    e.preventDefault()*/}
                    {/*    dispatch(deletedController(objectId))*/}
                    {/*  }}>*/}
                    {/*    <img src={deleteIcon}/>*/}
                    {/*  </button>*/}
                    {/*</li>*/}
                  </ul>
                  <ul className={style.controller__grid}>
                    {userList && userList.concat(visitor).map(el => <WorkerInfo model={info.szDevType} el={el}/>)}
                  </ul>
                  <div className={style.controller__bottom}>
                    <MButtonGray onClick={() => {
                      setIsAddModal(false)
                      setModel(info.szDevType);
                      setRole("visitor")
                    }}>
                      <span>+ Додати</span>
                    </MButtonGray>
                  </div>
                </>
              }
            </div> : <span style={{color: "gray", justifyContent: "center", width: "100%", display: "flex"}}>Контролер не відповідає</span> :
          <div
            style={{display: "flex", width: "100%", justifyContent: "center", alignItems: "center", height: "100px"}}>
            <Loader style={{transform: "scale(2.0)"}}/></div>}
      </React.Fragment> :
      history &&
      <Modal title='Історія працівника' closeIcon={close} className={style.history_controller} divider={false} style={{
        overflowY: "hidden"
      }} toggleFunc={(e) => {
        toggleModal(e)
      }}><ControllerUserInfo visitorId={visitorIndex} history={history} date={info.date}/></Modal>
  )
}

export default EdiRentController;
