import instanceController from "../../instanceController";
import showAlert from "../alerts/showAlert";
import {LOAD_LIST_OBJECT} from "../../types";
import {GET_OBJECT_LIST_CONTROLLER} from "../../../constants/urls";

const getObjectListController = () => async (dispatch) => {
  try {
    const response = await instanceController.get(GET_OBJECT_LIST_CONTROLLER)
    dispatch({
      type: LOAD_LIST_OBJECT,
      payload: response.data
    })
    return response.data
  } catch (e) {
    if (e.response) {
      dispatch(showAlert(e.response.data.message, 'error'))
    }
  }
}

export default getObjectListController
