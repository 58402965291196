import instanceController from "../../../instanceController";
import showAlert from "../../alerts/showAlert";
import {CREATE_CONTROLLER_VISITOR} from "../../../../constants/urls";
import loadControllerVisitor from "./load";

const createdControllerVisitor = (update, objectId) => async (dispatch) => {
  try {
    const response = await instanceController.post(CREATE_CONTROLLER_VISITOR, {update})
    dispatch(loadControllerVisitor(objectId));
    return response;
  } catch (e) {
    console.log(e.response)
    if (e.response) {
      dispatch(showAlert(e.response.data.message, "error"))
    }
  }
}

export default createdControllerVisitor

