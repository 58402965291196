import { EMPLOYER_GET_DATA } from "../../../constants/urls";
import { LOAD_CURRENT_WORKER } from "../../types";
import instance from "../../instance";

const loadCurrentWorker =
  (workerId, alert, openModal, isReturn = false) =>
  async (dispatch) => {
    try {
      const response = await instance.post(EMPLOYER_GET_DATA, { id: workerId });

      if (response.status === 200) {
        if (!isReturn) {
          dispatch({
            type: LOAD_CURRENT_WORKER,
            payload: response.data.employer,
          });
          if (openModal) {
            openModal();
          }
          return response.data.employer;
        } else {
          dispatch({
            type: LOAD_CURRENT_WORKER,
            payload: response.data.employer,
          });
          if (openModal) {
            openModal();
          }
          return response.data.employer;
        }
      } else {
        throw Error(response.data.message || "Щось пішло не так");
      }
    } catch (e) {
      throw Error(e.message || "Щось пішло не так");
    }
  };

export default loadCurrentWorker;
