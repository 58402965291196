import React, { useEffect, useState } from "react";
import deleteIcon from "../../../images/icons/delete.svg";
import plus from "../../../assets/plus.svg";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../../api/users";
import { InputForm } from "../../../styledComponents/Inputs";
import { useAlert } from "react-alert";
import userTypes from "../../../constants/userTypes";
import loadUserData from "../../../redux/actions/loadUserData";
import { getCookie } from "../../../utilits/cookies";

const matches = (needle, haystack) => {
  needle = needle.toLocaleLowerCase();
  haystack = (haystack || "").toLocaleLowerCase();
  return haystack.includes(needle);
};
const token = getCookie("token");
const EditRentResponsiblePersons = ({
  setFieldValue,
  region,
  user,
  objManager,
  regionalManagerValue = null,
  managerValue = [],
  accountantValue = [],
  landlordValue = [],
}) => {
  const getWorkers = useSelector((state) => state.employers);
  const [object, setObject] = React.useState(null);
  const [accountant, setAccounted] = useState(accountantValue);
  const [regionalManager, setRegionalManager] = useState(regionalManagerValue);
  const [worker, setWorker] = useState(managerValue);
  const dispatch = useDispatch();
  const alert = useAlert();
  useEffect(() => {
    console.log(accountantValue, "accountantValue");
    console.log(regionalManagerValue, "regionalManagerValue");
  }, []);
  const setAccountedValue = (users) => {
    let result = users.filter((el) =>
      landlordValue.some((items) => {
        if (el.userType === "accountant" || el.userType === "headAccountant") {
          return el._id === items.userSuperior;
        }
      })
    );
    console.log(result);
    setAccounted(result);
    setFieldValue("accountant", result.length === 0 ? [] : result);
  };
  useEffect(async () => {
    const users = await getUsers().then(({ users }) => users);
    setFieldValue(
      "regionalManager",
      users.filter(
        (el) =>
          el.userType === "regionalManager" &&
          (typeof el.region === "string"
            ? el.region === region
            : el.region.filter((reg) => reg === region)[0])
      )[0]
    );
    setRegionalManager(
      users.filter(
        (el) =>
          el.userType === "regionalManager" &&
          (typeof el.region === "string"
            ? el.region === region
            : el.region.filter((reg) => reg === region)[0])
      )[0]
    );
    setObject(users.concat(getWorkers.filter((el) => el.rnokpp.length !== 8)));
    setAccountedValue(users);
  }, []);

  const addWorker = async (e) => {
    e.preventDefault();
    setWorker((prevState) => [
      ...prevState,
      { status: false, fio: "", id: null },
    ]);
  };

  const deleteList = (e, index, array) => {
    e.preventDefault();
    let result = array.filter((el, i) => i !== index);
    setWorker(result);
    setFieldValue("manager", result);
  };
  const justifyCenterStyle = {
    justifyContent: "center",
  };
  const ListWorker = () => {
    const setList = async (value, index, listIndex, type) => {
      let comparison = worker.every((el) => el.id !== index);
      if (!comparison)
        return alert.show("Запись уже существует", { type: "error" });

      let result = worker.map((items, i) => {
        console.log(listIndex, "listIndexlistIndex");

        if (listIndex === i) {
          return {
            ...items,
            userType: type,
            id: index,
            status: true,
            fio: value,
          };
        }
        return {
          ...items,
        };
      });
      setWorker(result);
      setFieldValue("manager", result);
    };
    const userTypeFilter = (type) => {
      return Object.keys(userTypes).map(
        (items) => items === type && userTypes[type].name
      );
    };
    const WorkerOne = ({ list, indexList }) => {
      const [search, setSearch] = React.useState("");
      let filtered = object || [];
      if (filtered.length > 0 && search.length > 0) {
        const fields = [
          (item) => item?.lastName,
          (item) => item?.firstName,
          (item) => item?.middleName,
          (item) => item?.userInfO?.full_name,
        ];
        filtered = filtered.filter((item) => {
          return fields.some((field) => matches(search, field(item)));
        });
      }

      return (
        <div className="edit_rent__search-container">
          {!list?.status ? (
            <React.Fragment>
              <InputForm
                placeholder="Пошук"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                autoFocus
                type="text"
                value={search}
              />
              {search.length > 0 && (
                <ul style={{ transform: "translate(0,-100%)", bottom: "auto" }}>
                  {filtered?.length ? (
                    filtered.map((items, index) =>
                      items?.lastName ? (
                        <li
                          key={index}
                          onClick={() =>
                            setList(
                              `${items.firstName} ${items.middleName} ${items.lastName}`,
                              items._id,
                              indexList,
                              items?.userType
                            )
                          }
                        >
                          {`${items.firstName} ${items.lastName}`}
                        </li>
                      ) : (
                        ""
                      )
                    )
                  ) : (
                    <li>Немає користувачів</li>
                  )}
                </ul>
              )}
            </React.Fragment>
          ) : (
            list.fio
          )}
        </div>
      );
    };
    useEffect(() => {
      console.log(worker, "worker worker");
    });
    return (
      worker &&
      worker.map((list, indexList) => (
        <div className="edit_rent-persons__container edit_rent-persons__grid">
          <div className="edit_rent-persons__row">
            <WorkerOne list={list} indexList={indexList} />
          </div>
          <div className="edit_rent-persons__row">
            {list?.userType ? userTypeFilter(list.userType) : "-"}
          </div>
          <div className="edit_rent-persons__row" style={justifyCenterStyle}>
            <button onClick={(e) => deleteList(e, indexList, worker)}>
              <img src={deleteIcon} />
            </button>
          </div>
        </div>
      ))
    );
  };
  return (
    <div className="edit_rent-persons__wrapper">
      <div className="edit_rent-persons">
        <div className="edit_rent-persons__header">
          <div className="edit_rent-persons__title">Регіональний директор</div>
        </div>
        <div className="edit_rent-persons__table">
          <div className="edit_rent-persons__head edit_rent-persons__grid">
            <div className="edit_rent-persons__row">ПІБ</div>
            <div className="edit_rent-persons__row">Посада</div>
            <div
              className="edit_rent-persons__row"
              style={justifyCenterStyle}
            />
          </div>
          {regionalManager && (
            <div className="edit_rent-persons__container edit_rent-persons__grid">
              <div className="edit_rent-persons__row">
                {regionalManager
                  ? `${regionalManager.firstName} ${regionalManager.middleName} ${regionalManager.lastName}`
                  : "-"}
              </div>
              <div className="edit_rent-persons__row">
                {regionalManager ? "Регіональний директор" : "-"}
              </div>
              <div
                className="edit_rent-persons__row"
                style={justifyCenterStyle}
              ></div>
            </div>
          )}
        </div>
      </div>

      <div className="edit_rent-persons">
        <div className="edit_rent-persons__header">
          <div className="edit_rent-persons__title">Бухгалтер</div>
        </div>
        <div className="edit_rent-persons__table">
          <div className="edit_rent-persons__head edit_rent-persons__grid">
            <div className="edit_rent-persons__row">ПІБ</div>
            <div className="edit_rent-persons__row">Посада</div>
            <div
              className="edit_rent-persons__row"
              style={justifyCenterStyle}
            />
          </div>
          {accountant.length !== 0 ? (
            accountant.map((items, i) => (
              <div
                key={i}
                className="edit_rent-persons__container edit_rent-persons__grid"
              >
                <div className="edit_rent-persons__row">
                  {items?.firstName
                    ? `${items.firstName} ${items.middleName} ${items.lastName}`
                    : "-"}
                </div>
                <div className="edit_rent-persons__row">
                  {items.userType === "accountant"
                    ? "Бухгалтер"
                    : items?.userType
                    ? "Головний Бухгалтер"
                    : "-"}
                </div>
                <div
                  className="edit_rent-persons__row"
                  style={justifyCenterStyle}
                />
              </div>
            ))
          ) : (
            <div className="edit_rent-persons__head edit_rent-persons__grid">
              <div className="edit_rent-persons__row">-</div>
              <div className="edit_rent-persons__row">-</div>
              <div
                className="edit_rent-persons__row"
                style={justifyCenterStyle}
              />
            </div>
          )}
        </div>
      </div>
      <div className="edit_rent-persons">
        <div className="edit_rent-persons__header">
          <div className="edit_rent-persons__title">
            Керуюча / адміністратор / заступник
          </div>
          <button className="edit_rent-persons__button" onClick={addWorker}>
            <img
              width="9"
              height="9"
              style={{ marginRight: 7 }}
              src={plus}
              alt=""
            />{" "}
            Додати
          </button>
        </div>
        <div className="edit_rent-persons__table">
          <div className="edit_rent-persons__head edit_rent-persons__grid">
            <div className="edit_rent-persons__row">ПІБ</div>
            <div className="edit_rent-persons__row">Посада</div>
            <div className="edit_rent-persons__row" style={justifyCenterStyle}>
              Видалити
            </div>
          </div>
          <ListWorker />
        </div>
      </div>
    </div>
  );
};

export default EditRentResponsiblePersons;
