import Select from "react-select";
import React, {useEffect, useState} from "react";

const CustomController = ({list, objectId, setFieldValue}) => {
  const [value, setValue] = useState(list.filter(el => el.objectId === objectId).map(el => {
    return {isFixed: true, value: el.objectId, label: el.location}
  }));
  const orderOptions = (values) => {
    return values
      .filter((v) => v.isFixed)
      .concat(values.filter((v) => !v.isFixed));
  };
  const onChange = (
    newValue,
    actionMeta
  ) => {
    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
        if (actionMeta.removedValue.isFixed) {
          return;
        }
        break;
      case 'clear':
        newValue = value.filter((v) => v.isFixed);
        break;
    }

    setValue(orderOptions(newValue));
    console.log(newValue)
    setFieldValue("location", newValue)
  }
  useEffect(()=>{
    setFieldValue("location", value)
  },[])


  return <Select isMulti isClearable={value.some((v) => !v.isFixed)} value={value} styles={{
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? {...base, fontWeight: 'bold', color: 'black', paddingRight: 6}
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? {...base, display: 'none'} : base;
    },
    control: provided => ({
      ...provided,
      minHeight: 31,
      borderRadius: 6,
      border: "1px solid #616161;"
    }),
    option: provided => ({
      ...provided,
      padding: "10px 12px",
      fontSize: "12px"
    }),
    valueContainer: provided => ({
      ...provided,
      padding: 0,
      paddingLeft: 12
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 140,
      paddingTop: 0,
      paddingBottom: 0
    }),
    placeholder: provided => ({
      ...provided,
      fontSize: '12px'
    }),
    dropdownIndicator: provided => ({
      ...provided,
      padding: 0
    }),
    multiValue: provided => ({
      ...provided,
      margin: 2,
      height: 24,
      display: "flex",
      alignItems: "center",
      fontSize: "14px",
      "div:nth-child(1)": {
        display: "flex",
        alignItems: "center",
        padding: "0 3px",
        height: 24
      }
    }),
    input: provided => ({
      ...provided,
      margin: 0,
      height: 20,
      padding: 0,
      "input": {
        height: 20
      }
    }),
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
  }} options={list.map(el => {
    return {
      value: el.objectId,
      label: el.location
    }
  })} placeholder="Виберіть локацію" onChange={onChange}/>
}

export default CustomController;
