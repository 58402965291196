import {LOAD_EMPLOYERS} from "../types"

const initialUser = null

export const employerReducer = (state = initialUser, action) => {
  switch (action.type) {
    case LOAD_EMPLOYERS:
      return action.payload
    default:
      return state
  }
}
