import not_found from "../../assets/not_found.svg";

export const NotFound = () => {
  return (
    <div className="reference-not-found">
      <img src={not_found} alt="Not Found" />
      <p className="p">Нічого не знайдено</p>
      <p>
        Поради: <br /> Переконайтеся, що всі слова написані правильно. Спробуйте
        інші ключові слова.
      </p>
    </div>
  );
};
