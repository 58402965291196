import React, { useEffect, useState } from "react";

import closeGrey from "../../../../../assets/closeGrey.svg";

import { TextGrey } from "../../../../../styledComponents/Text";
import { SelectForm } from "../../../../../styledComponents/Inputs";
import TableHeaderCell from "../components/TableHeaderCell";
import Holidays from "date-holidays";
import { connect } from "react-redux";
import TableTd from "./TableTd/TableTd";
import {
  getWorkDays,
  setWorkDay,
} from "../../../../../redux/cadrDocuments/cadrDocuments-thunks";
import { days, months } from "../../../../../utilits/getDateAndTime";
import axios from "axios";
import isDeletedWorker from "../../../../../utilits/isDeletedWorker";
import { HOLIDAYDAYS } from "../../../../../constants/urls";
import moment from "moment";

const hd = new Holidays();
hd.init("UA");

export let KadriCardsWorkTimeModal = ({
  toggleWorkTimeModal,
  workers,
  getWorkDays,
  workDays,
  setWorkDay,
}) => {
  const tableItem = () => <td className="table__sm"></td>;
  const [currentMonth, setCurrentMonth] = useState(
    new Date().getUTCMonth() + 1
  );
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  let daysCount = new Date(currentYear, currentMonth, 0).getDate();
  const [arrayHoliday, setArrayHoliday] = useState(null);

  function isDateInRangeAndWeekend(date, currentYear, currentMonth) {
    const inputDate = moment(date, "YYYY-MM-DD");
    const startOfMonth = moment([currentYear, currentMonth - 1]); // month-1, так как месяцы начинаются с 0

    // Конец диапазона - последний день этого месяца
    const endOfMonth = moment(startOfMonth).endOf("month");

    const isInRange = inputDate.isBetween(
      startOfMonth,
      endOfMonth,
      "day",
      "[]"
    );
    const isWeekend = inputDate.day() === 6 || inputDate.day() === 0;
    return isInRange && isWeekend;
  }

  function isDateInRange(date, year, month) {
    const inputDate = moment(date, "YYYY-MM-DD");

    const startOfMonth = moment([year, month - 1]);

    const endOfRange = moment().subtract(1, "days");

    return inputDate.isBetween(startOfMonth, endOfRange, "day", "[]");
  }

  useEffect(async () => {
    const result = await axios.post(HOLIDAYDAYS, { year: currentYear });
    setArrayHoliday(result.data);
    getWorkDays(currentYear, parseInt(currentMonth));
  }, [currentMonth, currentYear]);
  return (
    <div className="doc__modal__container">
      <div className="doc__modal__header">
        <div className="modal__header__title">
          <div></div>
          <TextGrey style={{ textAlign: "center", marginBottom: "16px" }}>
            Табель обліку робочого часу
          </TextGrey>
          <button className={"modal__close"} onClick={toggleWorkTimeModal}>
            <img src={closeGrey} />
          </button>
        </div>
        <div style={{ marginBottom: "10px" }} className="modal__header__select">
          <SelectForm
            value={currentYear}
            onChange={(e) => setCurrentYear(e.target.value)}
          >
            <option value={new Date().getUTCFullYear() - 1}>
              {new Date().getUTCFullYear() - 1} рік
            </option>
            <option value={new Date().getUTCFullYear()}>
              {new Date().getUTCFullYear()} рік
            </option>
          </SelectForm>
        </div>
        <div className="modal__header__select">
          <SelectForm
            value={currentMonth}
            onChange={(e) => setCurrentMonth(e.target.value)}
          >
            {months.map((month, index) => (
              <option value={index + 1}>{month}</option>
            ))}
          </SelectForm>
        </div>
      </div>
      <div className="workTime__modal__table">
        {arrayHoliday && (
          <table>
            <tr style={{ height: "45px" }}>
              <td className="table__sm"></td>
              <td className="table__lg">
                <span></span> <br />
              </td>
              {Array.apply(null, { length: daysCount }).map((_, index) => {
                let day = new Date(
                  `${currentYear}-${currentMonth
                    .toString()
                    .padStart(2, "0")}-${(index + 1)
                    .toString()
                    .padStart(2, "0")}`
                ).getUTCDay();

                return (
                  <TableHeaderCell
                    date={index + 1}
                    day={days[day]}
                    weekend={day === 0 || day === 6}
                    holiday={arrayHoliday.filter((h) => {
                      return moment(new Date(h.date), "DD-MM-YYYY").isSame(
                        moment(
                          new Date(
                            `${currentYear}-${currentMonth
                              .toString()
                              .padStart(2, "0")}-${(index + 1)
                              .toString()
                              .padStart(2, "0")}`
                          ),
                          "DD-MM-YYYY"
                        )
                      );
                    })}
                  />
                );
              })}
            </tr>
            {workers
              ?.filter((el) => isDeletedWorker(el))
              .map((worker, index) => {
                return (
                  <tr style={{ height: "40px" }}>
                    <td className="table__sm">{index + 1}</td>
                    <td style={{ width: "200px" }} className="table__lg">
                      <span>
                        {worker.surname} {worker.name} {worker.patronymic}
                      </span>{" "}
                      <br />{" "}
                      <span style={{ fontWeight: "300" }}>
                        {
                          <p>
                            {worker.postInfo.status}{" "}
                            {worker.postInfo.format !== "основне" && (
                              <strong style={{ fontSize: 10 }}>
                                (сумісництво)
                              </strong>
                            )}
                          </p>
                        }
                      </span>
                    </td>
                    {Array.apply(null, { length: daysCount }).map(
                      (_, index) => {
                        let getDateTime = (currentYear, currentMonth, day) => {
                          return `${currentYear}-${currentMonth
                            .toString()
                            .padStart(2, "0")}-${day
                            .toString()
                            .padStart(2, "0")}`;
                        };
                        let dateTime = getDateTime(
                          currentYear,
                          currentMonth,
                          index + 1
                        );

                        let foundedDay = workDays.find((w) => {
                          let foundedDateTime = getDateTime(
                            new Date(w.dateTime).getUTCFullYear(),
                            new Date(w.dateTime).getUTCMonth() + 1,
                            new Date(w.dateTime).getUTCDate()
                          );

                          return (
                            foundedDateTime === dateTime &&
                            worker._id === w.epmloyerID
                          );
                        });

                        return (
                          <TableTd
                            key={index}
                            index={index}
                            hd={hd}
                            arrayHoliday={arrayHoliday}
                            currentMonth={currentMonth}
                            currentYear={currentYear}
                            value={
                              foundedDay !== undefined
                                ? foundedDay.status
                                : isDateInRangeAndWeekend(
                                    dateTime,
                                    currentYear,
                                    currentMonth
                                  )
                                ? "ВХ"
                                : isDateInRange(
                                    dateTime,
                                    currentYear,
                                    currentMonth
                                  )
                                ? "8"
                                : ""
                            }
                            hours={
                              worker.postInfo.hours
                                ? worker.postInfo.hours
                                : "8"
                            }
                            onChange={(e) => {
                              setWorkDay({
                                employerid: worker._id,
                                status: e.target.value,
                                year: currentYear,
                                month: parseInt(currentMonth),
                                dateTime: new Date(dateTime),
                                dayid:
                                  foundedDay !== undefined
                                    ? foundedDay._id
                                    : null,
                              });
                            }}
                          />
                        );
                      }
                    )}
                  </tr>
                );
              })}
          </table>
        )}
      </div>
      {/*<div className="doc__modal__footer__btn">*/}
      {/*  <button>*/}
      {/*    <img src={printer}/>*/}
      {/*    <p>*/}
      {/*      Відкрити у <br/> PDF форматі*/}
      {/*    </p>*/}
      {/*  </button>*/}
      {/*</div>*/}
    </div>
  );
};

const mapStateToProps = (state) => ({
  workers: state.workers,
  workDays: state.cadrDocumentsReducer.workDays,
});

KadriCardsWorkTimeModal = connect(mapStateToProps, { getWorkDays, setWorkDay })(
  KadriCardsWorkTimeModal
);
