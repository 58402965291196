import React, {useEffect, useState} from "react";
import {SButtonLightGreen, SButtonLightGrey} from "../../../../../styledComponents/Buttons";
import ConditionsHeader from "../CardsWorkDoc/components/ConditionsHeader";
import {Divider} from "../../../../../styledComponents/Dividers";
import "../KadriCards.css";
import {Formik} from "formik";
import InputMask from 'react-input-mask'
import {useAlert} from "react-alert";
import {InputForm} from "../../../../../styledComponents/Inputs";
import {connect} from "react-redux";
import {acceptTruancy} from "../../../../../redux/workers/workers-thunks";
import datePicker from "../../../../../assets/datePicker.svg";
import Calendar from "react-calendar";
import getDateAndTime from "../../../../../utilits/getDateAndTime";
import {getSettings} from "../../../../../redux/settings/settings-thunks";

export let KadriCardsTruancy__2 = ({
                                     toggleTruancy__2,
                                     employerId,
                                     workerId,
                                     setTypeId,
                                     openEditWorkerModal,
                                     setTypeModal,
                                     setIndexTab,
                                     acceptTruancy,
                                     ...props
                                   }) => {
  const [beginDate, setBeginDate] = useState(null)
  const [isFetching, setIsFetching] = useState(false)
  const alert = useAlert()
  const alertFunc = (message, type) => {
    alert.show(message, {type: type})
    setIsFetching(false)
  }
  const id = window.location.pathname.slice(window.location.pathname.lastIndexOf('/') + 1)
  useEffect(() => {
    props.getSettings(id)
  }, [])
  return (
    <>
      {props.settings && <Formik
        initialValues={{
          date: '',
          number: props.settings?.number1 !== -1 ? props.settings?.number1 + 1 : ''
        }}
        onSubmit={values => {
          setIsFetching(true)
          acceptTruancy({
            id: employerId,
            workerId: workerId,
            number: values.number,
            date: values.date,
            type: 'full day'
          }, alertFunc, toggleTruancy__2).then((fileId) => {
            openEditWorkerModal(employerId)
            setTypeId(fileId);
            setTypeModal("Пакет документів на прогул");
            setIndexTab();
          })
        }}
      >
        {({values, handleChange, handleSubmit, setFieldValue}) => {
          const isValid = values.date && values.number
          return <div className="conditions__workdoc">
            <ConditionsHeader toggle={toggleTruancy__2}>
              Оформлення прогулу
            </ConditionsHeader>
            <Divider></Divider>
            <div className="conditions__content ">
              <div className="conditions__content-left">
                <p>Дата відсутності</p>
                <p>Наказ №</p>
              </div>
              <div className="conditions__content-right">
                <div className="conditions__form">
                  <div className="conditions__row">
                    <InputMask
                      mask={'99.99.9999'}
                      maskChar={''}
                      value={values.date}
                      id={'date'}
                      onChange={handleChange}
                      type="text"
                      tabIndex="4"
                      className="conditions__time-input"
                    />
                    <div className={'pos-rel'}>
                      <button className="item__input__check"
                              type={'button'}
                              style={{paddingTop: "8px", marginBottom: '2px'}}>
                    <span>
                      <img src={datePicker}/>
                    </span>
                      </button>
                      <div style={{width: '250px'}} className={"table__dropdown__content"}>
                        <Calendar
                          value={beginDate}
                          onChange={(e) => {
                            setBeginDate(e)
                            setFieldValue('date', getDateAndTime(e, true))
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="conditions__row">
                    <InputForm
                      value={values.number}
                      onChange={handleChange}
                      id={'number'}
                      style={{
                        backgroundColor: props.settings?.number1 !== -1 ? 'rgb(242, 242, 242)' : ''
                      }}
                      disabled={props.settings?.number1 !== -1}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="conditions__buttons">
              {(isValid && !isFetching) ? <SButtonLightGreen onClick={handleSubmit}>Зберегти</SButtonLightGreen>
                : <SButtonLightGrey>Зберегти</SButtonLightGrey>}
            </div>
          </div>
        }}
      </Formik>}
    </>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settingsReducer.settings
})

KadriCardsTruancy__2 = connect(mapStateToProps, {acceptTruancy, getSettings})(KadriCardsTruancy__2)
