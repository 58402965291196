import {UPDATE_CONTROLLER_VISITOR} from "../../../../constants/urls";
import instanceController from "../../../instanceController";
import showAlert from "../../alerts/showAlert";
import loadControllerVisitor from "./load";

const updateControllerVisitor = (id, update, objectId) => async (dispatch) => {
  try {
    const response = await instanceController.post(UPDATE_CONTROLLER_VISITOR, {id, update, objectId})
    dispatch(loadControllerVisitor(objectId));
    return response;
  } catch (e) {
    dispatch(showAlert(e.message, 'error'))
  }
}

export default updateControllerVisitor;
