import {CREATE_CONTROLLER} from "../../../constants/urls";
import instanceController from "../../instanceController";
import showAlert from "../alerts/showAlert";
import loadController from "./loadController";

const createController = (update) => async (dispatch) => {
  try {
    const response = await instanceController.post(CREATE_CONTROLLER, update)
    dispatch(loadController());
    return response;
  } catch (e) {
      dispatch(showAlert(e.message, 'error'))
  }
}

export default createController;
