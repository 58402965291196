import React, { useState } from 'react';
import MiniButton from "../../../../../components/common/MiniButton/MiniButton";
import arrowLeft from '../../../../../assets/keyboard_arrow_left.svg';
import arrowRight from '../../../../../assets/keyboard_arrow_right.svg';

const Pagination = ({onChange, itemsPerPage, totalPagesToShow, itemsLength }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil((itemsLength || 1) / itemsPerPage);

  // Calculate the range of pagination links to display
  const getPageRange = () => {
    const halfTotalPages = Math.floor(totalPagesToShow / 2);
    let start = currentPage - halfTotalPages;
    let end = currentPage + halfTotalPages;

    if (start < 1) {
      start = 1;
      end = totalPagesToShow;
    }

    if (end > totalPages) {
      end = totalPages;
      start = Math.max(1, end - totalPagesToShow + 1);
    }

    return Array.from({ length: end - start + 1 }, (_, i) => i + start);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    onChange(page);
  };

  const handlePrevPage = () => {
    const prevPage = Math.max(currentPage - 1, 1);
    setCurrentPage(prevPage)
    onChange(prevPage);
  };

  const handleNextPage = () => {
    const nextPage = Math.min(currentPage + 1, totalPages);
    setCurrentPage(nextPage)
    onChange(nextPage);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <MiniButton disabled={false} icon={arrowLeft} style={{fontSize: '32px'}} type='edit' onClick={handlePrevPage}/>
      {getPageRange().map((page) => (
        <button
          className="pagination_item"
          key={page}
          onClick={() => handlePageChange(page)}
          disabled={currentPage === page}
        >
          {page}
        </button>
      ))}
      <MiniButton disabled={false} icon={arrowRight} style={{fontSize: '32px'}} type='edit' onClick={handleNextPage}/>
    </div>
  );
};

export default Pagination;
