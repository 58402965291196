import { StartCardDescription } from "./StartCardDescription"
import React from 'react'
const StartCardSwitches = props => {

    const { switches } = props

    const switchStyle = {
        height: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px'
    }

    const containerStyle = {
        // height: 'auto',
        // display: 'flex',
        // justifyContent: 'space-between',
        // alignItems: 'center',
        margin: '-12px 55px 47px 0'
    }

    const getSwitchesDOM = () => {
        return switches.map((el) => {
            return (
                <div style={switchStyle}>
                    <StartCardDescription style={{margin: 0}}>
                        {el.title}
                    </StartCardDescription>
                    <label class="checkbox-google">
                        <input
                            type="checkbox"
                            checked={el.checked}
                            onChange={el.func}
                        />
                        <span class="checkbox-google-switch"></span>
                    </label>
                </div>
            )
        })
    }

    return(
        <div style={containerStyle}>
            {getSwitchesDOM()}
        </div>
    )
}

export default StartCardSwitches