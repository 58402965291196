import {
  GET_MY_USERS,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  GET_JKS_RECORDS_HISTORY,
  CREATE_JKS_SIGNATURE,
  CREATE_JKS_DOCUMENT,
  DELETE_JKS_RECORD,
  UPDATE_JKS_DOCUMENT,
  GET_JKS_RECEIVER,
} from "../constants/urls";
import { getCookie } from "../utilits/cookies";

export const createJksSignature = async (file, jksPass) => {
  var formdata = new FormData();
  formdata.append("key", file);
  formdata.append("password", jksPass);

  console.log(CREATE_JKS_SIGNATURE);

  const response = await fetch(CREATE_JKS_SIGNATURE, {
    method: "POST",
    body: formdata,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
  const data = await response.json();

  return data?.signature;
};

export const getJksReceiver = async (signature, year) => {
  const token = getCookie("token");

  var formdata = new FormData();
  formdata.append("signature", signature);
  formdata.append("year", year);

  const response = await fetch(GET_JKS_RECEIVER, {
    method: "POST",
    body: formdata,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
    },
  });
  const data = await response.json();

  return data?.data || [];
};

export const updateJksDocument = async ({ name, code, receiver, signed }) => {
  const token = getCookie("token");

  var formdata = new FormData();
  if (name) {
    formdata.append("name", name);
  }
  formdata.append("code", code);
  formdata.append("receiver", receiver);
  formdata.append("signed", signed || false);

  const response = await fetch(UPDATE_JKS_DOCUMENT, {
    method: "POST",
    body: formdata,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
    },
  });
  const data = await response.json();

  return data;
};

export const createJksDocument = async ({ name, code, receiver }) => {
  const token = getCookie("token");

  var formdata = new FormData();
  formdata.append("name", name);
  formdata.append("code", code);
  formdata.append("receiver", receiver);

  const response = await fetch(CREATE_JKS_DOCUMENT, {
    method: "POST",
    body: formdata,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
    },
  });
  const data = await response.json();

  return data;
};

export const deleteJksDocument = async (code) => {
  const token = getCookie("token");
  var formdata = new FormData();
  formdata.append("code", code);

  const response = await fetch(DELETE_JKS_RECORD, {
    method: "POST",
    body: formdata,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
    },
  });
  const data = await response.json();

  return data;
};

export const getUsers = async () => {
  const token = getCookie("token");
  const response = await fetch(GET_MY_USERS, {
    method: "POST",
    headers: {
      Authorization: token,
    },
  });
  return await response.json();
};

// page, limit, date, name
export const getJksRecordsHistory = async (params) => {
  const token = getCookie("token");
  const queryString = new URLSearchParams(params).toString();

  const url = `${GET_JKS_RECORDS_HISTORY}?${queryString}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });
  return await response.json();
};

export const createUser = async (data) => {
  const token = getCookie("token");
  const response = await fetch(CREATE_USER, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  return await response.json();
};

export const updateUser = async (id, update) => {
  const token = getCookie("token");
  const response = await fetch(UPDATE_USER, {
    method: "POST",
    body: JSON.stringify({ id, update }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  return await response.json();
};

export const deleteUser = async (id) => {
  const token = getCookie("token");
  const response = await fetch(DELETE_USER, {
    method: "POST",
    body: JSON.stringify({ id }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  return await response.json();
};
