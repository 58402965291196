import React, { useState } from "react";
import "./EmployersInfo.css";

import reloadIcon from "../../../images/icons/reload.svg";
import { getConfig } from "@testing-library/react";
import useHttp from "../../../hooks/http.hook";
import { CHANGE_WORKER_URL } from "../../../constants/urls";
import Modal from "../modalComponents/Modal/Modal";
import RefreshButton from "../../buttons/RefreshButton/RefreshButton";
import { getListOfActivities } from "../../../utilits/getListOfActivities";
import { getCorrectFormatOfDate } from "../../../utilits/getCorrectFormatOfDate";
import { useSelector } from "react-redux";
import { getCookie } from "../../../utilits/cookies";

const EmployersInfo = (props) => {
  console.log('props.data', props.data)
  const {toggleFunc, data, changeFunc, getWorkersFunc} = props

  const getRefreshButtonStatus = () => {
    const currentTime = new Date()
    const updateTime = new Date(data.updatedAt)
    return `${currentTime.getDate()}${currentTime.getMonth()}${currentTime.getFullYear()}` !== `${updateTime.getDate()}${updateTime.getMonth()}${updateTime.getFullYear()}` //обновлять пользователя можно раз в календарный день
  }

  const [ buttonStatus, setButtonStatus ] = useState(getRefreshButtonStatus())

  console.log(data)
  const token = getCookie('token')
  const { loading, request, error, clearError } = useHttp()

  const changeWorker = async (rnokpp) => {
    try {
      const data = await request(CHANGE_WORKER_URL, 'POST', { "rnokpp":`${rnokpp}` }, {Authorization: token})
      if (data.message === 'Работодатель обновлён') {
        setButtonStatus(false)
      }
      getWorkersFunc()
      console.log(data)
    } catch (e) {
      console.log(e)
    } finally {
      // setWorkersData([1])
    }
  }

  return (
    <Modal title='Інформація про роботодаця' toggleFunc={toggleFunc}>
      <div className='EmployersInfo__content'>
        <>
          <p className='EmployersInfo__text'>Роботодавець</p>
          <p className='EmployersInfo__text'>{data.userInfO.full_name}</p>
        </><>
          <p className='EmployersInfo__text'>РНОКПП</p>
          <p className='EmployersInfo__text'>{data.rnokpp}</p>
        </><>
          <p className='EmployersInfo__text'>Адреса</p>
          <p className='EmployersInfo__text'>{data.userInfO.location}</p>
        </><>
          <p className='EmployersInfo__text'>Статус реєстрації</p>
          <p className='EmployersInfo__text'>{data.userInfO.status}</p>
        </><>
          <p className='EmployersInfo__text'>Види економічної діяльності</p>
          <div className='EmployersInfo__text-container'>
            {data.userInfO.activity_kinds.map((el) => {
              return <p className='EmployersInfo__text'>{`${el.code} ${el.name}`}</p>
            })}
          </div>
        </><>
          <p className='EmployersInfo__text'>Податковий борг</p>
          <p className='EmployersInfo__text'></p>
        </><>
          <p className='EmployersInfo__text'>Статус платника ПДВ або ЄН</p>
          <p className='EmployersInfo__text'></p>
        </><>
          <p className='EmployersInfo__text'>Дата реєстрації</p>
          <p className='EmployersInfo__text'>{getCorrectFormatOfDate(data.userInfO.registration_date)}</p>
        </>
      </div>
      <div className='EmployersInfo__content' style={{marginTop: '37px'}}>
          <p className='EmployersInfo__date'>Данi</p>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <p className='EmployersInfo__date'>{`Станом на ${getCorrectFormatOfDate(data.updatedAt)}`}</p>
            <RefreshButton
              disabled={!buttonStatus}
              onClick={() => {changeWorker(data.rnokpp)}}
            />
          </div>
      </div>
    </Modal>
  );
};

export default EmployersInfo
