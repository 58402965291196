import moment from "moment";

const dateRangeSubtract = (format, subtract = 1, days = 30, type = "day") => {
  // Получаем текущую дату
  let currentDate = moment();

  let dates = [];
   
  for (let i = 0; i < days; i++) {
    dates.push(currentDate.format(format));
    currentDate.subtract(subtract, type);
  }

  return dates;
}

export default dateRangeSubtract;