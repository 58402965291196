import styled from "styled-components";

const InfoBlock = styled.div`
    width: 434px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 15%);
    color: #313131;
    font-size: 13px;
    font-weight: 400;
    background-color: #fff;
    margin-left: 8px;
    padding: 12px 30px 15px;
    box-sizing: border-box;
    @media (max-width: 1155px) {
      width: 390px;
     }
`

export default InfoBlock