import close from "../../../../../assets/close.svg";
import Modal from "../../../../../components/modals/modalComponents/Modal/Modal";
import "./styled.css";
import PlacesAutocomplete from "../../../../../components/googlePlaceInput/googlePlaceInput";
import { InputForm } from "../../../../../styledComponents/Inputs";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { createPersonal, updatePersonal } from "../../../../../api/personal";
import { useFormik } from "formik";
import { MButtonGreen } from "../../../../../styledComponents/Buttons";
import { useAlert } from "react-alert";

const Practitioners = ({
  updateDataPersonal,
  setEditPersonal,
  editPersonal,
  objectId,
}) => {
  const alert = useAlert();
  const [disabled, setDisabled] = useState(false);
  const savePersonal = async (personalData) => {
    try {
      const { _id, ...update } = personalData;
      let response;
      if (_id) {
        response = await updatePersonal(_id, update);
      } else {
        response = await createPersonal(update);
      }
      if (!response.success) {
        throw new Error(response.message || "Щось не так");
      }
      setEditPersonal(null);
      updateDataPersonal();
      alert.show("Оновлено", { type: "success" });
    } catch (error) {
      alert.show(error.message, { type: "error" });
    }
  };
  const validationSchema = Yup.object().shape({
    lastname: Yup.string()
      .max(200, "Досягнуто ліміт символів")
      .required("Вкажіть прізвище"),
    surname: Yup.string()
      .max(200, "Досягнуто ліміт символів")
      .required("Вкажіть по батькові"),
    name: Yup.string()
      .max(200, "Досягнуто ліміт символів")
      .required("Вкажіть ім’я"),
    houseNumber: Yup.string()
      .max(200, "Досягнуто ліміт символів")
      .required("Вкажіть номер будинку"),
    address: Yup.string()
      .max(200, "Досягнуто ліміт символів")
      .required("Вкажіть адресу"),
  });
  const {
    values,
    handleSubmit,
    setFieldValue,
    handleChange,
    touched,
    errors,
    setValues,
    handleBlur,
  } = useFormik({
    initialValues: editPersonal,
    validationSchema,
    onSubmit: () => {
      values.employerId = objectId;
      savePersonal(values);
    },
  });

  const ErrorMessage = ({ name }) => {
    return errors[name] && touched[name] ? (
      <div className="error">{errors[name]}</div>
    ) : null;
  };
  const toggleModal = () => {
    setEditPersonal(null);
  };

  useEffect(() => {
    values.place &&
      values.place?.street &&
      values.place.street.length >= 1 &&
      setFieldValue(
        "address",
        Object.values({
          region_up: values.place.region_up,
          region: values.place.region,
          oblast: values.place.oblast,
          city: values.place.city,
          street: values.place.street,
        })
          .filter((el) => !!el)
          .join(", ")
      );

    console.log(values.place, "values.place?.street");

    if (
      values.place &&
      values.place?.city &&
      values.place?.street &&
      values.place?.street.length >= 1
    ) {
      validationSchema.isValidSync(values)
        ? setDisabled(false)
        : setDisabled(true);
    } else {
      setDisabled(true);
    }
  }, [values]);
  useEffect(() => {
    setValues(editPersonal);
  }, [editPersonal]);

  return (
    <Modal
      title="Дані підрядника"
      closeIcon={close}
      className="edit_personal"
      toggleFunc={toggleModal}
      divider={false}
    >
      <form
        action="#"
        method="post"
        className="edit_personal__form"
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <div className="edit_personal__row">
          <span>Персональні дані</span>
          <div className="edit_personal__col edit_personal__info">
            <div className="edit_personal__input">
              <label htmlFor="lastname">Прізвище</label>
              <div className="edit__wrapper">
                <InputForm
                  placeholder="Введіть прізвище"
                  value={values.lastname}
                  name="lastname"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="lastname" />
              </div>
            </div>
            <div className="edit_personal__input">
              <label htmlFor="name">Ім’я</label>
              <div className="edit__wrapper">
                <InputForm
                  placeholder="Введіть ім'я"
                  value={values.name}
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="name" />
              </div>
            </div>
            <div className="edit_personal__input">
              <label htmlFor="surname">По батькові</label>
              <div className="edit__wrapper">
                <InputForm
                  placeholder="Введіть по батькові"
                  value={values.surname}
                  name="surname"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="surname" />
              </div>
            </div>
          </div>
        </div>
        <div className="edit_personal__row">
          <span>Адреса місця реєстрації або проживання</span>
          <div className="edit_personal__col edit_personal__address">
            <div className="edit_personal__input edit_personal__input-address">
              <div className="edit__wrapper">
                <PlacesAutocomplete
                  placeholder="Введіть назву вулиці"
                  place={values.place}
                  values={values}
                  name="address"
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                />
                <ErrorMessage name="address" />
              </div>
            </div>
            <div className="edit_personal__input">
              <label htmlFor="houseNumber">Будинок</label>
              <div className="edit__wrapper">
                <InputForm
                  placeholder="Введіть №"
                  value={values.houseNumber}
                  name="houseNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="houseNumber" />
              </div>
            </div>
            <div className="edit_personal__input">
              <label htmlFor="flat">Квартира</label>
              <div className="edit__wrapper">
                <InputForm
                  placeholder="Введіть №"
                  value={values.flat}
                  name="flat"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          </div>
        </div>

        <MButtonGreen
          className={disabled && "disabled-btn"}
          disabled={disabled}
          style={{
            margin: 0,
            marginLeft: "auto",
            marginTop: 70,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          type="submit"
        >
          Зберегти
        </MButtonGreen>
      </form>
    </Modal>
  );
};
export default Practitioners;
