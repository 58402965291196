import moment from "moment";

export const diffMinutes = (endTime, startTime) => {
  let start = moment(new Date(startTime));
  let end = moment(new Date(endTime));
  let duration = moment.duration(end.diff(start));
  console.log(duration.asMinutes());
  return duration.asMinutes();
};

export const getTimeFromMins = (mins) => {
  let hours = Math.trunc(mins / 60);
  let minutes = mins % 60;
  return hours + ":" + minutes;
};
export const sumArray = (arr) => arr.reduce((acc, num) => acc + num, 0);