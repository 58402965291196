import {useState, useCallback} from 'react'
import { useSelector } from 'react-redux'
import { getCookie } from '../utilits/cookies'

const useHttp = () => {

  const [ state, setState ] = useState({
    data: null,
    ok: false
  })

  const [error, setError] = useState(null)
  const token = useSelector(state => {return state.auth.token}) || getCookie('token')

  const post = async (url, body = {}) => {
    try {
      const response = await fetch(
        url, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token
          }
        }
      )
      const data = await response.json()
      if (!response.ok) {
        throw new Error(data.message || 'Щось пішло не так')
      }
      return {data: data, ok: response.ok}
    } catch (error) {
      return {data: error.toString(), ok: false}
    }
  }

  const upload = async (url, body = {}) => {
    try {
      const response = await fetch(
        url, {
          method: 'POST',
          body: body,
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': token
          }
        }
      )
      const data = await response.json()
      if (!response.ok) {
        throw new Error(data.message || 'Щось пішло не так')
      }
      return {data: data, ok: response.ok}
    } catch (error) {
      return {data: error.toString(), ok: false}
    }
  }



  const request = async (url, method = 'GET', body = null, headers = {}) => {

    try {
      if (body) {
        body = JSON.stringify(body)
        headers['Content-Type'] = 'application/json'
      }

      const response = await fetch(url, {method, body, headers})
      const data = await response.json()

      if (!response.ok) {
        throw new Error(data.message || 'Щось пішло не так')
      }

      return data

    } catch (e) {

      setError(e.message)
      throw e

    } finally {

    }
  }

  const clearError = useCallback(() => setError(null), [])

  return { state, error, clearError, post, request, upload }
}

export default useHttp