import React, { useState } from "react";
import { Link } from "react-router-dom";

import settings from "../../../../assets/settings.svg";
import journal from "../../../../assets/journal.svg";
import editor from "../../../../assets/editor.svg";
import holidays from "../../../../assets/holidays.svg";
import reportCard from "../../../../assets/reportCard.svg";
import wage from "../../../../assets/wage.svg";
import jksdocument from '../../../../assets/jksdocument.svg';

import { Divider } from "../../../../styledComponents/Dividers";
import InfoBlock from "../../../../components/InfoBlock/InfoBlock";
import InfoHeader from "../../../../components/InfoBlock/InfoHeader";

import "./KadriCards.css";

export const KadriCardsDoc = props => {

  const {
    toggleSettingEdit,
    toggleDocModal,
    toggleJournalModal,
    toggleEditorModal,
    toggleWageModal,
    toggleWorkTimeModal,
    toggleJksDocumentModal
  } = props

  return (
    <div className="kadri__item__content">
      <InfoBlock>
        <InfoHeader buttonType='settings' onClick={toggleSettingEdit}>
          Кадрові документи
        </InfoHeader>
        <Divider/>
        <div style={{marginTop: '22px'}}>
          <button className="kadri__item__info__row" onClick={toggleJournalModal}>
            <img src={journal} />
            <p>Журнал наказів</p>
          </button>
          <button className="kadri__item__info__row" onClick={toggleDocModal}>
            <img src={holidays} />
            <p>Графік відпусток</p>
          </button>
          <button className="kadri__item__info__row" onClick={toggleWageModal}>
            <img src={wage} />
            <p>Заробітна плата</p>
          </button>
          <button className="kadri__item__info__row" onClick={toggleWorkTimeModal}>
            <img src={reportCard} />
            <p>Табель працівників</p>
          </button>
          <button className="kadri__item__info__row" onClick={toggleEditorModal}>
            <img src={editor} />
            <p> Редактор посад</p>
          </button>
          <button className="kadri__item__info__row" onClick={toggleJksDocumentModal}>
            <img src={jksdocument} />
            <p>Електронний документообіг</p>
          </button>
        </div>
      </InfoBlock>
    </div>
  );
};
