import styled from "styled-components";

export const StartCardDescription = styled.p`
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #172B4D;
    margin: 0 0 28px 0
`