import styled from "styled-components";

export const StartCardTitle = styled.h4`
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #172B4D;
    margin: 0 0 21px 0;
`