export const rolesTL = {
  ROLE_ADMIN: 'Адмінка',
  ROLE_RENT: 'Об\'єкт діяльності',
  ROLE_HR: 'Кадри',
  ROLE_CONTROLLER: 'Контролер',
  ROLE_STORE: 'Магазин',
  ROLE_STATISTIC: 'Статистика',
};

export default rolesTL;
