import {GET_CONTROLLER_VISITOR} from "../../../../constants/urls";
import {LOAD_CONTROLLER_VISITOR, LOAD_INFO_CONTROLLER} from "../../../types";
import instanceController from "../../../instanceController";
import showAlert from "../../alerts/showAlert";

const loadControllerVisitor = (objectId) => async (dispatch) => {

  try {
    const response = await instanceController.post(GET_CONTROLLER_VISITOR, {objectId: objectId})
    dispatch({
      type: LOAD_CONTROLLER_VISITOR,
      payload: response.data
    })
    return response.data
  } catch (e) {
    dispatch({
      type: LOAD_INFO_CONTROLLER,
      payload: null
    })
    dispatch(showAlert(e.message, 'error'))
  }
}

export default loadControllerVisitor
