import {GET_CONTROLLER} from "../../../constants/urls";
import {LOAD_CONTROLLER} from "../../types";
import instanceController from "../../instanceController";
import showAlert from "../alerts/showAlert";

const loadController = () => async (dispatch) => {
  let response
  try {
    response = await instanceController.post(GET_CONTROLLER)
    dispatch({
      type: LOAD_CONTROLLER,
      payload: response.data
    })
    return response.data
  } catch (e) {
    dispatch(showAlert(e.message, 'error'))
  }
}

export default loadController
