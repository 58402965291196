import {getCookie} from "../utilits/cookies";
import {
  CREATE_PERSONAL,
  DELETE_PERSONAL,
  DOC_PERSONAL,
  EMPLOYER_PERSONAL_GETLIST, GET_ID_EMPLOYER_LENGTH,
  GET_PERSONAL,
  UPDATE_PERSONAL
} from "../constants/urls";
import axios from "axios";



export const getIdEmployerLength = async (data) => {
  const token = getCookie('token')
  const response = await fetch(GET_ID_EMPLOYER_LENGTH, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  });
  return await response.json();
};

export const createPersonal = async (data) => {
  const token = getCookie('token')
  const response = await fetch(CREATE_PERSONAL, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  });
  return await response.json();
};
export const getPersonal = async (id) => {
  const token = getCookie('token')
  const response = await fetch(GET_PERSONAL, {
    method: 'POST',
    body: JSON.stringify({id}),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  });
  return await response.json();
};

export const deletePersonal = async (id) => {
  const token = getCookie('token')
  const response = await fetch(DELETE_PERSONAL, {
    method: 'POST',
    body: JSON.stringify({id: id}),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  });
  return await response.json();
};
export const updatePersonal = async (id, update) => {
  const token = getCookie('token')
  const response = await fetch(UPDATE_PERSONAL, {
    method: 'POST',
    body: JSON.stringify({id, update}),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  });
  return await response.json();
};
export const getPersonalEmployerAll = async (data, accountant = undefined) => {
  const token = getCookie('token')
  const response = await axios.post(EMPLOYER_PERSONAL_GETLIST,{array:data,accountant:accountant}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  })
  return response.data;
}
export const docGenerated = async (data) => {
  const token = getCookie('token')
  const response = await fetch(DOC_PERSONAL, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  });
  return await response.json();
};
