//libs
import React, {useState} from "react";
import { useSelector } from "react-redux";
import { store } from "../../../index";
import { Formik, useFormik } from "formik";
//components
import Modal from '../modalComponents/Modal/Modal'
import ModalAcceptButton from "../../buttons/ModalAcceptButton/ModalAcceptbutton";
import Input from "../../Inputs/Input/Input";
//styles
import '../modalComponents/Modal/Modal.css'

const LoginWithGoogleAuth = props => {

  const { closeFunc, loginFunc } = props
  const [authenticator, setAuthenticator] = useState('')

  const changeHandler = (event) => {
    setAuthenticator(event.target.value)
  }

  const submitHandler = (event) => {
    event.preventDefault()
    loginFunc(authenticator)
  }

  return (
    <Modal title={`Введите код из приложения Google Authenticator`} toggleFunc={closeFunc}>
      <form onSubmit={submitHandler}>
        <div className='Modal__content'>
          <label className='Modal__text'>6-значный код</label>
          <Input 
            id="googleAuthenticator"
            name="googleAuthenticator"
            type="text"
            placeholder='Google Authenticator'
            onChange={changeHandler}
            value={authenticator}
          ></Input>
        </div>
        <div className='Modal__button-row'>
          <ModalAcceptButton type='submit'>Зберегти</ModalAcceptButton>
        </div>
      </form>
    </Modal>
  );
};

export default LoginWithGoogleAuth