import styled from "styled-components";

export const DividerKadriInfo = styled.div`
  width: 87%;
  margin: 0 auto;
  height: 0px;
  background: #d7d7d7;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #d7d7d7;
`;
