import styled from 'styled-components'
import { borderRadius } from '../../component-variables/variables'

const Button = styled.button.attrs(props => ({
    disabled: props.disabled || false
}))`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px 8px;
    min-width: 82px;
    border-radius: 3px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: ${props => props.disabled ? '#515151' : '#FFF'};
    box-sizing: border-box;
    cursor: pointer;
    transition: .2s ease;
    background: ${props => props.disabled ? '#F2F2F2' : '#009640'};
    border-radius: ${borderRadius};
    cursor: ${props => props.disabled ? 'auto' : 'pointer'};
    &:hover {
        opacity: .8;
    };
`

export default Button