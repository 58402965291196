//libs
import React, { useEffect, useState } from "react";
import { useAuth } from "../../hooks/auth.hook";
import { Login } from "./Login";

export const Logout = () => {

  const { logout } = useAuth() //logout

  useEffect(() => {
    logout()

  })

  return (
    <Login/>
  );
};
