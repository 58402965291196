import React from 'react';
import trash from "../../../../../../assets/trash.svg";
import InputMask from 'react-input-mask'

const KadriPageWorkRow = ({
                            onDelete,
                            setIsEdited,
                            index,
                            dateDismissal,
                            dateDismissalId,
                            position,
                            positionId,
                            reason,
                            reasonId,
                            worker,
                            workerId,
                            handleChange
                          }) => {
  return (
    <tr className="tr-lg">
      <td>{index + 1}</td>
      <td style={{width: "177px"}}>
        <p>Назва роботодавця</p>
        <input value={worker} id={workerId} onChange={(event) => {
          handleChange(event);
          setIsEdited(true)
        }} type="text"/>
      </td>
      <td>
        <p>Посада</p>
        <input value={position} id={positionId} onChange={(event) => {
          handleChange(event);
          setIsEdited(true)
        }} type="text"/>
      </td>
      <td className="table-md">
        <p>Причина звільнення</p>
        <select value={reason} id={reasonId} onChange={(event) => {
          handleChange(event);
          setIsEdited(true)
        }}>
          <option value="" disabled selected hidden>Виберіть зі списку</option>
          <option value="Ініціатива працівника">Ініціатива працівника</option>
          <option value="Ініціатива роботодавця">Ініціатива роботодавця</option>
          <option value="Угода сторін">Угода сторін</option>
        </select>
      </td>
      <td className="table-sm">
        <p>Дата звільнення</p>
        <InputMask mask={'99.99.9999'} maskChar={''} value={dateDismissal}
                   id={dateDismissalId} onChange={(event) => {
          handleChange(event);
          setIsEdited(true)
        }} type="text"/>
      </td>
      <td
        className="quota__row__table__trash"
        style={{width: "32px", height: "45px"}}
      >
        <button onClick={onDelete}>
          <img src={trash}/>
        </button>
      </td>
    </tr>
  );
};

export default KadriPageWorkRow;
