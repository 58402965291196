import DatePicker from "react-datepicker";
import "./style.css"
import uk from "date-fns/locale/uk";

const DateMonth = (props) => {
  return (
    <DatePicker
      locale={uk}
      {...props}
      dateFormat="MM/yyyy"
      // excludeDates={[1661990400000, 1664582400000, 1667260800000, 1672531200000]}
      showMonthYearPicker
    />
  );
}
export default DateMonth;