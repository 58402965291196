import styled from "styled-components";
import React from "react";

export const SearchInput = styled.input`
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 17px;
  font-size: 14px;
  line-height: 16px;
  color: #535353;
  transition: 0.2s ease;
  position: relative;
  &:focus {
    border: 3px solid #56ccf2;
  }
`;

const InputStyle = styled.input`
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 4px;
  height: 32px;
  padding: 10px 17px;
  font-size: 13px;
  line-height: 16px;
  color: black;
  transition: 0.2s ease;
  &:hover {
    border-color: #a7a7a7;
  }
  &:disabled {
    background-color: #f2f2f2 !important;
    color: black;
    pointer-events: none;
  }

  &::placeholder {
    color: #BFB5BD
  }
  &.errorBox{
    border-color: red;
  }
  & + .table__dropdown__content {
    top: 50px;
    max-height: 130px;
    overflow: auto;
    width: 100% !important;
  }
  &:focus + .table__dropdown__content {
    display: block;
  }
`;

export const InputForm = (props) => {
  return <InputStyle type="text" autoComplete="off"  {...props} />
}

export const SelectForm = styled.select`
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 4px;
  height: 32px;
  padding: 4px 10px;
  font-size: 13px;
  line-height: 16px;
  color: black;
  transition: 0.2s ease;
  outline: none;

 
  &:hover {
    border-color: #a7a7a7;
  }
`;
