import React from 'react';
import trash from "../../../../../../assets/trash.svg";
import {BASE_EDUCATION, PROF_EDUCATION, SECOND_EDUCATION} from "../../../../../../redux/types";
import InputMask from 'react-input-mask'

const KadriPageEducationRow = ({
                                 index,
                                 item,
                                 setIsEdited,
                                 educationRows,
                                 setEducationRows,
                                 atestat,
                                 power,
                                 qualification,
                                 speciality,
                                 title,
                                 yearRelease,
                                 handleChange,
                                 atestatId,
                                 powerId,
                                 qualificationId,
                                 specialityId,
                                 titleId,
                                 yearReleaseId,
                                 setFieldValue
                               }) => {
  return (
    <tr className="tr-lg">
      <td>{index + 1}</td>
      <td>
        <p>Вид осіти</p>
        <span>{item.education}</span>
      </td>
      <td style={{width: '220px'}}>
        <p>Назва освітнього закладу</p>
        <input value={title} onChange={(event) => {
          handleChange(event);
          setIsEdited(true)
        }}  id={titleId} type="text"/>
      </td>
      <td className="table-md">
        <p>{item.education === 'базова загальна середня' ? 'Свідоцтво (атестат)' : 'Диплом'}</p>
        <input value={atestat} onChange={(event) => {
          handleChange(event);
          setIsEdited(true)
        }}  id={atestatId} type="text"
               placeholder={item.education === 'базова загальна середня' ? 'АА - 0000000' : ''}/>
      </td>
      <td style={{width: '90px'}} className="table-sm">
        <p>Рік закінчення</p>
        <InputMask mask={'9999'} maskChar={''}
                   value={yearRelease} onChange={(event) => {
          handleChange(event);
          setIsEdited(true)
        }}  id={yearReleaseId} type="text"/>
      </td>
      {item.type === SECOND_EDUCATION && <td className="table-md">
        <p>Спеціальність</p>
        <input value={speciality} onChange={(event) => {
          handleChange(event);
          setIsEdited(true)
        }}  id={specialityId} type="text"/>
      </td>}
      {item.type === SECOND_EDUCATION && <td className="table-md">
        <p>Кваліфікація</p>
        <input value={qualification} onChange={(event) => {
          handleChange(event);
          setIsEdited(true)
        }}  id={qualificationId} type="text"/>
      </td>}
      {item.type === PROF_EDUCATION && <td className="table-md">
        <p>Наукова ступінь</p>
        <input value={power} onChange={(event) => {
          handleChange(event);
          setIsEdited(true)
        }}  id={powerId} type="text"/>
      </td>}
      <td
        className="quota__row__table__trash"
        style={{width: "32px", height: "45px"}}
      >
        <button onClick={() => {
          setIsEdited(false)
          setEducationRows([...educationRows].filter((_, i) => i !== index))
        }}>
          <img src={trash}/>
        </button>
      </td>
    </tr>
  );
};

export default React.memo(KadriPageEducationRow)
