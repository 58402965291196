import React from "react";
import {Popup} from "../../styledComponents/PopUps";
import {SButtonGreenBorder} from "../../styledComponents/Buttons";

export const InfoPopUp = (props) => {
  return (
    <Popup>
      <svg
        width="100"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M50 0L37.9276 50L50 100C77.6143 100 100 77.6143 100 50C100 22.3857 77.6143 0 50 0Z"
          fill="#54BBFF"
        />
        <path
          d="M0 50C0 77.6143 22.3857 100 50 100V0C22.3857 0 0 22.3857 0 50Z"
          fill="#8DD2FF"
        />
        <path
          d="M50 6.03613V93.9637C74.2805 93.9637 93.9639 74.2805 93.9639 49.9998C93.9639 25.7191 74.2805 6.03613 50 6.03613Z"
          fill="#0789DE"
        />
        <path
          d="M87.9275 50C87.9275 25.7195 70.9469 6.03613 50 6.03613C25.7195 6.03613 6.03613 25.7195 6.03613 50C6.03613 74.2805 25.7195 93.9639 50 93.9639C70.9469 93.9639 87.9275 74.2805 87.9275 50Z"
          fill="#2EA2EF"
        />
        <path
          d="M50 30.7848C52.2225 30.7848 54.0242 28.9831 54.0242 26.7605C54.0242 24.538 52.2225 22.7363 50 22.7363C47.7775 22.7363 45.9758 24.538 45.9758 26.7605C45.9758 28.9831 47.7775 30.7848 50 30.7848Z"
          fill="white"
        />
        <path
          d="M50 36.8209C47.7775 36.8209 45.9758 38.6225 45.9758 40.8451V73.2395C45.9758 75.4619 47.7773 77.2637 50 77.2637C52.2224 77.2637 54.0242 75.4621 54.0242 73.2395V40.8451C54.0242 38.6225 52.2224 36.8209 50 36.8209Z"
          fill="white"
        />
      </svg>
      <p>{props.message}</p>
      <SButtonGreenBorder onClick={() => props.setInfoModal(false)}>Ok</SButtonGreenBorder>
    </Popup>
  );
};
