import {
  LOAD_USER_DATA,
  EDIT_USER_PASSWORD,
  DELETED_DATE
} from "../types"

const initialUser = {}

export const userReducer = (state = initialUser, action) => {
  switch (action.type) {
    case LOAD_USER_DATA:
      return action.payload;
    case EDIT_USER_PASSWORD:
      return action.payload;
    case DELETED_DATE:
      return null;
    default:
      return state
  }
}
