//components
import React, { useState } from "react";
import StartCard from "./components/StartCard";
//style
import "./Start.css";
import "./components/StartCard.css";
//modals
import UserEdit from "../../components/modals/UserEdit/UserEdit";
import ChangeEmail from "../../components/modals/EnterNewEmail/EnterNewEmail";
import EnterConfirmCode from "../../components/modals/EnterConfirmCode/EnterConfirmCode";
import ChangePassword from "../../components/modals/ChangePassword/ChangePassword";
import GoogleAuth from "../../components/modals/GoogleAuth/GoogleAuth";
import ChangeEmailStatus from "../../components/modals/ChangeEmailStatus/ChangeEmailStatus";
import useHttp from "../../hooks/http.hook";
import {
  ADD_GOOGLE_2FA,
  GET_TELEGRAM_LINK,
  SEND_CONFIRMATION_CODE,
} from "../../constants/urls";
import { StartCardTitle } from "./components/StartCardTitle";
import { StartCardSwitchContainer } from "./components/StartCardSwitchContainer";
import { StartCardSubtitle } from "./components/StartCardSubtitle";
import { StartCardSwitch } from "./components/StartCardSwitch";
import { StartCardDescription } from "./components/StartCardDescription";
import { StartButton, StartYellowButton } from "./components/StartButton";
import TelegramLink from "../../components/modals/TelegramLink/TelegramLink";
import { useAlert } from "react-alert";
import { connect, useSelector } from "react-redux";
import DeleteTwoStepAuth from "../../components/modals/DeleteTwoStepAuth/DeleteTwoStepAuth";
import ChangeConfirmedEmail from "../../components/modals/ChangeConfirmedEmail/ChangeConfirmedEmail";
import { editNotificationSettings } from "../../redux/user/user-thunks";
import { store } from "../../index";
import { setUserData } from "../../redux/user/user-actions";

const Start = (props) => {
  const { post } = useHttp();
  const alert = useAlert();

  const user = useSelector((state) => {
    return state.user;
  });

  const [modals, setModals] = useState({
    userEdit: false,
    enterNewEmail: false,
    confirmationCode: false,
    changePassword: false,
    googleAuth: false,
    telegramLinkModal: false,
    deleteTwoStepModal: false,
    changeConfirmedEmail: false,
  });

  const closeModal = (name) => {
    setModals((prev) => ({ ...prev, [name]: false }));
  };
  const openModal = (name) => {
    setModals((prev) => ({ ...prev, [name]: true }));
  };
  const alertFunc = (message, type) => {
    alert.show(message, { type: type });
  };

  const [userEmail, setUserEmail] = useState(user && user.email);
  const [googleData, setGoogleData] = useState(null);
  const [telegramLink, setTelegramLink] = useState(null);

  const changeEmailStatus = async () => {
    try {
      const request = await post(SEND_CONFIRMATION_CODE);
      if (request.ok) {
        openModal("changeEmailStatusModal");
      } else {
        throw new Error(request.data || "Щось пішло не так");
      }
    } catch (e) {
      alert.show(e.message, { type: "error" });
    }
  };

  const changeConfirmedEmail = async () => {
    try {
      const request = await post(SEND_CONFIRMATION_CODE);
      if (request.ok) {
        openModal("changeConfirmedEmail");
      } else {
        throw Error(request.data || "Щось пішло не так");
      }
    } catch (e) {
      alert.show(e.message, { type: "error" });
    }
  };

  const addTwoStepAuth = async () => {
    try {
      const request = await post(ADD_GOOGLE_2FA);
      if (request.ok) {
        setGoogleData(request.data);
        openModal("googleAuth");
      } else throw Error(request.data || "Щось пішло не так");
    } catch (e) {
      alert.show(e.message, { type: "error" });
    }
  };

  const addTelegram = async () => {
    try {
      const request = await post(GET_TELEGRAM_LINK);
      if (request.data.link) {
        window.open("https://t.me/ECZahidBot", "_blank");
        setTelegramLink("https://t.me/ECZahidBot");
      } else throw Error(request.data || "Щось пішло не так");
    } catch (e) {
      alert.show(e.message, { type: "error" });
    }
  };

  const saveNotificationSettings = async () => {
    store.dispatch(
      editNotificationSettings(
        {
          NotificationEmail: user.NotificationEmail,
          NotificationTelegram: user.NotificationTelegram,
        },
        alertFunc
      )
    );
  };

  const toggleNotifications = async (event) => {
    props.setUserData({ ...user, [event.target.name]: !!event.target.checked });
  };

  return (
    <div className="Start">
      <StartCard
        title="Особиста інформація"
        subtitle={user && `Ім'я: ${user.firstName}, прізвище: ${user.lastName}`}
        description="Використовується для безпеки вашого акаунта і підтвердження особи"
        buttonText="Заповнити"
        note="Потрібно заповнити*"
        buttonFunc={() => {
          openModal("userEdit");
        }}
      />
      {/*{user.email &&*/}
      {/*<div className='StartCard'>*/}
      {/*  <StartCardTitle>{user && `Почта: ${user.email}, ${user.emailConfirmed ? 'подтверждена' : 'не подтверждена'}`}</StartCardTitle>*/}
      {/*  <StartCardSubtitle>Вы всегда можете сменить почту</StartCardSubtitle>*/}
      {/*  <StartCardDescription>Используется в случае подазрительной активности на сайте, в целях безопасности или при*/}
      {/*    утере старой почты</StartCardDescription>*/}
      {/*  <div className='StartCard__button-row'>*/}
      {/*    {user && !user.emailConfirmed ?*/}
      {/*      <StartYellowButton onClick={changeEmailStatus}>Подтвердить почту</StartYellowButton> : null}*/}
      {/*    {user && !user.emailConfirmed ? <StartButton onClick={() => {*/}
      {/*      openModal('enterNewEmail')*/}
      {/*    }}>Изменить</StartButton> : null}*/}
      {/*    {user && user.emailConfirmed ? <StartButton onClick={changeConfirmedEmail}>Изменить</StartButton> : null}*/}
      {/*    {user && user.emailConfirmed ?*/}
      {/*      <StartButton onClick={changeEmailStatus}>Открепить почту</StartButton> : null}*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*}*/}
      <div className="StartCard">
        <StartCardTitle>Двофакторна аутентифікація</StartCardTitle>
        <StartCardSubtitle>Google Authenticator / Authy</StartCardSubtitle>
        <StartCardDescription>
          Використовується для виведення коштів і зміни налаштувань безпеки
        </StartCardDescription>
        <div className="StartCard__button-row">
          {user && !user.googleAuthenticatorConfirmed ? (
            <StartYellowButton onClick={addTwoStepAuth}>
              Активувати
            </StartYellowButton>
          ) : null}
          {user && user.googleAuthenticatorConfirmed ? (
            <StartButton
              onClick={() => {
                openModal("deleteTwoStepModal");
              }}
            >
              Деактивувати
            </StartButton>
          ) : null}
        </div>
      </div>
      <StartCard
        title="Пароль"
        subtitle="ви завжди можете змінити пароль"
        description="Використовується у випадку підозрілої активності на сайті або з метою безпеки"
        buttonText="Змінити"
        buttonFunc={() => {
          openModal("changePassword");
        }}
      />
      <div className="StartCard">
        <StartCardTitle>Телеграм</StartCardTitle>
        <StartCardDescription>
          Прив'язати телеграм-акаунт для отримання повідомлень
        </StartCardDescription>

        <div className="StartCard__button-row">
          {user && !user.telegram ? (
            <StartYellowButton onClick={addTelegram}>
              Прив'язати
            </StartYellowButton>
          ) : null}
          {user && user.telegram ? (
            <StartButton onClick={addTelegram}>Деактивувати</StartButton>
          ) : null}
        </div>
      </div>

      <div className="StartCard">
        <StartCardTitle>Налаштування сповіщень</StartCardTitle>
        <StartCardSwitchContainer>
          {user.email && (
            <StartCardSwitch>
              <StartCardDescription style={{ margin: 0 }}>
                Отримувати повідомлення на пошту{" "}
              </StartCardDescription>
              <label className="checkbox-google">
                <input
                  type="checkbox"
                  id="NotificationEmail"
                  name="NotificationEmail"
                  checked={user && user.NotificationEmail}
                  onChange={toggleNotifications}
                />
                <span className="checkbox-google-switch"></span>
              </label>
            </StartCardSwitch>
          )}
          <StartCardSwitch>
            <StartCardDescription style={{ margin: 0 }}>
              Отримувати повідомлення в телеграм{" "}
            </StartCardDescription>
            <label className="checkbox-google">
              <input
                type="checkbox"
                id="NotificationTelegram"
                name="NotificationTelegram"
                checked={user && user.NotificationTelegram}
                onChange={toggleNotifications}
              />
              <span className="checkbox-google-switch"></span>
            </label>
          </StartCardSwitch>
        </StartCardSwitchContainer>
        <StartButton onClick={saveNotificationSettings}>Зберегти</StartButton>
      </div>

      {Object.values(modals).some((isOpened) => {
        return isOpened === true;
      }) ? (
        <div className="bg__layer"></div>
      ) : null}
      <div className="modals">
        {modals.userEdit && (
          <UserEdit
            closeFunc={() => {
              closeModal("userEdit");
            }}
          />
        )}
        {modals.enterNewEmail && (
          <ChangeEmail
            closeFunc={() => {
              closeModal("enterNewEmail");
            }}
            openModal={openModal}
            setEmail={setUserEmail}
          />
        )}
        {modals.deleteTwoStepModal && (
          <DeleteTwoStepAuth
            closeFunc={() => {
              closeModal("deleteTwoStepModal");
            }}
          />
        )}
        {modals.confirmationCode && (
          <EnterConfirmCode
            userEmail={userEmail || user.email}
            closeFunc={() => {
              closeModal("confirmationCode");
            }}
          />
        )}
        {modals.changePassword && (
          <ChangePassword
            closeFunc={() => {
              closeModal("changePassword");
            }}
          />
        )}
        {modals.googleAuth && (
          <GoogleAuth
            closeFunc={() => {
              closeModal("googleAuth");
            }}
            data={googleData}
          />
        )}
        {modals.telegramLinkModal && (
          <TelegramLink
            closeFunc={() => {
              closeModal("telegramLinkModal");
            }}
            link={telegramLink}
          />
        )}
        {modals.changeEmailStatusModal && (
          <ChangeEmailStatus
            closeFunc={() => {
              closeModal("changeEmailStatusModal");
            }}
            openModal={openModal}
            userEmail={userEmail || user.email}
          />
        )}
        {modals.changeConfirmedEmail && (
          <ChangeConfirmedEmail
            closeFunc={() => {
              closeModal("changeConfirmedEmail");
            }}
            openModal={openModal}
            userEmail={userEmail || user.email}
          />
        )}
      </div>
    </div>
  );
};

export default connect(null, { setUserData })(Start);
