//images
import closeIcon from "../../../../icons/close.svg";

const ConditionsHeader = props => {

    const { toggle } = props

    return (
        <div className="conditions__header">
            <p>{props.children}</p>
            <img
                src={closeIcon}
                alt=""
                onClick={toggle}
                className="conditions__close-icon"
            />
        </div>
    )
}

export default ConditionsHeader