import React, {useState} from "react";
import {InputForm, SelectForm} from "../../../../../styledComponents/Inputs";

import closeIcon from "../../../../../assets/closeGrey.svg";
import trash from "../../../../../assets/trash.svg";
import {SButtonGreen, SButtonLightGrey} from "../../../../../styledComponents/Buttons";
import {Formik} from "formik";
import {addPosition} from "../../../../../redux/cadrDocuments/cadrDocuments-thunks";
import {connect} from "react-redux";
import {useAlert} from "react-alert";
import week from "../../../../../constants/week";

export let KadriCardsEditAddModal = ({toggleAddEditModal, addPosition, editPosition}) => {
  const [isFetching, setIsFetching] = useState(false)
  const [articles, setArticles] = useState(editPosition ?
    editPosition.responsibilities.map((resp, index) => ({id: Date.now() + index})) : [])
  const workerId = window.location.pathname.slice(window.location.pathname.lastIndexOf('/') + 1)
  const alert = useAlert()
  const alertFunc = (message, type) => {
    alert.show(message, {type: type})
    setIsFetching(false)
  }
  const getArticleValues = () => {
    let articleValues = []
    let objectValues = editPosition ? editPosition.responsibilities.map((r, index) => ({
      [`article${articles[index]?.id}`]: r.text,
    })) : []
    for (let obj of objectValues) {
      articleValues = {...articleValues, ...obj}
    }
    return articleValues
  }
  return (
    <Formik
      autoComplete="off"
      initialValues={{
        numberProfessions: editPosition?.numberProfessions ?? '',
        posada: editPosition?.vacancy ?? '',
        day: editPosition?.day ?? 'weekend',
        selectedSalary: editPosition?.percent ? 'percent' : 'uah',
        salary: editPosition?.salary ? editPosition?.salary.toFixed(2) : '',
        percent: editPosition?.percent ? editPosition?.percent : '',
        ...getArticleValues()
      }}
      onSubmit={(values) => {
        setIsFetching(true)
        let body = {
          vacancy: values.posada,
          title: values.posada,
          day: values.day,
          numberProfessions: values.numberProfessions,
          percent: values.selectedSalary !== 'uah' ? values.percent : null,
          salary: values.selectedSalary === 'uah' ? values.salary : null,
          responsibilities: articles.map((article) => ({
            text: values[`article${article.id}`]
          })),
          id: workerId
        }
        addPosition(body, alertFunc, toggleAddEditModal, editPosition ? editPosition._id : null)
        //toggleAddEditModal()
      }}
    >
      {({values, handleChange, handleSubmit}) => {
        const isValid = values.posada && (values.selectedSalary === 'uah' ? values.salary : values.percent) && articles.length > 0 && values.numberProfessions
        return <div className="add__modal_container">

          <div style={{height: '90%', overflow: 'scroll'}}>
            <div className="modal__header">
              <div className="modal__header__left">
                <div className="table__value__item">
                  <span>Назва посади</span>
                  <InputForm value={values.posada} onChange={handleChange} id={'posada'}
                             style={{width: "298px"}}/>
                </div>
                <div className="table__value__item table__value__item__bottom">
                  <SelectForm value={values.day} onChange={handleChange} id={'day'}
                              style={{width: "298px"}}>
                    {Object.keys(week).map(key => <option key={key.id} value={key}>{week[key].name}</option>)}
                  </SelectForm>
                </div>

                <div className="table__value__item">
                  <span style={{marginLeft: '7px'}}>Код професії</span>
                  <InputForm value={values.numberProfessions}
                             id={'numberProfessions'}
                             onChange={handleChange}
                             type={'number'}
                             style={{width: "107px", marginLeft: "7px"}}/>
                </div>
              </div>

              <img src={closeIcon} onClick={toggleAddEditModal}/>
            </div>
            <div className="modal__header__content">
              <div className="header__content__item">
                <div className="table__value__item">
                  <span>Посадовий оклад </span>
                  <SelectForm value={values.selectedSalary} id={'selectedSalary'} onChange={handleChange}
                              defaultValue={'uah'}>
                    <option value="percent">Відсоток</option>
                    <option value="uah">грн</option>
                  </SelectForm>
                </div>
                <div className="table__value__item">
                  <span style={{marginLeft: '7px'}}>(відсоток від мінімальної ЗП) або (фіксована сума)</span>
                  <InputForm value={values.selectedSalary === 'uah' ? values.salary : values.percent}
                             id={values.selectedSalary === 'uah' ? 'salary' : 'percent'}
                             onChange={handleChange}
                             type={'number'}
                             style={{width: "107px", marginLeft: "7px"}}/>
                </div>
              </div>

              <div
                className="header__content__item"
                style={{flexDirection: "column"}}
              >
                <div className="table__value__item">
                  <span>Посадові обов’язки - буде відображено в трудовому договорі: </span>
                  {articles.map((article, index) => {
                    return <div key={index} className="header__content__text__table">
                      <div className="text__table__left">п.{index + 1}</div>
                      <textarea value={values[`article${article.id}`]} onChange={handleChange}
                                id={`article${article.id}`}
                                className="text__table__content"/>
                      <button onClick={() => setArticles(articles.filter(a => a.id !== article.id))}
                              className="text__table__right">
                        <img src={trash}/>
                      </button>
                    </div>
                  })}
                </div>
              </div>
            </div>
            <div className="modal__footer">
              <button onClick={() => setArticles([...articles, {id: Date.now()}])}>
                <img src={closeIcon}/>
              </button>
            </div>
          </div>
          <div className="modal__footer__green">
            {(isFetching || !isValid) ? <SButtonLightGrey>Зберегти</SButtonLightGrey> :
              <SButtonGreen onClick={handleSubmit}>Зберегти</SButtonGreen>}
          </div>
        </div>
      }}
    </Formik>
  );
};

KadriCardsEditAddModal = connect(null, {addPosition})(KadriCardsEditAddModal)
