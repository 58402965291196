import ReactTooltip from "react-tooltip";
import React from "react";

const TableHeaderCell = props => {

  const {date, day, weekend, holiday} = props

  const getBGColor = () => {
    return weekend ? holiday.length > 0 ? holiday[0]?.status ? 'rgb(185 255 188)' : '#ff3333' : '#e3e3e3' : holiday.length > 0 ? '#ff3333' : 'white'
  }

  const getFontColor = () => {
    return weekend ? holiday.length > 0 ? holiday[0]?.status ? 'black' : 'white' : 'black' : holiday.length > 0 ? 'white' : 'black'
  }

  return (
    <td style={{width: '30px', padding: '5px 0 0 0'}}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: '13px',
        textAlign: 'center',
        cursor: holiday.length > 0 && 'pointer'
      }}
           data-tip data-for={`${holiday.length > 0 ? holiday[0].name : ''}`}
      >
        <div style={{
          width: '1.5rem',
          height: '1.5rem',
          paddingTop: '5px',
          borderRadius: '80px',
          backgroundColor: getBGColor(),
          color: getFontColor()
        }}>
          {date}
        </div>
        <span style={{
          fontFamily: 'Montserrat',
          fontSize: 10,
        }}>{day}</span>
      </div>
      {holiday.length > 0 &&
        <ReactTooltip id={holiday.length > 0 ? holiday[0].name : ''} effect={'solid'} backgroundColor={'#52616E'}>
          {holiday[0].name}
        </ReactTooltip>}
    </td>
  )
}

export default TableHeaderCell
