import DatePicker from "react-datepicker";
import style from "./style.module.css"
import uk from "date-fns/locale/uk";

const DatePickerInline = (props) => {
  return (

    <DatePicker
      locale={uk}
      calendarClassName={style.datepicker__inline}
      {...props}
      showYearDropdown
      yearDropdownItemNumber={1}
    />
  );
}

export default DatePickerInline;