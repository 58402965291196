import {GET_MY_OBJECT} from "../../../constants/urls";
import {LOAD_OBJECT} from "../../types";
import instance from "../../instance";
import showAlert from "../alerts/showAlert";

const loadObjects = (alert = false) => async (dispatch) => {
  try {
    const response = await instance.post(GET_MY_OBJECT)
    dispatch({
      type: LOAD_OBJECT,
      payload: response.data.object
    })
    return response.data.object
  } catch (e) {
    dispatch(showAlert(e.message, 'error'))
  }
}

export default loadObjects
