import React, { useState } from "react";
import {SearchInput, SelectForm} from "../../../../../styledComponents/Inputs";
import {connect} from "react-redux";
import {setAddress} from "../../../../../redux/settings/settings-thunks";
import {Formik} from "formik";

export let KadriCardsSettingsThree = ({settings, setAddress, ...props}) => {
  const [editMode, setEditMode] = useState(false);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  return (
    <Formik
      autoComplete="off"
      initialValues={{
        pdfo: settings.rahunki?.pdfo ?? '',
        military: settings.rahunki?.military ?? '',
        esv: settings.ragunki?.esv ?? ''
      }}
    >
      {({values, handleChange}) => {
        return <div className="settings__container__2">
          <div className="settings__row__header__2">
            <span>Отримувати дані автоматично</span>
            <label className="checkbox-google">
              <input
                type="checkbox"
                checked={!editMode}
                onChange={toggleEditMode}
              />
              <span className="checkbox-google-switch"></span>
            </label>
          </div>
          <div className="settings__row__2">
            <SelectForm>
              <option value="">2020 рік</option>
            </SelectForm>
          </div>
          <div className="settings__row__2">
            <div className="history__container__settings">
              <table className="history__table"
                     style={{fontSize: "13px", width: '528px'}}>
                <tr>
                  <td
                    style={{
                      borderRight: "1px solid #f2f2f2",
                      padding: "10px 5px",
                      fontWeight: "bold",
                      width: '350px'
                    }}
                  >
                    Назва
                  </td>
                  <td style={{fontWeight: "bold"}}>Номер</td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderRight: "1px solid #f2f2f2",
                      padding: "10px 5px",
                    }}
                  >
                    Бюджетний рахунок ПДФО
                  </td>
                  {editMode ? (
                    <td style={{width: '50%'}}>
                      <SearchInput
                        style={{
                          width: '100%',
                          height: "27px",
                          padding: "5px 5px 2px 2px",
                          color: "#000",
                          margin: "0",
                        }}
                        value={values.pdfo}
                        id={'pdfo'}
                        onChange={handleChange}
                        onBlur={(e) => {
                          setAddress({
                            status: 'off',
                            pdfo: e.target.value,
                            military: values.military,
                            esv: values.esv
                          })
                        }}
                      />
                    </td>
                  ) : (
                    <td style={{width: '50%'}}>
                      {values.pdfo}
                    </td>
                  )}
                </tr>
                <tr>
                  <td
                    style={{
                      borderRight: "1px solid #f2f2f2",
                      padding: "10px 5px",
                    }}
                  >
                    Бюджетний рахунок Військовий збір
                  </td>
                  {editMode ? (
                    <td style={{width: '50%'}}>
                      <SearchInput
                        style={{
                          width: '100%',
                          height: "27px",
                          padding: "5px 5px 2px 2px",
                          color: "#000",
                          margin: "0",
                          fontSize: '14px'
                        }}
                        value={values.military}
                        id={'military'}
                        onChange={handleChange}
                        onBlur={(e) => {
                          setAddress({
                            status: 'off',
                            pdfo: values.pdfo,
                            military: e.target.value,
                            esv: values.esv
                          })
                        }}
                      />
                    </td>
                  ) : (
                    <td style={{width: '50%'}}>
                      {values.military}
                    </td>
                  )}
                </tr>
                <tr>
                  <td
                    style={{
                      borderRight: "1px solid #f2f2f2",
                      padding: "10px 5px",
                    }}
                  >
                    Бюджетний рахунок ЄСВ
                  </td>
                  {editMode ? (
                    <td style={{width: '50%'}}>
                      <SearchInput
                        style={{
                          width: '100%',
                          height: "27px",
                          padding: "5px 5px 2px 2px",
                          color: "#000",
                          margin: "0",
                        }}
                        value={values.esv}
                        id={'esv'}
                        onChange={handleChange}
                        onBlur={(e) => {
                          setAddress({
                            status: 'off',
                            pdfo: values.pdfo,
                            military: values.military,
                            esv: e.target.value
                          })
                        }}
                      />
                    </td>
                  ) : (
                    <td style={{width: '50%'}}>
                      {values.esv}
                    </td>
                  )}
                </tr>
              </table>
            </div>
          </div>
        </div>
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settingsReducer.settings
})

KadriCardsSettingsThree = connect(mapStateToProps, {setAddress})(KadriCardsSettingsThree)
