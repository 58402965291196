//libs
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { store } from "../../../index";
import { Formik, useFormik } from "formik";
//components
import Modal from "../modalComponents/Modal/Modal";
import TextInput from "../../Inputs/TextInput/TextInput";
import ModalAcceptButton from "../../buttons/ModalAcceptButton/ModalAcceptbutton";
import Input from "../../Inputs/Input/Input";
//hoooks
import useHttp from "../../../hooks/http.hook";
import { useAlert } from "react-alert";
//consts
import { CHANGE_MY_EMAIL, CHANGE_MY_INFO_URL } from "../../../constants/urls";
//actions
import loadUserData from "../../../redux/actions/loadUserData";
//styles
import "../modalComponents/Modal/Modal.css";

const EnterNewEmail = (props) => {
  const { closeFunc, openModal, setEmail } = props;

  const { post } = useHttp();
  const alert = useAlert();

  const formik = useFormik({
    initialValues: { email: "" },
    onSubmit: async (values) => {
      try {
        const request = await post(CHANGE_MY_EMAIL, { ...values });
        console.log(request);
        if (request.ok) {
          closeFunc();
          setEmail(request.data.email);
          openModal("confirmationCode");
        } else {
          throw new Error(request.data);
        }
      } catch (e) {
        alert.show(e.toString(), { type: "error" });
        console.log(e);
      }
    },
  });

  return (
    <Modal title="Змінити адресу пошти" toggleFunc={closeFunc}>
      <form onSubmit={formik.handleSubmit}>
        <div className="Modal__content">
          <label className="Modal__text">Новий e-mail</label>
          <Input
            id="email"
            name="email"
            type="email"
            placeholder="E=mail"
            onChange={formik.handleChange}
            value={formik.values.email}
          ></Input>
        </div>
        <div className="Modal__button-row">
          <ModalAcceptButton type="submit">Зберегти</ModalAcceptButton>
        </div>
      </form>
    </Modal>
  );
};

export default EnterNewEmail;
