import React from 'react';
import {ID_CARD, OLD_PASSPORT} from "../../../../../../redux/types";
import InputMask from "react-input-mask";
import datePicker from "../../../../../../assets/datePicker.svg";
import Calendar from "react-calendar";
import getDateAndTime from "../../../../../../utilits/getDateAndTime";
import {InputForm} from "../../../../../../styledComponents/Inputs";
import * as countries from "i18n-iso-countries";

const PassportData = ({
                        handleChange,
                        selectedPassport,
                        setSelectedPassport,
                        setFieldValue,
                        passport,
                        dateOfIssue,
                        dateRelease,
                        setDateRelease,
                        authority,
                        nationality,
                        setIsEdited
                      }) => {
  return (
    <div className="table__row" style={{marginBottom: '2px'}}>
      <div style={{paddingTop: 53}} className="table__place">
        <span>Паспортні дані</span>
      </div>
      <div className="table__value">
        <div style={{justifyContent: 'flex-start'}} className="table__value__row">
          <div className="table__choose">
            <button onClick={() => {
              setSelectedPassport(ID_CARD)
              setFieldValue('passport', '')
            }} type={'button'} className={`table__chooseItem ${selectedPassport === ID_CARD && 'selected'}`}>
              ID-картка
            </button>
            <button onClick={() => {
              setSelectedPassport(OLD_PASSPORT)
              setFieldValue('passport', '')
            }} type={'button'} className={`table__chooseItem ${selectedPassport === OLD_PASSPORT && 'selected'}`}>
              Паспорт старого зразка
            </button>
          </div>
        </div>
        <div className="table__value__row">
          <div className="table__value__item">
            <span>{selectedPassport === ID_CARD ? 'Номер ID картки' : 'Серія та номер паспорту'}</span>
            <InputMask
              maskChar={''}
              mask={selectedPassport === ID_CARD ? '999999999' : [/[^а-яё]/i, '999999']}
              style={{width: "368px"}}
              value={passport}
              id={'passport'}
              type={'text'}
              autoComplete={'off'}
              onChange={(e) => {
                if (!(e.target.value.length > 8 && selectedPassport !== ID_CARD)) {
                  handleChange(e)
                  console.log(e.target.value)
                }

              }}
              className={'inputForm'}
              placeholder={''}
            />
          </div>
          <div className="table__value__item null">
            <div className="table__value__item__row">
                <span className="table__value__item__input">
                  <span>Дата видачі </span>
                  <InputMask
                    maskChar={''}
                    mask={'99.99.9999'}
                    value={dateOfIssue}
                    id={'dateOfIssue'}
                    type={'text'}
                    autoComplete={'off'}
                    onChange={handleChange}
                    placeholder={'дд.мм.рррр'}
                    style={{
                      width: "304px",
                      borderTopRightRadius: "0",
                      borderBottomRightRadius: "0",
                    }}
                    className={'inputForm'}
                  />
                </span>
              <div className={'pos-rel'}>
                <button className="item__input__check"
                        type={'button'}
                        style={{paddingTop: "8px", marginBottom: '2px'}}>
                    <span>
                      <img src={datePicker}/>
                    </span>
                </button>
                <div style={{width: '250px', bottom: 40}} className={"table__dropdown__content"}>
                  <Calendar
                    value={dateRelease}
                    onChange={(e) => {
                      setDateRelease(e)
                      setFieldValue('dateOfIssue', getDateAndTime(e, true))
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="table__value__row" style={{marginBottom: '3px'}}>
          <div className="table__value__item">
            <span>Органа, що видав (код)</span>
            <InputForm style={{width: "368px"}}
                       value={authority}
                       onChange={handleChange}
                       id={'authority'}/>
          </div>
          <div className="table__value__item null pos-rel">
            <span>Громадянство</span>
            <InputForm value={nationality}
                       onChange={handleChange}
                       id={'nationality'}
                       style={{width: "354px"}}
                       autoComplete={'off'}
            />
            <div style={{width: '250px'}} className={"table__dropdown__content"}>
              {Object.entries(countries.getNames('uk')).map(([k, v]) => v)
                .map((item, index) => (
                  <button key={index}
                          type={'button'}
                          onClick={() => {
                            setFieldValue('nationality', item)
                            setIsEdited(true)
                          }}
                          className={'submenu__dropdown__btn'}>
                    {item}
                  </button>))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PassportData)