import {SET_CURRENT_OBJECT} from "./object-types";

const initialState = {
  currentEmployer: null
}

export const objectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_OBJECT:
      return {...state, objectOne: action.currentEmployer}
    default:
      return state
  }
}
