import {GET_CONTROLLER_HISTORY} from "../../../constants/urls";

import {LOAD_HISTORY_CONTROLLER} from "../../types";
import instanceController from "../../instanceController";
import showAlert from "../alerts/showAlert";

const loadControllerHistory = (objectId, id) => async (dispatch) => {
  let response
  try {
    response = await instanceController.post(GET_CONTROLLER_HISTORY, {objectId: objectId, id: id})
    dispatch({
      type: LOAD_HISTORY_CONTROLLER,
      payload: response.data
    })
    return response.data
  } catch (e) {
    dispatch(showAlert(e.message, 'error'))
  }
}

export default loadControllerHistory
