import moment from "moment";

export const get_number_vacation = (start_work_date, vacation_list) => {
  const startDate = moment(start_work_date);
  const startOfYear = moment().startOf("year");
  const currentDate = moment();

  const workStartDate = startDate.isBefore(startOfYear)
    ? startOfYear
    : startDate;

  let monthsWorked = currentDate.diff(workStartDate, "months");
  let daysWorkedInCurrentMonth = currentDate.diff(
    workStartDate.clone().add(monthsWorked, "months"),
    "days"
  );

  let vacationDaysEarned = monthsWorked * 2;

  if (daysWorkedInCurrentMonth >= 15) {
    vacationDaysEarned += 1;
  }

  const maxVacationDays = 24;
  vacationDaysEarned = Math.min(vacationDaysEarned, maxVacationDays);

  let vacationDaysUsed = 0;
  let currentVacationDays = 0;

  if (vacation_list.length > 1) {
    vacation_list.forEach((vacation) => {
      const vacationStart = moment(vacation.info.start, "YYYY-MM-DD");
      const vacationEnd = moment(vacation.info.end, "YYYY-MM-DD");

      if (
        vacationStart.isSameOrAfter(startOfYear) &&
        vacationEnd.isSameOrBefore(moment().endOf("year"))
      ) {
        if (vacationEnd.isBefore(currentDate, "day")) {
          vacationDaysUsed += vacationEnd.diff(vacationStart, "days") + 1;
        } else if (
          vacationStart.isSameOrBefore(currentDate, "day") &&
          vacationEnd.isSameOrAfter(currentDate, "day")
        ) {
          currentVacationDays += currentDate.diff(vacationStart, "days") + 1;
        }
      }
    });
  }

  return vacationDaysEarned - vacationDaysUsed - currentVacationDays;
};

export const get_current_employer = (employer_list) => {
  return (
    employer_list.find(
      (record) => "uvalLastDay" in record && record.uvalLastDay !== ""
    ) || employer_list[0]
  );
};
