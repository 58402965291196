import InputWithButton from "../../../Inputs/InputWithButton/InputWithButton"
import './AddEmployerModal.css'
import MiniButton from "../../../common/MiniButton/MiniButton"
import ButtonSearchInput from "../../../buttons/ButtonSearchInput/ButtonSearchInput"
import closeIcon from '../../../../images/icons/close.svg'
import {useFormik} from "formik"
import rnokppValidation from "../../../../validations/rnokppValidation"
import {useState} from "react"
import {useAlert} from "react-alert"
import addEmployer from "../../../../redux/actions/employers/addEmployer"
import {store} from "../../../../index";


const AddEmployerModal = props => {

  const {toggleFunc} = props
  const [loading, setLoading] = useState(false)
  const alert = useAlert()

  const validate = values => {
    const errors = {};
    if (!rnokppValidation(values.rnokpp)) {
      errors.rnokpp = 'Перевірте правильність введеного коду';
    }
    return errors;
  };

  const alertFunc = (message, type) => {
    alert.show(message, {type: type})
  }

  const formik = useFormik({
    initialValues: { rnokpp: '' },
    validate,
    onSubmit: async values => {
      store.dispatch(addEmployer(values, alertFunc, setLoading, toggleFunc, false))
    }
  });

  return (
    <div className='AddEmployerModal'>
      <form onSubmit={!!formik.values.rnokpp && !formik.errors.rnokpp ? formik.handleSubmit : null} className='AddEmployerModal__form'>
        <InputWithButton
          id="rnokpp"
          name="rnokpp"
          type="text"
          placeholder='Вкажіть номер ЄДРПОУ або РНОКПП'
          onChange={formik.handleChange}
          value={formik.values.rnokpp}
          autoFocus={true}
        />
        <ButtonSearchInput
          type='submit'
          disabled={!formik.values.rnokpp || formik.errors.rnokpp || loading}
          loading={loading}
        />
      </form>
      {formik.errors.rnokpp ? <p className='AddEmployerModal__error'>{formik.errors.rnokpp}</p> : null}
      <MiniButton
        icon={closeIcon}
        style={{position: 'absolute', top: '4px', right: '4px'}}
        onClick={toggleFunc}
      />
    </div>
  )
}

export default AddEmployerModal
