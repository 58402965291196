import { EMPLOYER_GETLIST, GET_MY_WORKER_URL } from "../../../constants/urls";
import { getCookie } from "../../../utilits/cookies";
import { LOAD_CURRENT_WORKER, LOAD_EMPLOYERS, LOAD_WORKERS } from "../../types";
import loadCurrentWorker from "../currentWorker/loadCurrentWorker";

const loadWorkers = (id, alert, isSetCurrentWorker) => async (dispatch) => {
  const token = getCookie("token");
  let data;
  try {
    const response = await fetch(EMPLOYER_GETLIST, {
      method: "POST",
      body: JSON.stringify({ _id: id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    data = await response.json();
    if (response.ok) {
      dispatch({
        type: LOAD_WORKERS,
        payload: data.workers || data.employers,
      });

      if (isSetCurrentWorker) {
        dispatch(
          loadCurrentWorker(data.employers[data.employers.length - 1]._id)
        );
      } else {
      }
    } else {
      throw Error(data.message || "Щось пішло не так");
    }
  } catch (e) {
    alert(e.message, "error");
  }
};

export default loadWorkers;
