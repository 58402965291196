import React, {useEffect, useState} from "react";
import {SButtonLightGreen, SButtonLightGrey} from "../../../../styledComponents/Buttons";
import ConditionsHeader from "./CardsWorkDoc/components/ConditionsHeader";
import {Divider} from "../../../../styledComponents/Dividers";

import "./KadriCards.css";
import {useFormik} from "formik";
import {useAlert} from "react-alert";
import DatePickerFragment from "../../../../components/datePicker/datePicker";
import * as Yup from "yup";
import ErrorMessageAll from "../../../../utilits/errorMessageAll";
import {useDispatch, useSelector} from "react-redux";
import {getSettings} from "../../../../redux/settings/settings-thunks";
import CustomAllSelect from "../../../../components/selectCustom/customAllSelect";
import loadCurrentWorker from "../../../../redux/actions/currentWorker/loadCurrentWorker";
import Loader from "../../../../components/loaders/Loader";
import transferPosadaEmployer from "../../../../redux/actions/employers/transferPosadaEmployer";
import {InputForm} from "../../../../styledComponents/Inputs";
import moment from "moment";
import {setModalInfo} from "../../../../redux/modalInfo/modal-actions";
import {deletedDocument} from "../../../../redux/documentOld/document-thunks";


const KadriCardsTransfer = ({
                              toggleTransfer,
                              employerId,
                              workerId,
                              setTypeModal,
                              toggleModalList,
                              setTypeId,
                              setIndexTab,
                              openEditWorkerModal,
                            }) => {
  const positions = useSelector(state => state.cadrDocumentsReducer.positions)
  const modal = useSelector(state => state.modal.info)
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    number: Yup.string().required('Вкажіть номер наказу'),
    posadanew: Yup.object().nullable()
      .required("Виберіть посаду"),
    dateTransfer: Yup.string().nullable().required('Вкажіть дату переведення'),
    dateNakaz: Yup.string().nullable().required('Вкажіть дату наказу'),
    dateStart: Yup.string().nullable().required('Вкажіть дату заяви працівника'),
  });
  useEffect(() => {
    dispatch(loadCurrentWorker(employerId))
    dispatch(getSettings(workerId))
  }, [])
  const settings = useSelector(state => state.settingsReducer.settings)
  const [isFetching, setIsFetching] = useState(false)

  const {
    values,
    handleSubmit,
    setFieldValue,
    handleChange,
    touched,
    errors,
    handleBlur,
    submitForm
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      isEdit: modal?.isEdit,
      employerId: modal?.employerId ?? employerId,
      number: modal?.nomer ?? (settings?.number1 !== -1 ? settings?.number1 + 1 : ''),
      dateTransfer: (modal && new Date(moment(modal?.dateTransfer, "DD.MM.YYYY"))) ?? null,
      dateStart: (modal && new Date(moment(modal?.claimDate, "DD.MM.YYYY"))) ?? null,
      dateNakaz: (modal && new Date(moment(modal?.dateNakaz, "DD.MM.YYYY"))) ?? null,
      posadanew: (modal && positions.map(item => {
        return {
          value: item._id, label: item.vacancy
        }
      }).filter((el) => el.label === modal?.posadanew)[0]) ?? null
    },
    validationSchema,
    onSubmit: async (values) => {
      !isFetching &&
      await dispatch(transferPosadaEmployer(values, setIsFetching, toggleTransfer, workerId)).then(({fileId}) => {
        if (!modal) {
          openEditWorkerModal(employerId)
          setTypeId(fileId);
          setTypeModal("Пакет документів про переведення");
          setIndexTab();
        } else {
          dispatch(deletedDocument(modal.documentId, modal.year))
          dispatch(setModalInfo(null))
        }
      });
    },
  });


  return <>
    {modal && <div className="bg__layer" style={{zIndex: 999}}/>}
    {settings && <div className="conditions__workdoc conditions__transfer">
      <ConditionsHeader toggle={() => {
        toggleTransfer()
        if (modal) {
          dispatch(setModalInfo(null))
        }
      }}>
        Оформлення переведення
      </ConditionsHeader>
      <Divider/>
      <div className="conditions__content">
        <div className="conditions__content-left">
          <p>Нова посада</p>
          <p>Дата заяви працівника</p>
          <p>Дата наказу</p>
          <p>Дата переведення</p>
          <p>Номер наказу</p>
        </div>
        <div className="conditions__content-right">
          <form onSubmit={handleSubmit} className="conditions__form" action="#">
            <div className="conditions__row" style={{maxWidth: 314}}>
              <CustomAllSelect
                placeholder={"Виберіть посаду"}
                disabled={isFetching}
                name="posadanew"
                setFieldValue={setFieldValue}
                defaultValue={(modal && positions.map(item => {
                  return {
                    value: item._id, label: item.vacancy
                  }
                }).filter((el) => el.label === modal?.posadanew)[0]) ?? positions.map((el) => {
                  return {
                    value: el._id, label: el.vacancy
                  }
                })[0]}
                options={positions.map((el) => {
                  return {
                    value: el._id, label: el.vacancy
                  }
                })}
              />
              <ErrorMessageAll name="posadanew" errors={errors} touched={touched}/>
            </div>
            <div className="conditions__row">
              <DatePickerFragment placeholder={"Виберіть дату"} disabled={isFetching} selected={values.dateStart}
                                  dateFormat="dd.MM.yyyy"
                                  onChange={date => setFieldValue("dateStart", date.setHours(12))}/>
              <ErrorMessageAll name="dateStart" errors={errors} touched={touched}/>
            </div>
            <div className="conditions__row">
              <DatePickerFragment placeholder={"Виберіть дату"} disabled={isFetching} selected={values.dateNakaz}
                                  dateFormat="dd.MM.yyyy"
                                  onChange={date => setFieldValue("dateNakaz", date.setHours(12))}/>
              <ErrorMessageAll name="dateNakaz" errors={errors} touched={touched}/>
            </div>
            <div className="conditions__row">
              <DatePickerFragment placeholder={"Виберіть дату"} disabled={isFetching} selected={values.dateTransfer}
                                  dateFormat="dd.MM.yyyy"
                                  onChange={date => setFieldValue("dateTransfer", date.setHours(12))}/>
              <ErrorMessageAll name="dateTransfer" errors={errors} touched={touched}/>
            </div>
            <div className="conditions__row">
              <InputForm disabled={isFetching} name="number" value={values.number}
                         onBlur={handleBlur}
                         placeholder={"Введіть номер наказу"}
                         onChange={handleChange}/>
              <ErrorMessageAll name="number" errors={errors} touched={touched}/>
            </div>
          </form>
        </div>
      </div>
      <div className="conditions__buttons" style={{marginLeft: "auto"}}>
        {isFetching && <Loader/>} <SButtonLightGreen type={'submit'} onClick={submitForm}>Зберегти</SButtonLightGreen>
      </div>
    </div>}
  </>
}

export default KadriCardsTransfer;


