import './TableNote.css'

const TableNote = props => {
    return (
        <div className='TableNote'>
            {props.children}
        </div>
    )
}

export default TableNote