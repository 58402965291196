import React from "react";
import {DividerKadriInfo} from "../../../../styledComponents/Dividers";

import "./KadriCards.css";
import ReactTooltip from "react-tooltip";
import addressPlace from "../../../../utilits/addressPlace";

export let KadriCardsInfo = ({currentEmployer, onEditClick}) => {
  return (
    <div className="kadri__item__content">
      <div className="kadri__item__info">
        <div className="kadri__item__info__header">
          <div className="kadri__item__info__header-text">
            Інформація про роботодавця
          </div>
          <button onClick={onEditClick} className="kadri__item__info__close-btn">
            <div data-tip data-for={'edit'} className="table-icon">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0)">
                  <path
                    d="M14.7674 10.0511C14.5473 10.0511 14.3689 10.2295 14.3689 10.4496V13.9877C14.3681 14.6477 13.8334 15.1825 13.1734 15.1831H1.99241C1.33243 15.1825 0.797744 14.6477 0.796966 13.9877V3.60362C0.797744 2.94379 1.33243 2.40895 1.99241 2.40817H5.53051C5.75061 2.40817 5.92899 2.22979 5.92899 2.00969C5.92899 1.78974 5.75061 1.61121 5.53051 1.61121H1.99241C0.892539 1.61245 0.00124526 2.50374 0 3.60362V13.9878C0.00124526 15.0877 0.892539 15.979 1.99241 15.9802H13.1734C14.2733 15.979 15.1646 15.0877 15.1659 13.9878V10.4496C15.1659 10.2295 14.9875 10.0511 14.7674 10.0511Z"
                    fill="#313131"
                  />
                  <path
                    d="M15.008 0.585896C14.3077 -0.114407 13.1724 -0.114407 12.4721 0.585896L5.36287 7.69508C5.31415 7.7438 5.27897 7.80419 5.26061 7.8705L4.32573 11.2456C4.28728 11.384 4.32635 11.5322 4.42784 11.6338C4.52948 11.7353 4.67767 11.7744 4.81605 11.7361L8.19117 10.8011C8.25748 10.7827 8.31787 10.7475 8.3666 10.6988L15.4756 3.58946C16.1748 2.88869 16.1748 1.75426 15.4756 1.05349L15.008 0.585896ZM6.23113 7.95409L12.0495 2.13562L13.9259 4.01207L8.10743 9.83054L6.23113 7.95409ZM5.85631 8.70623L7.35545 10.2055L5.28178 10.7801L5.85631 8.70623ZM14.9121 3.02598L14.4895 3.44859L12.6129 1.57199L13.0357 1.14938C13.4247 0.760388 14.0554 0.760388 14.4444 1.14938L14.9121 1.61697C15.3005 2.00643 15.3005 2.63668 14.9121 3.02598Z"
                    fill="#313131"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="16" height="16" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <ReactTooltip id={'edit'} effect={'solid'} backgroundColor={'#52616E'}>
              Редагувати
            </ReactTooltip>
          </button>
        </div>
        <DividerKadriInfo style={{height: "1px"}}/>
        <div className="kadri__item__info__content">
          <div className="kadri__item__info__row">
            <p className="kadri__item__info__box-left">
              <span className={'kadri__item__info__box_tab'}>Роботодавець</span>
            </p>
            <p className="kadri__item__info__box-right">
              <span>{currentEmployer.userInfO.full_name}</span>
            </p>
          </div>
          <div className="kadri__item__info__row">
            <p className="kadri__item__info__box-left">
              <span className={'kadri__item__info__box_tab'}>РНОКПП</span>
            </p>
            <p className="kadri__item__info__box-right">
              <span>{currentEmployer.rnokpp}</span>
            </p>
          </div>
          <div className="kadri__item__info__row">
            <p className="kadri__item__info__box-left">
              <span className={'kadri__item__info__box_tab'}>Адреса</span>
            </p>
            <p className="kadri__item__info__box-right">
              <span>{currentEmployer.userInfO.location}</span>
            </p>
          </div>

          {currentEmployer.object && <div className="kadri__item__info__row">
            <p className="kadri__item__info__box-left">
              <span className={'kadri__item__info__box_tab'}>Об’єкт оренди</span>
            </p>
            <p className="kadri__item__info__box-right">
              <span>{addressPlace({...currentEmployer.object.place}, currentEmployer.object.houseNumber).join(", ")}</span>
            </p>
          </div>}
          {/*<div style={{alignItems: 'center'}} className="kadri__item__info__row">*/}
          {/*  <span className={'kadri__item__info__wrapper'}>*/}
          {/*    <img data-tip data-for={'cabinet'} className={'kadri__item__info__icon'} src={questionSvg} alt=""/>*/}
          {/*    <Tooltip clickable={true} id={'cabinet'} effect={'solid'} backgroundColor={'#52616E'}>*/}
          {/*    <ReactPlayer url={video} controls width={320} height={180}/>*/}
          {/*  </Tooltip>*/}
          {/*    <span className="kadri__item__info__box-left">*/}
          {/*      <span style={{paddingLeft: 7}} className={'kadri__item__info__box_tab'}>Е-кабінет токен</span>*/}
          {/*    </span>*/}
          {/*  </span>*/}
          {/*  <p className="kadri__item__info__box-right">*/}
          {/*    <SButtonGrey>+ Додати</SButtonGrey>*/}
          {/*    <span*/}
          {/*      style={{*/}
          {/*        color: "#000F96",*/}
          {/*        fontWeight: "bold",*/}
          {/*        marginRight: "5px",*/}
          {/*      }}*/}
          {/*    >aбо</span> дійсний до 00.00.2020*/}
          {/*  </p>*/}
          {/*</div>*/}
          {/*<div style={{alignItems: 'center'}} className="kadri__item__info__row">*/}
          {/*  <span className={'kadri__item__info__wrapper'}>*/}
          {/*    <img data-tip data-for={'privat24'} className={'kadri__item__info__icon'} src={questionSvg} alt=""/>*/}
          {/*    <Tooltip clickable={true} id={'privat24'} effect={'solid'} backgroundColor={'#52616E'}>*/}
          {/*    <ReactPlayer url={video} controls width={320} height={180}/>*/}
          {/*  </Tooltip>*/}
          {/*  <span className="kadri__item__info__box-left">*/}
          {/*    <span style={{paddingLeft: 7}} className={'kadri__item__info__box_tab'}>Приват24 токен</span>*/}
          {/*  </span>*/}
          {/*    </span>*/}
          {/*  <p className="kadri__item__info__box-right">*/}
          {/*    <SButtonGrey>+ Додати</SButtonGrey>*/}
          {/*    <span*/}
          {/*      style={{*/}
          {/*        color: "#000F96",*/}
          {/*        fontWeight: "bold",*/}
          {/*        marginRight: "5px",*/}
          {/*      }}*/}
          {/*    >aбо</span> дійсний до 00.00.2020*/}
          {/*  </p>*/}
          {/*</div>*/}
          {/*<div className="kadri__item__info__box-right">*/}
          {/*  <p>*/}
          {/*    {currentEmployer.userInfO.full_name}*/}
          {/*    /!*<span className="kadri__item__info__span">Ботвинко</span> Світлана*!/*/}
          {/*    /!*Павлівна*!/*/}
          {/*  </p>*/}
          {/*  <p>{currentEmployer.rnokpp}</p>*/}
          {/*  <p>33000, м. Рівне, вул</p>*/}
          {/*  <p>м. Рівне, вул. Соборна, 275</p>*/}
          {/*  <p>*/}
          {/*    <SButtonGrey>+ Додати</SButtonGrey>*/}
          {/*    <span>*/}
          {/*      <span*/}
          {/*        style={{*/}
          {/*          color: "#000F96",*/}
          {/*          fontWeight: "bold",*/}
          {/*          marginRight: "5px",*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        aбо*/}
          {/*      </span>*/}
          {/*      дійсний до 00.00.2020*/}
          {/*    </span>*/}
          {/*  </p>*/}
          {/*  <p>*/}
          {/*    <SButtonGrey>+ Додати</SButtonGrey>*/}
          {/*    <span>*/}
          {/*      <span style={{ color: "#000F96", fontWeight: "bold" }}>*/}
          {/*        aбо*/}
          {/*      </span>{" "}*/}
          {/*      дійсний до 00.00.2020*/}
          {/*    </span>*/}
          {/*  </p>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

