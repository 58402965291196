import instanceController from "../../instanceController";
import {GET_CONTROLLER_IP} from "../../../constants/urls";
import {LOAD_CONTROLLER_IP} from "../../types";
import showAlert from "../alerts/showAlert";

const loadControllerIp = (ip = true, objectId = null) => async (dispatch) => {
  try {
    const response = await instanceController.post(GET_CONTROLLER_IP)
    dispatch({
      type: LOAD_CONTROLLER_IP,
      payload: response.data
    })
  } catch (e) {
    dispatch(showAlert(e.message, 'error'))
  }
}
export default loadControllerIp
