import styled from "styled-components"

export const StartButton = styled.button`
  height: 36px;
  border-radius: 3px;
  height: 40px;
  font-family: Roboto;
  min-width: 130px;
  font-size: 16px;
  padding: 0 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 19px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #fff;
  margin-right: 10px;
  background: #009640;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: #27ae60;
  }
  &:last-of-type {
      margin-right: 0
  }
`

export const StartRedButton = styled(StartButton)`
    background: #ef5b5b;
    &:hover {
        background: #ef7b7b;
    }
`
export const StartYellowButton = styled(StartButton)`
    background: #F2994A;
    &:hover {
        background: #F2994A;
    }
`