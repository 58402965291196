import instance from "../instance";
import {setAll, setRegion} from "./statistic-actions";
import regions from "../../constants/regions";
import {sumArray, sumKeys} from "../../utilits/sum";

const selectTabResult = (index, region, data, name) => {
  return Object.keys(data).map(el => {
    const fullFirst = data[el].fullFirst === null ? 0 : parseFloat(data[el].fullFirst);
    const full = data[el].full === null ? 0 : parseFloat(data[el].full);
    const difference = (full - fullFirst) / fullFirst * 100;
    const label = [
      "До 18:",
      "18 - 25:",
      "25 - 35:",
      "35 - 45:",
      "45 - 55:",
      "Від 55:",
    ];
    return {
      name: region.length === 1 ? el : regions[el].name,
      worker: data[el].worker,
      dismissed: data[el].dismissed,
      ageRange: sumKeys([data[el]], "ageRange").map((el, i, array) => {
        const percent = parseFloat(
          ((el.value / sumArray(array.map((el) => el.value))) * 100).toFixed(1)
        );
        return {
          name: label[i],
          label: el.value,
          percent: percent,
        };
      }),
      position: data[el].position,
      different: data[el].different,
      staffingAll: data[el].staffingAll,
      dismissedInfoMinMax: data[el].dismissedInfoMinMax,
      sumWorker: data[el].sumWorker,
      dismissedInfo: data[el].dismissedInfo,
      percentStaffingExcess: data[el].percentStaffingExcess === 0 || data[el].percentStaffingExcess === null ? 0 : parseFloat(Math.abs(data[el].percentStaffingExcess).toFixed(2)),
      percentStaffing: data[el].percentStaffing === 0 || data[el].percentStaffing === null || Math.sign(data[el].percentStaffing) === -1 ? 0 : parseFloat(data[el].percentStaffing.toFixed(2)),
      experience: data[el].experience,
      experienceTop: data[el].experienceTop.sort((a, b) => {
        if (a.days > b.days) {
          return -1;
        } else if (a.days < b.days) {
          return 1;
        } else {
          return 0;
        }
      })
        .slice(0, 5),
      employerObject: region.length === 1 ? data[el].experienceTop.sort((a, b) => {
        if (a.days > b.days) {
          return -1;
        } else if (a.days < b.days) {
          return 1;
        } else {
          return 0;
        }
      }) : [],
      average: data[el].average,
      birthdayAge: data[el].birthdayAge,
      positionDismissed: data[el].positionDismissed,
      accept: data[el].accept,
      positionTrainee: data[el].positionTrainee,
      trainee: data[el].trainee,
      positionRangeWork: data[el].positionRangeWork,
      fullFirst: fullFirst,
      full: full,
      difference: fullFirst === 0 ? 0 : parseFloat(difference.toFixed(0)),
      procent: full === 0 ? "0" : parseFloat(data[el].full).toFixed(2)
    }
  })

}

export const allRegion = (region, days, indexTab, name, setLoading) => async (dispatch) => {
  try {
    setLoading(false)
    const response = await instance.post("/statistic/allRegion", {region: region, days: days, name: name})
    const {all, ...data} = response.data;
    const result = selectTabResult(indexTab, region, data, name);
    dispatch(setRegion(result))
    dispatch(setAll(all))
    setLoading(true)
  } catch (e) {
    alert(e.message, 'error')
  }
}