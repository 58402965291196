import React, {useEffect, useState} from "react";
import {MButtonGreenBorder} from "../../../styledComponents/Buttons";

import InputWithButton from "../../../components/Inputs/InputWithButton/InputWithButton";
import ButtonSearchInput from "../../../components/buttons/ButtonSearchInput/ButtonSearchInput";
import rnokppValidation from "../../../validations/rnokppValidation";
import reloadSvg from "../../../assets/reload.svg"
import ButtonReload from "../../../components/buttons/ButtonReload/ButtonReload";
import deleteIcon from "../../../images/icons/delete.svg";
import closeIcon from "../../../images/icons/close.svg";
import addEmployer from "../../../redux/actions/employers/addEmployer";
import {useAlert} from "react-alert";
import {useDispatch} from "react-redux";
import {getCookie} from "../../../utilits/cookies";
import useHttp from "../../../hooks/http.hook";
import {CHANGE_WORKER_URL} from "../../../constants/urls";
import moment from "moment";


const EditRentPartiesAgreement = ({setFieldValue, lesseeValue = [], landlordValue = [], ErrorMessage}) => {
  const [lesseeArray, setLesseeArray] = useState(lesseeValue);
  const [landlordArray, setLandlordArray] = useState(landlordValue);
  const [lesseeList, setLesseeList] = useState([]);
  const [landlordList, setLandlordList] = useState([]);

  const alert = useAlert();

  const addSearch = (e, callback) => {
    e.preventDefault();
    callback(prevState => [...prevState, "list"])
  }
  const deleteList = (e, index, callback) => {
    e && e.preventDefault();
    callback(prevState => prevState.filter((el, i) => i !== index))
  }

  const btnStyle = {
    padding: "5px 10px",
    width: "max-content",
    height: "28px",
    lineHeight: "12px",
    marginRight: 0
  }

  useEffect(() => {
    setFieldValue("landlord", landlordArray)
  }, [landlordArray])

  useEffect(() => {
    setFieldValue("lessee", lesseeArray)
  }, [lesseeArray])

  const rnokppFn = (callbackLessee, callbackLandlord, data, isLessee) => {
    const isCoincidence = lesseeArray.concat(landlordArray).every(items => items.rnokpp !== data.rnokpp)
    if (!isCoincidence) return alert.show("Пользователь уже существует", {type: "error"})
    isLessee ?
      callbackLessee(prevState => [...prevState, data]) :
      callbackLandlord(prevState => [...prevState, data])
  }

  const SearchList = ({arrayList, callback, isLessee}) => {
    const [values, setValues] = useState({rnokpp: ""});
    const dispatch = useDispatch();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false)

    const alertFunc = (message, type) => {
      alert.show(message, {type: type})
    }
    const validate = (values) => {
      if (!rnokppValidation(values)) {
        setError('Перевірте правильність введеного коду')
        return false;
      }
      setError('');
      return true;
    };
    const handleChange = (e) => {
      setValues({rnokpp: e.target.value});
    }
    const forValidate = async (index, callback) => {
      console.log(values.rnokpp)
      if (!validate(values.rnokpp)) return
      if (values.rnokpp.length === 8) {
        await getEmployerData(isLessee)
      } else {
        await getEmployerData(isLessee)
      }

      deleteList(null, index, callback)
    }
    const handleKeyUp = async (e, index, callback) => {
      e.preventDefault();
      if (e.key === "Enter") {
        await forValidate(index, callback)
      }
    }
    const getEmployerData = async (isLessee) => {
      let result = await dispatch(addEmployer(values, alertFunc, setLoading, null, true, isLessee))
      result && rnokppFn(setLesseeArray, setLandlordArray, result, isLessee)
    }
    const handleClick = async (e, index, callback) => {
      e.preventDefault();
      await forValidate(index, callback)
    }

    return <React.Fragment>
      {
        arrayList && arrayList.map((items, index) =>
          <div className="edit_rent__search" key={index}>
            <div className='AddEmployerModal__form'>
              <InputWithButton
                id="rnokpp"
                name="rnokpp"
                type="text"
                placeholder='Вкажіть номер ЄДРПОУ або РНОКПП'
                onInput={(e) => handleChange(e)}
                onKeyUp={(e) => handleKeyUp(e, index, callback)}
                autoFocus={true}
              />
              <ButtonSearchInput
                type='submit'
                onClick={(e) => handleClick(e, index, callback)}
                disabled={loading}
                loading={loading}
              />
            </div>
            {error ? <p className='AddEmployerModal__error'>{error}</p> : null}
            <button onClick={(e) => deleteList(e, index, callback)} className="edit_rent__search-close">
              <img src={closeIcon}/>
            </button>
          </div>)
      }
    </React.Fragment>
  }

  const ContainerList = ({array, setArray, field}) => {

    const deleteContainer = (id) => {
      let result = array.filter(items => items.rnokpp !== id);
      setArray(result)
      setFieldValue(field, result)
    }

    const token = getCookie('token')
    const {loading, request, error, clearError} = useHttp()

    const changePersons = async (rnokpp) => {
      try {
        const data = await request(CHANGE_WORKER_URL, 'POST', {"rnokpp": `${rnokpp}`}, {Authorization: token})
        if(data.message==="Работодатель обновлён"){

        }
        // setArray(result);
      } catch (e) {
        console.log(e)
      } finally {
      }
    }
    return <React.Fragment>
      {
        array && array.map((item, i) => {
            let {code, full_name, status, location, ceo_name, history} = item.userInfO;

            return <div key={i} className="edit_rent__container">
              <ul key={item.code} className="edit_rent__list">
                <li>
                  <div className="edit_rent__list-title">Назва</div>
                  <div className="edit_rent__list-content">{full_name}</div>
                </li>
                <li>
                  <div className="edit_rent__list-title">{code.length === 8 ? "ЄДРПОУ" : "РНОКПП"}</div>
                  <div className="edit_rent__list-content">{code}</div>
                </li>
                <li>
                  <div className="edit_rent__list-title">Адреса</div>
                  <div className="edit_rent__list-content">{location}</div>
                </li>
                <li>
                  <div className="edit_rent__list-title">Стан</div>
                  <div className="edit_rent__list-content">{status}</div>
                </li>
                {ceo_name && < li>
                  < div className="edit_rent__list-title">Керівник</div>
                  <div className="edit_rent__list-content">{ceo_name}</div>
                </li>}
                <li>
                  <div className="edit_rent__list-title">Дані</div>
                  {console.log(item)}
                  <div
                    className="edit_rent__list-content">{history === null ?
                    <React.Fragment>
                      станом на {moment(item.createdAt).format("DD.MM.YYYY")}
                    </React.Fragment> :
                    <React.Fragment>станом на {moment(item.updatedAt).format("DD.MM.YYYY")} <ButtonReload
                      onClick={(e) => {
                        e.preventDefault();
                        changePersons(item.rnokpp)
                      }}
                      className="edit_rent__list-button">
                      <img src={reloadSvg} alt=""/>
                      Оновити
                    </ButtonReload></React.Fragment>}
                  </div>
                </li>
              </ul>
              <button className="edit_rent__container-button" onClick={() => deleteContainer(code)}>
                <img src={deleteIcon}/>
              </button>
            </div>
          }
        )
      }
    </React.Fragment>
  }

  return (
    <div className="edit_rent__wrapper">
      <div className="edit_rent__left">
        <div className="edit_rent__wrapper edit_rent__header">
          Бизнес-партнер
          <MButtonGreenBorder style={btnStyle} onClick={(e) => addSearch(e, setLesseeList)}>
            + Додати
          </MButtonGreenBorder>
        </div>
        <SearchList arrayList={lesseeList} callback={setLesseeList} isLessee={true}/>
        <ContainerList array={lesseeArray} setArray={setLesseeArray} field="lessee"/>
      </div>
      <div className="edit_rent__right" style={{position: "relative"}}>
        <div className="edit_rent__wrapper edit_rent__header">
          Мережа ECONOMCLASS
          <MButtonGreenBorder style={btnStyle} onClick={(e) => addSearch(e, setLandlordList)}>
            + Додати
          </MButtonGreenBorder>
        </div>
        <SearchList arrayList={landlordList} callback={setLandlordList} isLessee={false}/>
        <ContainerList array={landlordArray} setArray={setLandlordArray} field="landlord"/>
        <ErrorMessage name="landlord"/>
      </div>
    </div>
  );
}

export default EditRentPartiesAgreement;
