import {CREATE_OBJECT} from "../../../constants/urls";
import instance from "../../instance";
import showAlert from "../alerts/showAlert";
import loadObjects from "./loadObjects";

const createObjects = (update) => async (dispatch) => {
  try {
    const response = await instance.post(CREATE_OBJECT, update)
    dispatch(loadObjects());
    return response;
  } catch (e) {
    dispatch(showAlert(e.message, 'error'))
  }
}

export default createObjects;
