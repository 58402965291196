import {LOAD_OBJECT} from "../types"

const initialObject = null

export const objectReducer = (state = initialObject, action) => {
  switch (action.type) {
    case LOAD_OBJECT:
      return action.payload
    default:
      return state
  }
}
