import moment from "moment";

export const diffDate = (dateStart, dateEnd, type) => {
  let start = moment(dateStart);
  let end = moment(dateEnd);

  console.log(start, "star");
  console.log(end, "end");
  return end.diff(start, type);
};

export const isInCurrentMonth = (date_list) => {
  const currentMonth = moment().month();
  return date_list.map((date) => date.month() === currentMonth);
};
