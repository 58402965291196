//libs
import React, { useState } from "react";
//images
import mainLogo from "../../images/main-logo.svg";
//hooks
import useHttp from "../../hooks/http.hook";
import { useAuth } from "../../hooks/auth.hook";
import { useHistory } from "react-router";
import { useAlert } from "react-alert";
//constants
import { LOGIN_URL, REGISTRATION_URL } from "../../constants/urls";
//styles
import "./Login.css";
import store from "../../redux/rootReducer";
import loadUserData from "../../redux/actions/loadUserData";
import { LOAD_USER_DATA } from "../../redux/types";
import { setUserData } from "../../redux/user/user-actions";
import { connect } from "react-redux";
import { setCookie } from "../../utilits/cookies";

export let Register = (props) => {
  const firstNameErrors = {
    "Error: Поле firstName не может быть пустым":
      'Поле "имя" не может быть пустым',
  };
  const lastNameErrors = {
    "Error: Поле lastName не может быть пустым":
      'Поле "фамилия" не может быть пустым',
  };
  const emailErrors = {
    "Error: Неправильный email": "Неправильный e-mail",
  };
  const passwordErrors = {
    "Error: Пароль должен содержать минимум 5 символов":
      "Пароль должен содержать минимум 5 символов",
  };
  const alertErrors = {
    "Error: User with this email already exists":
      "Такой пользователь уже существует",
  };

  const alert = useAlert();

  const history = useHistory();
  const { login } = useAuth(); //logout
  const { loading, request, error, clearError, post } = useHttp();
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const registerHandler = async (event) => {
    event.preventDefault();
    try {
      const request = await post(REGISTRATION_URL, form);
      if (request.data.jwtToken) {
        setCookie("user", JSON.stringify(request.data.newUser));
        props.setUserData(request.data.newUser);
        login(request.data.jwtToken);
        alert.show("Вы успешно зарегистрировались и вошли в систему", {
          type: "success",
        });
      } else if (request.ok) {
        throw Error({ message: request.data.message });
      } else {
        throw Error(request.data);
      }
    } catch (e) {
      if (Object.keys(firstNameErrors).includes(e.message)) {
        setFirstNameError(firstNameErrors[e.message]);
        return;
      }
      if (Object.keys(lastNameErrors).includes(e.message)) {
        setLastNameError(lastNameErrors[e.message]);
        return;
      }
      if (Object.keys(emailErrors).includes(e.message)) {
        setEmailError(emailErrors[e.message]);
        return;
      }
      if (Object.keys(passwordErrors).includes(e.message)) {
        setPasswordError(passwordErrors[e.message]);
        return;
      }
      if (Object.keys(alertErrors).includes(e.message)) {
        alert.show(alertErrors[e.message], { type: "error" });
        return;
      }
      alert.show("Щось пішло не так", { type: "error" });
    }
  };

  const changeHandler = (event) => {
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPasswordError("");
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  return (
    <div className="login">
      <div className="login__left">
        <div className="login__logo">
          <img src={mainLogo} alt="logo"></img>
        </div>
      </div>
      <div className="login__right">
        <form onSubmit={registerHandler} className="login__form">
          <input
            type="text"
            name="firstName"
            className={`login__input ${
              !!firstNameError ? "login__input_error" : ""
            }`}
            placeholder="Имя"
            onChange={changeHandler}
          />
          <p className="login__error">{firstNameError}</p>
          <input
            type="text"
            name="lastName"
            className={`login__input ${
              !!lastNameError ? "login__input_error" : ""
            }`}
            placeholder="Фамилия"
            onChange={changeHandler}
          />
          <p className="login__error">{lastNameError}</p>
          <input
            type="text"
            name="email"
            className={`login__input ${
              !!emailError ? "login__input_error" : ""
            }`}
            placeholder="E-mail"
            onChange={changeHandler}
          />
          <p className="login__error">{emailError}</p>
          <input
            type="password"
            name="password"
            className={`login__input ${
              !!passwordError ? "login__input_error" : ""
            }`}
            placeholder="Пароль"
            onChange={changeHandler}
          />
          <p className="login__error">{passwordError}</p>
          <button
            className="login__btn"
            style={{ width: "250px", height: "50px" }}
          >
            <a>Зарегистрироваться</a>
          </button>
        </form>
      </div>
    </div>
  );
};

Register = connect(null, { setUserData })(Register);
