//images
import {useEffect} from 'react'
import closeIcon from '../../../../images/icons/close.svg'
//components
import {Divider} from '../../../../styledComponents/Dividers'
import MiniButton from '../../../common/MiniButton/MiniButton'
import React from 'react'
//styles
import './Modal.css'

const Modal = props => {

  const {title, toggleFunc, width, className, divider = true} = props

  const closeByEsc = event => {
    if (event.key === 'Escape') {
      toggleFunc()
    }
  }
  const closeI = props.closeIcon || closeIcon;

  useEffect((event) => {
      window.addEventListener('keyup', closeByEsc);
      return () => {
        window.removeEventListener('keyup', closeByEsc)
      }
    }
  )

  return (
    <div className={"Modal " + (className || '')} style={{width}}>
      <div className="Modal__header">
        <p>{title}</p>
        <MiniButton
          type='close'
          icon={closeI}
          onClick={toggleFunc}
        />
      </div>
      {divider && <Divider/>}
      {props.children}
    </div>
  )
}

export default Modal