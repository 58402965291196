import { getCookie } from "../utilits/cookies";
import axios from "axios";
import {
  ADD_KADRI_MANDATE,
  ADD_KADRI_VACATION_FUTURE,
  EMPLOYER_GET_INFO,
  EMPLOYER_UPDATE_INFO,
} from "../constants/urls";
import instance from "../redux/instance";

export const getInfoKadrConditions = async (id, workerId) => {
  console.log("🚀 ~ getInfoKadrConditions ~ workerId:", workerId);
  const token = getCookie("token");

  const res = await axios.post(
    EMPLOYER_GET_INFO,
    { id: id, workerId: workerId },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  return res.data;
};

export const saveInfoEmployer = async (id, form) => {
  const token = getCookie("token");
  const res = await axios.post(
    EMPLOYER_UPDATE_INFO,
    { id: id, update: form },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  return res.data;
};

export const addMandate = async (data, setFetch) => {
  setFetch(true);
  const token = getCookie("token");
  const res = await axios.post(
    ADD_KADRI_MANDATE,
    { ...data },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  setFetch(false);
  return res.data;
};
export const addVacationFuture = async (data) => {
  console.log(data, "START");
  const token = getCookie("token");
  const res = await axios.post(
    ADD_KADRI_VACATION_FUTURE,
    { ...data },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  return res.data;
};

export const deletedVacationFuture = async (id) => {
  return await instance.post("/employer/kadr/delldoc", { id });
};
