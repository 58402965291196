import {SET_SETTINGS, SET_ZP_SETTINGS} from "./settings-types";


const initialState = {
  settings: null,
  zpSettings: null
}

export const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SETTINGS: return {...state, settings: action.settings}
    case SET_ZP_SETTINGS: return {...state, zpSettings: action.zpSettings}
    default: return state
  }
}