import {SET_PREVIEW_FILE, SET_SEARCHED_WORKER, SET_WORKER_DOCUMENTS} from "./workers-types";


const initialState = {
  workerDocuments: [],
  previewFile: null,
  searchedWorker: null,
}

export const workersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WORKER_DOCUMENTS: return {...state, workerDocuments: action.workerDocuments}
    case SET_PREVIEW_FILE: return {...state, previewFile: action.previewFile}
    case SET_SEARCHED_WORKER: return {...state, searchedWorker: action.searchedWorker}
    default: return state
  }
}