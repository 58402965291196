import React, {useEffect, useState} from "react";
import IconEngineer from "../assets/icon/engineering.svg"
import IconContractor from "../assets/icon/contractors.svg"
import IconDoc from "../assets/icon/doc.svg"
import {getPersonal, getPersonalEmployerAll} from "../../../api/personal";
import {MButtonGreen, SButtonGrey} from "../../../styledComponents/Buttons";
import {InputForm} from "../../../styledComponents/Inputs";
import {useFormik} from "formik";
import ErrorMessageAll from "../../../utilits/errorMessageAll";
import CustomAllSelect from "../../../components/selectCustom/customAllSelect";
import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {getPositions} from "../../../redux/cadrDocuments/cadrDocuments-thunks";
import trash from "../../../assets/trash.svg";


const EdiRentStatistic = ({id, landlordValue, docPersonal, setFieldValue, update, positionList}) => {
  const [personalCount, setPersonalCount] = useState(null);
  const [workCount, setWorkCount] = useState(null);

  const dispatch = useDispatch();


  const positions = useSelector(state => state.cadrDocumentsReducer.positions)
  useEffect(async () => {
    const res = await getPersonalEmployerAll(landlordValue);
    setWorkCount(res.employers.length);
    dispatch(getPositions(landlordValue[0]._id))
    await getPersonal(id).then(data => {
      setPersonalCount(data.length)
    })
  }, [])

  const {
    values,
    touched,
    errors,
    setFieldValue: newSet,
    submitForm
  } = useFormik({
    initialValues: {
      positionList: positionList ? positionList : [],
    },
    onSubmit: (values) => {
      setFieldValue("position", values.positionList)
    },
  });


  return <div className="statistic__container">
    <MButtonGreen className="state__button" onClick={(e) => {
      e.preventDefault();
      submitForm();
      update();
    }} type="submit">Зберегти</MButtonGreen>
    <div className="state">
      <h4>Штатний розпис</h4>
      <SButtonGrey onClick={(e) => {
        e.preventDefault();
        newSet("positionList", [...values.positionList, {
          position: null,
          quality: 0
        }])
      }}>+ Додати</SButtonGrey>
      <form action="#" method="post"
            className="state__form">

        {values.positionList.map((el, i) => <div key={i} className="state__column">
          <div className='state__row' style={{minWidth: "300px", maxWidth: 300}}>
            <label htmlFor='region'>Назва посади</label>
            <div className='edit_rent__wrapper'>
              <CustomAllSelect
                placeholder={"Виберіть посаду"}
                name="namePosition"
                isChange={false}
                changeFn={(data) => {
                  newSet("positionList", values.positionList.map((el, k) => {
                    if (k === i) {
                      return {
                        position: data,
                        quality: 0
                      }
                    } else {
                      return el
                    }
                  }))
                }}
                defaultValue={positionList ? el.position : positions.map((el) => {
                  return {
                    value: el._id, label: el.vacancy
                  }
                })[0]}
                options={positions.map((el) => {
                  return {
                    value: el._id, label: el.vacancy
                  }
                })}
              />
              <ErrorMessageAll name="namePosition" touched={touched} errors={errors}/>
            </div>
          </div>
          <div className='state__row'>
            <label htmlFor='region'>Кількість</label>
            <div className='edit_rent__wrapper'>
              <InputForm value={el.quality} style={{
                width: 60,
                textAlign: "center"
              }} placeholder="Введіть кількість" name='quality' type='number'
                         onChange={(data) => {
                           newSet("positionList", values.positionList.map((item, k) => {
                             if (k === i) {
                               return {
                                 position: item.position,
                                 quality: parseInt(data.target.value)
                               }
                             } else {
                               return item
                             }
                           }))
                         }}/>
              <ErrorMessageAll name="code" touched={touched} errors={errors}/>
            </div>
          </div>
          <div
            className="quota__row__table__trash"
            style={{width: "32px", height: "45px"}}
          >
            <button onClick={(e) => {
              e.preventDefault();
              newSet("positionList", values.positionList.filter((item, j) => i !== j))
            }
            }>
              <img src={trash}/>
            </button>
          </div>
        </div>)}

      </form>
    </div>
    <div className="statistic__row">
      <div className="statistic__box statistic__box-flex">
        <div className="statistic__img">
          <img src={IconEngineer} alt=""/>
        </div>
        <div className="statistic__info">
          <p>Працівників</p>
          <span>{workCount ? workCount : 0}</span>
        </div>
      </div>
      <div className="statistic__box statistic__box-flex">
        <div className="statistic__img">
          <img src={IconContractor} alt=""/>
        </div>
        <div className="statistic__info">
          <p>Підрядників</p>
          <span>{personalCount ? personalCount : 0}</span>
        </div>
      </div>
      <div className="statistic__box statistic__box-flex">
        <div className="statistic__img">
          <img src={IconDoc} alt=""/>
        </div>
        <div className="statistic__info">
          <p>Створено договорів
            підряду за рік</p>
          <span>{docPersonal ? docPersonal.count : 0}</span>
        </div>
      </div>
    </div>
  </div>
}

export default EdiRentStatistic;
