import React from "react";
import moment from "moment";

const TableTd = ({
  arrayHoliday,
  currentYear,
  currentMonth,
  hours,
  index,
  onChange,
  value,
}) => {
  let date = new Date(
    `${currentYear}-${currentMonth.toString().padStart(2, "0")}-${(index + 1)
      .toString()
      .padStart(2, "0")}`
  );
  let day = date.getUTCDay();
  let isHoliday = arrayHoliday.filter((h) => {
    return moment(new Date(h.date), "DD-MM-YYYY").isSame(
      moment(date, "DD-MM-YYYY")
    );
  });
  let holidayLength = isHoliday.length !== 0;
  let isWeekend = day === 0 || day === 6;

  const holidayStatus = (array, today, postInfo) => {
    let status =
      postInfo.format === "основне"
        ? "8"
        : !!postInfo.hours
        ? postInfo.hours
        : "4";

    let minutes = postInfo.format === "основне" ? 8 * 60 : postInfo.hours * 60;
    for (let j = 0; j < array.length; j++) {
      if (moment(array[j].date).isSame(moment(today).add(1, "days"))) {
        if (postInfo.format !== "основне") {
          status = !!postInfo.hours ? postInfo.hours - 1 : 4 - 1;
          minutes = (postInfo.hours - 1) * 60;
        } else {
          status = "7";
          minutes = 7 * 60;
        }
      }
      if (moment(array[j].date).isSame(moment(today))) {
        status = "СВ";
      }
    }
    return { status: status };
  };

  const checkWeekend = (params) => {
    if (isWeekend) {
      if (holidayLength) {
        if (!isHoliday[0]?.status) {
          return "СВ";
        } else {
          return "ВХ";
        }
      }
      return "ВХ";
    } else {
      if (holidayLength) {
        return "СВ";
      }
      return "";
    }
  };

  const span_value = () => {
    if (value.length === 0) {
      return checkWeekend();
    } else {
      if (hours !== "8") {
        if (isWeekend) return checkWeekend();
        return hours;
      }

      return value;
    }
  };

  return (
    <td
      className="table__sm"
      style={{
        backgroundColor: isWeekend
          ? holidayLength
            ? isHoliday[0]?.status
              ? "rgb(185 255 188)"
              : "#FFE1E1"
            : "#F0F0F0"
          : holidayLength
          ? "#FFE1E1"
          : "",
        color: isWeekend
          ? holidayLength
            ? "#FF0000"
            : "#828282"
          : holidayLength
          ? "#FF0000"
          : "",
      }}
    >
      <span
        style={{ display: (isWeekend || holidayLength) && "block" }}
        className={"table__workDay"}
      >
        {span_value()}
      </span>
      <select value={value} onChange={onChange} className="workTime__select">
        <option value="" hidden selected></option>
        <option value={hours}>{hours} - звичайний роб. день</option>
        <option value="7">7 - скорочений роб. день</option>
        <option value="ВД">ВД - основна відпустка</option>
        <option value="Н">Н - додаткова відпустка у зв'язку з навчанням</option>
        <option value="ВП">
          ВП - відпустка у зв'язку з вагітністю та пологами
        </option>
        <option value="ВПД">
          ВПД - відпустка для догляду за дитиною до досягнення нею трирічного
          віку
        </option>
        <option value="НА">НА - відпустка без оплати</option>
        <option value="НН">НН - лікарняний (непрацездатність)</option>
        <option value="ВХ">ВХ - вихідний</option>
        <option value="СВ">СВ - святковий день</option>
        <option value="ПР">ПР - прогул</option>
        {/*<option value="">...</option>*/}
      </select>
    </td>
  );
};

export default TableTd;
