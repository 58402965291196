import Input from "../Input/Input";
import styled from "styled-components";

const InputWithButton = styled(Input)`
    height: 34px;
    width: 277px;
    margin: 1px;
    border-width: 2px;
    border-color: #EFEFEF;
    border-radius: 5px 0 0 5px;
    &:hover{
        border-width: 2px;
    }
    &:focus{
        width: 279px;
        border-width: 3px;
    }
`

export default InputWithButton  