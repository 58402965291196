import instance from "../../instance";
import {BACK_EVENT} from "../../../constants/urls";
import loadWorkers from "../workers/loadWorkers";

const backEvent = (data, workerId) => async (dispatch) => {
  const response = await instance.post(BACK_EVENT, data)
  dispatch(loadWorkers(workerId, alert))
  return response.data
}

export default backEvent;