import React, {useEffect, useRef} from "react";
import {useState} from "react";
import DatePickerFragment from "../../../components/datePicker/datePicker";
import moment from "moment";
import {InputForm} from "../../../styledComponents/Inputs";
import SwitchButton from "react-switch";
import {addRealMonth} from "../../../utilits/addRealMonth";
import {diffDate} from "../../../utilits/diffDate";
import {updateMonthProlongation} from "../../../api/objects";

const EditRentWashContract = ({
                                id,
                                setFieldValue,
                                dateStartValue = null,
                                dateEndValue = null,
                                monthValue,
                                ErrorMessage,
                                prolongationValue = false
                              }) => {
  const [disabled, setDisabled] = useState(true);
  const [dateStart, setStartDate] = useState(dateStartValue && new Date(moment(dateStartValue).format("YYYY MM DD")))
  const [dateEnd, setFinishDate] = useState(dateEndValue && new Date(moment(dateEndValue).format("YYYY MM DD")))
  const startMonth = useRef(diffDate(dateStartValue, dateEndValue, 'month'))
  const dayTreaty = diffDate(new Date(), dateEnd, 'days');
  const changeStartDate = (date) => {
    if (!date) return
    setDisabled(false)
    let dateFormat = new Date(moment(date).format("YYYY MM DD"))
    setStartDate(dateFormat);
    setFieldValue("dateStart", dateFormat)
    if (dateEnd) {
      setFieldValue("month", diffDate(dateFormat, dateEnd, 'month'))
    }
  }
  const changeFinishDate = (date) => {
    if (!date) return
    let dateFormat = new Date(moment(date).format("YYYY MM DD"))
    setFinishDate(dateFormat);
    setFieldValue("month", diffDate(dateStart, dateFormat, 'month'))
    setFieldValue("dateEnd", dateFormat)
  }

  useEffect(() => {
    setFieldValue("month", startMonth.current)
  }, [])

  const handleOnBlur = () => {
    setFieldValue("dateEnd", dateEnd)
  }

  const handleChange = (e) => {
    e.preventDefault();
    let addMonth
    if (e.target.value >= 99999) {
      console.log(parseInt(e.target.value))
      return setFieldValue("month", parseInt(e.target.value))
    }
    if (!dateStart) {
      let dateFormat = new Date()
      addMonth = new Date(addRealMonth(moment(dateStartValue), (e.target.value)).format("YYYY MM DD"))
      setStartDate(dateFormat);
      setFinishDate(addMonth);
      setFieldValue("dateStart", dateFormat)
    } else {
      addMonth = new Date(addRealMonth(moment(dateStartValue), (e.target.value)).format("YYYY MM DD"))
      setFinishDate(addMonth);
    }
    setFieldValue("month", parseInt(e.target.value))
  }

  const handleSwitch = async (data) => {
    if (data) {
      if (dayTreaty <= 0 && id) {
        await updateMonthProlongation(id).then(res => {
          let dateFormat = new Date(moment(res).format("YYYY MM DD"))
          setFinishDate(dateFormat);
          setFieldValue("dateEnd", dateFormat)
        })
      }
      if (!id) {
        setFinishDate(dateEnd);
        setFieldValue("dateEnd", dateEnd)
      }
      setFieldValue("prolongation", true)
    } else {
      setFieldValue("prolongation", false)
      setFieldValue("monthHistory", startMonth.current)
    }


  }
  useEffect(() => {
    if (dateStart) {
      setDisabled(false)
    }
  }, [])

  return (
    <div className="edit_rent__condition-container">
      <div className="edit_rent__column">
        <label htmlFor="dateStart">Дата укладення</label>
        <div className="edit_rent__wrapper">
          <DatePickerFragment selected={dateStart} dateFormat="dd/MM/yyyy" onChange={date => changeStartDate(date)}/>
          <ErrorMessage name="dateStart"/>
        </div>
      </div>
      <div className="edit_rent__column">
        <label htmlFor='month'>Строк оренди</label>
        <InputForm disabled={disabled} value={monthValue} placeholder="Кількість місяців" type="number"
                   onBlur={handleOnBlur}
                   onChange={handleChange}
                   name='month'/>
      </div>
      <div className="edit_rent__column">
        <label htmlFor="dateStart">Договір діє до</label>
        <div className="edit_rent__wrapper">
          <DatePickerFragment selected={dateEnd} dateFormat="dd/MM/yyyy" onChange={date => changeFinishDate(date)}/>
          <ErrorMessage name="dateEnd"/>
        </div>
      </div>
      <div className="edit_rent__column edit_rent__condition-prolongation">
        <label htmlFor="prolongation">
          Пролонгація
          (продовження строку
          дії договору)
        </label>

        <SwitchButton checkedIcon={false} onColor="#AEDEC2"
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.15)"
                      activeBoxShadow="0px 2px 4px rgba(0, 0, 0, 0.15)"
                      height={16}
                      width={40}
                      handleDiameter={25}
                      onHandleColor="#6FCF97"
                      uncheckedIcon={false} onChange={handleSwitch}
                      checked={prolongationValue}/>
      </div>
    </div>
  );
}

export default EditRentWashContract;
