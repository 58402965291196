import './DropdownButton.css'

const DropdownButton = props => {

    const {icon, onClick,disabled} = props

    return (
        <button className='DropdownButton' onClick={onClick} disabled={disabled}>
            {icon && <img src={icon} style={{marginRight: '12px'}}></img>}
            <span>{props.children}</span>
        </button>
    )
}

export default DropdownButton
