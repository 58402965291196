import styled from "styled-components";

export const Popup = styled.div`
  width: 400px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 10px;
  position: fixed;
  top: 0;
  left: 0;
  background: #FFFFFF;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  p{
    width: 260px;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    color: #535353;
    margin: 15px 0;
  }
  span {
    font-weight: bold;
  }
`;
