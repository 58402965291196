import React, {useEffect, useState} from "react";
import "./EditUser.css";

import Modal from '../../components/modals/modalComponents/Modal/Modal';
import {MButtonGreen, MButtonGreenBorder} from "../../styledComponents/Buttons"
import {InputForm, SelectForm} from "../../styledComponents/Inputs"
import regions from '../../constants/regions';
import rolesTL from '../../constants/roles';
import userTypes from '../../constants/userTypes';
import {useFormik} from 'formik';
import close from "../../assets/close.svg";
import SelectCustom from "../../components/selectCustom";
import {useDispatch} from "react-redux";
import loadUserData from "../../redux/actions/loadUserData";
import {getCookie} from "../../utilits/cookies";
import InputMask from "react-input-mask";

const RoleSelector = (props) => {
  const {roles = [], name, onChange} = props;
  const roleMap = {};
  roles.forEach((role) => roleMap[role] = true);

  const toggleRole = (event) => {
    const role = event.target.dataset.role;
    roleMap[role] = !roleMap[role];
    const value = Object.keys(rolesTL).filter((role) => roleMap[role]);
    onChange({target: {name, value}});
  };

  return (<div className='edit_user__roles'>
    {Object.keys(rolesTL).map((role) =>
      <label key={role}>
        <input
          data-role={role} className='green__checkbox'
          type='checkbox' checked={roles.includes(role)}
          onChange={toggleRole}
        />
        {rolesTL[role]}
      </label>)}
  </div>)
};

const UserTypeSelect = (props) => (
  <SelectForm {...props}>
    <option disabled>Виберіть зі списку</option>
    {Object.keys(userTypes).map((key) =>
      <option key={key} value={key}>{userTypes[key].name}</option>)}
  </SelectForm>
);
const token = getCookie("token")
const EditUser = (props) => {
  const {setEditUser, saveUser, userData} = props
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const {values, handleSubmit, handleChange, setValues, setFieldValue} = useFormik({
    initialValues: userData,
    onSubmit: async () => {

      if (values.telephone !== "" || values.email !== "") {
        setError(false)
        values.telephone = (values.telephone || "").replace(/[^0-9]/g, "");
        await saveUser(values);
        await dispatch(loadUserData(token))
      }
    },
  });
  useEffect(() => {
    if (values.email === "" && values.telephone === "") {
      setError(true)
    }else{
      setError(false)
    }
  }, [values])
  React.useEffect(() => {
    setValues(userData);
  }, [userData]);

  const toggleModal = () => {
    setEditUser(null)
  };
  const handleChangeRegion = (data) => {
    setFieldValue("region", data.map(item => item.value
    ))
  }

  const requirePassword = !values?._id;
  return (
    <Modal title='Дані користувача' closeIcon={close} className="edit_user__modal" toggleFunc={toggleModal} width={600}>
      <form action="#" method="post" className='edit_user__form'
            onSubmit={handleSubmit} autoComplete='off'>
        <div className='edit_user__row'>
          <label htmlFor='lastName'>Прізвище</label>
          <InputForm value={values.lastName} name='lastName' required onChange={handleChange}/>
        </div>
        <div className='edit_user__row'>
          <label htmlFor='firstName'>Ім'я</label>
          <InputForm value={values.firstName} name='firstName' required onChange={handleChange}/>
        </div>
        <div className='edit_user__row'>
          <label htmlFor='middleName'>По батькові</label>
          <InputForm value={values.middleName} name='middleName' onChange={handleChange}/>
        </div>
        <div className='edit_user__row'>
          <label htmlFor='telephone'>Номер телефону</label>
          <InputMask mask="(999) 999-99-99" value={values.telephone} name='telephone' className={`telephone ${error ? "error" : ""}`}
                     type='tel'
                     onChange={handleChange}/>
          <p>{error && "Номер телефону або емейл має бути заповнено"}</p>
        </div>
        <div className='edit_user__row'>
          <label htmlFor='email'>Email</label>
          <InputForm value={values.email} name='email' type='email' className={error && "error"}
                     onChange={handleChange}/>
        </div>
        <div className='edit_user__row'>
          <label htmlFor='password'>Пароль</label>
          <InputForm value={values.password} name='password' onChange={handleChange}
                     type='password' required={requirePassword}
                     placeholder={requirePassword ? 'введіть пароль' : 'залишіть пустим щоб не міняти'}/>
        </div>
        <div className='edit_user__row'>
          <label htmlFor='userType'>Тип</label>
          <UserTypeSelect value={values.userType} name='userType' onChange={handleChange}/>
        </div>
        <div className='edit_user__row'>
          <label htmlFor='region'>Регіон</label>
          <SelectCustom options={Object.keys(regions).map((key) => {
            return {
              value: key,
              label: regions[key].name
            }
          })} value={values.region && typeof (values.region) === "string" ?
            [{
              value: values.region,
              label: regions[values.region].name
            }]
            : values.region.map(item => {
              return {
                value: item,
                label: regions[item].name
              }
            })} placeholder="Виберіть регіон" onChange={handleChangeRegion}/>
        </div>
        <div className='edit_user__row'>
          <label>Доступ</label>
          <RoleSelector required roles={values.roles} name='roles' onChange={handleChange}/>
        </div>
        <div className='edit_user__row edit_user__footer'>
          <MButtonGreenBorder onClick={() => setEditUser(null)}>
            Скасувати
          </MButtonGreenBorder>
          <MButtonGreen type="submit">Зберегти</MButtonGreen>
        </div>
      </form>

    </Modal>
  );
};

export default EditUser
