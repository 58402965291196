import instance from "../../../redux/instance";

export const get_worker = async (filter_data) => {
  const searchParams = new URLSearchParams(filter_data);
  const { data } = await instance.get(
    `/user/get_reference_user?${searchParams}`
  );

  return data;
};
