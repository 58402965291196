import {DELETED_CONTROLLER_VISITOR} from "../../../../constants/urls";
import instanceController from "../../../instanceController";
import showAlert from "../../alerts/showAlert";
import loadControllerVisitor from "./load";


const deletedControllerVisitor = (id, objectId) => async (dispatch) => {
  try {
    const response = await instanceController.post(DELETED_CONTROLLER_VISITOR, {id: id, objectId: objectId})
    dispatch(loadControllerVisitor(objectId));
    dispatch(showAlert("Гостя видалено", 'success'))
    return response;
  } catch (e) {
    dispatch(showAlert(e.message, 'error'))
  }
}

export default deletedControllerVisitor
