import {UPDATE_OBJECT} from "../../../constants/urls";
import instance from "../../instance";
import showAlert from "../alerts/showAlert";
import loadObjects from "./loadObjects";

const updateObjects = (id, update) => async (dispatch) => {
  try {
    const response = await instance.post(UPDATE_OBJECT, {id, update})
    dispatch(loadObjects());
    return response;
  } catch (e) {
    dispatch(showAlert(e.message, 'error'))
  }
}

export default updateObjects
