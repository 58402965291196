import instanceController from "../../instanceController";
import showAlert from "../alerts/showAlert";
import {LOAD_INFO_CONTROLLER} from "../../types";
import {GET_INFO_CONTROLLER} from "../../../constants/urls";

const loadInfoController = (id, setLoader) => async (dispatch) => {

  try {
    const response = await instanceController.post(GET_INFO_CONTROLLER, {objectId: id})
    dispatch({
      type: LOAD_INFO_CONTROLLER,
      payload: response.data
    })
    setLoader(true)
    return response.data
  } catch (e) {
    setLoader(true)
    dispatch({
      type: LOAD_INFO_CONTROLLER,
      payload: null
    })
    showAlert(e.message, 'error')
  }
}

export default loadInfoController
