import React, {useCallback, useEffect, useState} from 'react';
import getDateAndTime from "../../../../../../utilits/getDateAndTime";
import edit from "../../../../../../assets/edit.svg";
import tash from "../../../../../../assets/trash.svg";
import instance from "../../../../../../redux/instance";
import {EMPLOYER_GET_DATA} from "../../../../../../constants/urls";
import {downloadDocument} from "../../../../../../redux/workers/workers-thunks";
import {connect, useDispatch, useSelector} from "react-redux";
import {docTypes} from "../../../../../../redux/types";
import moment from "moment";
import {deletedDocument, viewDocument} from "../../../../../../redux/documentOld/document-thunks";
import {setModalInfo} from "../../../../../../redux/modalInfo/modal-actions";

const event = {
  "Наказ про переведення": "transfer",
  "Наказ про надання відпуски": "holiday",
  "закінчення строку": "work__3",
  "угода сторін": "work__2",
  "ініціатива працівника": "work",
  "Строковий": "work__6",
  "Не строковий": "work__5"
}
const JournalTd = ({
                     doc,
                     index,
                     setDocument,
                     getNumber,
                     getDate,
                     downloadDocument,
                     toggleAddModal,
                     year,
                     toggleModalList,
                   }) => {
  const dispatch = useDispatch();


  const editDocument = (info, employerId, documentId) => {
    if (info.typeName === "Звільнення") {
      info.typeName = info.typeDismissal;
    }
    if (info.hasOwnProperty("typeString")) {
      info.typeName = info.typeString
    }
    if (info.typeName !== "Наказ") {
      toggleModalList[event[info.typeName]]()
    } else {
      toggleAddModal();
    }
    dispatch(setModalInfo({
      ...info,
      isEdit: true,
      year: year,
      toggle: "journalModal",
      employerId: employerId,
      documentId: documentId
    }))
  }
  const title = doc.info.typeName === "Наказ" ? `${doc.info.nameMandate}` :
    `${doc.info.typeName} ${doc?.infoEmployer && `(${doc.info?.posada?.name ?? (doc.postInfo?.posada??doc.postInfo?.status)})${doc.info?.strokeDays ? ` (Строком на ${doc.info?.strokeDays} днів)` : ""} - ${[doc.infoEmployer.surname, doc.infoEmployer.name, doc.infoEmployer.patronymic].filter(el => !!el).join(" ")}`}`;
  return (
    <>
      <tr
        className="table-row">

        <td>{index + 1}</td>
        <td>{getNumber}</td>
        <td>вiд {moment(getDate).format("DD.MM.YYYY")}</td>
        <td>
          <p className="hover-underline"
             onClick={async () => {
               const res = await dispatch(viewDocument(doc.id, doc.path, doc.filename))
               let link = document.createElement("a");
               link.target = "_blank";
               link.href = res.generateUrl;
               link.click();
               link.remove();
             }}
             style={{
               width: "max-content",
               color: "#2F80ED", cursor: 'pointer',
             }}>
            {title}</p>
        </td>
        <td style={{textAlign: "center"}}>
          {(doc.info.hasOwnProperty("typeDismissal") || doc.info.hasOwnProperty("daysRange") || doc.info.hasOwnProperty("typeText") || doc.info.hasOwnProperty("dateTransfer") || doc.info.hasOwnProperty("nameMandate")) &&
            <button className="MiniButton " style={{display: "inherit"}} onClick={(e) => {
              e.preventDefault();
              editDocument({...doc.info}, doc.indexId, doc._id)
            }}>
              <img src={edit} alt=""/>
            </button>}
        </td>
        <td style={{textAlign: "center"}}>
          <button className="MiniButton " style={{display: "inherit"}} onClick={(e) => {
            e.preventDefault();
            setDocument({
              deletedId: doc._id,
              year: year,
              name: title
            })

          }}>
            <img src={tash} alt=""/>
          </button>
        </td>
      </tr>
    </>
  );
};

export default connect(null, {downloadDocument})(JournalTd)