import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const customStyles = {
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    paddingLeft: 12,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: 140,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  option: (provided) => ({
    ...provided,
    padding: "0 12px",
    fontSize: "12px",
  }),
  multiValue: (provided) => ({
    ...provided,
    margin: 2,
    height: 24,
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    "div:nth-child(1)": {
      display: "flex",
      alignItems: "center",
      padding: "0 3px",
      height: 24,
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "12px",
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    height: 24,
    padding: 0,
    input: {
      height: 24,
    },
  }),
  control: (provided) => ({
    ...provided,
    minHeight: 20,
  }),
  container: (provided) => ({
    ...provided,
    position: "relative",
    width: "66.6%",
    display: "inline-block",
  }),
};
const animatedComponents = makeAnimated();
const SelectCustom = (props) => {
  return (
    <Select
      closeMenuOnSelect={false}
      {...props}
      isMulti
      components={{
        animatedComponents,
        IndicatorSeparator: false,
      }}
      styles={customStyles}
    />
  );
};

export const SelectCustomOne = (props) => {
  delete customStyles.multiValue;

  return (
    <Select
      closeMenuOnSelect={true}
      {...props}
      components={{
        animatedComponents,
        IndicatorSeparator: false,
      }}
      styles={customStyles}
    />
  );
};
export default SelectCustom;
