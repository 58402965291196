import {UPDATE_CONTROLLER} from "../../../constants/urls";
import showAlert from "../alerts/showAlert";
import loadController from "./loadController";
import instanceController from "../../instanceController";

const updateController = (id, update) => async (dispatch) => {
  try {
    const response = await instanceController.post(UPDATE_CONTROLLER, {id, update})
    dispatch(loadController());
    return response;
  } catch (e) {
    dispatch(showAlert(e.message, 'error'))
  }
}

export default updateController
