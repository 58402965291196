import style from "../assets/style/controller.module.css";
import Close from "../../../images/icons/close.svg";
import fingerBig from "../assets/icon/finger-big.svg";
import { MButtonGray } from "../../../styledComponents/Buttons";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import updateControllerVisitor from "../../../redux/actions/controller/visitor/update";
import createdControllerVisitor from "../../../redux/actions/controller/visitor/add";
import loadControllerIp from "../../../redux/actions/controller/loadControllerIp";
import { useAlert } from "react-alert";
import CustomController from "../../../components/selectCustom/customController";
import updateFingerUser from "../../../redux/actions/controller/updateFingerUser";
import scanFinger from "../../../redux/actions/controller/scanFinger";
import FingerAnimation from "../../../components/fingerAnimation";

const defaultVisitor = {
  name: "",
  comment: "",
  location: [],
  finger: null,
};
const ControllerAddUser = ({
  setIsModalAdd,
  role,
  model,
  objectId,
  visitorId,
  userList,
  setVisitorId,
}) => {
  const visitor = useSelector((state) => state.controller.visitor);
  const [visitorData, setVisitorData] = useState(
    visitor && visitorId && role !== "employer"
      ? visitor
          .filter((el) => el._id === visitorId)
          .map((item) => {
            const { _id, name, location, stuFingerPrintInfoEx } = item;
            return {
              _id: _id,
              name: name,
              comment: item.postInfo.status,
              role: "visitor",
              location: location,
              finger: stuFingerPrintInfoEx,
            };
          })[0]
      : {
          ...defaultVisitor,
          _id: visitorId,
          finger:
            userList && userList.length >= 1
              ? userList
                  .filter((el) => el._id === visitorId)
                  .map((el) => el.stuFingerPrintInfoEx)[0]
              : null,
        }
  );

  const list = useSelector((state) => state.controller.listIp);
  const alert = useAlert();
  const dispatch = useDispatch();
  const saveVisitor = useCallback(
    async (object) => {
      try {
        const { _id, ...update } = object;
        let response;
        if (_id) {
          if (role !== "employer") {
            response = await dispatch(
              updateControllerVisitor(_id, update, objectId)
            );
            if (response.data.success) {
              alert.show("Гостя оновленно", { type: "success" });
            }
          } else {
            response = await dispatch(updateFingerUser(_id, update, objectId));
            if (response.data.success) {
              alert.show("Відбиток додано", { type: "success" });
            }
          }
        } else {
          response = await dispatch(
            createdControllerVisitor(update, objectId, role)
          );
          if (response.data.success) {
            alert.show("Гостя додано", { type: "success" });
          }
        }

        if (!response.data.success) {
          alert.show(response.data.message || "Щось не так", { type: "error" });
          return { status: false };
        }

        setVisitorData(null);
        setIsModalAdd(true);
        return { status: true };
      } catch (error) {
        alert.show(error.message, { type: "error" });
        return { status: false };
      }
    },
    [dispatch]
  );

  const validationSchema =
    role === "employer"
      ? Yup.object().shape({
          finger: Yup.object().nullable(),
        })
      : Yup.object().shape({
          name: Yup.string().required("Вкажіть імя"),
          comment: Yup.string(),
          location: Yup.array().required("Вкажіть локацію"),
          finger: Yup.object().nullable(),
        });

  const { values, handleSubmit, errors, touched, handleChange, setFieldValue } =
    useFormik({
      initialValues: visitorData,
      validationSchema,
      onSubmit: async (values) => await saveVisitor(values),
    });

  useEffect(() => {
    dispatch(loadControllerIp(true, objectId));
    return () => setVisitorId(null);
  }, []);
  const [loading, setLoading] = useState(false);
  console.log(model);
  const handleScanFinger = (e) => {
    e.preventDefault();
    dispatch(scanFinger(setLoading, objectId, visitorId)).then((res) => {
      console.log(res);
      if (res) {
        console.log(res);
        if (model !== "ASC1202B-S") {
          console.log(res.data, "NEW CONTROLLER SCANNER");
          setFieldValue("finger", {
            ...res.data.result.resultFinger,
            nCount: res.data.result.resultFinger.nPacketNum,
          });
        } else if (res && res.data.result?.resultFinger) {
          setFieldValue("finger", res.data.result.resultFinger);
        }
      }
    });
  };
  const ErrorMessage = ({ name }) => {
    return errors[name] && touched[name] ? (
      <div className="error">{errors[name]}</div>
    ) : null;
  };
  return loading ? (
    <FingerAnimation />
  ) : (
    <form
      action="#"
      method="post"
      onSubmit={handleSubmit}
      autoComplete="off"
      className={style.modal__controller__add}
    >
      <div className={style.modal__controller__header}>
        <button
          className={style.controller__box__container}
          style={{ padding: 0 }}
          onClick={() => setIsModalAdd(true)}
        >
          <img src={Close} alt="" />
        </button>
      </div>
      {role !== "employer" && (
        <div className={style.modal__controller__row}>
          <p>Ім’я</p>
          <div className={style.modal__controller__field}>
            <input
              type="text"
              value={values.name}
              name="name"
              onChange={handleChange}
            />
            <ErrorMessage name="name" />
          </div>
        </div>
      )}
      {role !== "employer" && (
        <div className={style.modal__controller__row}>
          <p>Коментар</p>
          <input
            type="text"
            value={values.comment}
            name="comment"
            onChange={handleChange}
          />
          <ErrorMessage name="comment" />
        </div>
      )}
      <div className={style.modal__controller__row}>
        <p>Відбитки</p>
        <div className="modal__controller__finger">
          {values?.finger && values?.finger.nCount > 0 && (
            <ul className={style.modal__controller__finger__list}>
              {Array(values.finger.nCount)
                .fill()
                .map(() => (
                  <li className={style.modal__controller__finger__item}>
                    <div className={style.modal__controller__finger__box}>
                      <img src={fingerBig} alt="" />
                    </div>
                  </li>
                ))}
            </ul>
          )}
          <MButtonGray
            style={{ marginLeft: 0 }}
            onClick={(e) => handleScanFinger(e)}
          >
            <span>+ Додати відбиток</span>
          </MButtonGray>
        </div>
      </div>
      {role !== "employer" && (
        <div className={style.modal__controller__row}>
          <p>Локації</p>
          {list && (
            <CustomController
              objectId={objectId}
              setFieldValue={setFieldValue}
              list={list}
            />
          )}
          <ErrorMessage name="location" />
        </div>
      )}
      <MButtonGray style={{ width: "100%", marginLeft: 0 }}>
        <span>Зберегти</span>
      </MButtonGray>
    </form>
  );
};

export default ControllerAddUser;
