import close from "../../../assets/close.svg";
import Modal from "../../../components/modals/modalComponents/Modal/Modal";
import {useFormik} from "formik";
import * as Yup from "yup";
import {InputForm} from "../../../styledComponents/Inputs";
import React, {useEffect, useState} from "react";
import {MButtonGreen, MButtonRed} from "../../../styledComponents/Buttons";
import deleteIcon from "../../../images/icons/delete.svg";
import deletedController from "../../../redux/actions/controller/deletedController";
import {useDispatch, useSelector} from "react-redux";
import getObjectListController from "../../../redux/actions/controller/getObjectController";
import addressPlace from "../../../utilits/addressPlace";
import matches from "../../../utilits/matches";
import loadInfoController from "../../../redux/actions/controller/loadInfoController";
import getAuthController from "../../../redux/actions/controller/getAuthController";
import showAlert from "../../../redux/actions/alerts/showAlert";

const validationSchema = Yup.object().shape({
  ip: Yup.string().required('Вкажіть ip'),
  port: Yup.string().required('Вкажіть порт'),
  login: Yup.string().required('Вкажіть логін'),
  password: Yup.string().required('Вкажіть пароль'),
  location: Yup.string().required('Вкажіть локацію')
});

const ControllerAdd = ({setEditController, controllerData, saveController}) => {

  const list = useSelector(state => state.controller.listObject)
  const dispatch = useDispatch();
  const [data, setData] = useState(null)
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    dispatch(getObjectListController())
  }, [])

  const toggleModal = () => {
    setEditController(false)
  }
  const {
    values,
    handleSubmit,
    errors,
    touched, handleChange, setFieldValue,
  } = useFormik({
    initialValues: controllerData,
    validationSchema,
    onSubmit: async (values) => {
      dispatch(getAuthController(values)).then(async res => {
        if (res.data.success) {
          await saveController(values)
        }else{
          dispatch(showAlert("Не вдалося знайти контролер перевірте правильність даних", 'error'))
        }
      })
    }
  });

  const handleChangeListObject = (event) => {
    let {value} = event.target;
    if (value.length > 0) {
      setIsShow(true)
      setData(list.filter((item) => [(item) => item.address].some((field) => matches(value, field(item)))))
    } else {
      setIsShow(false)
    }
    setFieldValue("location", value)
  }
  const clickSelectObject = (id, event) => {
    setFieldValue("objectId", id)
    setFieldValue("location", event.target.textContent)
    setIsShow(false)
  }
  const ErrorMessage = ({name}) => {
    return errors[name] && touched[name] ? <div className="error">{errors[name]}</div> : null
  }
  return <Modal title='Дані контролера' closeIcon={close} className="controller__modal" divider={false} style={{
    overflowY: "hidden"
  }} toggleFunc={toggleModal}>
    <form action="#" method="post"
          onSubmit={handleSubmit} autoComplete='off' className="controller__container">
      <div className='controller__column'>
        <label htmlFor='ip'>IP</label>
        <div className='controller__wrapper'>
          <InputForm value={values.ip} name='ip' type='text' onChange={handleChange}/>
          <ErrorMessage name="ip"/>
        </div>
      </div>
      <div className='controller__column'>
        <label htmlFor='port'>Порт</label>
        <div className='controller__wrapper'>
          <InputForm value={values.port} name='port' type='text' onChange={handleChange}/>
          <ErrorMessage name="port"/>
        </div>
      </div>
      <div className='controller__column' style={{flexBasis: "100%"}}>
        <label htmlFor='location'>Адрес</label>
        <div className='controller__wrapper'>
          <InputForm value={values.location} name='location' type='text' onChange={handleChangeListObject}/>
          <ErrorMessage name="location"/>
          {isShow && <ul className="controller__column__list">
            {data && data.map(el => <li
              onClick={(e) => clickSelectObject(el._id, e)}>{!el.place.street ? "вулиця відсутня" : `${addressPlace({
              city: el.place.city,
              street: el.place.street,
              street_number: el.place.street_number,
              isNameCity: false
            }, el.houseNumber).join(", ").replace("будинок ", "")}`}</li>)}
          </ul>}
        </div>
      </div>
      <div className='controller__column'>
        <label htmlFor='login'>Логин</label>
        <div className='controller__wrapper'>
          <InputForm value={values.login} name='login' type='text' onChange={handleChange}/>
          <ErrorMessage name="login"/>
        </div>
      </div>
      <div className='controller__column'>
        <label htmlFor='password'>Пароль</label>
        <div className='controller__wrapper'>
          <InputForm value={values.password} name='password' type='text' onChange={handleChange}/>
          <ErrorMessage name="password"/>
        </div>
      </div>
      <div className="controller__bottom">
        {controllerData.updatedAt && <MButtonRed style={{marginLeft: "auto", marginRight: 4}} onClick={(e) => {
          e.preventDefault();
          dispatch(deletedController(controllerData.objectId))
          toggleModal()
        }
        }> <img src={deleteIcon}/></MButtonRed>}
        <MButtonGreen style={{marginLeft: 0}} type="submit">Зберегти</MButtonGreen>
      </div>
    </form>
  </Modal>
}
export default ControllerAdd
