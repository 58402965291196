import React, {useEffect, useState} from "react";
import {SButtonLightGreen, SButtonLightGrey} from "../../../../../styledComponents/Buttons";
import clockIcon from "../../../icons/clock-grey.svg";
import ConditionsHeader from "../CardsWorkDoc/components/ConditionsHeader";
import {Divider} from "../../../../../styledComponents/Dividers";
import InputMask from 'react-input-mask'

import "../KadriCards.css";
import {Formik} from "formik";
import {connect} from "react-redux";
import {acceptTruancy} from "../../../../../redux/workers/workers-thunks";
import {InputForm} from "../../../../../styledComponents/Inputs";
import {useAlert} from "react-alert";
import datePicker from "../../../../../assets/datePicker.svg";
import Calendar from "react-calendar";
import getDateAndTime from "../../../../../utilits/getDateAndTime";
import {getSettings} from "../../../../../redux/settings/settings-thunks";

export let KadriCardsTruancy = ({
                                  toggleTruancy,
                                  setTypeId,
                                  acceptTruancy,
                                  workerId,
                                  openEditWorkerModal,
                                  setTypeModal,
                                  setIndexTab,
                                  employerId,
                                  ...props
                                }) => {
  const [beginDate, setBeginDate] = useState(null)
  const [isFetching, setIsFetching] = useState(false)
  const alert = useAlert()
  const alertFunc = (message, type) => {
    alert.show(message, {type: type})
    setIsFetching(false)
  }
  const id = window.location.pathname.slice(window.location.pathname.lastIndexOf('/') + 1)
  useEffect(() => {
    props.getSettings(id)
  }, [])
  return (
    <>
      {props.settings && <Formik
        initialValues={{
          date: '',
          beginTime: '',
          endTime: '',
          number: props.settings?.number1 !== -1 ? props.settings?.number1 + 1 : ''
        }}
        onSubmit={(values) => {
          setIsFetching(true)
          acceptTruancy({
            id: employerId,
            workerId: workerId,
            date: values.date,
            type: 'part time',
            number: values.number,
            time: {
              from: values.beginTime,
              to: values.endTime
            }
          }, alertFunc, toggleTruancy).then((fileId) => {
            openEditWorkerModal(employerId)
            setTypeId(fileId);
            setTypeModal("Пакет документів про прогул");
            setIndexTab();
          })
        }}
      >
        {({values, handleChange, handleSubmit, setFieldValue, ...formikProps}) => {
          let isValid = values.date && values.beginTime && values.endTime && values.number
          return <div className="conditions__workdoc">
            <ConditionsHeader toggle={toggleTruancy}>
              Оформлення прогулу
            </ConditionsHeader>
            <Divider/>
            <div className="conditions__content">
              <div className="conditions__content-left">
                <p>Дата відсутності</p>
                <p>Період відсутності</p>
                <p>Наказ №</p>
              </div>
              <div className="conditions__content-right">
                <div className="conditions__form">
                  <div className="conditions__row">
                    <InputMask
                      mask={'99.99.9999'}
                      maskChar={''}
                      id={'date'}
                      value={values.date}
                      onChange={handleChange}
                      type="text"
                      className="conditions__time-input"
                    />
                    <div className={'pos-rel'}>
                      <button className="item__input__check"
                              type={'button'}
                              style={{paddingTop: "8px", marginBottom: '2px'}}>
                    <span>
                      <img src={datePicker}/>
                    </span>
                      </button>
                      <div style={{width: '250px'}} className={"table__dropdown__content"}>
                        <Calendar
                          value={beginDate}
                          onChange={(e) => {
                            setBeginDate(e)
                            setFieldValue('date', getDateAndTime(e, true))
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="conditions__row">
                    <InputMask
                      mask={'99:99'}
                      maskChar={''}
                      id={'beginTime'}
                      value={values.beginTime}
                      onChange={handleChange}
                      type={'text'}
                      className="conditions__time-input"
                      placeholder="Початок"
                    />
                    <button type={'button'} className="conditions__input-button">
                      <img src={clockIcon} alt="" className="conditions__clock-icon--1"/>
                    </button>
                    <p> - </p>
                    <InputMask
                      mask={'99:99'}
                      maskChar={''}
                      id={'endTime'}
                      value={values.endTime}
                      onChange={handleChange}
                      type="text"
                      className="conditions__time-input"
                      placeholder="Закінчення"
                    />
                    <button type={'button'} className="conditions__input-button">
                      <img src={clockIcon} alt="" className="conditions__clock-icon--1"/>
                    </button>
                  </div>
                  <div className="conditions__row">
                    <InputForm
                      value={values.number}
                      onChange={handleChange}
                      id={'number'}
                      style={{
                        backgroundColor: props.settings?.number1 !== -1 ? 'rgb(242, 242, 242)' : ''
                      }}
                      disabled={props.settings?.number1 !== -1}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="conditions__buttons">
              {(!isFetching && isValid) ? <SButtonLightGreen onClick={handleSubmit}>Зберегти</SButtonLightGreen>
                : <SButtonLightGrey>Зберегти</SButtonLightGrey>}
            </div>
          </div>
        }}
      </Formik>}
    </>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settingsReducer.settings
})

KadriCardsTruancy = connect(mapStateToProps, {acceptTruancy, getSettings})(KadriCardsTruancy)
