import PlacesAutocomplete from "../../../components/googlePlaceInput/googlePlaceInput";
import {InputForm, SelectForm} from "../../../styledComponents/Inputs";
import React, {useEffect} from "react";
import regions from "../../../constants/regions";
import typeObject from "../../../constants/typeObject";

const RegionSelect = (props) => (
  <SelectForm {...props}>
    <option value="">Виберіть зі списку</option>
    {Object.keys(regions).map((key) =>
      <option key={key} value={key}>{regions[key].name}</option>)}
  </SelectForm>
);

const TypeObjectSelect = (props) => (
  <SelectForm {...props}>
    <option value="">Виберіть зі списку</option>
    {Object.keys(typeObject).map((key) =>
      <option key={key} value={key}>{typeObject[key].name}</option>)}
  </SelectForm>
);

const EditRentInfo =
  ({
     handleChange, values, handleBlur,
     setFieldValue, ErrorMessage, errors, setValid, touched
   }) => {
    useEffect(() => {
      const result = Object.keys(errors).filter(items => items !== "dateStart" && items !== "dateEnd" && items !== "landlord" && items)
      result.length === 0 ? setValid(true) : setValid(false)
    }, [errors])


    return (
      <React.Fragment>
        <div className='edit_rent__column edit_rent__select'>
          <label htmlFor='region'>Регіон</label>
          <div className='edit_rent__wrapper'>
            <RegionSelect value={values.region} className="select-custom" name='region' onChange={handleChange}/>
            <ErrorMessage name="region"/>
          </div>
        </div>
        <div className='edit_rent__column edit_rent__select'>
          <label htmlFor='typeObject'>Тип об’єкта</label>
          <div className='edit_rent__wrapper'>
            <TypeObjectSelect value={values.typeObject} className="select-custom" name='typeObject'
                              onChange={handleChange}/>
            <ErrorMessage name="typeObject"/>
          </div>
        </div>
        <div className='edit_rent__column'>
          <label htmlFor='region'>Код об’єкта</label>
          <div className='edit_rent__wrapper'>
            <InputForm value={values.code} className={errors.code && touched.code ? "errorBox" : ""}
                       placeholder="Введіть код об'єкту"
                       name='code' type='text' onBlur={handleBlur} onChange={handleChange}/>
            <ErrorMessage name="code"/>
          </div>
        </div>
        <div className='edit_rent__column'>
          <label htmlFor='email'>E-mail об’єкта</label>
          <div className='edit_rent__wrapper'>
            <InputForm value={values.email} className={errors.email && touched.email ? "errorBox" : ""} name='email' type='email'
                       onChange={handleChange}/>
            <ErrorMessage name="email"/>
          </div>
        </div>
        <div className="edit_rent__address">
          <div className='edit_rent__address-wrapper'>
            <label htmlFor='address'>Адреса об’єкта</label>
            <div className='edit_rent__wrapper'>
              <PlacesAutocomplete placeholder="Введіть назву вулиці"
                                  values={values}
                                  isObject={true}
                                  name="address"
                                  setFieldValue={setFieldValue}
                                  place={values.place} handleBlur={handleBlur}/>
            </div>
          </div>
          <div className='edit_rent__wrapper edit_rent__home'>
            <label htmlFor='houseNumber'>Номер будівлі</label>
            <div className='edit_rent__wrapper'>
              <InputForm value={values.houseNumber} className={errors.houseNumber && touched.houseNumber ? "errorBox" : ""}
                         name='houseNumber' onChange={handleChange} onBlur={handleBlur}/>
              <ErrorMessage name="houseNumber"/>
            </div>
          </div>
        </div>
      </React.Fragment>)
  }

export default EditRentInfo;
